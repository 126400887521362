import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { OrganisationService } from '../../firebase-services/organisation.service';

// Interfaces
import { Organisation } from '../../interfaces/organisation';
import { KioskProductVariantReference, KioskReferenceType, ProductCategory } from '../../interfaces/kiosk';

@Component({
    selector: 'app-membership',
    templateUrl: './membership.component.html',
    styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    organisation: Organisation;
    settingsChangedInfo = 0;
    disableSaveSettings = true;

    // Payment vars
    productCategory: ProductCategory = ProductCategory.ORGANISATION_MEMBERSHIP;
    referenceType: KioskReferenceType = KioskReferenceType.ORGANISATION;

    bundleWidth: KioskProductVariantReference[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private organisationService: OrganisationService
    ) {
        this.bundleWidth = [{
            productKey: 'premium',
            variantKey: 'yearly',
            name: 'Yearly Premium Membership',
            quantity: 1
        }];

        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.organisation = null;
        this.disableSaveSettings = true;
        this.getOrganisation(this.route.snapshot.paramMap.get('organisationKey'));
    }

    private getOrganisation(organisationKey: string) {
        this.organisationService.getOrganisation(organisationKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((organisation) => this.organisation = organisation);
    }

    settingsChanged() {
        this.disableSaveSettings = false;
    }

    saveSettings() {
        this.disableSaveSettings = true;
        this.organisationService.updateName(this.organisation)
            .then(() => this.settingsChangedInfo++)
            .catch((err) => console.error('Settings-Update error occurred:', err.message));
    }

    onKioskProductCreated(kioskProductKey: string): Promise<void> {
        this.organisation.membershipProductKey = kioskProductKey;
        return this.organisationService.updateKioskProduct(this.organisation);
    }
}
