import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trail-area-story-archive',
    templateUrl: './trail-area-story-archive.component.html',
    styleUrls: ['./trail-area-story-archive.component.css']
})
export class TrailAreaStoryArchiveComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
