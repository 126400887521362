import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-news-room-archive',
    templateUrl: './news-room-archive.component.html',
    styleUrls: ['./news-room-archive.component.css']
})
export class NewsRoomArchiveComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
