<div class="content-wrapper">
    <app-content-header-section contentPageId="trailsImport" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div *ngIf="!parseDone && (existingTrailAreas && existingTrails)" class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title">Select File With Trails</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="importInput">Trail file - (Esri-JSON format)</label>
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="btn btn-default btn-file">
                                            Browse… <!--suppress TypeScriptUnresolvedVariable -->
                                            <input type="file" (change)="onImportFileSelected($event.target.files)" id="importInput">
                                        </span>
                                    </span>
                                    <input id="filename" aria-describedby="filenameHelp" type="text" class="form-control" readonly>
                                    <!--								<span class="input-group-append">
                                                                        <button [disabled]="disableUploadFile" class="btn btn-info" (click)="onImportFileUpload()">Upload</button>
                                                                    </span>-->
                                </div>
                                <small class="form-text text-muted" id="filenameHelp">Choose a local Esri-JSON-file with trail-paths.</small>
                            </div>

                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" id="showDisqualified" class="custom-control-input"
                                           aria-describedby="showDisqualifiedHelp">
                                    <label class="custom-control-label" for="showDisqualified">Show disqualified trails (RED-)</label>
                                </div>
                                <small class="form-text text-muted" id="showDisqualifiedHelp">Also show all the trails in the file, that was
                                    disqualified for import.</small>
                            </div>

                        </div>
                        <div class="card-footer">
                            Notice: Download Mountainbike-Routen in Tirol <a
                                href="https://www.data.gv.at/katalog/dataset/land-tirol_mountainbikeroutenintirol" target="_blank">here</a>
                        </div>
                    </div>

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title">Select File With Trails</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="importInputGEO">Trail file - (GEO-JSON format)</label>
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="btn btn-default btn-file">
                                            Browse… <!--suppress TypeScriptUnresolvedVariable -->
                                            <input type="file" (change)="onImportGeoJSONFileSelected($event.target.files)" id="importInputGEO">
                                        </span>
                                    </span>
                                    <input id="filenameGeoJSON'" aria-describedby="filenameHelp" type="text" class="form-control" readonly>
                                    <!--								<span class="input-group-append">
                                                                        <button [disabled]="disableUploadFile" class="btn btn-info" (click)="onImportFileUpload()">Upload</button>
                                                                    </span>-->
                                </div>
                                <small class="form-text text-muted" id="filenameHelp1">Choose a local Esri-JSON-file with trail-paths.</small>
                            </div>

                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" id="showDisqualifiedGeoJson" class="custom-control-input"
                                           aria-describedby="showDisqualifiedHelp">
                                    <label class="custom-control-label" for="showDisqualifiedGeoJson">Show disqualified trails (RED-)</label>
                                </div>
                                <small class="form-text text-muted" id="showDisqualifiedHelp2">Also show all the trails in the file, that was
                                    disqualified for import.</small>
                            </div>

                        </div>
                        <!-- <div class="card-footer">
                            Notice: Download Mountainbike-Routen in Tirol <a
                                href="https://www.data.gv.at/katalog/dataset/land-tirol_mountainbikeroutenintirol" target="_blank">here</a>
                        </div> -->
                    </div>

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title">Post import data processing</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <button class="btn btn-primary" id="analyze_imported_trails"
                                        (click)="mergeTrailAreasByCityName()">Analyze
                                </button>
                                <small class="form-text text-muted" id="filenameHelp2">Analyse which trail areas are eligible for merging and prepare data for changes. Open console to see report.</small>
                                <button class="btn btn-primary" id="process_imported_trails"
                                        (click)="postProcessImportedData()">Process
                                </button>
                                <small class="form-text text-muted" id="filenameHelp3">Start the post processing of imported data. This will merge trail areas by city name. Currently set up to process GB data only.</small>
                                <button class="btn btn-primary" id="make_imported_trails_searchable"
                                        (click)="ensureTrailAreaHasMapPoint()">Update
                                </button>
                                <small class="form-text text-muted" id="filenameHelp4"><!--Make imported trails searchable by updating elasticsearch. Currently set up to process GB data only.-->Ensure that new trail areas have mapPointsWorld entry. Currently set up to process GB data only. You can also set this button to ensure trails are searchable by switching methods in code.</small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="parseDone" class="row">
                <div *ngFor="let trailArea of trailAreas; let i = index">
                    <div *ngIf="!displayMapFor || displayMapFor.importKey === trailArea.importKey" class="col-12">

                        <div class="card card-outline card-info">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i *ngIf="trailArea.key === null" class="text-yellow fa fa-plus"></i>
                                    <i *ngIf="trailArea.key !== null" class="text-green far fa-map"></i>
                                    {{ trailArea.name }} <small>Trail Area in {{ trailArea.region }}</small>
                                </h3>
                            </div>
                            <div class="card-body">
                                <table class="table row-bordered">
                                    <tbody>
                                    <tr *ngFor="let trail of trails[i]">
                                        <td>
                                            <i *ngIf="trail.key === 'noImport'" class="text-red fa fa-minus"
                                               title="Trail not accepted - ask Frederik"></i>
                                            <i *ngIf="trail.key !== null && trail.key !== 'noImport'" class="text-green fa fa-check"
                                               title="{{trail.key}}"></i>
                                            <i *ngIf="trail.key === null" class="text-yellow fa fa-plus"></i>
                                            <input type="checkbox" (change)="trail.active = !trail.active" [checked]="trail.active">
                                        </td>
                                        <td>{{ trail.name}}</td>
                                        <td>{{ trail.polyline.length}}</td>
                                        <td>{{trail.color}}</td>
                                        <td [title]="trail.iconUrl">{{trail.iconUrl| slice:0:20}}...</td>
<!--                                        <td>{{trail.heightDecrease}}</td>-->
<!--                                        <td>{{trail.maxHeight}}</td>-->
<!--                                        <td>{{trail.minHeight}}</td>-->
<!--                                        <td>{{trail.estimatedTime}}</td>-->
                                        <td [title]="trail.description">{{trail.description| slice:0:100}}...</td>
                                    </tr>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <th style="width: 44px"><i class="fa fa-check"></i></th>
<!--                                        <th>OID</th>-->
                                        <th>Name</th>
                                        <th title="Number of Waypoint in trail path">#WP</th>
                                        <th>Color</th>
                                        <th>iconUrl</th>
<!--                                        <th>HeightInc</th>-->
<!--                                        <th>HeightDec</th>-->
<!--                                        <th>Max Height</th>-->
<!--                                        <th>Min Height</th>-->
<!--                                        <th>Time</th>-->
                                        <th>Description (clipped)</th>
                                    </tr>
                                    </thead>
                                </table>

                                <div class="gmap" id="{{trailArea.importKey}}"></div>
                                <div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button *ngIf="!displayMapFor" (click)="showMap(trailArea.importKey)" class="btn btn-info">Show map</button>
                                <div *ngIf="displayMapFor && (displayMapFor.importKey === trailArea.importKey)">
                                    <button class="btn btn-info" (click)="displayMapFor = null">Hide map</button>
                                    <button class="btn btn-danger"
                                            (click)="importTrails(trailArea)">Upload selected YELLOW+ trails
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
<!--                <div *ngIf="displayMapFor" class="col-12">-->

<!--                    <div class="card card-outline card-info">-->
<!--                        <div class="card-header">-->
<!--                            <h3 class="card-title">Map of {{ displayMapFor.name }}</h3>-->
<!--                        </div>-->
<!--                        <div class="card-body">-->
<!--                            <div class="form-group">-->
<!--                                <div id="map"></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
                <button class="btn btn-danger"
                        (click)="importAllTrails()">Upload all selected trails
                </button>
            </div>
        </div>
    </section>

</div>
