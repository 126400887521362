<div class="content-wrapper">
    <app-content-header-section *ngIf="rtwEvent" contentPageId="editRtwEvent"
                                [pageParams]="{ rtwEventName: rtwEvent.title }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-xl-4">

                    <div *ngIf="rtwEvent" [ngClass]="'card card-outline card-'+layout.getTheme('rtwEvent')">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getIcon('rtwEvent')"></i> Ride the World Event Settings</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ settingsChangedInfo }}">
                                <h4>RtW event settings saved successfully</h4>
                            </app-alert-info-box>

                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="rtwEvent.active"
                                           [disabled]="!rtwEvent.endTime || !rtwEvent.startTime || !rtwEvent.imageUrl || !rtwEvent.title || !rtwEvent.feedEntryDescription || !rtwEvent.feedEntryTitle || !rtwEvent.heroKey || !rtwEvent.heroImageUrl || !rtwEvent.heroName"
                                           (change)="settingsChanged()" id="activeCB" aria-describedby="activeCBHelp" class="custom-control-input"
                                           type="checkbox">
                                    <label class="custom-control-label" for="activeCB">Active</label>
                                </div>
                                <small class="form-text text-muted" id="activeCBHelp">Whether the RtW-event is active or not.</small>
                                <small *ngIf="!rtwEvent.endTime || !rtwEvent.startTime" class="form-text text-danger"><i
                                        class="fas fa-exclamation-triangle"></i> Please set event period.</small>
                                <small *ngIf="!rtwEvent.imageUrl" class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i> Please
                                    upload an image for the event.</small>
                                <small *ngIf="!rtwEvent.title || !rtwEvent.feedEntryDescription || !rtwEvent.feedEntryTitle"
                                       class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i> Please provide all texts in (at
                                    least) english for the event.</small>
                                <small *ngIf="!rtwEvent.heroKey || !rtwEvent.heroImageUrl || !rtwEvent.heroName" class="form-text text-danger"><i
                                        class="fas fa-exclamation-triangle"></i> Please set up the hero fully.</small>
                            </div>

                            <div class="form-group">
                                <label>Collection</label>
                                <div class="pl-3" *ngIf="collectionName">{{ collectionName }}</div>
                            </div>

                            <div class="form-group">
                                <label for="colour"><i class="fas fa-square mr-2" [ngStyle]="{'color': rtwEvent.color}"></i> Event Theme
                                    Colour</label>
                                <div class="input-group colorpicker-element" id="colorPickerGroup">
                                    <input type="text" [disabled]="rtwEvent.active" id="colour" aria-describedby="colorHelp" class="form-control"
                                           [(ngModel)]="rtwEvent.color" (change)="settingsChanged()" (keyup)="settingsChanged()">
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="fas fa-square" [ngStyle]="{'color': rtwEvent.color}"></i></span>
                                    </div>
                                </div>
                                <small class="form-text text-muted" id="colorHelp">Used as theme colour for RtW-Event. E.g. overlay-colour of the
                                    conquered area.</small>
                            </div>

                            <div class="form-group">
                                <label for="eventPeriod">Event period</label>
                                <div class="input-group">
                                    <input type="text" [disabled]="rtwEvent.active" class="form-control" id="eventPeriod"
                                           aria-describedby="eventPeriodHelp">
                                </div>
                                <small class="form-text text-muted" id="eventPeriodHelp">The period in which the event is held.</small>
                            </div>

                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveSettings" (click)="saveSettings()" class="btn btn-primary">Save settings</button>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 col-xl-4">

                    <app-translatable-texts textsName="RtW Event" infoTrigger="{{ textsChangedInfo }}" [textModel]="textModel" [textObject]="rtwEvent"
                                            (alteredTextObject)="onAlteredTextObject($event)" *ngIf="rtwEvent"></app-translatable-texts>

                    <app-image-handler readonly="{{ rtwEvent.active }}" imageName="RtW Event Image" imageIndex="0" imageUrl="{{ rtwEvent.imageUrl }}"
                                       imagePath="rtwEvents/{{ rtwEvent.key }}/" imageUrlOnline="{{ rtwEvent.imageUrl }}"
                                       (uploadedImageUrl)="onUploadedImageUrl($event)"
                                       sizeNote="Max image dimensions are up to 250 pixels in the widest/tallest dimension. A transparent logo is preferred."
                                       *ngIf="rtwEvent"></app-image-handler>

                </div>
                <div class="col-lg-6 col-xl-4">

                    <div *ngIf="rtwEvent" [ngClass]="'card card-outline card-'+layout.getTheme('rtwEvent')">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-mask"></i> Hero Settings</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ heroSettingsChangedInfo }}">
                                <h4>Hero settings saved successfully</h4>
                            </app-alert-info-box>

                            <div class="form-group">
                                <label for="findHero">Search for the hero profile</label>
                                <div class="input-group">
                                    <app-find-user id="findHero" modalId="findHero" modalTitle="Find Hero Profile" btnClassType="default"
                                                   (userSelected)="foundHero($event)"><i class="fa fa-mask" title="Find profile to adjust"></i> Find
                                        Hero Profile
                                    </app-find-user>
                                    <span *ngIf="rtwEvent.heroKey"><i class="fas fa-key px-2"></i> {{ rtwEvent.heroKey }}</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="heroName">Hero Name</label>
                                <input [disabled]="!rtwEvent.heroKey" type="text" id="heroName" aria-describedby="heroNameHelp" class="form-control"
                                       [(ngModel)]="rtwEvent.heroName" (change)="heroSettingsChanged()" (keyup)="heroSettingsChanged()">
                                <small class="form-text text-muted" id="heroNameHelp">Here you can alter the name of the event hero if needed.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableHeroSaveSettings" (click)="saveHeroSettings()" class="btn btn-primary">Save hero settings
                            </button>
                        </div>
                    </div>

                    <app-image-handler imageName="RtW Hero Image" imageIndex="1" imageUrl="{{ rtwEvent.heroImageUrl }}"
                                       imagePath="rtwEvents/{{ rtwEvent.key }}/" imageUrlOnline="{{ rtwEvent.heroImageUrl }}"
                                       (uploadedImageUrl)="onUploadedHeroImageUrl($event)" sizeNote="Preferred dimensions are 128 x 128 pixels."
                                       *ngIf="rtwEvent && rtwEvent.heroKey"></app-image-handler>

                </div>
            </div>
        </div>
    </section>
</div>
