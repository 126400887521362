<div class="content-wrapper">
    <app-content-header-section contentPageId="newDestination" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Create new Destination</h3>
                        </div>

                        <div class="card-body">
                            <div class="form-group">
                                <label for="name">Destination name</label>
                                <input [(ngModel)]="destinationName" (change)="valueChanged()" (keyup)="valueChanged()" id="name"
                                       aria-describedby="nameHelp" class="form-control" placeholder="Name of the Destination"/>
                                <small class="form-text text-muted" id="nameHelp">This is the name of the destination as it is known to the world in
                                    general.</small>
                            </div>

                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="destinationPromoted" (change)="valueChanged()" id="destinationPromoted"
                                           class="custom-control-input" type="checkbox"/>
                                    <label class="custom-control-label" for="destinationPromoted">Promoted</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="destinationAdventuresEnabled" (change)="valueChanged()" id="destinationAdventuresEnabled"
                                           class="custom-control-input" type="checkbox"/>
                                    <label class="custom-control-label" for="destinationAdventuresEnabled">Adventures Enabled</label>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <button [disabled]="disableCreate" (click)="createDestination()" class="btn btn-primary">
                                Create new Destination
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
