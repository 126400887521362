import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { CountryService } from '../../../../firebase-services/country.service';
import { AdventureService } from '../../../../firebase-services/adventure.service';

// Interfaces
import { Country } from '../../../../interfaces/countries';
import { Adventure, AdventureItem } from '../../../../interfaces/adventure';

@Component({
    selector: 'app-country-adventure-item-edit',
    templateUrl: './country-adventure-item-edit.component.html',
    styleUrls: ['./country-adventure-item-edit.component.css']
})
export class CountryAdventureItemEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    country: Country = null;
    adventure: Adventure = null;
    adventureItem: AdventureItem = null;
    routerRoot: string;
    routerBack: string;

    constructor(
        private route: ActivatedRoute,
        private countryService: CountryService,
        private adventureService: AdventureService
    ) {
    }

    ngOnInit() {
        this.countryService.getCountry(this.route.snapshot.paramMap.get('countryCode'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => {
                this.country = country;
                this.routerRoot = '/country-manager/' + country.countryCode;
            });
        this.adventureService.getAdventure(this.route.snapshot.paramMap.get('adventureKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventure) => {
                this.routerBack = '/adventures/' + adventure.key;
                this.adventure = adventure;
            });
        this.adventureService.getAdventureItem(this.route.snapshot.paramMap.get('adventureItemKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventureItem) => this.adventureItem = adventureItem);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
