import {Injectable, inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable({providedIn: 'root'})
class PermService {
    constructor(
        private authService: AuthService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.uid$
            .pipe(
                map(() => (this.authService.user !== null))
            );
    }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return inject(PermService).canActivate(next, state);
}

export const adminGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const eventManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('eventManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const dmoManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('dmoManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const countryManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('countryManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const destinationManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('destinationManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const trailAreaManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('trailAreaManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const trailManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('trailManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const organisationManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('organisationManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};

export const trailCenterManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                    if (authService.user !== null && authService.authRoles.includes('admin')) return true;
                    if (authService.user !== null && authService.authRoles.includes('trailCenterManager')) return true;
                    router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                    return false;
                }
            )
        );
};