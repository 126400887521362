<div class="row">
    <div [ngClass]="'col-md-' + (showStoryColumn ? 6 : 12)">

        <div *ngIf="newsStories" [ngClass]="'card card-outline card-' + layout.getTheme(theme)">
            <div class="card-header">
                <h3 class="card-title">{{ listName }}</h3>
            </div>

            <div class="card-body p-0">
                <p class="text-muted p-3" *ngIf="newsStories.length===0">No stories in list</p>
                <div class="list-group list-group-flush" *ngIf="newsStories.length" [attr.role]="showStoryColumn ? 'tablist' : ''">
                    <a *ngFor="let news of newsStories" class="list-group-item list-group-item-action flex-column align-items-start"
                       [id]="'listitem-' + news.key" data-toggle="list" [href]="showStoryColumn ? '#details-' + news.key : ''"
                       [attr.role]="showStoryColumn ? 'tab' : ''" [attr.aria-controls]="news.key">
                        <div>
                            <span [ngClass]="'float-right badge badge-' + layout.getTheme(theme)" *ngIf="news.reach"
                                  [title]="'Present in the feed of ' + news.reach + ' profiles'">Reach: {{ news.reach }}</span>
                            <i [ngClass]="layout.getIcon(theme) + ' mr-2'"></i><strong>{{ news.title }}</strong>
                        </div>
                        <div class="truncate_line">
                            <span class="truncate_content">{{ news.description }}</span>
                        </div>
                        <div class="float-right" *ngIf="!showStoryColumn">
                            <i *ngIf="showListRemove" (click)="removeFromList(news)" class="far fa-trash-alt mr-2" title="Remove from list"></i>
                            <a *ngIf="navigateTo" [routerLink]="navigateTo + news.key">
                                <i class="far fa-edit" [title]="'Edit the ' + news.title + ' news story'"></i>
                            </a>
                        </div>
                        <div>
                            <span [ngClass]="'badge badge-' + layout.getTheme('newsDraft')" title="Draft created"
                                  *ngIf="!news.publishedAt && !news.archivedAt">{{ news.createdAt | date:'dd/MM/yyy HH:mm' }}
                                by {{ news.creatorName }}</span>
                            <span *ngIf="news.publishedAt && !news.archivedAt" [ngClass]="'badge badge-' + layout.getTheme('news')"
                                  title="Published">{{ news.publishedAt | date:'dd/MM/yyy HH:mm' }} by {{ news.publisherName }}</span>
                            <span *ngIf="news.archivedAt" [ngClass]="'badge badge-' + layout.getTheme('newsArchive')"
                                  title="Archived">{{ news.archivedAt | date:'dd/MM/yyy HH:mm' }} by {{ news.archivistName }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    </div>
    <div class="col-md-6" *ngIf="showStoryColumn">

        <div class="tab-content" *ngIf="newsStories">
            <div *ngFor="let news of newsStories" class="tab-pane fade" [id]="'details-' + news.key" role="tabpanel">
                <div [ngClass]="'card card-outline card-' + layout.getTheme(theme)">
                    <div class="card-body">
                        <div class="feed-entry-poster">
                            <div class="feed-entry-poster-image-holder float-left">
                                <img src="assets/images/logo.png" alt="Author: Mountainbike United" class="feed-entry-poster-image">
                            </div>
                            <div class="feed-entry-poster-name-and-time mx-4 px-4">
                                <span class="feed-entry-poster-name">Mountainbike United</span><br>
                                <span class="feed-entry-poster-time">{{ news.publishedAt | date:'medium' }}</span>
                            </div>
                        </div>

                        <h3 class="mx-4 p-4">{{ news.title }}</h3>

                        <p class="mx-4 px-4">{{ news.description }}</p>

                        <div class="carousel slide" data-ride="carousel" *ngIf="news.content">
                            <div class="carousel-inner">
                                <div [ngClass]="'carousel-item' + (contentKey === '0' ? ' active' : '')"
                                     *ngFor="let contentKey of objectKeys(news.content)">
                                    <img [src]="news.content[contentKey].url" [alt]="news.content[contentKey].type" class="d-block w-100">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer" *ngIf="navigateTo || applicable">
                        <div class="d-flex justify-content-between">
                            <button (click)="addToFeed(news,authService.user.userID)" class="btn btn-warning" *ngIf="applicable">
                                <img [src]="authService.user.userPicture" class="img-on-btn" alt="This is you"> Apply to me
                            </button>

                            <app-find-user modalId="findUser{{ news.key }}" modalTitle="Find Recipient" (userSelected)="addToFeed(news, $event)"
                                           btnClassType="success" *ngIf="applicable"><i class="fas fa-user"></i> Apply to user
                            </app-find-user>

                            <app-find-profile-segment *ngIf="minimumAppVersions && applicable" modalId="segment{{ news.key }}"
                                                      modalTitle="Find a User Segment" (profileSegment)="addToFeeds(news, $event)"
                                                      btnClassType="success" [minimumAppVersions]="minimumAppVersions"><i class="fas fa-users"></i>
                                Apply to user-segment
                            </app-find-profile-segment>

                            <button *ngIf="navigateTo" [routerLink]="navigateTo + news.key" class="btn btn-info">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
