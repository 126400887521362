<div class="content-wrapper">
    <app-content-header-section *ngIf="trail && trailArea" contentPageId="trailEdit"
                                [pageParams]="{ trailAreaName: trailArea.name, trailAreaKey: trailArea.key, trailName: trail.name }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-4">

                    <div *ngIf="trail" [ngClass]="'card card-outline card-'+layout.getTheme('trail')">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-cogs"></i> Settings</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ settingsChangedInfo }}">
                                <h4>Trail settings saved successfully</h4>
                            </app-alert-info-box>

                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="trail.active"
                                           [disabled]="!trail.encodedPolyline || !trail.imageUrl"
                                           (change)="settingsChanged()" id="activeCB"
                                           aria-describedby="activeCBHelp"
                                           class="custom-control-input"
                                           type="checkbox">
                                    <label class="custom-control-label" for="activeCB">Active</label>
                                </div>
                                <small class="form-text text-muted" id="activeCBHelp">Whether the trail is active in the Mountainbike United
                                    app and online.</small>
                                <small *ngIf="!trail.encodedPolyline" class="form-text text-danger"><i
                                        class="fas fa-exclamation-triangle"></i> Please upload a gpx with the trail coordinates or edit and
                                    save your trail.</small>
                                <small *ngIf="!trail.imageUrl" class="form-text text-danger"><i
                                        class="fas fa-exclamation-triangle"></i> Please upload an image for the trail.</small>
                            </div>

                            <div *ngIf="!trail.awards?.OnTrail">
                                <div class="form-group">
                                    <label for="trailType">Trail type</label>
                                    <select [(ngModel)]="trailType" id="trailType" aria-describedby="trailTypeHelp" class="form-control"
                                            (change)="settingsChanged()">
                                        <option *ngFor="let type of trailTypes" [value]="type.key">{{ type.value }}</option>
                                    </select>
                                    <small class="form-text text-muted" id="trailTypeHelp">Select the type of trail.</small>
                                </div>

                                <div class="form-group">
                                    <label for="trailGrading">Grading</label>
                                    <div class="dropdown">
                                        <button class="btn btn-default dropdown-toggle" type="button" id="trailGrading"
                                                aria-describedby="trailGradingHelp" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                            <img [src]="trail.iconUrl" class="select-image" alt="Grading icon">
                                            <ng-container *ngFor="let trailGrading of trailGradings">
                                                <span *ngIf="trailGrading.iconURL === trail.iconUrl">{{ trailGrading.text }}</span>
                                            </ng-container>&nbsp;
                                            <span class="caret"></span>
                                        </button>
                                        <div class="dropdown-menu">
                                            <button *ngFor="let trailGrading of trailGradings" class="dropdown-item" type="button"
                                                    (click)="updateTrailGrading(trailGrading)">
                                                <img [src]="trailGrading.iconURL" class="select-image" alt="Icon"> {{ trailGrading.text }}
                                            </button>
                                        </div>
                                    </div>
                                    <small class="form-text text-muted" id="trailGradingHelp">Select the trail difficulty grading.</small>
                                </div>

                                <div class="form-group" *ngIf="trailType != 2">
                                    <label for="trailColor"><i class="fas fa-square" [ngStyle]="{'color': trail.color}"></i> Trail color on
                                        map</label>
                                    <select [(ngModel)]="trail.color" id="trailColor" aria-describedby="trailColorHelp" class="form-control"
                                            (change)="settingsChanged()">
                                        <option *ngFor="let trailColor of trailColors"
                                                [value]="trailColor.value">{{ trailColor.text }}</option>
                                    </select>
                                    <small class="form-text text-muted" id="trailColorHelp">Select the color of the trail path on
                                        maps.</small>
                                </div>
                            </div>


                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveSettings" (click)="saveSettings()" class="btn btn-primary">
                                Save settings
                            </button>
                        </div>
                    </div>

                    <div *ngIf="trail && trail.awards?.OnTrail && onTrailAward" class="card card-outline card-navy">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-certificate mr-2"></i> OnTrail Award </h3>
                        </div>
                        <div class="card-body">
                            <div>
                                <img [src]="onTrailAward.logoUrl" class="img-fluid float-right on-trail-logo" alt="OnTrail">
                                <h3>{{ onTrailAward.header }}
                                </h3>
                                <p>{{ onTrailAward.description }}</p>
                                <p>
                                    <img [src]="onTrailAward.categories[trail.awards.OnTrail].signUrl" alt="OnTrail" height="40">
                                    {{ onTrailAward.categories[trail.awards.OnTrail].name }}
                                    - {{ onTrailAward.categories[trail.awards.OnTrail].nickName }}<br>
                                    <i>{{ onTrailAward.categories[trail.awards.OnTrail].description }}</i>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-xl-4">

                    <app-image-handler *ngIf="trail" imageName="Trail Image" imageIndex="0" imageUrl="{{trail.imageUrl}}"
                                       imagePath="trailImages/{{trail.key}}/"
                                       (uploadedImageUrl)="onUploadedImageUrl($event)"></app-image-handler>

                </div>
                <div class="col-12 col-xl-4">

                    <app-translatable-texts *ngIf="trail" textsName="Trail" colorTheme="teal" [textModel]="textModel" [textObject]="trail"
                                            (alteredTextObject)="onAlteredTextObject($event)"
                                            [infoTrigger]="textsChangedInfo"></app-translatable-texts>

                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-9">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-map-pin mr-2"></i> Trail Path</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ gpxUploadedInfo }}">
                                <h4>New gpx-file for the trail area successfully uploaded.</h4>
                            </app-alert-info-box>

                            <div class="form-group" *ngIf="trailArea && trail">
                                <div #gmap id="gmap"></div>
                            </div>
                            <div *ngIf="trail" class="row">
                                <div class="col-sm-6 border-right">

                                    <div class="form-group">
                                        <strong><i class="far fa-edit mr-2"></i> Manually Edit Path</strong>
                                        <div class="checkbox">
                                            <label class="mr-2">
                                                <input type="checkbox" [(ngModel)]="pathEditable" aria-describedby="editHelp"
                                                       (click)="setPathEditable()"> Enable edit mode
                                            </label>
                                            <span class="badge badge-info">Beta Feature</span>
                                            <button type="button" class="btn btn-danger float-right" (click)="savePath()" [disabled]="!pathEdited"
                                                    [ngSwitch]="heatMapState">Save path
                                            </button>
                                            <small class="form-text text-muted" id="editHelp">Click and drag a vertex to move it. Create a new vertex
                                                on an edge. Delete a vertex by right clicking it.</small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <strong><i class="fas fa-fire mr-2"></i> Heat Map</strong>
                                        <div>
                                            <button type="button" aria-describedby="heatHelp" class="btn btn-default mr-2" (click)="loadHeatMap()"
                                                    [disabled]="heatMapState === 'loading' || heatMapState === 'completed'" [ngSwitch]="heatMapState">
                                                <span *ngSwitchCase="'completed'">Completed</span>
                                                <span *ngSwitchCase="'loading'">Loading...</span>
                                                <span *ngSwitchCase="'ready'">Load more</span>
                                                <span *ngSwitchDefault>Load now</span>
                                            </button>
                                            <i class="fas fa-question-circle mr-2"
                                               title="Fetches the latest {{stepSize}} rides per click - this way you can see the current ride tendency"></i>
                                            <span class="badge badge-info">Beta Feature</span>
                                        </div>
                                        <small class="form-text text-muted" id="heatHelp">See a heat map of rides on the trail.</small>
                                    </div>

                                </div>
                                <div class="col-sm-6">

                                    <div class="form-group">
                                        <label for="gpxInp"><i class="fas fa-cloud-upload-alt mr-2"></i> Trail Path (upload replacement)</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend">
                                                <span class="btn btn-default btn-file">
                                                    Browse… <!--suppress TypeScriptUnresolvedVariable -->
                                                    <input type="file" (change)="onGpxFileSelected($event.target.files)" id="gpxInp"
                                                           aria-describedby="gpxInpHelp">
                                                </span>
                                            </span>
                                            <input id="gpx_filename" type="text" class="form-control" readonly>
                                            <span class="input-group-append">
                                                <button [disabled]="disableUploadGpx" class="btn btn-info" (click)="onGpxUpload()">Upload</button>
                                            </span>
                                        </div>
                                        <small class="form-text text-muted" id="gpxInpHelp">Choose a local GPX-file to replace the existing
                                            trail-path.</small>
                                    </div>
                                    <div *ngIf="trail.gpxUrl" class="form-group">
                                        <label for="gpxDownload"><i class="fas fa-cloud-download-alt"></i> Download GPX</label>
                                        <div>
                                            <button type="button" class="btn btn-default btn-xs" (click)="downloadFile(trail.gpxUrl)" id="gpxDownload"
                                                    aria-describedby="gpxDownloadHelp">Download
                                            </button>
                                        </div>
                                        <small class="form-text text-muted" id="gpxDownloadHelp">This is the original gpx-file, from which the trail
                                            was originally created.<br>Note: Not all original files are available for download.</small>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-3 offset-lg-0">

                    <div class="card card-outline card-orange">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-info mr-2"></i> Trail Trivia</h3>
                        </div>
                        <div class="card-body">
                            <div *ngIf="trail">

                                <div class="description-block margin-bottom" *ngIf="trail.avgRating">
                                    <div class="description-text">Avg. rating</div>
                                    <span class="rating_blank">
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <span class="rating_full text-yellow" [style]="setStarWidth()">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </span>
                                    <h5 class="description-header">{{ trail.avgRating | number:'1.1-1' }} in {{ trail.numberOfRatings }} votes</h5>
                                </div>

                                <div class="description-block margin-bottom" *ngIf="trail.lengthInMeters">
                                    <div class="description-text">Metrics</div>
                                    <h6><i class="fas fa-ruler-horizontal"></i> Length: {{ trail.lengthInMeters }} m</h6>
                                    <h6><i class="fas fa-ruler"></i> Height increase: {{ trail.heightIncrease }} m</h6>
                                    <h6><i class="fas fa-ruler fa-flip-horizontal"></i> Height decrease: {{ trail.heightDecrease }} m</h6>
                                    <h6><i class="fas fa-angle-double-up"></i> Max height: {{ trail.maxHeight }} m</h6>
                                    <h6><i class="fas fa-angle-double-down"></i> Min height: {{ trail.minHeight }} m
                                    </h6>
                                </div>

                                <div class="description-block margin-bottom" *ngIf="trail.trackStatus">
                                    <div class="description-text">Trail status</div>
                                    <i class="far fa-smile fa-3x text-success" *ngIf="trail.trackStatus === 1"></i>
                                    <i class="far fa-meh fa-3x text-warning" *ngIf="trail.trackStatus === 2"></i>
                                    <i class="far fa-frown fa-3x text-danger" *ngIf="trail.trackStatus === 3"></i>
                                    <h5 class="description-header">{{ trail.trackStatusTimestamp | date:'fullDate' }}</h5>
                                </div>

                                <div class="description-block margin-bottom" *ngIf="trail.boundsNorth">
                                    <div class="description-text">Bounds</div>
                                    <h6>North: {{ trail.boundsNorth }}</h6>
                                    <h6>South: {{ trail.boundsSouth }}</h6>
                                    <h6>East: {{ trail.boundsEast }}</h6>
                                    <h6>West: {{ trail.boundsWest }}</h6>
                                </div>

                                <div class="description-block margin-bottom" *ngIf="trail.shortDynamicLink || trail.webURL">
                                    <div class="description-text">Links</div>
                                    <h6 *ngIf="trail.shortDynamicLink">
                                        Short Dynamic Link: <a class="text-black" href="{{ trail.shortDynamicLink }}"
                                                               target="sdl">{{ trail.shortDynamicLink }}</a>&nbsp;
                                        <i class="fas fa-question-circle"
                                           title="A short dynamic link is a link to the trail, which will act differently depending on the context you are in. E.g. if you are on your phone it will try to open the link in the Mountainbike United app."></i>
                                    </h6>
                                    <h6 *ngIf="trail.webURL">Web-page: <a class="text-black" href="{{ trail.webURL }}"
                                                                          target="trailurl">{{ trail.webURL }}</a></h6>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
