<div class="content-wrapper">
    <app-content-header-section *ngIf="area" contentPageId="trailAreaNews"
                                [pageParams]="{ trailAreaName: area.name, trailAreaKey: area.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="alert alert-danger">
                <h5><i class="icon fas fa-hard-hat"></i> Sorry - we are rebuilding</h5>
                The News Room is being rebuilt for you.
            </div>
            <!--            <div *ngIf="area" class="row">
                            <div class="col-md-6">

                                <app-news-room-list storyType="draft" [roomName]="area.key" [navigateTo]="'/trail-area/' + area.key + '/news-stories/'" [listName]="area.name + ' News'"></app-news-room-list>

                                <app-news-room-list storyType="published" [roomName]="area.key" [navigateTo]="'/trail-area/' + area.key + '/news-stories/'" [listName]="area.name + ' News'"></app-news-room-list>

                            </div>
                            <div class="col-md-6">

                                <app-news-story-add-box [roomName]="area.key" [newsStory]="newNewsStory" [navigateTo]="'/trail-area/' + area.key + '/news-stories/'"></app-news-story-add-box>

                            </div>
                        </div>-->
        </div>
    </section>

</div>
