<div class="content-wrapper">
    <app-content-header-section *ngIf="trailArea" contentPageId="trailArea"
                                [pageParams]="{ trailAreaName: trailArea.name, trailAreaKey: trailArea.key }"></app-content-header-section>

    <section class="content" *ngIf="trailArea">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12">

                    <div [ngClass]="'small-box bg-' + layout.getTheme('trail')">
                        <div class="inner">
                            <h3>{{ trailCount }}</h3>
                            <p>Trails</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('trail')"></i>
                        </div>
                        <a [routerLink]="layout.getUrl('trails', { trailAreaKey: trailArea.key })"
                           class="small-box-footer">
                            Go to trails <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <div *ngIf="trailCount < 0" class="overlay">
                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                    <div [ngClass]="'small-box bg-' + layout.getTheme('poi')">
                        <div class="inner">
                            <h3>{{ poiCount }}</h3>
                            <p>Points of Interests</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('poi')"></i>
                        </div>
                        <a *ngIf="authService.isUser(roles.DMO_MANAGER) && areaIsDmoArea" [routerLink]="layout.getUrl('pois', { trailAreaKey: trailArea.key })"
                           class="small-box-footer">
                            Go to POIs <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <span *ngIf="!authService.isUser(roles.DMO_MANAGER) && !areaIsDmoArea" class="small-box-footer"><i class="fas fa-phone"></i> Please contact us for more information.</span>
                        <div *ngIf="poiCount < 0" class="overlay">
                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="clearfix visible-md-block"></div>
                <div class="col-lg-3 col-md-6 col-12">

                    <div *ngIf="!trailArea.destinationKey || adventureCount > 0"
                         [ngClass]="'small-box bg-' + (trailArea.adventuresEnabled ? layout.getTheme('adventure') : 'light')">
                        <div class="inner">
                            <h3>{{ trailArea.adventuresEnabled ? adventureCount : 'Not enabled' }}</h3>
                            <p>Adventures</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('adventure')"></i>
                        </div>
<!--                        <a *ngIf="trailArea.adventuresEnabled"-->
<!--                           [routerLink]="layout.getUrl('trailAreaAdventures', { trailAreaKey: trailArea.key })"-->
<!--                           class="small-box-footer">-->
<!--                            Go to adventures <i class="fas fa-arrow-circle-right"></i>-->
<!--                        </a>-->
                        <span class="small-box-footer"><i class="fas fa-phone"></i> Please contact us for more information.</span>
<!--                        <div *ngIf="trailArea.adventuresEnabled && adventureCount < 0" class="overlay">-->
<!--                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>-->
<!--                        </div>-->
                    </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                    <div [ngClass]="'small-box bg-' + layout.getTheme('manager')">
                        <div class="inner">
                            <h3>{{ areaManagersCount }}</h3>
                            <p>Area Managers</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('manager')"></i>
                        </div>
                        <a [routerLink]="layout.getUrl('areaManagers', { trailAreaKey: trailArea.key })"
                           class="small-box-footer">
                            Go to area managers <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <div *ngIf="areaManagersCount < 0" class="overlay">
                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row" *ngIf="trailArea && trailArea.boundsNorth">
                <div class="col-12 col-md-8">

                    <div class="card card-outline card-primary">
                        <div class="card-header">
                            <h3 class="card-title"><i class="far fa-map mr-2"></i> Overview of {{ trailArea.name }}</h3>
                        </div>
                        <div class="card-body p-0">
                            <div #gmap id="gmap"></div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-4">
                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-exclamation-triangle text-warning mr-2"></i> Warnings for {{ trailArea.name }}</h3>
                        </div>
                        <div class="card-body">

                            <div class="card text-dark bg-trail-area-warning mx-4 mb-3" *ngIf="trailArea.warningText">
                                <div class="card-header">English (default)</div>
                                <div class="card-body">
                                    <p class="card-text font-weight-bolder">
                                        <i class="fas fa-exclamation-triangle fa-3x float-right"></i>
                                        {{ trailArea.warningText }}
                                    </p>
                                </div>
                            </div>

                            <!--suppress TypeScriptValidateTypes -->
                            <ng-container *ngFor="let lang of trailArea.lang | keyvalue">
                                <div class="card text-dark bg-trail-area-warning mx-4 mb-3" *ngIf="lang.value.warningText">
                                    <div class="card-header">Language code: <strong>{{ lang.key }}</strong></div>
                                    <div class="card-body">
                                        <p class="card-text font-weight-bolder">
                                            <i class="fas fa-exclamation-triangle fa-3x float-right"></i>
                                            {{ lang.value.warningText }}
                                        </p>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-4" *ngIf="trailArea.country === 'dk' && builderOrganisation">

                    <div class="card card-outline card-navy">
                        <div class="card-header">
                            <h3 class="card-title">Trail Builder Organisation</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ trailBuilderChangedInfo }}">
                                <h4>Trail Builder Organisation saved successfully</h4>
                            </app-alert-info-box>

                            <p class="card-text">Do your area need donation from the riders? Fill in the MobilePay-number below and the "Donate
                                Motivator"-text in the Texts-box to start receiving donations from our users.</p>

                            <div class="form-group">
                                <strong><i class="fas fa-hard-hat mr-2"></i> <label for="orgName">Organisation</label></strong>
                                <input [(ngModel)]="builderOrganisation.name" (change)="builderChanged()" (keyup)="builderChanged()" id="orgName"
                                       aria-describedby="orgHelp" class="form-control mb-1" placeholder="{{ trailArea.name }} Trail Builders">
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-envelope"></i></span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.email" (change)="builderChanged()" (keyup)="builderChanged()"
                                           id="orgEmail" aria-describedby="orgHelp" class="form-control" placeholder="contact@trailarea.com">
                                </div>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                                    </div>
                                    <textarea [(ngModel)]="builderOrganisation.address" id="orgAddress" class="form-control"
                                              (change)="builderChanged()" rows="3" (keyup)="builderChanged()" aria-describedby="orgHelp"
                                              [placeholder]="'Trail Builder Organisation Street 1\n0123 Builder City\nDenmark'"></textarea>
                                </div>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-phone"></i></span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.phone" (change)="builderChanged()" (keyup)="builderChanged()"
                                           id="orgPhone" aria-describedby="orgHelp" class="form-control" placeholder="+45 12 34 56 78">
                                </div>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">CVR</span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.cvr" (change)="builderChanged()" (keyup)="builderChanged()" id="orgCvr"
                                           aria-describedby="orgHelp" class="form-control" placeholder="12345678">
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">MobilePay</span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.mobilePay" (change)="builderChanged()" (keyup)="builderChanged()"
                                           id="orgMobilePay" aria-describedby="orgHelp" class="form-control" placeholder="123456"
                                           [disabled]="!builderOrganisation.name || !builderOrganisation.email || !builderOrganisation.phone || !builderOrganisation.cvr">
                                </div>
                                <small class="form-text text-muted" id="orgHelp">Trail builder organisation information.</small>
                                <small *ngIf="!builderOrganisation.name" class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i>
                                    Please provide a name for your trail builder organisation.</small>
                                <small *ngIf="!builderOrganisation.email" class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i>
                                    Please provide an e-mail for your trail builder organisation.</small>
                                <small *ngIf="!builderOrganisation.phone" class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i>
                                    Please provide a phone-number for your trail builder organisation.</small>
                                <small *ngIf="!builderOrganisation.cvr" class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i>
                                    Please provide a CVR-number for your trail builder organisation.</small>
                                <small *ngIf="builderOrganisation.mobilePay && !mobilePayRegex.test(builderOrganisation.mobilePay)"
                                       class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i> Invalid MobilePay number.</small>
                            </div>

                            <div class="form-group">
                                <strong><i class="fas fa-user-tie mr-2"></i> <label for="trailBossName">Trail Organisation Boss</label></strong>
                                <input [(ngModel)]="builderOrganisation.bossName" (change)="builderChanged()" (keyup)="builderChanged()"
                                       id="trailBossName" aria-describedby="trailBossHelp" class="form-control mb-1" placeholder="Big Boss">
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-envelope"></i></span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.bossEmail" (change)="builderChanged()" (keyup)="builderChanged()"
                                           id="trailBossEmail" aria-describedby="trailBossHelp" class="form-control"
                                           placeholder="boss@trailarea.com">
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-phone"></i></span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.bossPhone" (change)="builderChanged()" (keyup)="builderChanged()"
                                           id="trailBossPhone" aria-describedby="trailBossHelp" class="form-control" placeholder="+45 12 34 56 78">
                                </div>
                                <small class="form-text text-muted" id="trailBossHelp">Name, e-mail and phone of the trail organisation boss.</small>
                            </div>

                            <div class="form-group">
                                <strong><i class="fas fa-hand-holding-usd mr-2"></i> <label for="trailCashierName">Trail Organisation Cashier</label></strong>
                                <input [(ngModel)]="builderOrganisation.cashierName" (change)="builderChanged()" (keyup)="builderChanged()"
                                       id="trailCashierName" aria-describedby="trailCashierHelp" class="form-control mb-1" placeholder="Real Boss">
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-envelope"></i></span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.cashierEmail" (change)="builderChanged()" (keyup)="builderChanged()"
                                           id="trailCashierEmail" aria-describedby="trailCashierHelp" class="form-control"
                                           placeholder="cashier@trailarea.com">
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-phone"></i></span>
                                    </div>
                                    <input [(ngModel)]="builderOrganisation.cashierPhone" (change)="builderChanged()" (keyup)="builderChanged()"
                                           id="trailCashierPhone" aria-describedby="trailCashierHelp" class="form-control"
                                           placeholder="+45 12 34 56 78">
                                </div>
                                <small class="form-text text-muted" id="trailCashierHelp">Name, e-mail and phone of the trail organisation
                                    cashier.</small>
                            </div>

                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveBuilder" (click)="saveBuilder()" class="btn btn-primary">Save values</button>
                        </div>
                    </div>

                </div>
                <div [ngClass]="(trailArea.country === 'dk') ? 'col-12 col-xl-4' : 'col-12 col-xl-8'">
                    <div class="row">
                        <div [ngClass]="(trailArea.country === 'dk') ? 'col-12' : 'col-12 col-xl-6'">

                            <app-translatable-texts textsName="Trail Area" colorTheme="teal" [textModel]="textModel"
                                                    [infoTrigger]="textsChangedInfo" [textObject]="trailArea"
                                                    (alteredTextObject)="onAlteredTextObject($event)"
                                                    (languageAdded)="onLanguageAdded($event)"></app-translatable-texts>

                        </div>
                        <div [ngClass]="(trailArea.country === 'dk') ? 'col-12' : 'col-12 col-xl-6'">

                            <app-image-handler imageName="Trail Area Image" imageIndex="0" imageUrl="{{ trailArea.imageUrl }}"
                                               imagePath="trailAreaImages/{{ trailArea.key }}/"
                                               imageUrlOnline="{{ trailArea.imageUrl }}"
                                               (uploadedImageUrl)="onUploadedImageUrl($event)"></app-image-handler>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-4">

                    <div class="card card-outline card-navy">
                        <div class="card-header">
                            <h3 class="card-title">About the Trail Area</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ aboutChangedInfo }}">
                                <h4>Trail Area settings saved successfully</h4>
                            </app-alert-info-box>

                            <div class="form-group">
                                <div class="row border-bottom">
                                    <div class="col-2"><i class="fas fa-language"></i></div>
                                    <div class="col-5"><strong><i
                                            [ngClass]="layout.getIcon('country') + ' mr-2'"></i>
                                        Country</strong></div>
                                    <div class="col-5"><strong>Region</strong></div>
                                </div>
                                <div class="row">
                                    <div class="col-2">en*</div>
                                    <div class="col-5">{{ trailArea.countryName }}</div>
                                    <div class="col-5">{{ trailArea.region }}</div>
                                </div>
                                <!--suppress TypeScriptValidateTypes -->
                                <div class="row" *ngFor="let lang of trailArea.lang | keyvalue">
                                    <div class="col-2">{{ lang.key }}</div>
                                    <div class="col-5">{{ lang.value.countryName }}</div>
                                    <div class="col-5">{{ lang.value.region }}</div>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-secondary btn-xs" id="location-texts-button"
                                            (click)="updateLocationTexts()">Update texts
                                    </button>
                                </div>
                                <div>
                                    <small>* English (en) is default language.</small>
                                </div>
                            </div>

                            <div [ngSwitch]="showEditCreatedDate" class="form-group">
                                <strong><i class="far fa-calendar-alt mr-2"></i> <label for="dateCreated">Trail
                                    area
                                    founded</label></strong>
                                <div *ngSwitchCase="true" class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text far fa-calendar-alt"></i>
                                    </div>
                                    <input type="text" [(ngModel)]="trailArea.dateCreated"
                                           class="form-control float-right" id="dateCreated"
                                           aria-describedby="datepickerHelp"
                                           placeholder="Click to select a date">
                                </div>
                                <div *ngSwitchDefault (click)="editDateCreated()"><span
                                        *ngIf="!trailArea.dateCreated">Not set - click edit to set</span>{{ trailArea.dateCreated }}
                                    <i
                                            class="far fa-edit"></i></div>
                                <small class="form-text text-muted" id="datepickerHelp">The date where the trail
                                    area
                                    was founded.</small>
                            </div>

                            <div class="form-group">
                                <label for="maintainers"><i class="far fa-id-card mr-2"></i> Public Contact Info</label>
                                <textarea [(ngModel)]="trailArea.maintainers" (change)="aboutChanged()"
                                          (keyup)="aboutChanged()" id="maintainers"
                                          aria-describedby="maintainersHelp" class="form-control" rows="3"
                                          placeholder="Maintaining Organisation, Trail builders..."></textarea>
                                <small class="form-text text-muted" id="maintainersHelp">How and who do you
                                    prefer that
                                    users contact about the trails
                                    in the area.</small>
                            </div>

                            <div class="form-group">
                                <strong><i class="fas fa-hashtag mr-2"></i> <label
                                        for="hashtag">Hashtag</label></strong>
                                <input [(ngModel)]="trailArea.hashtag" (change)="aboutChanged()" (keyup)="aboutChanged()" id="hashtag"
                                       aria-describedby="hashtagHelp" class="form-control" placeholder="Hashtag">
                                <small class="form-text text-muted" id="hashtagHelp">Hashtag to use on social media platforms.</small>
                            </div>

                            <div class="form-group">
                                <label><i class="fas fa-comment-dollar mr-2"></i> The trails in this area are
                                    free?</label>
                                <div>
                                    <input [(ngModel)]="isAccessibleForFree" type="radio" name="isFree"
                                           value="true"
                                           (change)="aboutChanged()"> Yes
                                    <input [(ngModel)]="isAccessibleForFree" type="radio" name="isFree"
                                           value="false"
                                           (change)="aboutChanged()"> No
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="owner"><i class="fas fa-gavel mr-2"></i> Land owners</label>
                                <textarea [(ngModel)]="trailArea.owner" (change)="aboutChanged()"
                                          (keyup)="aboutChanged()" id="owner"
                                          aria-describedby="ownerHelp" class="form-control" rows="3"
                                          placeholder="Who actually owns the land"></textarea>
                                <small class="form-text text-muted" id="ownerHelp">The actual land
                                    owners.</small>
                            </div>

                            <div class="form-group">
                                <label for="sponsor"><i
                                        class="fas fa-hand-holding-usd mr-2"></i>Sponsors</label>
                                <textarea [(ngModel)]="trailArea.sponsor" (change)="aboutChanged()"
                                          (keyup)="aboutChanged()" id="sponsor"
                                          aria-describedby="sponsorHelp" class="form-control" rows="3"
                                          placeholder="Use this space to the best sponsors of the trail area"></textarea>
                                <small class="form-text text-muted" id="sponsorHelp">Sponsors of the trail
                                    area.</small>
                            </div>

                            <div class="form-group">
                                <label for="openingHours"><i class="far fa-clock mr-2"></i> Open</label>
                                <textarea [(ngModel)]="trailArea.openingHours" (change)="aboutChanged()"
                                          (keyup)="aboutChanged()" id="openingHours"
                                          aria-describedby="openingHoursHelp" class="form-control" rows="3"
                                          placeholder="All year or seasonal? All day? All night?"></textarea>
                                <small class="form-text text-muted" id="openingHoursHelp">When can you use the
                                    trail
                                    area.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveAbout" (click)="saveAbout()" class="btn btn-primary">
                                Save
                                values
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
