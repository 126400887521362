import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {TrailArea} from "../../interfaces/trailArea";
import {AuthService} from "../../core/auth.service";
import {LayoutService} from "../../core/layout.service";
import {takeUntil} from "rxjs/operators";
import {EventManagerService} from "../../root/event-managers/event-manager.service";

@Component({
    selector: 'event-manager-dashboard',
    templateUrl: './event-manager-dashboard.component.html',
    styleUrls: ['./event-manager-dashboard.component.css']
})
export class EventManagerDashboardComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    eventManagerTrailAreas: TrailArea[];

    constructor(
        private eventManagerService: EventManagerService,
        public layout: LayoutService,
        private authService: AuthService
    ) {
        this.eventManagerTrailAreas = [];
        this.eventManagerService.getEventManagerTrailAreas(this.authService.user.userID)
            .pipe(takeUntil(this.destroy$)).subscribe((a) => {
            this.eventManagerTrailAreas = a;
        });
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
