<button type="button" class="btn btn-{{ btnClassType }}" data-toggle="modal" [attr.data-target]="'#findTrailModal_'+modalId"
        (click)="focusInSearch()">
    <ng-content></ng-content>
</button>
<div class="modal fade" id="findTrailModal_{{modalId}}" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">{{ modalTitle }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <strong><i class="fas fa-search mr-2"></i> <label [for]="'searchForTrails'+modalId">Search for trails by name, starting
                        with</label></strong>
                    <input (change)="searchForTrails()" (keyup)="searchForTrails()" [disabled]="disableSearch" [id]="'searchForTrails'+modalId"
                           [attr.aria-describedby]="'searchForTrailsHelp' + modalId" class="form-control" placeholder="Enter trail name here...">
                    <small class="form-text text-muted" [id]="'searchForTrails' + modalId">Enter at least 3 characters. Searches trail names
                        starting with your search term. The search is case-sensitive.</small>
                </div>
                <div class="table-responsive">
                    <ul class="todo-list">
                        <li *ngFor="let trail of foundTrails">
                            <img class="img-fluid img-circle img-sm" src="{{ trail.imageUrl }}" alt="">
                            <div class="tools">
                                <button class="btn btn-xs btn-success" (click)="selectTrail(trail.key)">Select</button> &nbsp;
                                <i (click)="copyText(trail.key)" class="far fa-copy black-todolist-item" title="Copy trail key"></i>
                            </div>
                            <div class="img-push">
                                <span class="text">{{ trail.name }}</span>
                                <small class="badge badge-danger" title="Country info">{{ trail.countrie }}</small>
                                <small class="badge badge-default" title="Unique trail key">{{ trail.key }}</small>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Cancel</button>
            </div>
            <div class="overlay" *ngIf="disableSearch">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
        </div>
    </div>
</div>
