<div class="content-wrapper">
    <app-content-header-section contentPageId="kioskOrders" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-shopping-basket"></i> Orders</h3>
                        </div>
                        <div *ngIf="!dataReady" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="kioskOrders" id="kioskOrdersDataTable" class="table row-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                    <th>Customer</th>
                                    <th>Text</th>
                                    <th>Payment type</th>
                                    <th>Amount</th>
                                    <th>Created</th>
                                    <th>Success Time</th>
                                    <th>Transaction ID</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let kioskOrder of kioskOrders"
                                    [ngClass]="'status-'+kioskOrder.status+(kioskOrder.realIncome?' real-income':'')">
                                    <td [attr.data-order]="kioskOrder.orderID || -1">
                                        <i class="fas fa-coins gold" *ngIf="kioskOrder.realIncome"></i>
                                        {{ kioskOrder.orderID }} <span class="badge badge-secondary">{{ kioskOrder.key }}</span>
                                    </td>
                                    <td [attr.data-order]="kioskOrder.status">
                                        {{ kioskOrder.status }}
                                    </td>
                                    <td [attr.data-order]="kioskOrder.kioskCustomer.name">
                                        <span title="{{ kioskOrder.kioskCustomer.street }}, {{ kioskOrder.kioskCustomer.zip }} {{ kioskOrder.kioskCustomer.city }}, {{ kioskOrder.kioskCustomer.country }}">{{ kioskOrder.kioskCustomer.name }}</span>
                                    </td>
                                    <td [attr.data-order]="kioskOrder.orderText">
                                        {{ kioskOrder.orderText }}
                                    </td>
                                    <td>{{ kioskOrder.paymentType }}</td>
                                    <td [attr.data-order]="kioskOrder.amount">
                                        {{ kioskOrder.amount / 100 | number:'1.2-2' }} {{ kioskOrder.currency }}
                                    </td>
                                    <td [attr.data-order]="kioskOrder.createdTimestamp">
                                        {{ kioskOrder.createdTimestamp | date:'dd/MM/yyy HH:mm:ss' }}
                                    </td>
                                    <td [attr.data-order]="kioskOrder.successTimestamp">
                                        {{ kioskOrder.successTimestamp | date:'dd/MM/yyy HH:mm:ss' }}
                                    </td>
                                    <td>
                                        {{ kioskOrder.transactionID }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
