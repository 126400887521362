import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/compat/database';

import { Meetup } from '../interfaces/meetup';

@Injectable({
    providedIn: 'root'
})
export class MeetupService {

    constructor(private db: AngularFireDatabase) {
    }

    getAllMeetUps(): Observable<Meetup[]> {
        return this.db.list<Meetup>(`/meetups`).snapshotChanges().pipe(
            take(1),
            map(meetupSnaps => {
                const meetups: Meetup[] = [];
                meetupSnaps.forEach((meetupSnap) => {
                    const meetUpDBEntry = <Meetup>meetupSnap.payload.val();

                    let meetup: Meetup = null;
                    // Only for the past two years
                    if (typeof meetUpDBEntry.timestamp === 'number' &&
                        meetUpDBEntry.timestamp > Date.now() - 2 * 365 * 24 * 60 * 60 * 1000) {
                        let date: Date = new Date(meetUpDBEntry.timestamp);
                        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                        date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
                        const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
                        const weekNo = Math.ceil((((date.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
                        meetup = {
                            key: meetupSnap.key,
                            timestamp: meetUpDBEntry.timestamp,
                            public: meetUpDBEntry.public,
                            year: date.getUTCFullYear(),
                            week: weekNo
                        };
                        meetups.push(meetup);
                        // } else {
                        //     meetup = {key: meetUpDBEntries[i].key, timestamp: 0, public: meetUpDBEntry.public, year: 3000, week: 0};
                    }
                });
                return meetups;
            })
        );
    }
}
