<div class="content-wrapper">
    <app-content-header-section *ngIf="trailArea" contentPageId="areaManagers"
                                [pageParams]="{ trailAreaName: trailArea.name, trailAreaKey: trailArea.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title">Area Managers</h3>
                        </div>
                        <div class="card-body p-0">
                            <div *ngIf="admins">
                                <table class="table row-bordered">
                                    <tbody>
                                    <tr *ngFor="let admin of admins">
                                        <td><i class="fa fa-user"></i> {{admin.name}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="overlay" *ngIf="!admins">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
