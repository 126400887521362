import {Injectable} from '@angular/core';
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {forkJoin, Observable, of, zip} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {UserService} from "../../firebase-services/user.service";
import {TrailAreaService} from "../../firebase-services/trail-area.service";
import firebase from "firebase/compat";
import {EventManager, EventManagerListItem} from "../../interfaces/event-manager";
import {TrailArea} from "../../interfaces/trailArea";

@Injectable({
    providedIn: 'root'
})
export class EventManagerService {
    private eventManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private trailAreaService: TrailAreaService
    ) {
        this.eventManagersRef = this.db.database.ref('eventManagers')
    }

    public getEventManagers(): Observable<EventManager[]> {
        return this.db.list<EventManager>(this.eventManagersRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((emSnapshots) => {
                    const managers: EventManager[] = [];
                    for (const emSnapshot of emSnapshots) {
                        managers.push(this.EventManagerFromSnap(emSnapshot));
                    }
                    return managers;
                })
            );
    }

    getEventManagerListItems(): Observable<EventManagerListItem[]> {
        return this.db.list<EventManager>(this.eventManagersRef.ref).snapshotChanges()
            .pipe(switchMap((emSnapshots) => {
                const eventManagerObservables = emSnapshots.map(emSnapshot => {
                    const eventManager: EventManager = this.EventManagerFromSnap(emSnapshot);
                    return this.userService.getUser(eventManager.userKey)
                        .pipe(take(1), switchMap((emUser) => {
                            if (eventManager.trailAreaKeys) {
                                return this.trailAreaService.getTrailAreas(eventManager.trailAreaKeys)
                                    .pipe(take(1), map((emTrailAreas) => ({
                                        user: emUser,
                                        trailAreas: emTrailAreas
                                    })));
                            } else {
                                return of({user: emUser, trailAreas: []});
                            }
                        }));
                });
                return forkJoin(eventManagerObservables);
            }));
    }

    public getEventManagerTrailAreas(userKey: string): Observable<TrailArea[]> {
        return this.db.object<EventManager>(this.eventManagersRef.child(userKey).ref).snapshotChanges()
            .pipe(
                switchMap((eventManagerSnap) => {
                    const emTrailAreas: Observable<TrailArea>[] = [];
                    const entry: EventManager = this.EventManagerFromSnap(eventManagerSnap);
                    if (entry.trailAreaKeys) {
                        for (const i of entry.trailAreaKeys) {
                            emTrailAreas.push(this.trailAreaService.getTrailArea(i));
                        }
                    }
                    if (emTrailAreas.length === 0) {
                        return of([]);
                    }
                    return zip(emTrailAreas);
                })
            );
    }

    private EventManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<EventManager>>): EventManager {
        const eventManager: EventManager = snapshot.payload.val();
        eventManager.userKey = snapshot.key;
        eventManager.trailAreaKeys = (typeof eventManager.trailAreaKeys === 'object' && eventManager.trailAreaKeys !== null)
            ? Object.values(eventManager.trailAreaKeys) : [];
        return eventManager;
    }

    public addTrailAreaToEventManager(userKey: string, areaKey: string): Promise<void> {
        const area: object = {
            [areaKey]: areaKey
        }
        return this.eventManagersRef.child(userKey).child('trailAreaKeys').update(area);
    }

    public removeTrailAreaFromEventManager(userKey: string, areaKey: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('trailAreaKeys').child(areaKey).remove();
    }

}