<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 col-lg-4">

            <div class="card card-outline card-navy">
                <div class="card-header">
                    <h3 class="card-title"><i class="fas fa-cogs"></i> Settings</h3>
                </div>
                <div class="card-body">
                    <app-alert-info-box infoTrigger="{{ settingsChangedInfo }}">
                        <h4>Adventure settings saved successfully</h4>
                    </app-alert-info-box>

                    <div class="form-group">
                        <div class="custom-control custom-switch">
                            <input [(ngModel)]="_adventure.active" [disabled]="!_adventure.accessProductKey" (change)="settingsChanged()"
                                   id="activeCB" aria-describedby="activeCBHelp" class="custom-control-input" type="checkbox">
                            <label class="custom-control-label" for="activeCB">Active</label>
                        </div>
                        <small class="form-text text-muted" id="activeCBHelp">Whether the adventure is active in the Mountainbike United app.</small>
                        <small *ngIf="!_adventure.accessProductKey" class="form-text text-danger"><i class="fas fa-exclamation-triangle"></i> Please
                            set up pricing for this adventure.</small>
                    </div>

                    <div class="form-group">
                        <div>
                            <label><i class="fas fa-map-marker-alt mr-2"></i> Marker position</label>
                        </div>
                        <div #gMarkerMap class="gmap"></div>
                        <small class="form-text text-muted">The position of the adventure marker.</small>
                    </div>
                    <div class="form-group">
                        <label for="tintColor"><i class="fas fa-map-marker-alt mr-2" [ngStyle]="{'color': _adventure.tintColor}"></i> Marker
                            colour</label>
                        <div class="input-group colorpicker-element" id="tintColorPickerGroup">
                            <input type="text" id="tintColor" aria-describedby="tintColorHelp" class="form-control" [(ngModel)]="_adventure.tintColor"
                                   (change)="settingsChanged()" (keyup)="settingsChanged()">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fas fa-square" [ngStyle]="{'color': _adventure.tintColor}"></i></span>
                            </div>
                        </div>
                        <small class="form-text text-muted" id="tintColorHelp">Colour of the marker on the map.</small>
                    </div>
                </div>
                <div class="card-footer">
                    <button [disabled]="disableSaveSettings" (click)="saveSettings()" class="btn btn-primary">Save settings</button>
                </div>
            </div>

        </div>
        <div class="col-md-6 col-lg-4">

            <app-image-handler imageName="Adventure Image" imageIndex="0" [imageUrl]="_adventure.imageUrl"
                               [imagePath]="'adventureImages/' + _adventure.key + '/'"
                               (uploadedImageUrl)="onUploadedImageUrl($event)"></app-image-handler>

        </div>
        <div class="col-12 col-lg-4">

            <div class="card card-outline card-success">
                <div class="card-header">
                    <h3 class="card-title"><i class="fas fa-map"></i> Adventure Map</h3>
                </div>
                <div class="card-body">

                    <div #gElementsMap class="gmap"></div>

                    <div *ngIf="mapTrails && mapTrails.length" class="form-group">
                        <label>Adventure Trails</label>
                        <div *ngFor="let trail of mapTrails">
                            <input type="checkbox" checked (change)="toggleFeature($event, trail.key)"> {{ trail.name }}
                        </div>
                    </div>
                    <div *ngIf="mapOnlyTrails && mapOnlyTrails.length" class="form-group">
                        <label>Map Only Trails</label>
                        <div *ngFor="let trail of mapOnlyTrails">
                            <input type="checkbox" checked (change)="toggleFeature($event, trail.key)"> {{ trail.name }}
                        </div>
                    </div>

                    <div *ngIf="mapPois && mapPois.length" class="form-group">
                        <label>POIs</label>
                        <div *ngFor="let poi of mapPois">
                            <input type="checkbox" checked (change)="toggleFeature($event, poi.key)"> {{ poi.name }}
                        </div>
                    </div>
                    <div *ngIf="mapOnlyPois && mapOnlyPois.length" class="form-group">
                        <label>Map Only POIs</label>
                        <div *ngFor="let poi of mapOnlyPois">
                            <input type="checkbox" checked (change)="toggleFeature($event, poi.key)"> {{ poi.name }}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <hr>
    <h3><i class="fa fa-puzzle-piece"></i> Adventure elements</h3>

    <div class="row">
        <div class="col-md-12 col-lg-6">

            <div *ngIf="adventureItems" class="card card-outline card-success">
                <div class="card-header">
                    <h3 class="card-title"><i class="fa fa-puzzle-piece"></i> Adventure Elements</h3>
                </div>
                <div class="card-body">
                    <div>
                        These are the actual elements of the adventure. Sort them in the most appropriate order for your adventure.
                    </div>
                    <ul class="todo-list" id="adventureItemList">
                        <li *ngFor="let item of adventureItems" id="{{ item.key }}">
                            <span class="handle">
                                <i class="fa fa-ellipsis-v pr-22"></i>
                                <i class="fa fa-ellipsis-v"></i>
                            </span>
                            <span class="element-order">{{ item.order }}</span>.
                            <i *ngIf="item.refType == 1 && !item.voucherPin" class="fas fa-map-marker-alt"></i>
                            <i *ngIf="item.refType == 1 && item.voucherPin" class="fa fa-gift"></i>
                            <i *ngIf="item.refType == 2" class="fas fa-route"></i>
                            {{ item.name }}
                            <span *ngIf="item.adventurePoints > 0">({{ item.adventurePoints }} points)</span>
                            <div class="tools">
                                <i (click)="setRemoveElementCandidate(item)" class="far fa-trash-alt" title="Delete adventure element"></i>&nbsp;
                                <a routerLink="{{ routerRoot }}/adventures/{{ _adventure.key }}/item/{{ item.key }}"><i class="far fa-edit"
                                                                                                                        title="Edit adventure element"></i></a>&nbsp;
                                <i (click)="copyElementLink(item)" class="far fa-copy black-todolist-item" title="Copy link for use in infolet"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="itemsMapOnly" class="card card-outline card-success">
                <div class="card-header">
                    <h3 class="card-title"><i class="fa fa-puzzle-piece text-muted"></i> Map Only Elements</h3>
                </div>
                <div class="card-body">
                    <div>
                        Map only elements are shown in the app on the adventure map, but they are not included in the adventure. This could be
                        connecting roads, lifts, water taps or toilets.
                    </div>
                    <ul class="todo-list">
                        <li *ngFor="let item of itemsMapOnly">
                            <i *ngIf="item.refType == 1" class="fas fa-map-marker-alt"></i>
                            <i *ngIf="item.refType == 2" class="fas fa-route"></i>
                            {{ item.name }}
                            <div class="tools">
                                <i (click)="setRemoveElementCandidate(item)" class="far fa-trash-alt" title="Delete map element"></i>&nbsp;
                                <a routerLink="{{ routerRoot }}/adventures/{{ _adventure.key }}/item/{{ item.key }}"><i class="far fa-edit"
                                                                                                                        title="Edit map element"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="col-md-12 col-lg-6">

            <div *ngIf="_trailAreas || (_trails && _pois)" class="card card-success">
                <div class="card-header">
                    <h3 class="card-title">+ <i class="fa fa-puzzle-piece"></i> Add new Adventure Element or Map Only Element</h3>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <div>
                            <label for="mapOnly"><input [(ngModel)]="newItemMapOnly" type="checkbox" name="mapOnly" id="mapOnly"
                                                        aria-describedby="mapOnlyHelp" [disabled]="newItemVoucherPin"> Map only</label>
                        </div>
                        <small class="form-text text-muted" id="mapOnlyHelp">Whether this element is not an active part of the adventure, but rather a
                            nice to have in relation to the adventure. Will be shown on the map in Adventure Mode, but will only be limited
                            interactive. Use it for connecting roads, toilets etc.</small>
                    </div>

                    <div class="form-group">
                        <label>Time box</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
								<span class="input-group-text">
									<input [(ngModel)]="newItemIsTimeBoxed" type="checkbox">&nbsp;<i class="far fa-clock"></i>
								</span>
                            </div>
                            <input [disabled]="!newItemIsTimeBoxed" type="text" class="form-control" id="timebox" aria-describedby="timeboxHelp">
                        </div>
                        <small class="form-text text-muted" id="timeboxHelp">Use time box if the adventure element is only available or relevant for a
                            limited amount of time.</small>
                    </div>

                    <label>Type</label>
                    <div class="form-group">
                        <div class="input-group">
                            <span class="input-group-prepend">
								<span class="input-group-text">
									<input [(ngModel)]="newItemType" aria-describedby="trailHelp" type="radio" name="newItemType" value="trail">&nbsp;Trail
								</span>
                            </span>
                            <span *ngIf="_trailAreas" class="input-group-prepend"><i
                                    [ngClass]="'input-group-text ' + layout.getIcon('trailArea')"></i></span>
                            <select *ngIf="_trailAreas" [(ngModel)]="newItemTrailsTrailAreaKey" name="trailsTrailArea"
                                    class="form-control input-group-prepend" [disabled]="newItemType !== 'trail'" (change)="loadTrails()">
                                <option value="none">-- Please select trail area --</option>
                                <option *ngFor="let area of _trailAreas" [value]="area.key">{{ area.name }}</option>
                            </select>
                            <span class="input-group-prepend"><i [ngClass]="'input-group-text ' + layout.getIcon('trail')"></i></span>
                            <select [(ngModel)]="newItemTrailKey" name="trailKey" class="form-control"
                                    [disabled]="newItemType !== 'trail' || !_trails">
                                <option value="none">-- Please select trail --</option>
                                <option *ngFor="let trail of _trails"
                                        [value]="trail.key">{{ (trail.active ? '' : '(not active in app) ') + trail.name }}</option>
                            </select>
                        </div>
                        <small class="form-text text-muted" id="trailHelp">Choose this to create a "Map only Trail" or an Adventure Trail.<br>Note
                            that it is possible to use non active trails in an adventure.</small>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <span class="input-group-prepend">
								<span class="input-group-text">
									<input [(ngModel)]="newItemType" aria-describedby="poiHelp" type="radio" name="newItemType" value="poi">&nbsp;POI
								</span>
                            </span>
                            <span *ngIf="_trailAreas" class="input-group-prepend"><i
                                    [ngClass]="'input-group-text ' + layout.getIcon('trailArea')"></i></span>
                            <select *ngIf="_trailAreas" [(ngModel)]="newItemPoisTrailAreaKey" name="poisTrailArea" class="form-control pois-select"
                                    [disabled]="newItemType !== 'poi'" (change)="loadPois()">
                                <option value="none">-- Please select trail area --</option>
                                <option *ngFor="let area of _trailAreas" [value]="area.key">{{ area.name  }}</option>
                            </select>
                            <span class="input-group-prepend"><i [ngClass]="'input-group-text ' + layout.getIcon('poi')"></i></span>
                            <select [(ngModel)]="newItemPoiKey" name="poiKey" class="form-control pois-select"
                                    [disabled]="newItemType !== 'poi' || !_pois">
                                <option value="none">-- Please select POI --</option>
                                <option *ngFor="let poi of _pois" [value]="poi.key">{{ poi.name }}</option>
                            </select>
                            <span class="input-group-prepend">
								<span class="input-group-text">
									<input [(ngModel)]="newItemVoucherPin" type="checkbox" name="newItemVoucherPin"
                                           [disabled]="newItemType!=='poi' || newItemMapOnly">&nbsp;Treat-pin:
								</span>
                            </span>
                            <span class="input-group-prepend">
								<input [(ngModel)]="pin1" type="number" min="0" max="9" [disabled]="!newItemVoucherPin || newItemType!=='poi'">
                            </span>
                            <span class="input-group-prepend">
								<input [(ngModel)]="pin2" type="number" min="0" max="9" [disabled]="!newItemVoucherPin || newItemType!=='poi'">
                            </span>
                            <span class="input-group-prepend">
								<input [(ngModel)]="pin3" type="number" min="0" max="9" [disabled]="!newItemVoucherPin || newItemType!=='poi'">
                            </span>
                            <span class="input-group-append">
								<input [(ngModel)]="pin4" type="number" min="0" max="9" [disabled]="!newItemVoucherPin || newItemType!=='poi'">
                            </span>
                        </div>
                        <small class="form-text text-muted" id="poiHelp">Choose this to create a "Map only POI", an Adventure POI or an Adventure
                            Treat (with the Treat-pin).</small>
                    </div>

                    <div class="form-group">
                        <div>
                            <label for="adventurePoints">Adventure Points</label>
                            <div>
                                <input [(ngModel)]="newItemAdventurePoints" [disabled]="newItemMapOnly && !newItemVoucherPin" id="adventurePoints"
                                       aria-describedby="adventurePointsHelp" type="number" min="0" max="1000">
                            </div>
                        </div>
                        <small class="form-text text-muted" id="adventurePointsHelp">Used to sort explorers.</small>
                    </div>

                    <div class="form-group">
                        <label for="newItemName">Name</label>
                        <div>
                            <input [(ngModel)]="newItemName" id="newItemName" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        <div>
                            <label for="description">Description</label>
                            <textarea [(ngModel)]="newItemDescription" id="description" aria-describedby="descriptionHelp" class="form-control"
                                      rows="5" placeholder="Description of the reason to go here..."></textarea>
                        </div>
                        <small class="form-text text-muted" id="descriptionHelp">Describe the purpose of the adventure element.</small>
                    </div>
                </div>
                <div class="card-footer">
                    <button (click)="createNewItem()" class="btn btn-danger">Create new adventure element</button>
                </div>
            </div>

        </div>
    </div>

    <hr>
    <h3><i class="fas fa-language"></i> Adventure descriptions and infolets</h3>

    <div class="row">
        <div class="col-md-12 col-lg-6">

            <app-translatable-texts textsName="Adventure" colorTheme="teal" infoTrigger="{{ textsChangedInfo }}" [textModel]="textModel"
                                    [textObject]="_adventure" (alteredTextObject)="onAlteredTextObject($event)"></app-translatable-texts>

        </div>
        <div *ngFor="let infolet of _adventure.infolets; let i = index" class="col-md-12 col-lg-6">

            <div class="card card-outline card-teal">
                <div class="card-header">
                    <h3 class="card-title"><i class="far fa-file-alt"></i> Infolet {{ i + 1 }}/3<span
                            *ngIf="infolet.tabName">: {{ infolet.tabName }}</span></h3>
                </div>
                <div class="card-body">
                    <app-alert-info-box infoTrigger="{{ infoletChangedInfo[i] }}">
                        <h4>Infolet {{ i + 1 }} saved successfully</h4>
                    </app-alert-info-box>

                    <div class="form-group">
                        <div class="custom-control custom-switch">
                            <input [(ngModel)]="infolet.active" (change)="infoletChanged(i, false, $event)" [id]="'activeInfoletCB' + i"
                                   [attr.aria-describedby]="'activeInfoletCBHelp' + i" class="custom-control-input" type="checkbox">
                            <label class="custom-control-label" [for]="'activeInfoletCB' + i">Active</label>
                        </div>
                        <small class="form-text text-muted" [id]="'activeInfoletCBHelp' + i">Whether this infolet-tab is shown in the Mountainbike
                            United app.</small>
                    </div>

                    <div class="form-group">
                        <strong><label [for]="'tabName'+i">Tab name in app</label></strong>
                        <input [(ngModel)]="infolet.tabName" (change)="infoletChanged(i, false, $event)" [id]="'tabName'+i"
                               [attr.aria-describedby]="'tabNameHelp'+i" class="form-control" placeholder="Tab name" style="width:100px">
                        <small class="form-text text-muted" [id]="'tabNameHelp' + i">Note: Keep the tab name very short!</small>
                    </div>

                    <div class="form-group">
                        <strong><label>Tab content type</label></strong>
                        <div>
                            <input type="radio" value="editor" [(ngModel)]="infolet.type" (change)="infoletChanged(i, false, $event)"
                                   [name]="'infoletType'+i"> Editor
                        </div>
                        <div>
                            <input type="radio" value="raw" [(ngModel)]="infolet.type" (change)="infoletChanged(i, false, $event)"
                                   [name]="'infoletType'+i"> Raw HTML
                        </div>
                        <div>
                            <input type="radio" value="ranking" [(ngModel)]="infolet.type" (change)="infoletChanged(i, false, $event)"
                                   [name]="'infoletType'+i"> Ranking (automatic)
                        </div>
                        <small class="form-text text-muted">Choose the content format of the infolet.</small>
                    </div>

                    <div *ngIf="infolet.type === 'editor'" class="form-group">
                        <strong><label [for]="'infoletBodyContent'+i">Tab content - editor input</label></strong>
                        <ckeditor [editor]="Editor" [data]="infolet.bodyContent" (change)="infoletChanged(i, true, $event)"
                                  [id]="'infoletBodyContent' + i" [attr.aria-describedby]="'infoletBodyContentHelp' + i"></ckeditor>
                        <small class="form-text text-muted" [id]="'infoletBodyContentHelp' + i">Hint: If you want to link to an Adventure Element,
                            simply hover it and click the <i class="far fa-copy" title="Adventure-element copy button"></i>-button - then paste it as
                            a link-address in this editor.</small>
                    </div>
                    <div *ngIf="infolet.type === 'raw'" class="form-group">
                        <strong><label [for]="'infoletContent'+i">Tab content - raw HTML</label></strong>
                        <textarea [(ngModel)]="infolet.content" [id]="'infoletContent'+i"
                                  [attr.aria-describedby]="'infoletContentHelp' + i"
                                  style="width: 100%; height: 200px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;"></textarea>
                        <small class="form-text text-muted" [id]="'infoletContentHelp' + i">Raw HTML - include the entire HTML.</small>
                    </div>

                </div>
                <div class="card-footer">
                    <button [disabled]="disableSaveInfolet[i]" (click)="saveInfolet(i)" class="btn btn-primary">Save infolet {{ i + 1 }}</button>
                </div>
            </div>

        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-12">

            <div class="alert alert-danger alert-dismissible">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h4><i class="icon fa fa-warning"></i> Adventure pricing is currently not supported</h4>
            </div>

        </div>
    </div>

    <app-kiosk-product-handler [productCategory]="productCategory" [referenceType]="referenceType" [referenceKey]="_adventure.key"
                               [kioskProductKey]="_adventure.accessProductKey" handlerTitle="Adventure Pricing"
                               [defaultName]="'Access to ' + _adventure.name" [enableActivationCodes]="true"
                               (kioskProductCreated)="onKioskProductCreated($event)" [enableUpsell]="false"></app-kiosk-product-handler>

</div>

<div class="modal fade" id="modal-remove" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Delete element from adventure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p *ngIf="removeElementCandidate && _adventure">Are you sure you want to remove {{ removeElementCandidate.name }}
                    from {{ _adventure.name }}?</p>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-default" data-dismiss="modal">No, don't remove</button>
                <button type="button" class="btn btn-primary" (click)="removeElementFromAdventure(removeElementCandidate.key)"
                        *ngIf="removeElementCandidate">Yes, remove
                </button>
            </div>
        </div>
    </div>
</div>
