import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
// services
import {CountryService} from "../../firebase-services/country.service";
import {FileService} from "../../services/file.service";
// interfaces
import {CountriesImportCandidate, Country, NewCountry} from "../../interfaces/countries";

@Component({
    selector: 'dev-tools',
    templateUrl: './dev-tools.component.html',
    styleUrls: ['./dev-tools.component.css']
})
export class DevToolsComponent implements OnDestroy, OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();

    countriesInDb: Country[];
    private selectedCountriesFile: File = null;
    countriesImportDataCandidates: CountriesImportCandidate[];
    dataReadyForImport = false;

    constructor(
        private fileService: FileService,
        private countryService: CountryService
    ) {
    }

    ngOnInit(): void {
        this.getAllCountries()
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getAllCountries() {
        this.countryService.getCountries().pipe(takeUntil(this.destroy$)).subscribe((countries) => {
            this.countriesInDb = countries;
            console.log('Countries already in database: ');
            console.log(this.countriesInDb);
        });
    }

    onCountriesFileSelected(files: FileList) {
        this.selectedCountriesFile = this.fileService.onFileSelected(files, 'text/csv');
        if (this.selectedCountriesFile !== null) {
            $('#filenameCountries').val(this.selectedCountriesFile.name);
            const reader = new FileReader();
            reader.onload = () => {
                let text = reader.result;
                const countriesImportDataJson = this.csvJSON(text);
                this.countriesImportDataCandidates = JSON.parse(countriesImportDataJson);
                console.log('Countries import data candidates: ');
                console.log(this.countriesImportDataCandidates);
                if (this.countriesImportDataCandidates) this.dataReadyForImport = true;
            };
            reader.readAsText(this.selectedCountriesFile);
        } else {
            $('#filenameCountries').val('');
        }
    }

    importAllCountries() {
        console.log('*** Beginning of import ***');
        let countriesAdded = 0;
        let countriesExcluded = 0;
        let countryDuplicates = 0;
        for (let country of this.countriesImportDataCandidates) {
            let countryExists = false;
            for (let existingCountry of this.countriesInDb) {
                if (country.country.toLowerCase() === existingCountry.countryCode) {
                    console.log('Duplicate country found: ' + country.country);
                    countryDuplicates++;
                    countryExists = true;
                    break;
                }
            }
            if (!countryExists) {
                if (country.country && country.name && country.latitude && country.longitude) {
                    const newCountry: NewCountry = {
                        countryCode: country.country.toLowerCase(),
                        name: country.name.replace(/"/g, ''),
                        lat: country.latitude,
                        lng: country.longitude
                    }
                    this.countryService.addNewCountry(newCountry).then(() => {
                        console.log('Country added: ' + country.country);
                        countriesAdded++;
                    }).catch((e) => {
                        console.log('An error occurred while adding country: ' + country.country + ' ' + e)
                    });
                } else {
                    console.log('Country was excluded for missing data: ' + country.country);
                    countriesExcluded++;
                }
            }
        }
        console.log('*** Import finished ***\nReport \nCountry duplicates found: ' + countryDuplicates + '\nCountries added: ' + countriesAdded + '\nCountries excluded: ' + countriesExcluded);
    }

    public csvJSON(csv) {
        const lines = csv.split("\n");
        const result = [];
        const headers = lines[0].split(",");
        for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentLine = lines[i].split(",");
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentLine[j];
            }
            result.push(obj);
        }
        return JSON.stringify(result);
    }
}