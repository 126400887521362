import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';

// Services
import { AuthService } from '../../core/auth.service';
import { DestinationService } from '../../firebase-services/destination.service';
import { UserService } from '../../firebase-services/user.service';

// Interfaces

@Component({
    selector: 'app-new-destination',
    templateUrl: './new-destination.component.html',
    styleUrls: ['./new-destination.component.css']
})
export class NewDestinationComponent {

    destinationName: string = '';
    destinationPromoted: boolean = false;
    destinationAdventuresEnabled: boolean = false;

    disableCreate: boolean = true;

    constructor(
        private authService: AuthService,
        private router: Router,
        private destinationService: DestinationService,
        private userService: UserService
    ) {
    }

    valueChanged() {
        this.disableCreate = !(this.destinationName && this.destinationName.length);
    }

    createDestination() {
        this.disableCreate = true;
        var newDestinationThenableReference = this.destinationService.createDestination(this.destinationName, this.destinationPromoted, this.destinationAdventuresEnabled);

        var addAdminPromise = this.userService.addDestinationAdmin(this.authService.user.userID, newDestinationThenableReference.key, this.authService.user.userID);

        Promise.all([newDestinationThenableReference, addAdminPromise])
            .then(() => this.router.navigate(['/destination/' + newDestinationThenableReference.key + '/settings']))
            .catch(err => alert(err));
    }
}
