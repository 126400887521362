import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '../../../core/auth.service';

// Interfaces
import { TrailArea } from '../../../interfaces/trailArea';

@Component({
    selector: 'app-trail-area-modify-story',
    templateUrl: './trail-area-modify-story.component.html',
    styleUrls: ['./trail-area-modify-story.component.css']
})
export class TrailAreaModifyStoryComponent implements OnInit {

    area: TrailArea = null;
    newsStoryKey: string = null;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.newsStoryKey = this.route.snapshot.paramMap.get('newsStoryKey');
        let trailAreaKey = this.route.snapshot.paramMap.get('trailAreaKey');

        // Get the trail area
        this.area = this.authService.getTrailArea(trailAreaKey);
    }

}
