import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

@Injectable({
    providedIn: 'root'
})
export class RemoteConfigService {

    constructor(
        private rc: AngularFireRemoteConfig,
    ) {
    }

    getMinimumVersions(): Promise<string> {
        return this.rc.fetchAndActivate()
            .then(() => this.rc.getString('minimumAppVersion'));
    }
}
