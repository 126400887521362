<div class="content-wrapper">
    <app-content-header-section *ngIf="organisation" contentPageId="organisationMembership"
                                [pageParams]="{organisationKey: organisation.key, organisationName: organisation.name}"></app-content-header-section>

    <section class="content">
        <div *ngIf="organisation" class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-success">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-cogs"></i> Organisation settings</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ settingsChangedInfo }}">
                                <h4>Organisation settings saved successfully</h4>
                            </app-alert-info-box>

                            <div class="form-group">
                                <strong><label for="name">Name</label></strong>
                                <input [(ngModel)]="organisation.name" (change)="settingsChanged()" (keyup)="settingsChanged()" id="name"
                                       aria-describedby="nameHelp" class="form-control" placeholder="Your organisations name"/>
                                <small class="form-text text-muted" id="nameHelp">The name of the organisation.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveSettings" (click)="saveSettings()" class="btn btn-primary">Save settings</button>
                        </div>
                    </div>

                </div>
            </div>

            <hr/>

            <app-kiosk-product-handler [productCategory]="productCategory" [referenceType]="referenceType" [referenceKey]="organisation.key"
                                       [kioskProductKey]="organisation.membershipProductKey" handlerTitle="Membership Pricing"
                                       [defaultName]="organisation.name + ' membership'" [enableActivationCodes]="true"
                                       (kioskProductCreated)="onKioskProductCreated($event)" [recurring]="true"
                                       [enableUpsell]="false" [bundleWith]="bundleWidth"></app-kiosk-product-handler>

        </div>
    </section>
</div>
