import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { KioskOrderService } from '../../firebase-services/kiosk-order.service';

// Interfaces
import { KioskOrder } from '../../interfaces/kiosk';

declare var $: any;

@Component({
    selector: 'app-kiosk-orders',
    templateUrl: './kiosk-orders.component.html',
    styleUrls: ['./kiosk-orders.component.css']
})
export class KioskOrdersComponent implements OnInit, AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    kioskOrders: KioskOrder[] = null;
    dataTableCreated = false;
    kioskOrdersDataTable: any = null;
    dataReady = false;
    kioskOrdersDataTableName = '#kioskOrdersDataTable';

    constructor(
        private kioskOrderService: KioskOrderService
    ) {
    }

    ngOnInit() {
        this.getKioskOrders();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.kioskOrdersDataTableName + '>tbody>tr').length > 0)) {
            this.kioskOrdersDataTable = $(this.kioskOrdersDataTableName).DataTable({
                'order': [[0, 'desc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private getKioskOrders() {
        this.kioskOrderService.getKioskOrders()
            .pipe(takeUntil(this.destroy$))
            .subscribe(kioskOrders => this.setKioskOrders(kioskOrders));
    }

    private setKioskOrders(kioskOrders: KioskOrder[]) {
        if (kioskOrders.length === 0) {
            this.dataReady = true;
        }

        this.kioskOrders = kioskOrders;
        if (this.dataTableCreated && this.kioskOrdersDataTable !== null) {
            this.kioskOrdersDataTable.destroy();
            this.dataTableCreated = false;
        }
        this.dataReady = true;
    }

}
