import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { take, map } from 'rxjs/operators';

// Services
import { AuthService } from '../core/auth.service';
import { ProfileDataService } from '../firebase-services/profile-data.service';
import { CountryService } from '../firebase-services/country.service';
import { TrailAreaService } from '../firebase-services/trail-area.service';

// Interfaces
import { Country } from '../interfaces/countries';
import { DefaultMapProp } from '../interfaces/map';
import { TrailArea, TrailAreaShapes } from '../interfaces/trailArea';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    @ViewChild('gmap') gmapElement: ElementRef;
    map: google.maps.Map;
    infoWindow: google.maps.InfoWindow;

    country: Country = null;
    trailAreasShapes: TrailAreaShapes = {};
    trailAreas: { [trailAreaKey: string]: TrailArea } = {};
    riderVisitedTrailAreaKeys: string[] = null;

    constructor(
        public authService: AuthService,
        private profileDataService: ProfileDataService,
        private countryService: CountryService,
        private trailAreaService: TrailAreaService
    ) {
    }

    ngOnInit() {
        this.infoWindow = new google.maps.InfoWindow({disableAutoPan: false});
        this.getUserCountry();
    }

    private getUserCountry() {
        this.countryService.getCountry(this.authService.user.deviceCountry.toLowerCase())
            .subscribe((country) => {
                this.getVisitedTrailAreas();
                this.country = country;
                this.map = new google.maps.Map(this.gmapElement.nativeElement, DefaultMapProp);
                this.map.fitBounds({
                    north: country.boundsNorth,
                    south: country.boundsSouth,
                    east: country.boundsEast,
                    west: country.boundsWest
                }, {bottom: 1, left: 1, right: 1, top: 1});
                this.drawCountryShapes();
            });
    }

    private getVisitedTrailAreas() {
        this.profileDataService.getVisitedTrailAreaKeys(this.authService.user.userID)
            .pipe(take(1))
            .subscribe((visitedTrailAreaKeys) => this.riderVisitedTrailAreaKeys = visitedTrailAreaKeys);
    }

    private drawCountryShapes() {
        this.trailAreaService.loadTrailAreaShapes(this.country.countryCode)
            .pipe(
                take(1),
                map((trailAreasShapes) => {
                    const trailAreaKeys: string[] = Object.keys(trailAreasShapes);
                    if (trailAreaKeys.length === 0) {
                        return null;
                    }
                    this.trailAreasShapes = trailAreasShapes;
                    return this.loadTrailAreasFromKeys(trailAreaKeys);
                })
            )
            .subscribe();
    }

    private loadTrailAreasFromKeys(keys: string[]) {
        this.trailAreaService.getTrailAreas(keys)
            .pipe(
                map((trailAreas) => {
                    const trailAreaKeys = Object.keys(this.trailAreasShapes);
                    trailAreas.forEach((trailArea) => {
                        for (let i = 0; i < trailAreaKeys.length; i++) {
                            if (trailAreaKeys[i] === trailArea.key) {
                                const trailAreaPolygon: google.maps.Polygon = new google.maps.Polygon({
                                    paths: this.trailAreasShapes[trailArea.key],
                                    fillColor: this.getTrailAreaFillColor(trailArea.key),
                                    fillOpacity: this.getTrailAreaFillOpacity(trailArea.key),
                                    strokeColor: 'black',
                                    strokeOpacity: 0.2,
                                    strokeWeight: 1,
                                    map: this.map
                                });
                                trailAreaPolygon.addListener('mouseover', () => {
                                    this.infoWindow.setContent('<div>Name: <strong>' + trailArea.name + '</strong></div>');
                                    this.infoWindow.setPosition({
                                        lat: (trailArea.boundsNorth + trailArea.boundsSouth) / 2,
                                        lng: (trailArea.boundsEast + trailArea.boundsWest) / 2
                                    });
                                    this.infoWindow.open(this.map);
                                });
                                trailAreaPolygon.addListener('mouseout', () => {
                                    this.infoWindow.close();
                                });
                                trailAreaKeys.splice(i, 1);
                                break;
                            }
                        }
                    });

                })
            )
            .subscribe();
    }

    // Conquer the map functions:
    private getTrailAreaFillOpacity(trailAreaKey: string): number {
        return this.riderVisitedTrailAreaKeys.includes(trailAreaKey) ? 0.5 : 0;
    }

    private getTrailAreaFillColor(trailAreaKey: string): string {
        return this.riderVisitedTrailAreaKeys.includes(trailAreaKey) ? '#00ff17' : '#CCCCCC';
    }
}
