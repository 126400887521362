import { AfterViewChecked, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

// Services
import { SponsorService } from "src/app/firebase-services/sponsor.service";

// Interfaces
import {
  Sponsor,
  SponsorTargetType,
  SponsorTargetType2LabelMapping,
} from "src/app/interfaces/sponsor";
import {
  TextModel,
  TextModelItemType,
  TextObject,
} from "../../interfaces/text-model";
import { TrailArea } from "src/app/interfaces/trailArea";
import { forEach } from "jszip";
import { TrailAreaService } from "src/app/firebase-services/trail-area.service";

declare var $: any;

@Component({
  selector: "app-sponsor",
  templateUrl: "./sponsor.component.html",
  styleUrls: ["./sponsor.component.css"],
})
export class SponsorComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  objectKeys = Object.keys; // For use in HTML
  treatsDataTableName = "#treatsDataTable";
  now: number = Date.now();

  public sponsorTargetType2LabelMapping = SponsorTargetType2LabelMapping;

  sponsors: Sponsor[] = null;
  allIsLoaded = false;
  dataTableCreated = false;
  dataTable: any = null;

  textModel: TextModel = { items: [] };
  textObject: TextObject;
  textsChangedInfo = 0;

  constructor(
    private router: Router,
    private sponsorService: SponsorService,
    private trailAreaService: TrailAreaService,
    private domSanitizer: DomSanitizer
  ) {
    this.initTextModel();
  }

  ngOnInit() {
    this.sponsorService
      .getSponsors()
      .pipe(takeUntil(this.destroy$))
      .subscribe((sponsors) => {
        this.sponsors = sponsors;
        this.allIsLoaded = true;
      });
    // this.treatService.getTreatsTabTexts()
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe((treatsTabTexts) => this.textObject = treatsTabTexts);
  }

  ngAfterViewChecked() {
    if (!this.dataTableCreated && $("#treatsDataTable>tbody>tr").length > 0) {
      this.dataTable = $(this.treatsDataTableName).DataTable({
        order: [[2, "desc"]],
        columnDefs: [{ orderable: false, targets: 0 }],
      });
      this.dataTableCreated = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initTextModel(): void {
    this.textModel.items.push({
      name: "Title",
      varName: "title",
      help: "Title of the treats tab",
      placeholder: "Our Treats for You",
      type: TextModelItemType.INPUT,
    });
    this.textModel.items.push({
      name: "Description",
      varName: "description",
      help: "Subtitle / description on the treats tab",
      placeholder: "Our list of treats contains valuable offers for you.",
      type: TextModelItemType.TEXT_AREA,
    });
  }

  getSponsorTargetSpec(sponsor: Sponsor): string {
    console.log("bla");
    var textString: string = "<ul>";
    switch (sponsor.sponsorType) {
      case SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED:
        const value = "No target selected";
        textString.concat("<li>" + value.toString() + "</li>");
        var str = "</ul>";
        return textString.concat(str.toString());

      case SponsorTargetType.TRAIL_SPECIFIC_SPONSOR:
        var selectedTrailAreas: TrailArea[] = [];

        if (sponsor.trailTargetKeys) {
          //   this.trailAreaService.getTrailAreas(sponsor.trailTargetKeys)
          //   .pipe(take(1))
          //   .subscribe((trailAreas) => {
          //   trailAreas.forEach( function (value)  {
          //     textString = textString.concat('<li>' + value.name.toString()+'</li>');
          //   });

          //   var str =  "</ul>";
          //   return textString.concat(str.toString());

          // });

          sponsor.trailTargetKeys.forEach(function (value) {
            textString = textString.concat("<li>" + value.toString() + "</li>");
          });
          var str = "</ul>";
          return textString.concat(str.toString());
        }
      case SponsorTargetType.AREA_SPECIFIG_SPONSOR:
        if (sponsor.latitude && sponsor.longitude && sponsor.radius) {
          textString = textString.concat(
            "<li>" + "latitude: " + sponsor.latitude.toString() + "</li>"
          );
          textString = textString.concat(
            "<li>" + "longitude: " + sponsor.longitude.toString() + "</li>"
          );
          textString = textString.concat(
            "<li>" + "radius: " + sponsor.radius.toString() + "</li>"
          );
        }
        var str = "</ul>";
        return textString.concat(str.toString());

      case SponsorTargetType.COUNTRY_SPECIFIG_SPONSOR:
        if (sponsor.country) {
          textString = textString.concat(
            "<li>" + sponsor.country.toString() + "</li>"
          );
        } else {
          var str = "No country chosen";
          textString = textString.concat(str.toString());
        }
        var str = "</ul>";
        return textString.concat(str.toString());
    }
  }

  setProgressWidth(sponsor: Sponsor) {
    const width = Math.round(
      (100 * (this.now - sponsor.activeFrom)) /
        (sponsor.activeTo - sponsor.activeFrom)
    );
    return this.domSanitizer.bypassSecurityTrustStyle("width: " + width + "%");
  }

  copySponsor(sponsor: Sponsor): Promise<any> {
    return this.sponsorService
      .copySponsor(sponsor)
      .then((newSponsorKey) =>
        this.router.navigate(["/root/sponsor/" + newSponsorKey])
      );
  }

  createNewCampaign(): Promise<any> {
    return this.sponsorService
      .newSponsor()
      .then((newSponsorKey) =>
        this.router.navigate(["/root/sponsor/" + newSponsorKey])
      );
    // return this.router.navigate(['/root/sponsor/sponsor-edit']);
  }

  // return this.treatService.copyTreat(treat)
  // .then((newTreatKey) => this.router.navigate(['/root/treats/' + newTreatKey]));

  //   createNewCampaign(): void {
  //     // if (typeof this.newTitle !== 'string' || this.newTitle === '') {
  //     //     alert('Please give your news story a title');
  //     //     return;
  //     // }
  //     //this.newsStory.title = this.newTitle;
  //    // this.newsStory.disableInteractions = true;
  //    // var newKey = this.newsStoryService.createNewsStory(this.newsStory, this.roomName, this.authService.user);
  //     this.router.navigate(['/root/sponsor/' + 'new']);
  // }
}
