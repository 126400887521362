import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { combineLatest, Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

// Services
import {CountryService} from './country.service';
import {DestinationService} from './destination.service';
import {OrganisationService} from './organisation.service';

// Interfaces
import {
    AdminUser,
    EmailExtractUserAddress,
    RootDatabaseEntry,
    RootUser,
    User,
    UserAdminRightCountryManagerEntry,
    UserAdminRightDestinationEntry,
    UserAdminRightOrganisationEntry,
    UserAdminRights,
    UserReview
} from '../interfaces/user';
import {LastWeekTotalKpi} from '../interfaces/general';
import {Country} from '../interfaces/countries';
import {Destination} from '../interfaces/destination';
import {TrailArea} from '../interfaces/trailArea';
import {Organisation} from '../interfaces/organisation';

import {Md5} from 'ts-md5/dist/md5';
import firebase from 'firebase/compat';

const BATCH_SIZE = 500;

@Injectable({
    providedIn: 'root'
})
export class UserService {
    profileRef: firebase.database.Reference;
    userAdminRef: firebase.database.Reference;
    secureUserDataRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private httpClient: HttpClient,
        private countryService: CountryService,
        private destinationService: DestinationService,
        private organisationService: OrganisationService
    ) {
        this.profileRef = this.db.database.ref('profile');
        this.userAdminRef = this.db.database.ref('userGroups/admin');
        this.secureUserDataRef = this.db.database.ref('secure/userData');
    }

    /**
     * Gets a user from a key
     */
    getUser(profileKey: string, ensureImage: boolean = true): Observable<User> {
        return this.db.object<User>(this.profileRef.child(profileKey).ref).valueChanges()
            .pipe(
                switchMap((profile) => {
                    if (typeof profile !== 'object' || profile === null) {
                        return of(null);
                    }
                    if (!ensureImage) {
                        return of(profile);
                    }
                    return this.ensureProfileImage(profile);
                })
            );
    }

    ensureProfileImage(profile: User): Observable<User> {
        if (!profile.userID) {
            console.error('Invalid user!', profile);
            profile.userPicture = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&s=128&f=y';
            return of(profile);
        }
        return this.httpClient.get(profile.userPicture, {responseType: 'text'})
            .pipe(
                map(() => profile),
                catchError((error) => {
                    return this.getProfileEmailFromKey(profile.userID)
                        .pipe(
                            map((email) => {
                                profile.userPicture = 'https://www.gravatar.com/avatar/' + Md5.hashStr(email.trim().toLowerCase()) + '?d=wavatar&s=128';
                                console.warn('Profile missing image - using:', profile.userPicture, error);
                                return profile;
                            }),
                            catchError((error2) => {
                                profile.userPicture = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&s=128&f=y';
                                console.error('Profile email also missing', profile.userPicture, error2);
                                return of(profile);
                            })
                        );
                })
            );
    }

    getUsers(profileKeys: string[]): Observable<User[]> {
        return combineLatest(profileKeys.map((profileKey) => this.getUser(profileKey)));
    }

    getUsersInRange(fromKey: string, toKey: string): Observable<User[]> {
        return this.db.list<User>(this.profileRef.ref, ref => ref.orderByKey().startAt(fromKey).endAt(toKey)).valueChanges();
    }

    /**
     * Gets an admin-user from a profile key
     */
    getAdminUser(profileKey: string): Observable<AdminUser> {
        return this.db.object(this.userAdminRef.child(profileKey).ref).valueChanges().pipe(
            switchMap((adminUser) => {
                if (adminUser) {
                    return this.getUser(profileKey).pipe(
                        switchMap((user) => {
                            return this.db.object<UserAdminRights>(this.userAdminRef.child(profileKey).ref).valueChanges()
                                .pipe(
                                    map((adminRights) => {
                                        user.isOnTrail = adminRights.ontrail;
                                        return user;
                                    })
                                );
                        })
                    );
                }
                return of(null);
            })
        );
    }

    getUserAdminCountries(profileKey: string): Observable<Country[]> {
        return this.db.list<UserAdminRightCountryManagerEntry>(this.userAdminRef.child(profileKey).child('countries').ref).snapshotChanges()
            .pipe(
                switchMap((countryEntries) => {
                    const countryObservables: Observable<Country>[] = [];
                    countryEntries.forEach((countryEntry) => {
                        countryObservables.push(this.countryService.getCountry(countryEntry.key));
                    });
                    if (countryObservables.length === 0) {
                        return of(null);
                    }
                    return zip(...countryObservables);
                })
            );
    }

    getUserAdminDestinations(profileKey: string): Observable<Destination[]> {
        return this.db.list<UserAdminRightDestinationEntry>(this.userAdminRef.child(profileKey).child('destinations').ref).snapshotChanges()
            .pipe(
                switchMap((destinationEntries) => {
                    const destinationObservables: Observable<Destination>[] = [];
                    destinationEntries.forEach((destinationEntry) => {
                        destinationObservables.push(this.destinationService.getDestination(destinationEntry.key));
                    });
                    if (destinationObservables.length === 0) {
                        return of(null);
                    }
                    return zip(...destinationObservables);
                })
            );
    }

    getUserAdminOrganisations(profileKey: string): Observable<Organisation[]> {
        return this.db.list<UserAdminRightOrganisationEntry>(this.userAdminRef.child(profileKey).child('organisations').ref).snapshotChanges()
            .pipe(
                switchMap((organisationEntries) => {
                    const organisationObservables: Observable<Organisation>[] = [];
                    organisationEntries.forEach((organisationEntry) => {
                        organisationObservables.push(this.organisationService.getOrganisation(organisationEntry.key));
                    });
                    if (organisationObservables.length === 0) {
                        return of(null);
                    }
                    return zip(...organisationObservables);
                })
            );
    }

    getTrailAreaManagers(trailAreaKey: string): Observable<User[]> {
        return this.db.list<UserAdminRights>(this.userAdminRef.ref).snapshotChanges()
            .pipe(
                switchMap((usersAdminRightsSnap) => {
                    const trailAreaManagers: Observable<User>[] = [];
                    for (const userAdminRightsSnap of usersAdminRightsSnap) {
                        const adminEntry: UserAdminRights = userAdminRightsSnap.payload.val();
                        if (adminEntry.trackGroups) {
                            for (const i in adminEntry.trackGroups) {
                                if (adminEntry.trackGroups[i].trackGroupId === trailAreaKey) {
                                    trailAreaManagers.push(this.getUser(userAdminRightsSnap.key));
                                    break;
                                }
                            }
                        }
                    }
                    if (trailAreaManagers.length === 0) {
                        return of([]);
                    }
                    return zip(...trailAreaManagers);
                })
            );
    }

    getTrailAreaManagersForTrailAreas(trailAreas: TrailArea[]): Observable<{ [trailAreaKey: string]: Observable<User[]> }> {
        return this.db.list<UserAdminRights>(this.userAdminRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((usersAdminRightsSnap) => {
                    const trailAreaManagers: { [trailAreaKey: string]: Observable<User>[] } = {};
                    for (const userAdminRightsSnap of usersAdminRightsSnap) {
                        // @todo: Function for creating a UserAdminRights from a snap.
                        const adminEntry: UserAdminRights = userAdminRightsSnap.payload.val();
                        if (typeof adminEntry.trackGroups === 'object') {
                            for (const i in adminEntry.trackGroups) {
                                if (typeof adminEntry.trackGroups[i] === 'object') {
                                    trailAreas.forEach((trailArea) => {
                                        if (adminEntry.trackGroups[i].trackGroupId === trailArea.key) {
                                            if (typeof trailAreaManagers[trailArea.key] !== 'object' ||
                                                trailAreaManagers[trailArea.key] === null) {
                                                trailAreaManagers[trailArea.key] = [];
                                            }
                                            const profile: Observable<User> = this.getUser(userAdminRightsSnap.key);
                                            trailAreaManagers[trailArea.key].push(profile);
                                            return;
                                        }
                                    });
                                }
                            }
                        }
                    }
                    const trailAreaManagersForTrailAreas: { [trailAreaKey: string]: Observable<User[]> } = {};
                    trailAreas.forEach((trailArea) => {
                        trailAreaManagersForTrailAreas[trailArea.key] =
                            (typeof trailAreaManagers[trailArea.key] === 'object' && trailAreaManagers[trailArea.key] !== null) ?
                                <Observable<User[]>>zip(...trailAreaManagers[trailArea.key]) :
                                <Observable<User[]>>of([]);
                    });
                    return trailAreaManagersForTrailAreas;
                })
            );
    }

    getDestinationManagersForDestinations(destinations: Destination[]): Observable<{ [destinationKey: string]: Observable<User[]> }> {
        return this.db.list<UserAdminRights>(this.userAdminRef.ref).snapshotChanges()
            .pipe(
                map((usersAdminRightsSnap) => {
                    const destinationManagers: { [destinationKey: string]: Observable<User>[] } = {};
                    for (const userAdminRightsSnap of usersAdminRightsSnap) {
                        const adminEntry: UserAdminRights = userAdminRightsSnap.payload.val();
                        if (adminEntry.destinations) {
                            destinations.forEach((destination) => {
                                for (const i in adminEntry.destinations) {
                                    if (adminEntry.destinations[i].destinationKey === destination.key) {
                                        if (typeof destinationManagers[destination.key] !== 'object' ||
                                            destinationManagers[destination.key] === null) {
                                            destinationManagers[destination.key] = [];
                                        }
                                        destinationManagers[destination.key].push(this.getUser(userAdminRightsSnap.key));
                                        return;
                                    }
                                }
                            });
                        }
                    }
                    const destinationManagersForDestinations: { [destinationKey: string]: Observable<User[]> } = {};
                    destinations.forEach((destination) => {
                        destinationManagersForDestinations[destination.key] =
                            (typeof destinationManagers[destination.key] === 'object' && destinationManagers[destination.key] !== null) ?
                                <Observable<User[]>>zip(...destinationManagers[destination.key]) :
                                <Observable<User[]>>of([]);
                    });
                    return destinationManagersForDestinations;
                })
            );
    }

    /**
     * Gets the number of administrators of a specific trail area
     */
    getTrailAreaAdministratorsCount(key: string): Observable<number> {
        return this.db.list<UserAdminRights>(this.userAdminRef.ref).snapshotChanges().pipe(
            map(adminUserEntries => {
                let count = 0;
                for (const adminUserEntry of adminUserEntries) {
                    const adminPermissions = adminUserEntry.payload.val();
                    for (const trackGroupKey in adminPermissions.trackGroups) {
                        if (trackGroupKey === key) {
                            count++;
                            break;
                        }
                    }
                }
                return count;
            })
        );
    }

    revokeDestinationAdministrator(profileKey: string, destinationKey: string): Promise<void> {
        return this.db.object(this.userAdminRef.child(profileKey).child('destinations').child(destinationKey).ref).remove();
    }

    revokeTrailAreaAdministrator(profileKey: string, trailAreaKey: string): Promise<void> {
        return this.db.object(this.userAdminRef.child(profileKey).child('trackGroups').child(trailAreaKey).ref).remove();
    }

    getProfileEmailFromKey(profileKey: string): Observable<string> {
        return this.db.object<string>(this.secureUserDataRef.ref.child(profileKey).child('email')).valueChanges();
    }

    // loadEmails(): Observable<EmailExtractUser[]> {
    loadEmails(): Observable<EmailExtractUserAddress[]> {
        return this.db.list<{ email: string }>(this.secureUserDataRef.ref).snapshotChanges()
            .pipe(
                take(1),
                switchMap((emailSnaps) => {
                    console.log('Total email entries in db', emailSnaps.length);
                    const emailObjects = emailSnaps.map((emailSnap) => {
                        const email = emailSnap.payload.val().email;
                        // Illegal emails:
                        if (typeof email === 'undefined' || !email.includes('@')) {
                            console.log('Illegal email', emailSnap.key, emailSnap.payload.val());
                            return null;
                        }
                        return {
                            profileKey: emailSnap.key,
                            email: email
                        };
                    });
                    const validEntries: { profileKey: string, email: string }[] = emailObjects.filter((nn) => nn);

                    // const emailWithProfileInfoBlockObservables: Observable<EmailExtractUser[]>[] = [];
                    const emailWithProfileInfoBlockObservables: Observable<EmailExtractUserAddress[]>[] = [];

                    let fromKey: string;
                    let toKey: string;

                    for (let i = 0; i < validEntries.length; i += BATCH_SIZE) {
                        const firstEntry = i;
                        const lastEntry = Math.min(i + BATCH_SIZE, validEntries.length) - 1;

                        fromKey = validEntries[firstEntry].profileKey; 
                        toKey = validEntries[lastEntry].profileKey;

                        console.log('Getting users in range', fromKey, toKey, firstEntry, lastEntry);
                        // const emailWithProfileInfoBlockObservable: Observable<EmailExtractUser[]> =
                        const emailWithProfileInfoBlockObservable: Observable<EmailExtractUserAddress[]> =
                            this.getProfilesWithEmailsInRange(fromKey, toKey, validEntries.slice(firstEntry, lastEntry + 1));
                        emailWithProfileInfoBlockObservables.push(emailWithProfileInfoBlockObservable);
                    }

                    return zip(...emailWithProfileInfoBlockObservables)
                        .pipe(
                            map((emailExtractBlocks) => {
                                let emailProfiles: EmailExtractUserAddress[] = [];
                                emailExtractBlocks.forEach((emailExtractBlock) => {
                                    emailProfiles = emailProfiles.concat(emailExtractBlock);
                                });
                                const validEmailProfiles: EmailExtractUserAddress[] = emailProfiles.filter((nn) => nn);
                                console.log('Blocks:', emailExtractBlocks.length, ', valid emails:', emailProfiles.length, ', with profiles:', validEmailProfiles.length);
                                return validEmailProfiles;
                            })
                        );
                })
            );
    }

    getReviewKpis(): Observable<LastWeekTotalKpi> {
        return this.db.list(`/trackRatings`).snapshotChanges().pipe(
            take(1),
            switchMap(trailReviewsSnap => {
                const lastWeekThreshold = Date.now() - 7 * 24 * 60 * 60 * 1000;
                let total = 0;
                let lastWeek = 0;
                for (const trailReviewSnap of trailReviewsSnap) {
                    if (trailReviewSnap.payload.exists()) {
                        const userReviews: UserReview[] = <UserReview[]>trailReviewSnap.payload.val();
                        for (const i in userReviews) {
                            if (typeof userReviews[i] === 'object') {
                                if (userReviews[i].time > lastWeekThreshold) {
                                    lastWeek++;
                                }
                                total++;
                            }
                        }
                    }
                }
                return of({total: total, lastWeek: lastWeek});
            })
        );
    }

    getCountryManagers(countryCode: string): Observable<User[]> {
        return this.db.list<UserAdminRights>(this.userAdminRef.ref).snapshotChanges()
            .pipe(
                switchMap((userAdminsRightsSnap) => {
                    const countryManagers: Observable<User>[] = [];
                    for (const userAdminRightsSnap of userAdminsRightsSnap) {
                        const adminEntry: UserAdminRights = userAdminRightsSnap.payload.val();
                        if (adminEntry.countries) {
                            for (const i in adminEntry.countries) {
                                if (adminEntry.countries[i].countryCode === countryCode) {
                                    countryManagers.push(this.getUser(userAdminRightsSnap.key));
                                    break;
                                }
                            }
                        }
                    }
                    if (countryManagers.length === 0) {
                        return of([]);
                    }
                    return zip(...countryManagers);
                })
            );
    }

    appointCountryManager(profileKey: string, countryCode: string, currentProfileKey: string): Promise<void> {
        const countryManagerEntry: UserAdminRightCountryManagerEntry = {
            at: Date.now(),
            by: currentProfileKey,
            countryCode: countryCode
        };
        return this.userAdminRef.child(profileKey).child('countries').child(countryCode).update(countryManagerEntry);
    }

    revokeCountryManager(profileKey: string, countryCode: string): Promise<void> {
        return this.userAdminRef.child(profileKey).child('countries').child(countryCode).remove();
    }

    isUserCountryManager(profileKey: string, countryCode: string): Observable<boolean> {
        return this.db.object(this.userAdminRef.child(profileKey).child('countries').child(countryCode)).snapshotChanges()
            .pipe(
                map((snap) => snap.payload.exists())
            );
    }

    /**
     * Grants admin-right for profileKey to trailAreaKey (by rootProfileKey)
     * @param {string} profileKey The key to the users profile
     * @param {string} trailAreaKey The key to the trail area
     * @param {string} rootProfileKey The key of the root granting access
     * @returns {Promise<boolean>}
     */
    addTrailAreaAdmin(profileKey: string, trailAreaKey: string, rootProfileKey: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (typeof profileKey !== 'string' || profileKey.length === 0) {
                reject('ProfileKey not provided');
                return;
            }

            const ref = this.userAdminRef.child(profileKey).child('trackGroups').child(trailAreaKey).ref;
            this.db.object(ref).snapshotChanges()
                .pipe(take(1))
                .subscribe(adminOnTrailArea => {
                    if (adminOnTrailArea.payload.exists()) {
                        resolve(false);
                        return;
                    }
                    const updateElement = {
                        at: new Date().getTime(),
                        by: rootProfileKey,
                        trackGroupId: trailAreaKey
                    };
                    this.db.object(ref).update(updateElement)
                        .then(() => resolve(true));
                });
        });
    }

    /**
     * Grants admin-right for profileKey to destinationKey (by rootProfileKey)
     * @param {string} profileKey The key to the users profile
     * @param {string} destinationKey The key to the destination
     * @param {string} rootProfileKey The key to root user granting access
     * @returns {Promise<boolean>}
     */
    addDestinationAdmin(profileKey: string, destinationKey: string, rootProfileKey: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (typeof profileKey !== 'string' || profileKey.length === 0) {
                reject('ProfileKey not provided');
                return;
            }

            const ref = this.userAdminRef.child(profileKey).child('destinations').child(destinationKey).ref;
            this.db.object<UserAdminRightDestinationEntry>(ref).snapshotChanges()
                .pipe(take(1))
                .subscribe((destinationAdmin) => {
                    if (destinationAdmin.payload.exists()) {
                        resolve(false);
                        return;
                    }
                    const updateElement: UserAdminRightDestinationEntry = {
                        at: new Date().getTime(),
                        by: rootProfileKey,
                        destinationKey: destinationKey
                    };
                    this.db.object(ref).update(updateElement)
                        .then(() => resolve(true));
                });
        });
    }

    private getRootUser(rootEntry: AngularFireAction<DatabaseSnapshot<RootDatabaseEntry>>): Observable<RootUser> {
        return this.getUser(rootEntry.key)
            .pipe(
                map((user) => {
                    const inDB = rootEntry.payload.val();
                    const rootUser: RootUser = {
                        root_at: inDB.at,
                        root_by: inDB.by,
                        root_active: inDB.root,
                        ...user
                    };
                    return rootUser;
                })
            );
    }

    // private getProfilesWithEmailsInRange(fromKey: string, toKey: string,
    //                                      validEntries: { profileKey: string, email: string }[]): Observable<EmailExtractUser[]> {
    private getProfilesWithEmailsInRange(fromKey: string, toKey: string,
                                         validEntries: { profileKey: string, email: string }[]): Observable<EmailExtractUserAddress[]> {
        return this.getUsersInRange(fromKey, toKey)
            .pipe(
                take(1),
                map((profiles) => {
                    console.log('Got profiles', profiles.length, fromKey, toKey);
                    // const profilesWithEmail: EmailExtractUser[] = [];
                    const profilesWithEmail: EmailExtractUserAddress[] = [];

                    for (let i = 0; i < validEntries.length; i++) {
                        const emailEntry = validEntries[i];
                        let found = false;

                        for (const profile of profiles) {
                            if (profile.userID === emailEntry.profileKey) {
                                const emailExtractUser: EmailExtractUserAddress = <EmailExtractUserAddress>profile;
                                emailExtractUser.email = emailEntry.email;
                                profilesWithEmail.push(emailExtractUser);
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            console.log('Email without profile', emailEntry);
                        }
                    }

                    console.log('Batch finished with', profilesWithEmail.length, 'out of', validEntries.length);
                    return profilesWithEmail;
                })
            );
    }
}
