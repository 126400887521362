import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalSettings } from '../../interfaces/general';

declare var $: any;

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
    @Output() confirmed = new EventEmitter<boolean>();

    @Input() set modalSettings(modalSettings: ModalSettings) {
        if (typeof modalSettings === 'undefined') {
            return;
        }
        this.iconClasses = modalSettings.iconClasses ?? 'far fa-question-circle';
        this.title = modalSettings.title;
        this.question = modalSettings.question;
        this.confirmText = modalSettings.confirmText ?? 'OK';
        this.cancelText = modalSettings.cancelText ?? 'Cancel';
        $('#' + this.dialogId).modal('show');
    }

    iconClasses: string;
    title: string;
    question: string;
    cancelText: string;
    confirmText: string;
    dialogId: string;

    constructor() {
    }

    ngOnInit() {
        this.dialogId = 'dialog' + Date.now();
    }

    reply(confirmed: boolean): void {
        $('#' + this.dialogId).modal('hide');
        this.confirmed.emit(confirmed);
    }

}
