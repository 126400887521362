import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FileService } from '../../services/file.service';

declare var $: any;

@Component({
    selector: 'app-image-handler',
    templateUrl: './image-handler.component.html',
    styleUrls: ['./image-handler.component.css']
})
export class ImageHandlerComponent {
    @Input() disableSave = false;
    @Input() imageIndex: number;
    @Input() imageName: string;
    @Input() imagePath: string;
    @Input() imageUrlOnline = '';
    @Input() required = false;
    @Input() sizeNote = 'Preferred dimensions are 640 x 480 pixels (width x height). 4:3 aspect ratio. JPEG or PNG-files only.';
    @Output() uploadedImageUrl = new EventEmitter<string>();
    @Output() imageFileSelected = new EventEmitter<boolean>();
    @Output() size:any;
    @Output() imageWidth: number;
    @Output() imageHeight: number;
    @Output() imageSize = new EventEmitter<{width: number, height: number}>();


    uploadSuccess: boolean;



    resetUrl: string;
    imageUploadedInfo = 0;
    tempFile: File = null;
    disableUploadImage: boolean;
    disableReset: boolean;

    constructor(
        private fileService: FileService
    ) {
        this.init();
    }

    @Input() set doUploadImage(val: number) {
        if (val > 0) {
            this.uploadImage().then();
        }
    }

    _nextImage = false;

    @Input() set nextImage(next: string) {
        this._nextImage = (next === 'true');
    }

    _readonly = false;

    @Input() set readonly(readonly: string) {
        this._readonly = (readonly === 'true');
    }

    _imageUrl = null;

    @Input() set imageUrl(url: string) {
        this._imageUrl = url;
        this.resetUrl = url;
        
        this.getMeta(url, (err, img) => {
            this.imageHeight = img.height
            this.imageWidth = img.width
            this.uploadSuccess = true
            // this.size = img.size;
            // img.reader

          });

        
    }


    init() {
        this.tempFile = null;
        this.disableUploadImage = true;
        this.disableReset = true;
    }

    //  getMeta(url, callback) {

    //     var img = new Image();
    //     img.src = url;
    //     img.onload = () => {
    //         this.imageSize.emit({ width: img.width, height: img.height });
    //         callback({ width: img.width, height: img.height })
    //         this.imageWidth = img.width;
    //         this.imageHeight = img.height;
    //     };
    //     // const img = new Image();
    //     // img.src = url;
    //     // img.onload = function() { callback(this.imageWidth , this.imageHeight); }
    // }

    getMeta(url, callback) {

        var img = new Image();
        img.src = url;
        img.onload = () => {
            callback( null, img );
            
        };
        img.onerror = (err) => callback(err);
        
    }

    onImageFileSelected(files: FileList): void {
        this.tempFile = this.fileService.onFileSelected(files, 'image/jpeg,image/png');
        if (this.tempFile !== null) {
            // $('#image_filename' + this.imageIndex).val(this.tempFile.name);
            this.uploadSuccess = true

            const reader = new FileReader();
            const imageIndex = this.imageIndex;
            this.size = this.tempFile.size;

            reader.onload = () => { // when file has loaded
                const imgUploadElement = $('#img-upload' + imageIndex) 
                imgUploadElement.attr('src', reader.result);
                imgUploadElement.removeClass('d-none');

                // Use like:
            this.getMeta(reader.result, (err, img) => {
                this.imageSize.emit({ width: img.width, height: img.height });
    this.imageHeight = img.height
    this.imageWidth = img.width
    this.uploadSuccess = true

  });

                // var img = new Image();
                // img.onload = () => {
                //     this.imageSize.emit({ width: img.width, height: img.height });
                //     this.imageWidth = img.width;
                //     this.imageHeight = img.height;
                // };
                //  img.src = reader.result as string; // This is the data URL 
               };
            

            reader.readAsDataURL(this.tempFile);
            this.disableUploadImage = false;
            this.imageFileSelected.emit(true);
        } else {
            this.reset();
        }
    }

    reset() {
        if (this.resetUrl) {
            $('#img-upload' + this.imageIndex).attr('src', this.resetUrl);
        } else {
            const imgUploadElement = $('#img-upload' + this.imageIndex);
            imgUploadElement.attr('src', null);
            imgUploadElement.addClass('d-none');
        }
        this.tempFile = null;
        this.disableUploadImage = true;
        this.uploadSuccess = false
        this.imageFileSelected.emit(false);
    }

    uploadImage(): Promise<string> {
        this.disableUploadImage = true;

        return this.fileService.uploadFileToStorage(this._imageUrl, this.imagePath, this.imageIndex, this.tempFile, !this.disableSave)
            .then((uploadFileResult) => {
                this.tempFile = null;
                this.uploadedImageUrl.emit(uploadFileResult.url);
                this.imageUploadedInfo++;
                if (this._nextImage === true) {
                    $('#img-upload' + this.imageIndex).attr('src', this._imageUrl);
                }
                return uploadFileResult.url;
            })
            .catch((err) => {
                console.error('Image upload error occurred:', err);
                return null;
            });
    }
}
