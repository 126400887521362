<div class="content-wrapper">
    <app-content-header-section [contentPageId]="contentPageId"></app-content-header-section>

    <section class="content">
        <div class="container-fluid" *ngIf="poiCategories">
            <div class="row">
                <div class="col-12 col-lg-6 col-xl-4">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('category')">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getPluralIcon('category') + ' mr-2'"></i> POI Categories</h3>
                        </div>
                        <div class="card-body p-0">
                            <div class="list-group">
                                <a *ngFor="let poiCategory of poiCategories | keyValuePoiCategory" class="list-group-item list-group-item-action"
                                   [routerLink]="layout.getUrl(poiCategoryPageId, {poiCategoryLabel: poiCategory.key})">
                                    <img *ngIf="poiCategory.mediaIconKey" [src]="poiCategory.mediaIconUrl" width="64" height="64" class="float-right"
                                         alt="POI Category Icon">
                                    <h5 class="mb-1">
                                        <i [ngClass]="poiCategoryStates[poiCategory.key].icon + ' fa-fw text-' + poiCategoryStates[poiCategory.key].theme + ' mr-2'"></i>
                                        {{ poiCategory.name || poiCategory.key }}
                                    </h5>
                                    <p class="mb-1">{{ poiCategory.definition }}</p>
                                    <small *ngIf="poiCategory.subCategories">{{ numberOfSubCategories(poiCategory.key) }} sub categories.</small>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6 col-xl-4">

                    <div class="card card-info">
                        <div class="card-header">
                            <h3 class="card-title">Update</h3>
                        </div>
                        <div class="card-body">
                            Update basic hardcoded values for POI-categories.<br>
                            This may be required when the administration has been updated.
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-info" (click)="updateBase()">Update base settings</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
