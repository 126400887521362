import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// Services
import {TreatService} from '../../firebase-services/treat.service';

// Interfaces
import {Treat} from '../../interfaces/treat';
import {TextModel, TextModelItemType, TextObject} from '../../interfaces/text-model';

declare var $: any;

@Component({
    selector: 'app-treats',
    templateUrl: './treats.component.html',
    styleUrls: ['./treats.component.css']
})

export class TreatsComponent implements OnInit, AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    objectKeys = Object.keys; // For use in HTML
    treatsDataTableName = '#treatsDataTable';
    now: number = Date.now();

    treats: Treat[] = null;
    allIsLoaded = false;
    dataTableCreated = false;
    dataTable: any = null;

    textModel: TextModel = {items: []};
    textObject: TextObject;
    textsChangedInfo = 0;
    disableCreateTreat = true;
    newTreatAlias: string;

    constructor(
        private router: Router,
        private treatService: TreatService,
        private domSanitizer: DomSanitizer
    ) {
        this.initTextModel();
    }

    ngOnInit() {
        this.loadTreatsList();
        this.treatService.getTreatsTabTexts()
            .pipe(takeUntil(this.destroy$))
            .subscribe((treatsTabTexts) => this.textObject = treatsTabTexts);
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($('#treatsDataTable>tbody>tr').length > 0)) {
            this.dataTable = $(this.treatsDataTableName).DataTable({
                'order': [[2, 'desc']],
                'columnDefs': [
                    {'orderable': false, 'targets': 0}
                ]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    initTextModel(): void {
        this.textModel.items.push({
            name: 'Title',
            varName: 'title',
            help: 'Title of the treats tab',
            placeholder: 'Our Treats for You',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'description',
            help: 'Subtitle / description on the treats tab',
            placeholder: 'Our list of treats contains valuable offers for you.',
            type: TextModelItemType.TEXT_AREA
        });
    }

    loadTreatsList(): void {
        this.treatService.getTreats()
            .pipe(takeUntil(this.destroy$))
            .subscribe((treats) => {
                this.treats = treats;
                this.allIsLoaded = true;
            });
    }

    setProgressWidth(treat: Treat) {
        const width = Math.round(100 * (this.now - treat.activeFrom) / (treat.activeTo - treat.activeFrom));
        return this.domSanitizer.bypassSecurityTrustStyle('width: ' + width + '%');
    }

    copyTreat(treat: Treat): Promise<any> {
        return this.treatService.copyTreat(treat)
            .then((newTreatKey) => this.router.navigate(['/root/treats/' + newTreatKey]));
    }

    deleteTreat(treat: Treat) {
        this.allIsLoaded = false;
        return this.treatService.deleteTreat(treat).then(() => {
            this.loadTreatsList();
            this.dataTable.destroy();
            this.dataTableCreated = false;
        });
    }

    loadStatsForTreat(treat: Treat): void {
        this.allIsLoaded = false;
        this.treatService.enhanceTreatWithStatistics(treat)
            .pipe(takeUntil(this.destroy$))
            .subscribe((enhancedTreat) => {
                for (const i in this.treats) {
                    if (this.treats[i].key === treat.key) {
                        this.treats[i] = enhancedTreat;
                        break;
                    }
                }
                this.dataTable.destroy();
                this.dataTableCreated = false;
                this.allIsLoaded = true;
            });
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.treatService.updateTreatsTabTexts(alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    newTreatAliasChanged() {
        this.disableCreateTreat = !(this.newTreatAlias && this.newTreatAlias !== '');
    }

    createTreat(): Promise<any> {
        return this.treatService.createTreat(this.newTreatAlias).then((newTreatKey) => {
            this.router.navigate(["/root/treats/" + newTreatKey])
        });
    }
}
