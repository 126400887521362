<button type="button" class="btn btn-{{ btnClassType }}" data-toggle="modal" [attr.data-target]="'#editRoleDescriptionModal_'+modalId">
    <ng-content></ng-content>
</button>
<div class="modal fade" id="editRoleDescriptionModal_{{ modalId }}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <strong><i class="fas fa-user mr-2"></i> <label [for]="'editRoleDescription'+modalId">Edit role description</label></strong>
                    <input [id]="'editRoleDescription'+modalId" [value]="oldRoleDesc"
                           class="form-control">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary pull-left" (click)="updateRoleDescription()">Update</button>
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
