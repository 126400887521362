import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../../core/layout.service';
import { RtwEventService } from '../../../firebase-services/rtw-event.service';
import { CountryService } from '../../../firebase-services/country.service';

// Interfaces
import { RtwEvent } from '../../../interfaces/rtw-event';

@Component({
    selector: 'app-create-rtw-event',
    templateUrl: './create-rtw-event.component.html',
    styleUrls: ['./create-rtw-event.component.css']
})
export class CreateRtwEventComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    rtwEvent: RtwEvent;
    collections: { [collectionKey: string]: string } = null;

    constructor(
        public layout: LayoutService,
        private router: Router,
        private rtwEventService: RtwEventService,
        private countryService: CountryService
    ) {
    }

    ngOnInit(): void {
        this.loadCollections();
        this.rtwEvent = {
            key: null,
            active: false,
            collectionKey: null,
            title: null,
            color: '#00FF17',
            endTime: null,
            startTime: null,
            feedEntryDescription: null,
            feedEntryTitle: null,
            heroKey: null,
            heroImageUrl: null,
            heroName: null,
            imageUrl: null
        };
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private loadCollections() {
        this.countryService.getCountries()
            .pipe(takeUntil(this.destroy$))
            .subscribe((countries) => {
                var collections: { [collectionKey: string]: string } = {};
                countries.forEach((country) => {
                    collections[country.countryCode] = country.name;
                });
                this.collections = collections;
            });
    }

    createRtwEvent() {
        // Validation of required input
        if (typeof this.rtwEvent.title !== 'string' || this.rtwEvent.title === '') {
            alert('Please give the event a title.');
            return;
        }
        if (typeof this.rtwEvent.collectionKey !== 'string' || this.rtwEvent.collectionKey === '') {
            alert('Please select a collection for the event.');
            return;
        }

        // Create RtW event
        var createdThenableRef = this.rtwEventService.createRtwEvent(this.rtwEvent);

        // Redirect to edit RtW event page
        createdThenableRef
            .then(() => this.router.navigate([this.layout.getUrl('editRtwEvent', {rtwEventKey: createdThenableRef.key})]));
    }

}
