import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common/';
import { firstValueFrom, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { PremiumService } from '../../../firebase-services/premium.service';
import { UserService } from '../../../firebase-services/user.service';

// Interfaces
import { PremiumMemberWithEmail } from '../../../interfaces/premium';
import { User } from '../../../interfaces/user';

import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-premium-members',
    templateUrl: './premium-members.component.html',
    styleUrls: ['./premium-members.component.css']
})
export class PremiumMembersComponent implements OnInit, AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    members: PremiumMemberWithEmail[] = null;

    adjustProfile: User = null;
    adjustExpires: number = null;

    // Datatable
    dataTableName = '#membersDataTable';
    dataTable: any = null;
    dataTableCreated = false;
    showReloadMessage = false;

    constructor(
        private userService: UserService,
        private premiumService: PremiumService
    ) {
    }

    ngOnInit() {
        this.getMembers();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.dataTableName + '>tbody>tr').length > 0)) {
            this.dataTable = $(this.dataTableName).DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'csvHtml5',
                    'excelHtml5'
                ],
                order: [[1, 'asc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    foundProfile(profileKey: string): void {
        this.userService.getUser(profileKey)
            .subscribe((user) => this.adjustProfile = user);
        this.premiumService.getPremiumExpiry(profileKey)
            .subscribe((expiry) => this.adjustExpires = expiry);
    }

    endMembership(): void {
        if (confirm('Are you sure you want to end this profiles membership now?')) {
            this.premiumService.updateMembership(this.adjustProfile.userID, moment.now());
            this.showReloadMessage = true;
        }
    }

    addTime(amount: number, unitOfTime: string): void {
        if (confirm(`Are you sure you want to add ${amount} ${unitOfTime} to this profiles membership?`)) {
            const expiry: moment.Moment = moment.max(moment(this.adjustExpires), moment()).add({[unitOfTime]: amount});
            this.premiumService.updateMembership(this.adjustProfile.userID, expiry.valueOf());
            this.showReloadMessage = true;
        }
    }

    plusOne(lineNumber: string): string {
        return (parseInt(lineNumber, 10) + 1).toString();
    }

    orderByNumericKey(a: KeyValue<any, any>, b: KeyValue<any, any>): number {
        return a.key - b.key;
    }

    private getMembers() {
        this.premiumService.getPremiumMembers()
            .pipe(
                takeUntil(this.destroy$),
                switchMap((members) => {
                    const memberWithPromises: Promise<PremiumMemberWithEmail>[] = [];
                    members.forEach((member) => {
                        const memberWithPromise = firstValueFrom(this.userService.getProfileEmailFromKey(member.profileKey))
                            .then((email) => {
                                const memberWith: PremiumMemberWithEmail = {
                                    ...member,
                                    email: email
                                };
                                return memberWith;
                            });
                        memberWithPromises.push(memberWithPromise);
                    });
                    return Promise.all(memberWithPromises);
                })
            )
            .subscribe((members) => this.members = members);
    }

}
