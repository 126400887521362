<footer class="main-footer">

    <div class="row">
        <div class="col-12 col-sm-8 col-md-7 offset-md-1 col-lg-6 offset-lg-2">
            <strong>Contact us</strong>
            <p>We are always interested in connecting with clubs, builders, owners, organisers, sponsors and everyone else invested in the betterment
                of mountainbiking.<br/>Get in touch and lets talk!</p>
            <p>
                <strong>© H&I Adventures Ltd 2024, <a href="https://mtbutd.com" target="mtbutdcom">mtbutd.com</a></strong>
            </p>
        </div>
        <div class="col-12 col-sm-4">
            <address>
                <strong>H&I Adventures Ltd</strong><br/>
                Bunchrew, Inverness, IV3 8TA<br/>
                Scotland<br/>
                <i class="fas fa-phone"></i> 01463 231 441<br/>
                <i class="far fa-envelope"></i> info&#64;mtbutd.com
            </address>
        </div>
    </div>

</footer>
