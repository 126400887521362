import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';

// Services
import { RestCountriesService } from './rest-countries.service';

// Interfaces
import { GOOGLEAPIS_GEOCODE_STATUS_OK, GoogleAPIsGeocode } from '../interfaces/googleapis';
import { AddressLookup } from '../interfaces/address-lookup';

@Injectable({
    providedIn: 'root'
})
export class GoogleGeocodeService {

    constructor(
        private client: HttpClient,
        private restCountriesService: RestCountriesService
    ) {
    }

    private static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    lookupCountry(countryName: string): Observable<AddressLookup> {
        const webAddress: string = 'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            countryName + '&key=' + environment.geocoding.apiKey;
        return this.client.get<GoogleAPIsGeocode>(webAddress)
            .pipe(
                map((data) => {
                    if (data.status === GOOGLEAPIS_GEOCODE_STATUS_OK &&
                        typeof data.results[0] !== 'undefined' &&
                        typeof data.results[0].geometry.viewport !== 'undefined') {
                        const countryLookup: AddressLookup = {
                            boundsNorth: data.results[0].geometry.viewport.northeast.lat,
                            boundsSouth: data.results[0].geometry.viewport.southwest.lat,
                            boundsEast: data.results[0].geometry.viewport.northeast.lng,
                            boundsWest: data.results[0].geometry.viewport.southwest.lng,
                            lat: data.results[0].geometry.location.lat,
                            lng: data.results[0].geometry.location.lng
                        };
                        return countryLookup;
                    }
                    return null;
                }),
                retry(3), // retry a failed request up to 3 times
                catchError(GoogleGeocodeService.handleError) // then handle the error
            );
    }

    lookupCity(latLng: string, language = 'en-GB'): Observable<AddressLookup> {
        const webAddress = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&language=${language}&result_type=postal_town&key=${environment.geocoding.apiKey}`;
        return this.client.get<GoogleAPIsGeocode>(webAddress)
            .pipe(
                map((data) => {
                    if (data.status === GOOGLEAPIS_GEOCODE_STATUS_OK &&
                        typeof data.results[0] !== 'undefined' &&
                        typeof data.results[0].address_components[0] !== 'undefined') {
                        const cityLookup: AddressLookup = <AddressLookup>{
                            city: data.results[0].address_components[0].long_name,
                        };
                        console.log(data.results[0].address_components)
                        return cityLookup;
                    }
                    return null;
                }),
                retry(3), // retry a failed request up to 3 times
                catchError(GoogleGeocodeService.handleError) // then handle the error
            );
    }

    lookupRegion(latLng: string, language = 'en-GB'): Observable<AddressLookup> {
        const webAddress = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&language=${language}&result_type=administrative_area_level_2&key=${environment.geocoding.apiKey}`;
        return this.client.get<GoogleAPIsGeocode>(webAddress)
            .pipe(
                map((data) => {
                    if (data.status === GOOGLEAPIS_GEOCODE_STATUS_OK &&
                        typeof data.results[0] !== 'undefined' &&
                        typeof data.results[0].address_components[0] !== 'undefined') {
                        const regionLookup: AddressLookup = <AddressLookup>{
                            regionName: data.results[0].address_components[0].long_name,
                        };
                        console.log(data.results[0].address_components)
                        return regionLookup;
                    }
                    return null;
                }),
                retry(3), // retry a failed request up to 3 times
                catchError(GoogleGeocodeService.handleError) // then handle the error
            );
    }

    lookupLatLng(latLng: string, language = 'en-GB', languages: Set<string> = null, searchForLand = true): Observable<AddressLookup> {
        if (latLng.includes('NaN')) {
            console.error('Can\'t lookup NaNs', latLng);
            return of(null);
        }
        if (latLng === '0,0') {
            console.error('Can\'t lookup 0,0', language, [...languages], searchForLand);
            return of(null);
        }
        const webAddress = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&language=${language}&result_type=administrative_area_level_1&key=${environment.geocoding.apiKey}`;
        return this.client.get<GoogleAPIsGeocode>(webAddress)
            .pipe(
                switchMap((data) => {
                    if (data.status === GOOGLEAPIS_GEOCODE_STATUS_OK &&
                        typeof data.results[0] !== 'undefined' &&
                        typeof data.results[0].address_components[0] !== 'undefined') {
                        const latLngLookup: AddressLookup = <AddressLookup>{
                            languageCode: language,
                            regionName: data.results[0].address_components[0].long_name,
                            countryName: data.results[0].address_components[1].long_name,
                            countryCode: data.results[0].address_components[1].short_name.toLowerCase()
                        };

                        if (languages !== null) {
                            return this.restCountriesService.getLanguageCodesInCountry(latLngLookup.countryCode)
                                .pipe(
                                    switchMap((countryLanguages) => {
                                        countryLanguages.forEach((languageCode) => languages.add(languageCode));
                                        languages.delete('en');
                                        const languageLookupObservables: Observable<AddressLookup>[] = [];
                                        languages.forEach((languageCode: string) => {
                                            languageLookupObservables.push(this.lookupLatLng(latLng, languageCode, null, false));
                                        });
                                        return zip(...languageLookupObservables)
                                            .pipe(
                                                map((languageLookups) => {
                                                    latLngLookup.lang = {};
                                                    languageLookups.forEach((addressLookup) => {
                                                        latLngLookup.lang[addressLookup.languageCode] = {
                                                            countryName: addressLookup.countryName,
                                                            regionName: addressLookup.regionName
                                                        };
                                                    });
                                                    return latLngLookup;
                                                })
                                            );
                                    })
                                );
                        }

                        return of(latLngLookup);
                    }
                    if (searchForLand) {
                        console.warn('We are taking in water, Captain');
                        return this.searchForLand(latLng, language, languages);
                    }
                    console.error('LatLng result from API not ok', webAddress, data);
                    return of(null);
                }),
                retry(3), // retry a failed request up to 3 times
                catchError(GoogleGeocodeService.handleError) // then handle the error
            );
    }

    private searchForLand(latLng: string, language: string, languages: Set<string>): Observable<AddressLookup> {
        const webAddress = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&result_type=street_address&key=${environment.geocoding.apiKey}`;
        return this.client.get<GoogleAPIsGeocode>(webAddress)
            .pipe(
                switchMap((data) => {
                    if (data.status === GOOGLEAPIS_GEOCODE_STATUS_OK &&
                        typeof data.results[0] !== 'undefined' &&
                        typeof data.results[0].geometry !== 'undefined' &&
                        typeof data.results[0].geometry.location !== 'undefined') {
                        console.log('Land Ho!');
                        return this.lookupLatLng(data.results[0].geometry.location.lat.toString() + ',' +
                            data.results[0].geometry.location.lng.toString(), language, languages, false);
                    }
                    console.error('So long - a captain never abandons his ship', webAddress, data);
                    return of(null);
                }),
                retry(1), // retry a failed request up to 3 times
                catchError(GoogleGeocodeService.handleError) // then handle the error
            );
    }

}
