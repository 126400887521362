<div class="content-wrapper">
    <app-content-header-section *ngIf="rtwEvent" contentPageId="rtwEventParticipants"
                                [pageParams]="{ rtwEventName: rtwEvent.title }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('rtwEvent')">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-dice"></i> Draw winners</h3>
                        </div>
                        <div class="card-body" *ngIf="drawnNumbers">
                            <div class="row">
                                <div class="col-3 col-md-2" *ngFor="let drawnNumber of drawnNumbers">
                                    <h1>{{ drawnNumber }}</h1>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="form-group">
                                <div class="input-group">
                                    <input [(ngModel)]="drawCount" class="form-control" type="number" min="1" [max]="lastTicketNumber"
                                           aria-describedby="drawInputHelp"/>
                                    <span class="input-group-append">
										<button (click)="drawWinners()"
                                                [ngClass]="'btn btn-' + layout.getTheme('rtwEvent')">Draw winner{{ (drawCount > 1 ? 's' : '') }}</button>
									</span>
                                </div>
                                <small id="drawInputHelp" class="form-text text-muted">How many winners do you wish to draw?</small>
                            </div>
                        </div>
                    </div>


                    <div [ngClass]="'card card-outline card-' + layout.getTheme('rtwEvent')">
                        <div class="card-header" *ngIf="rtwEvent">
                            <h3 class="card-title"><i class="fa fa-users"></i> Participants in {{ rtwEvent.name }}</h3>
                        </div>
                        <div *ngIf="!participants || !participantExtra" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="participants && participantExtra" id="participantsDataTable" class="table row-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>Tickets</th>
                                    <th>Name</th>
                                    <th># Areas</th>
                                    <th>On Trail Distance</th>
                                    <th>Premium</th>
                                    <th>Rides</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let participant of participants">
                                    <td>{{ participantExtra[participant.profileKey].tickets }}</td>
                                    <td>{{ participant.profileName }}</td>
                                    <td>{{ participant.conqueredAreas }}</td>
                                    <td>{{ (participant.onTrailDistance / 1000) | number:'1.1-1' }} km</td>
                                    <td>{{ participantExtra[participant.profileKey].isPremium }}</td>
                                    <td><a href="/root/rides">Rides</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
