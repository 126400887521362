import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-news-room-edit-draft',
    templateUrl: './news-room-edit-draft.component.html',
    styleUrls: ['./news-room-edit-draft.component.css']
})
export class NewsRoomEditDraftComponent implements OnInit {

    newsStoryKey: string = null;

    constructor(
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.newsStoryKey = this.route.snapshot.paramMap.get('newsStoryKey');
    }

}
