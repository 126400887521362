import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Interfaces
import { DefaultImage, MediaIcon } from '../interfaces/media-library';

@Injectable({
    providedIn: 'root'
})
export class MediaLibraryService {
    private mediaLibraryRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.mediaLibraryRef = this.db.database.ref('mediaLibrary');
    }

    private static mediaIconFromSnap(snap: AngularFireAction<DatabaseSnapshot<MediaIcon>>): MediaIcon {
        const mediaIcon = snap.payload.val();
        mediaIcon.key = snap.key;
        return mediaIcon;
    }

    private static defaultImageFromSnap(snap: AngularFireAction<DatabaseSnapshot<DefaultImage>>): DefaultImage {
        const defaultImage = snap.payload.val();
        defaultImage.key = snap.key;
        return defaultImage;
    }

    getMediaIcons(): Observable<MediaIcon[]> {
        return this.db.list<MediaIcon>(this.mediaLibraryRef.child('icons').ref).snapshotChanges()
            .pipe(
                map((snaps) => {
                    const mediaIcons: MediaIcon[] = [];
                    snaps.forEach((snap) => {
                        mediaIcons.push(MediaLibraryService.mediaIconFromSnap(snap));
                    });
                    return mediaIcons;
                })
            );
    }

    getMediaIconKeyFromIconUrl(iconUrl: string): Observable<string> {
        return this.db.list<MediaIcon>(this.mediaLibraryRef.child('icons').ref).snapshotChanges()
            .pipe(
                map((snaps) => {
                    let mediaIconKey: string = null;
                    snaps.forEach((snap) => {
                        const mediaIcon: MediaIcon = MediaLibraryService.mediaIconFromSnap(snap);
                        if (mediaIcon.pngUrl === iconUrl) {
                            mediaIconKey = snap.key;
                        }
                    });
                    return mediaIconKey;
                })
            );
    }

    getMediaIconUrlFromName(name: string): Observable<string> {
        return this.db.list<MediaIcon>(
            this.mediaLibraryRef.child('icons').ref, ref => ref.orderByChild('name').equalTo(name)).snapshotChanges()
            .pipe(
                map((snaps) => {
                    let url: string;
                    snaps.forEach((snap) => {
                        const mediaIcon: MediaIcon = MediaLibraryService.mediaIconFromSnap(snap);
                        url = mediaIcon.pngUrl;
                    });
                    return url;
                })
            );
    }

    getDefaultImages(): Observable<DefaultImage[]> {
        return this.db.list<DefaultImage>(this.mediaLibraryRef.child('defaultImages').ref).snapshotChanges()
            .pipe(
                map((snaps) => {
                    const defaultImages: DefaultImage[] = [];
                    snaps.forEach((snap) => {
                        defaultImages.push(MediaLibraryService.defaultImageFromSnap(snap));
                    });
                    return defaultImages;
                })
            );
    }

}
