import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TrailService } from '../../../firebase-services/trail.service';
import { TrailAreaService } from '../../../firebase-services/trail-area.service';

// Interfaces
import { Trail } from '../../../interfaces/trail';
import { TrailArea } from '../../../interfaces/trailArea';

declare var $: any;

@Component({
    selector: 'app-export-facilitydb',
    templateUrl: './export-facilitydb.component.html',
    styleUrls: ['./export-facilitydb.component.css']
})
export class ExportFacilitydbComponent implements OnInit, AfterViewChecked, OnDestroy {
    objectKeys = Object.keys;
    destroy$: Subject<boolean> = new Subject<boolean>();

    trails: Trail[] = null;
    trailAreas: TrailArea[] = null;
    tableHeaders = [
        'Key',
        'Kommune',
        'Kommune nr.',
        'Region',
        'Type idrætsfacilitet',
        'Navn på overordnet idrætsanlæg/-center',
        'Navn på konkret idrætsfacilitet',
        'Postadresse',
        'Hjemmeside',
        'Telefonnummer',
        'Email',
        'Ejerforhold (tilføj gerne kategorier på Ark2, hvis I finder en anden ejerform)',
        'Driftsherre',
        'UTM type',
        'X koordinat',
        'Y koordinat',
        'Indendørs/udendørs',
        'Tennisbane/fodboldbane/håndboldbane Underlag',
        'Badmintonhal/tennishal/tennisbaner/fodboldbaner/håndboldbaner/Golfanlæg Antal baner/huller',
        'Interne bemærkninger',
        'Eksterne bemærkninger (angiv særlige forhold, herunder også eventuel facilitetstype ved specialfaciliteter)',
        'Kontaktperson',
        'Kontaktoplysninger (mail eller telefon)'
    ];
    dataTableCreated = false;

    constructor(
        private trailService: TrailService,
        private trailAreaService: TrailAreaService
    ) {
    }

    ngOnInit() {
        this.getDanishTrails();
        this.getDanishTrailAreas();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($('#trailsDataTable>tbody>tr').length > 0)) {
            $('#trailsDataTable').DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'colvis',
                    'csvHtml5',
                    'excelHtml5'
                ],
                order: [[2, 'asc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private getDanishTrails(): void {
        this.trailService.getTrailsForCountry('dk')
            .pipe(takeUntil(this.destroy$))
            .subscribe((trails) => {
                this.trailService.applyDanishMetaDataToTrails(trails);
                this.trails = trails;
            });

    }

    private getDanishTrailAreas(): void {
        this.trailAreaService.getTrailAreasForCountry('dk')
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailAreas) => {
                this.trailAreas = trailAreas;
            });

    }
}
