import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Interfaces
import { PoiCategories, PoiCategory, PublicPoiCategory } from '../interfaces/poi-category';
import { FirebaseObjectService } from '../services/firebase-object.service';

@Injectable({
    providedIn: 'root'
})
export class PoiCategoryService {
    poiCategoriesRef: firebase.database.Reference;
    publicPoiCategoriesRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.poiCategoriesRef = this.db.database.ref('poiCategories');
        this.publicPoiCategoriesRef = this.db.database.ref('public').child('poiCategories');
    }

    updateBaseCategories(): Promise<any> {
        let sortOrder = 1;
        Object.keys(PoiCategories).forEach((label) => {
            PoiCategories[label].sortOrder = sortOrder;
            sortOrder++;
            if (PoiCategories[label].subCategories) {
                let subSortOrder = 1;
                Object.keys(PoiCategories[label].subCategories).forEach((subLabel) => {
                    PoiCategories[label].subCategories[subLabel].sortOrder = subSortOrder;
                    subSortOrder++;
                });
            }
        });

        const updateObject = FirebaseObjectService.prepareUpdateObject(PoiCategories);

        return this.poiCategoriesRef.update(updateObject);
    }

    getPoiCategories(): Observable<{ [label: string]: PoiCategory }> {
        return this.db.list<PoiCategory>(this.poiCategoriesRef.ref).snapshotChanges()
            .pipe(
                map((snaps) => {
                    const poiCats: { [label: string]: PoiCategory } = {};
                    snaps.forEach((snap) => {
                        poiCats[snap.key] = snap.payload.val();
                    });
                    return poiCats;
                })
            );
    }

    getPoiCategory(label: string): Observable<PoiCategory> {
        return this.db.object<PoiCategory>(this.poiCategoriesRef.child(label).ref).valueChanges();
    }

    savePoiCategoryDraft(label: string, poiCategory: PoiCategory): Promise<any> {
        const preparedPoiCategory = <PoiCategory>FirebaseObjectService.prepareSetObject(poiCategory);
        return this.poiCategoriesRef.child(label).set(preparedPoiCategory);
    }

    getPublicPoiCategories(): Observable<{ [label: string]: PublicPoiCategory }> {
        return this.db.list<PublicPoiCategory>(this.publicPoiCategoriesRef.ref).snapshotChanges()
            .pipe(
                map((snaps) => {
                    const publicPoiCats: { [label: string]: PublicPoiCategory } = {};
                    snaps.forEach((snap) => {
                        publicPoiCats[snap.key] = snap.payload.val();
                    });
                    return publicPoiCats;
                })
            );
    }

    getPublicPoiCategory(label: string): Observable<PublicPoiCategory> {
        return this.db.object<PublicPoiCategory>(this.publicPoiCategoriesRef.child(label).ref).valueChanges();
    }

    publish(label: string, poiCategory: PoiCategory): Promise<any> {
        const publicPoiCategory: PublicPoiCategory = {
            lang: poiCategory.lang ?? null,
            name: poiCategory.name,
            sortOrder: poiCategory.sortOrder,
            iconUrl: poiCategory.mediaIconUrl
        };

        if (poiCategory.subCategories) {
            publicPoiCategory.subCategories = {};
            Object.entries(poiCategory.subCategories).forEach(([subLabel, poiSubCategory]) => {
                if (poiSubCategory.enabled) {
                    publicPoiCategory.subCategories[subLabel] = {
                        lang: poiSubCategory.lang ?? null,
                        name: poiSubCategory.name,
                        sortOrder: poiSubCategory.sortOrder
                    };
                }
            });
        }

        return this.publicPoiCategoriesRef.child(label).set(FirebaseObjectService.prepareSetObject(publicPoiCategory));
    }

    unpublish(label: string): Promise<any> {
        return this.publicPoiCategoriesRef.child(label).remove();
    }
}
