import { environment } from '../environments/environment';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './core/auth.service';
import { MediaLibraryService } from './firebase-services/media-library.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    userIsOnline: boolean = null;

    constructor(
        private authService: AuthService,
        private mediaLibraryService: MediaLibraryService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.authService.uid$
            .subscribe((profileKey) => {
                this.userIsOnline = (profileKey !== null);
                if (!this.userIsOnline && this.router.url !== '/login') {
                    return this.router.navigate(['/login']);
                } else if (this.userIsOnline && this.router.url === '/login') {
                    return this.router.navigate(['/dashboard']);
                }
                this.userLoaded();
            });

        this.mediaLibraryService.getMediaIcons()
            .subscribe(((mediaIcons) => {
                    const imgSrc: string[] = [];

                    mediaIcons.forEach((mediaIcon) => {
                        imgSrc.push('img[src="' + mediaIcon.pngUrl + '"]');
                    });

                    // Fix icons - white background and fake drop shadow.
                    window.document.styleSheets[1].insertRule('.gm-style ' + imgSrc.join(', .gm-style ') + ' {background-color: white; border-right: 1px solid #CCC!important; border-bottom: 1px solid #CCC!important;');
                })
            );

        // Add Google maps script with proper key
        const script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?libraries=geometry,visualization&key=' + environment.maps.apiKey;
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    userLoaded(): void {
        const body = document.getElementsByTagName('body')[0];
        if (this.userIsOnline) {
            document.getElementsByClassName('main-header')[0].classList.remove('d-none');
            document.getElementsByClassName('main-sidebar')[0].classList.remove('d-none');
            document.getElementsByClassName('main-footer')[0].classList.remove('d-none');
            body.classList.remove('login-page');
            body.classList.add('sidebar-mini');
            body.classList.add('layout-fixed');
        } else {
            document.getElementsByClassName('main-header')[0].classList.add('d-none');
            document.getElementsByClassName('main-sidebar')[0].classList.add('d-none');
            document.getElementsByClassName('main-footer')[0].classList.add('d-none');
            body.classList.remove('sidebar-mini');
            body.classList.remove('layout-fixed');
            body.classList.add('login-page');
            body.style.height = '100vh';
        }
    }
}
