import { environment } from '../../../environments/environment';

import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Observable, Subject, of, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { OnTrailService } from '../../firebase-services/on-trail.service';

// Interfaces
import { OnTrailApplication } from '../../interfaces/on-trail';

declare var $: any;

@Component({
    selector: 'app-on-trail-contacts',
    templateUrl: './on-trail-contacts.component.html',
    styleUrls: ['./on-trail-contacts.component.css']
})
export class OnTrailContactsComponent implements OnInit, AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    webBaseUrl: string = environment.www;

    contacts: OnTrailApplication[] = null;
    tableHeaders = [
        'Application ID',
        'Name',
        'E-mail',
        'Address',
        'Phone',
        'Trail name'
    ];
    dataTableCreated = false;

    constructor(
        private onTrailService: OnTrailService
    ) {
    }

    ngOnInit() {
        const applicationsObservable = this.onTrailService.getOnTrailApplications();
        applicationsObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((allApplications) => {
                const tempOnTrailApplications: Observable<OnTrailApplication>[] = [];
                for (const i in allApplications) {
                    if (allApplications[i].trackId) {
                        tempOnTrailApplications.push(this.onTrailService.loadTrailForApplication(allApplications[i]));
                    } else {
                        tempOnTrailApplications.push(of(allApplications[i]));
                    }
                }
                zip(...tempOnTrailApplications)
                    .subscribe((onTrailApplications) => {
                        this.contacts = onTrailApplications;
                    });
            });
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($('#contactsDataTable>tbody>tr').length > 0)) {
            $('#contactsDataTable').DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'colvis',
                    'csvHtml5',
                    'excelHtml5'
                ],
                order: [[2, 'asc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
