<div class="content-wrapper">
    <app-content-header-section *ngIf="poiCategory" [contentPageId]="contentPageId"
                                [pageParams]="{poiCategoryName: poiCategory.name ?? poiCategoryLabel, poiCategoryLabel: poiCategoryLabel }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid" *ngIf="poiCategory">
            <div class="row">
                <div class="col-12 col-lg-6 col-xl-4">

                    <app-published-indicator [publishedState]="state.progress" (saveDraft)="saveDraft()" (publishElement)="publish()"
                                             (unpublishElement)="unpublish()" [modalSettings]="modalSettings"
                                             [contentName]="poiCategory.name || poiCategoryLabel" contentType="category"
                                             (leaveConfirmed)="canDeactivateReply($event)"></app-published-indicator>

                </div>
                <div class="col-12 col-lg-6 col-xl-4">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('category')">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i [ngClass]="layout.getIcon('category') + ' mr-2'"></i>
                                POI Category: {{ poiCategory.name ?? poiCategoryLabel }}</h3>
                        </div>
                        <div class="card-body">
                            <p class="card-text">{{ poiCategory.definition }}</p>

                            <div class="d-flex">
                                <div class="my-auto" *ngIf="mediaIcon">
                                    <img [src]="mediaIcon.pngUrl" [alt]="mediaIcon.name" class="bg-white mr-2 mb-3" width="64" height="64">
                                </div>
                                <div class="flex-grow-1">
                                    <div class="form-group">
                                        <label for="mediaIconSelect">POI icon</label>
                                        <select [(ngModel)]="poiCategory.mediaIconKey" id="mediaIconSelect" aria-describedby="mediaIconHelp"
                                                class="form-control" (change)="handleMediaIconUpdated()">
                                            <option *ngFor="let mediaIcon of mediaIcons" [value]="mediaIcon.key">{{ mediaIcon.name }}</option>
                                        </select>
                                        <small class="form-text text-muted" id="mediaIconHelp">Select the POI icon.</small>
                                        <div *ngIf="publicPoiCategory && publicPoiCategory.iconUrl !== poiCategory.mediaIconUrl"
                                             class="text-danger text-sm">
                                            <i class="fas fa-globe-europe"></i>
                                            Published icon <img [src]="publicPoiCategory.iconUrl" alt="Public icon" width="20" height="20">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <div class="form-group">
                                        <label for="mediaDefaultImageSelect">Fallback POI image</label>
                                        <select [(ngModel)]="poiCategory.mediaDefaultImageKey" id="mediaDefaultImageSelect" class="form-control"
                                                aria-describedby="mediaDefaultImageHelp" (change)="handleDefaultImageUpdated()">
                                            <option *ngFor="let mediaDefaultImage of defaultImages"
                                                    [value]="mediaDefaultImage.key">{{ mediaDefaultImage.name }}</option>
                                        </select>
                                        <small class="form-text text-muted" id="mediaDefaultImageHelp">Select a fallback image to use for POIs of this
                                            category without an image (used by import).</small>
                                    </div>
                                </div>
                                <div *ngIf="defaultImage">
                                    <img [src]="defaultImage.pngUrl" [alt]="defaultImage.name" class="bg-white ml-2" width="192" height="144">
                                </div>
                            </div>

                            <div>Access level: {{ poiCategory.accessLevel }}</div>
                            <div *ngIf="poiCategory.geofa_facil_ty_k">
                                Facility type key: {{ poiCategory.geofa_facil_ty_k }}
                                <a href="https://www.geodanmark.dk/wp-content/uploads/2019/10/GeoFA-specifikation-version-1.0-070421.pdf"
                                   target="geofa"><i class="fas fa-external-link-alt"></i></a>
                            </div>
                            <ul class="list-group" *ngIf="poiCategory.subCategories">
                                <li *ngFor="let subCategory of poiCategory.subCategories | keyValuePoiSubCategory" class="list-group-item">
                                    <h5 class="mt-0 mb-1">
                                        <i [ngClass]="layout.getIcon('category') + ' mr-2'"></i> {{ subCategory.name || subCategory.key }}
                                    </h5>

                                    <p>{{ subCategory.definition }}</p>

                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input [(ngModel)]="poiCategory.subCategories[subCategory.key].enabled"
                                                   [id]="'CB' + subCategory.key"
                                                   class="custom-control-input" type="checkbox" (change)="handlePoiCategoryAltered()">
                                            <label class="custom-control-label" [for]="'CB' + subCategory.key">Active</label>
                                        </div>
                                        <div *ngIf="subCategoryNotLikePublic(subCategory.key)" class="text-danger text-sm">
                                            <i class="fas fa-globe-europe"></i> Published value is inverted.
                                        </div>
                                    </div>

                                    <div *ngIf="subCategory.geofa_facil_ty_k" class="d-flex">
                                        <div class="flex-grow-1">
                                            <div class="form-group">
                                                <label [for]="subCategory.key + 'MediaDefaultImageSelect'">Fallback POI image</label>
                                                <select [(ngModel)]="poiCategory.subCategories[subCategory.key].mediaDefaultImageKey"
                                                        [id]="subCategory.key + 'MediaDefaultImageSelect'" (change)="handleDefaultImageUpdated()"
                                                        [attr.aria-describedby]="subCategory.key + 'mediaDefaultImageHelp'" class="form-control">
                                                    <option [ngValue]="null">--Same as {{ poiCategory.name || poiCategoryLabel }}--</option>
                                                    <option *ngFor="let mediaDefaultImage of defaultImages"
                                                            [ngValue]="mediaDefaultImage.key">{{ mediaDefaultImage.name }}</option>
                                                </select>
                                                <small class="form-text text-muted" [id]="subCategory.key + 'mediaDefaultImageHelp'">Select a fallback
                                                    image to use for POIs of this subcategory without an image (used by import).</small>
                                            </div>
                                        </div>
                                        <div *ngIf="subCategoryDefaultImages[subCategory.key]">
                                            <img [src]="subCategoryDefaultImages[subCategory.key].pngUrl" class="bg-white ml-2" width="192"
                                                 height="144" [alt]="subCategoryDefaultImages[subCategory.key].name">
                                        </div>
                                    </div>

                                    <div>Access level: {{ subCategory.accessLevel }}</div>
                                    <div *ngIf="subCategory.geofa_facil_ty_k">
                                        Facility type key: {{ subCategory.geofa_facil_ty_k }}
                                        <a href="https://www.geodanmark.dk/wp-content/uploads/2019/10/GeoFA-specifikation-version-1.0-070421.pdf"
                                           target="geofa"><i class="fas fa-external-link-alt"></i></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6 col-xl-4">

                    <app-translatable-text-edit [textModel]="textModel" [textsName]="poiCategory.name ?? poiCategoryLabel"
                                                [textObject]="poiCategory" (textChanged)="handlePoiCategoryAltered()"
                                                [publishedObject]="publicPoiCategory"></app-translatable-text-edit>

                </div>
            </div>
        </div>
    </section>
</div>
