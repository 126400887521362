<ul class="navbar-nav">
    <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
    </li>
</ul>
<ul class="navbar-nav ml-auto">
    <li *ngIf="authService.user as user" class="nav-item dropdown user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
            <img src="{{ user.userPicture }}" class="user-image img-circle elevation-2" alt="You are logged in as {{ user.name }}">
            <span class="d-none d-md-inline">{{ user.name }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <li class="user-header">
                <img src="{{ user.userPicture }}" class="img-circle elevation-2" alt="Profile image of {{ user.name }}">
                <p>
                    {{ user.name }}
                    <small>Member since {{ user.createdTime | date: 'longDate' }}</small>
                </p>
            </li>
            <li class="user-footer">
                <a href="/profile" class="btn btn-default btn-flat">Profile</a>
                <a class="btn btn-default btn-flat float-right" (click)="authService.signOut()">Sign out</a>
            </li>
        </ul>
    </li>
</ul>
