import { NgModule } from '@angular/core';

import { AuthService } from './auth.service';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

@NgModule({
    imports: [
        AngularFireAuthModule
    ],
    providers: [AuthService],
    declarations: []
})
export class CoreModule {
}
