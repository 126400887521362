import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { PoiCategory, PublicPoiCategory } from '../interfaces/poi-category';

@Injectable({
    providedIn: 'root'
})
export class PoiCategoryStateService extends StateService {

    constructor() {
        super();
    }

    protected static canPublish(poiCategory: PoiCategory): boolean {
        if (typeof poiCategory.name !== 'string' || poiCategory.name.trim() === '') {
            return false;
        }
        if (typeof poiCategory.mediaIconUrl !== 'string') {
            return false;
        }
        if (typeof poiCategory.mediaDefaultImageUrl !== 'string') {
            return false;
        }
        if (poiCategory.subCategories) {
            const subLabels = Object.keys(poiCategory.subCategories);
            for (const subLabel of subLabels) {
                if (poiCategory.subCategories[subLabel].enabled &&
                    (typeof poiCategory.subCategories[subLabel].name !== 'string' ||
                        poiCategory.subCategories[subLabel].name.trim() === '')) {
                    return false;
                }
            }
        }
        return true;
    }

    protected static isOutOfSync(poiCategory: PoiCategory, publicPoiCategory: PublicPoiCategory): boolean {
        if (!poiCategory || !publicPoiCategory) {
            return false;
        }
        if (poiCategory.name !== publicPoiCategory.name) {
            return true;
        }
        if (publicPoiCategory.lang) {
            for (const languageCode of Object.keys(publicPoiCategory.lang)) {
                if (!poiCategory.lang || !poiCategory.lang[languageCode] ||
                    (poiCategory.lang[languageCode].name !== publicPoiCategory.lang[languageCode].name)) {
                    return true;
                }
            }
        }
        if (poiCategory.lang) {
            for (const countryCode of Object.keys(poiCategory.lang)) {
                if (poiCategory.lang[countryCode].name &&
                    (!publicPoiCategory.lang ||
                        !publicPoiCategory.lang[countryCode] ||
                        !publicPoiCategory.lang[countryCode].name)) {
                    return true;
                }
            }
        }
        if (poiCategory.mediaIconUrl !== publicPoiCategory.iconUrl) {
            return true;
        }
        if (poiCategory.sortOrder !== publicPoiCategory.sortOrder) {
            return true;
        }
        if (poiCategory.subCategories) {
            const subLabels = Object.keys(poiCategory.subCategories);
            for (const subLabel of subLabels) {
                if (!poiCategory.subCategories[subLabel].enabled) {
                    if (publicPoiCategory.subCategories && publicPoiCategory.subCategories[subLabel]) {
                        return true;
                    } else {
                        continue;
                    }
                }
                if (!publicPoiCategory.subCategories || !publicPoiCategory.subCategories[subLabel]) {
                    return true;
                }
                if (poiCategory.subCategories[subLabel].name !==
                    publicPoiCategory.subCategories[subLabel].name) {
                    return true;
                }
                if (publicPoiCategory.subCategories[subLabel].lang) {
                    for (const countryCode of Object.keys(publicPoiCategory.subCategories[subLabel].lang)) {
                        if (!poiCategory.subCategories[subLabel].lang || !poiCategory.subCategories[subLabel].lang[countryCode] ||
                            (poiCategory.subCategories[subLabel].lang[countryCode].name !==
                                publicPoiCategory.subCategories[subLabel].lang[countryCode].name)) {
                            return true;
                        }
                    }
                }
                if (poiCategory.subCategories[subLabel].lang) {
                    for (const countryCode of Object.keys(poiCategory.subCategories[subLabel].lang)) {
                        if (poiCategory.subCategories[subLabel].lang[countryCode].name &&
                            (!publicPoiCategory.subCategories[subLabel].lang ||
                                !publicPoiCategory.subCategories[subLabel].lang[countryCode] ||
                                !publicPoiCategory.subCategories[subLabel].lang[countryCode].name)) {
                            return true;
                        }
                    }
                }
                if (poiCategory.subCategories[subLabel].sortOrder !==
                    publicPoiCategory.subCategories[subLabel].sortOrder) {
                    return true;
                }
            }
        }
        return false;
    }

    protected static isPublished(publicPoiCategory: PublicPoiCategory): boolean {
        return (typeof publicPoiCategory === 'object' && publicPoiCategory !== null);
    }

}
