<div class="content-wrapper">

    <app-content-header-section contentPageId="voronoi" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-info" *ngIf="countries && status <= 1">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-filter"></i> Filter trail areas</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="country"><i class="far fa-flag"></i> Select country</label>
                                <select [(ngModel)]="selectedCountry" class="form-control" id="country" aria-describedby="countryHelp"
                                        (change)="onSelectCountry()">
                                    <option *ngFor="let country of countries" [ngValue]="country">
                                        <ng-container *ngIf="countryCodesNeedingUpdate.includes(country.countryCode)">*NEEDS UPDATE*</ng-container>
                                        {{ country.name }}
                                    </option>
                                </select>
                                <small class="form-text text-muted" id="countryHelp">Select country to generate cells in.</small>
                            </div>
                            <!-- Regions is a project WIP -->
                            <!-- <div class="form-group" *ngIf="selectedCountry && regions[selectedCountry]">-->
                            <!--     <label for="region">Select region within {{ selectedCountry.name }}</label>-->
                            <!--     <select [(ngModel)]="selectedRegion" id="region" aria-describedby="regionHelp" class="form-control">-->
                            <!--         <option value=""> &#45;&#45; none &#45;&#45;</option>-->
                            <!--         <option *ngFor="let region of regions[selectedCountry]" [ngValue]="region">{{ region.name }}</option>-->
                            <!--     </select>-->
                            <!--     <small class="form-text text-muted" id="regionHelp">Select region to generate cells in (optional).</small>-->
                            <!-- </div>-->
                        </div>
                    </div>

                    <div class="card card-outline card-info" *ngIf="bounds && status <= 4">
                        <div class="card-header">
                            <h3 class="card-title"><i class="far fa-file-alt"></i> Select GeoJSON file</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group" *ngIf="status === 1">
                                <label for="importInput">Area shapes - (GeoJSON format)</label>
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="btn btn-default btn-file">
                                            Browse… <!--suppress TypeScriptUnresolvedVariable -->
                                            <input type="file" (change)="onImportFileSelected($event.target.files)" id="importInput">
                                        </span>
                                    </span>
                                    <input id="filename" aria-describedby="filenameHelp" type="text" class="form-control" readonly>
                                </div>
                                <small class="form-text text-muted" id="filenameHelp"><strong>Notice: Try to keep file size below 250k.</strong>
                                    Choose a local GeoJSON-file with coastlines and borders for the requested area.</small>
                            </div>
                            <div class="form-group" *ngIf="status === 3">
                                <!--                                <div><strong>Trail Areas:</strong> {{ seedObjects.length }}</div>-->
                                <div><strong>Land Shapes:</strong> {{ landShapes.length }}</div>
                                <div><strong>Points to describe:</strong> {{ pointsToDescribe }}
                                    <i *ngIf="pointsToDescribe > pointWarningLimit && pointsToDescribe <= pointLimit"
                                       class="fas fa-exclamation-triangle text-danger"></i>
                                    <i *ngIf="pointsToDescribe > pointLimit" class="fas fa-skull-crossbones text-danger"></i>
                                </div>
                                <div *ngIf="pointsToDescribe <= pointWarningLimit" class="btn btn-success" (click)="storeCountryShapes()">Store
                                    country cell-shapes for {{ selectedCountry.name }}</div>
                                <div *ngIf="pointsToDescribe > pointWarningLimit && pointsToDescribe <= pointLimit" class="btn btn-danger"
                                     (click)="storeCountryShapes()">Store country cell-shapes for {{ selectedCountry.name }}</div>
                                <button *ngIf="pointsToDescribe > pointLimit" class="btn btn-success" disabled>Store country cell-shapes
                                    for {{ selectedCountry.name }}</button>

                            </div>
                            <div class="form-group" *ngIf="status === 4">
                                <div><strong>Land Shapes saved:</strong> {{ landShapes.length }}</div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div>Get shapefiles from e.g. <a href="https://gadm.org/download_country_v3.html" target="_blank">gadm.org</a></div>
                            <div>Convert to GeoJSON from e.g. <a href="https://mapshaper.org/" target="_blank">mapshaper.org</a></div>
                            <div class="btn btn-default mr-2" (click)="reset()">Reset</div>
                            <div class="btn btn-default" (click)="skipToTrailAreaShapes()"
                                 *ngIf="status < 4 && landShapes && collectionUpdatedTimestamp > 0"> Skip
                            </div>
                        </div>
                        <div class="overlay" *ngIf="status === 2">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                    <div class="card card-outline card-maroon" *ngIf="status >= 4">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-map"></i> Generate new trail area cell shapes for {{ selectedCountry.name }}</h3>
                        </div>
                        <div class="card-body">
                            <div><strong>Old Trail Areas:</strong> {{ currentTrailAreaCount }}</div>
                            <div><strong>New Trail Areas:</strong> {{ seedObjects.length }}</div>
                            <div><strong>Land Shapes:</strong> {{ landShapes.length }}</div>
                            <button class="btn btn-success" (click)="generateShapes()" [disabled]="seedObjects.length === 0">Generate
                                for {{ selectedCountry.name }}
                                <!--<span *ngIf="selectedRegion"> - {{ selectedRegion.name }}</span>-->
                            </button>
                        </div>
                        <div class="overlay" *ngIf="status > 4">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">

                    <div #countryBoundsMap class="gmap"></div>

                </div>
            </div>
        </div>
    </section>

</div>
