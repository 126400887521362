import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../core/layout.service';
import { NewsStoryService } from '../../firebase-services/news-story.service';

@Component({
    selector: 'app-news-room',
    templateUrl: './news-room.component.html',
    styleUrls: ['./news-room.component.css']
})
export class NewsRoomComponent implements OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();

    draftCount: number = -1;
    storyCount: number = -1;
    archiveCount: number = -1;
    welcomeCount: number = -1;

    constructor(
        public layout: LayoutService,
        private newsStoryService: NewsStoryService
    ) {
        this.newsStoryService.getNewsStoriesIn('news-room', false)
            .pipe(takeUntil(this.destroy$))
            .subscribe((stories) => {
                let drafts: number = 0;
                let published: number = 0;
                let archived: number = 0;
                let welcome: number = 0;

                stories.reverse().forEach((story) => {
                    if (story.publishedAt) {
                        if (story.archivedAt) {
                            archived++;
                        } else {
                            published++;
                            if (story.disableInteractions === true) {
                                welcome++;
                            }
                        }
                    } else {
                        drafts++;
                    }
                });

                this.draftCount = drafts;
                this.storyCount = published;
                this.archiveCount = archived;
                this.welcomeCount = welcome;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
