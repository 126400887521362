export interface LastWeekTotalKpi {
    total: number;
    lastWeek: number;
}

export interface AreaObject {
    boundsNorth?: number;
    boundsSouth?: number;
    boundsEast?: number;
    boundsWest?: number;
}

export interface TranslatableObject {
    lang?: {
        [countryCode: string]: {
            [textKey: string]: string
        }
    };
}

export enum PublishedStates {
    DRAFT_ALTERED,
    DRAFT_SAVED,
    DRAFT_READY,
    PUBLISHED_NOT_READY,
    PUBLISHED_OUT_OF_SYNC,
    PUBLISHED_ONLINE
}

export interface ModalSettings {
    iconClasses?: string;
    title: string;
    question: string;
    confirmText?: string;
    cancelText?: string;
}

export interface PublishedState {
    altered: boolean;
    canPublish: boolean;
    icon: string;
    isPublished: boolean;
    outOfSync: boolean;
    progress: PublishedStates;
    theme: string;
}

export interface StatesBundle {
    [poiKey: string]: PublishedState;
}
