import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { take } from 'rxjs/operators';

// Services
import { FileService } from '../../services/file.service';
import { LayoutService } from '../../core/layout.service';

// Interfaces
import { MediaFileType, MediaLibraryTypes, MediaType } from '../../interfaces/media-library';

@Component({
    selector: 'app-add-media-of-type',
    templateUrl: './add-media-of-type.component.html',
    styleUrls: ['./add-media-of-type.component.css']
})
export class AddMediaOfTypeComponent implements OnInit {
    @Input() mediaTypeLabel: MediaLibraryTypes;
    @Input() mediaType: MediaType;

    fileIndex = 0;

    constructor(
        private afFunctions: AngularFireFunctions,
        private fileService: FileService,
        public layout: LayoutService
    ) {
    }

    ngOnInit(): void {
    }

    fileTypeCompareFunction(a: KeyValue<string, MediaFileType>, b: KeyValue<string, MediaFileType>): number {
        return (a.key > b.key) ? 1 : (a.key < b.key ? -1 : 0);
    }

    onFileSelected(fileType: string, files: FileList): void {
        this.mediaType.fileTypes[fileType].storageFileName = null;
        this.mediaType.fileTypes[fileType].storageFullPath = null;
        this.mediaType.fileTypes[fileType].tempFile =
            this.fileService.onFileSelected(files, this.mediaType.fileTypes[fileType].acceptedFileType);
        if (this.mediaType.fileTypes[fileType].tempFile !== null) {
            const reader = new FileReader();
            reader.readAsDataURL(this.mediaType.fileTypes[fileType].tempFile);
            this.mediaType.fileTypes[fileType].disableUpload = false;
            this.mediaType.fileTypes[fileType].fileName = this.mediaType.fileTypes[fileType].tempFile.name;
        } else {
            this.mediaType.fileTypes[fileType].disableUpload = true;
            this.mediaType.fileTypes[fileType].fileName = null;
        }
        this.updateDisableCreate();
    }

    onFileUpload(fileType: string): void {
        this.mediaType.fileTypes[fileType].disableUpload = true;

        this.fileService.uploadFileToStorage(null, 'temp/', this.fileIndex++, this.mediaType.fileTypes[fileType].tempFile)
            .then((uploadFileResult) => {
                this.mediaType.fileTypes[fileType].tempFile = null;
                this.mediaType.fileTypes[fileType].uploadInfo++;
                this.mediaType.fileTypes[fileType].storageFileName = uploadFileResult.name;
                this.mediaType.fileTypes[fileType].storageFullPath = uploadFileResult.fullPath;
                this.updateDisableCreate();
            })
            .catch((err) => {
                this.mediaType.fileTypes[fileType].tempFile = null;
                this.mediaType.fileTypes[fileType].storageFileName = null;
                this.mediaType.fileTypes[fileType].storageFullPath = null;
                this.updateDisableCreate();
                console.error(this.mediaTypeLabel + ':' + fileType + ' upload error occurred:', err.message);
            });
    }

    updateDisableCreate() {
        let disabled = (this.mediaType.newName.trim() === '');
        if (!disabled) {
            Object.values(this.mediaType.fileTypes).forEach((fileType) => {
                if (!fileType.storageFileName) {
                    disabled = true;
                }
            });
        }
        this.mediaType.disableCreate = disabled;
    }

    createMedia() {
        // Disable buttons while waiting
        this.mediaType.disableCreate = true;
        Object.keys(this.mediaType.fileTypes).forEach((fileTypeKey) => {
            this.mediaType.fileTypes[fileTypeKey].disableUpload = true;
        });

        this.afFunctions.httpsCallable('createMedia')(this.mediaType)
            .pipe(take(1))
            .subscribe(() => {
                // Reset form, making it ready for next input
                Object.keys(this.mediaType.fileTypes).forEach((fileTypeKey) => {
                    this.mediaType.fileTypes[fileTypeKey].fileName = null;
                    this.mediaType.fileTypes[fileTypeKey].tempFile = null;
                    this.mediaType.fileTypes[fileTypeKey].storageFileName = null;
                    this.mediaType.fileTypes[fileTypeKey].storageFullPath = null;
                });
                this.mediaType.newName = '';
            });
    }

}
