import { Injectable } from '@angular/core';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from '@angular/fire/storage';

// Interfaces
import { UploadFileResult } from '../interfaces/file';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor() {
    }

    onFileSelected(files: FileList, acceptedFileTypeMatch: string): File {
        if (files && files.length === 1) {
            const selectedFile = <File>files[0];
            if (acceptedFileTypeMatch.indexOf(selectedFile.type) !== -1) {
                return selectedFile;
            } else {
                window.alert('Wrong file type: ' + selectedFile.type);
                console.log('Wrong file type:', selectedFile.type, acceptedFileTypeMatch, selectedFile.type === acceptedFileTypeMatch);
            }
        }
        return null;
    }

    uploadFileToStorage(
        previousFileUrl: string,
        newFilePath: string,
        index: number,
        file: File, deletePrevious = true
    ): Promise<UploadFileResult> {
        const timestamp = Date.now(); // hack - hope this works everywhere...
        const newFileUrl = newFilePath + 'file.' + index + '.' + timestamp;

        // If there is already a previous file - delete this first.
        if (deletePrevious && typeof previousFileUrl === 'string' && previousFileUrl !== '') {
            const pUrl = previousFileUrl.replace(/%2F/g, '/');
            if (pUrl.slice(pUrl.indexOf(newFileUrl), pUrl.indexOf('?')) !== newFileUrl) {
                return this.deleteStorageFile(previousFileUrl)
                    .then(() => this.uploadFileToStorage(null, newFilePath, timestamp, file))
                    .catch((err) => {
                        console.error('Couldn\'t delete file' + previousFileUrl, err);
                        return this.uploadFileToStorage(null, newFilePath, timestamp, file);
                    });
            }
        }

        // Uploading file:
        const storage = getStorage();
        const storageRef = ref(storage, newFileUrl);
        return uploadBytes(storageRef, file)
            .then((res) => {
                return getDownloadURL(res.ref)
                    .then((url) => {
                        const uploadFileResult: UploadFileResult = {
                            fullPath: storageRef.fullPath,
                            name: storageRef.name,
                            url: url
                        };
                        return uploadFileResult;
                    });
            })
            .catch((err) => {
                console.error('Oh my no...', err);
                return err.message;
            });
    }

    deleteStorageFile(fileUrl: string): Promise<void> {
        try {
            const storage = getStorage();
            const storageRef = ref(storage, fileUrl);
            return deleteObject(storageRef);
        } catch (err) {
            console.error(err.message);
            return Promise.resolve();
        }
    }
}
