import { Component, Input } from '@angular/core';

import { Adventure } from '../../interfaces/adventure';

@Component({
    selector: 'app-adventure-list',
    templateUrl: './adventure-list.component.html',
    styleUrls: ['./adventure-list.component.css']
})
export class AdventureListComponent {

    @Input() routerRoot: string;
    @Input() adventures: Adventure[];

    constructor() {
    }

}
