<div *ngIf="trailArea" class="content-wrapper">
    <app-content-header-section *ngIf="trailArea" contentPageId="rideStatistics"
                                [pageParams]="{ trailAreaName: trailArea.name, trailAreaKey: trailArea.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title"><i class="far fa-calendar-alt"></i> Statistics Period</h3>
                        </div>
                        <div class="card-body">

                            <div class="form-group">
                                <label for="periodInput">Time period (weeks)</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text far fa-calendar-alt"></i>
                                    </div>
                                    <input type="text" class="form-control" id="periodInput">
                                    <span class="input-group-append">
                                        <button (click)="getStatisticsForPeriod()" class="btn btn-primary float-right">Load statistics</button>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="!dataReady" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>
                    </div>

                    <div class="card card-outline card-purple">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-chart-pie"></i> Results - Number of rides in {{ trailArea.name }}</h3>
                        </div>
                        <div class="card-body">
                            <div *ngIf="dataReady">
                                <div>Select a period and click load statistics</div>
                            </div>
                            <div class="statgraph">
                                <canvas #ridesInAreaChartCanvas></canvas>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 col-lg-2">
                                    <div class="description-block border-right">
                                        <h5 class="description-header">{{ ridesInAreaCount }}</h5>
                                        <span class="description-text">TOTAL RIDES IN PERIOD</span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-4 col-lg-2">
                                    <div class="description-block border-right">
                                        <h5 class="description-header">{{ profilesInAreaCount }}</h5>
                                        <span class="description-text">UNIQUE RIDERS IN PERIOD</span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-4 col-lg-2">
                                    <div class="description-block border-right">
                                        <h5 class="description-header">{{ newInPeriod }}
                                            / {{ 100 * newInPeriod / profilesInAreaCount | number:'1.0-0' }}%</h5>
                                        <span class="description-text">NEW RIDERS IN PERIOD</span>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-3">
                                    <div class="description-block border-right">
                                        <h5 class="description-header row">
                                                <span class="col-sm-6" *ngFor="let platform of platforms">
                                                    <i class="fab fa-android" *ngIf="platform[0] === 'Android'"></i>
                                                    <i class="fab fa-apple" *ngIf="platform[0] === 'iOS'"></i>
                                                    <span *ngIf="platform[0] !== 'Android' && platform[0] !== 'iOS'">{{ platform[0] }}</span>
                                                    {{ platform[1] }} / {{ 100 * platform[1] / profilesInAreaCount | number:'1.0-0' }}% &nbsp;
                                                </span>
                                        </h5>
                                        <span class="description-text">OS DISTRIBUTION</span>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="description-block">
                                        <h5 class="description-header row">
                                            <span class="col-sm-6" *ngFor="let country of countries">
                                                <span>{{ country[0] }}: {{ country[1] }}
                                                    / {{ 100 * country[1] / profilesInAreaCount | number:'1.0-0' }}%</span>
                                            </span>
                                        </h5>
                                        <span class="description-text">RIDER NATIONALITY</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="overlay" *ngIf="!ridesInAreaChart || !dataReady">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title">Popular Trails</h3>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 col-lg-12">
                                    <div class="chart-responsive">
                                        <canvas #trailsChartCanvas></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer no-padding">
                            <ul class="list-group list-group-flush">
                                <!--suppress TypeScriptValidateTypes -->
                                <li *ngFor="let trailData of popularTrails | keyvalue: popularCompareFunction"
                                    class="list-group-item d-flex justify-content-between align-items-center">
                                    <a routerLink="/trail-area/{{ trailArea.key }}/trails/{{ trailData.value.trail.key }}">
                                        <img [src]="trailData.value.trail.imageUrl" [alt]="trailData.value.trail.name" class="img-size-50"> &nbsp;
                                        #{{ plusOne(trailData.key) }}: {{ trailData.value.trail.name }}
                                    </a>
                                    <div class="float-right" [style]="setColor(trailData.value.trail)">
                                        <i class="fas fa-biking"></i> {{ trailData.value.visits }} &nbsp;
                                        <i class="fas fa-road"></i> {{ trailData.value.distance / 1000 | number:'1.0-2' }} km &nbsp;
                                        <i class="far fa-clock"></i> {{trailData.value.time * 1000 | date:'HH:mm:ss' }}
                                        <div *ngIf="trailData.value.trail.avgRating">
											<span class="rating_blank">
												<i class="far fa-star"></i>
												<i class="far fa-star"></i>
												<i class="far fa-star"></i>
												<i class="far fa-star"></i>
												<i class="far fa-star"></i>
												<span class="rating_full text-yellow" [style]="setStarWidth(trailData.value.trail)">
													<i class="fas fa-star"></i>
													<i class="fas fa-star"></i>
													<i class="fas fa-star"></i>
													<i class="fas fa-star"></i>
													<i class="fas fa-star"></i>
												</span>
											</span>
                                            {{ trailData.value.trail.avgRating | number:'1.1-1' }} in {{ trailData.value.trail.numberOfRatings }}
                                            votes
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title">Heatmap</h3>
                        </div>

                        <div class="card-body">
                            <div #gmap id="gmap"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
