import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of, Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { OnTrailService } from '../../firebase-services/on-trail.service';
import { AwardsService } from '../../firebase-services/awards.service';

// Interfaces
import {
    ON_TRAIL_AWARD_KEY,
    OnTrailApplication,
    StatusOptionApplied,
    StatusOptionApproved,
    StatusOptionBeingProcessed,
    StatusOptionRejected,
    StatusOptions
} from '../../interfaces/on-trail';
import { Award } from '../../interfaces/award';

@Component({
    selector: 'app-on-trail-status',
    templateUrl: './on-trail-status.component.html',
    styleUrls: ['./on-trail-status.component.css']
})
export class OnTrailStatusComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    statusOptions = StatusOptions;
    rejected = StatusOptionRejected;
    applied = StatusOptionApplied;
    beingProcessed = StatusOptionBeingProcessed;
    approved = StatusOptionApproved;

    dataReady = false;
    onTrailApplications: OnTrailApplication[] = null;
    onTrailAward: Award;

    // Stats
    numberOfRejected = 0;
    numberOfNew = 0;
    numberOfInProgress = 0;
    numberOfApproved = 0;
    numberOfConnectedTrails = 0;
    numberOfUnconnectedTrails = 0;
    connectedTrailsDistance = 0;
    connectedTrailsHeightIncrease = 0;
    connectedTrailsHeightDecrease = 0;

    constructor(
        private onTrailService: OnTrailService,
        private awardsService: AwardsService,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.onTrailService.getOnTrailApplications()
            .pipe(takeUntil(this.destroy$))
            .subscribe((allApplications) => {
                const tempOnTrailApplications: Observable<OnTrailApplication>[] = [];
                this.numberOfRejected = 0;
                this.numberOfNew = 0;
                this.numberOfInProgress = 0;
                this.numberOfApproved = 0;
                this.numberOfConnectedTrails = 0;
                this.numberOfUnconnectedTrails = 0;
                this.connectedTrailsDistance = 0;
                this.connectedTrailsHeightIncrease = 0;
                this.connectedTrailsHeightDecrease = 0;
                for (const i in allApplications) {
                    if (allApplications[i].trackId) {
                        tempOnTrailApplications.push(this.onTrailService.loadTrailForApplication(allApplications[i]));
                    } else {
                        tempOnTrailApplications.push(of(allApplications[i]));
                    }
                }
                zip(...tempOnTrailApplications)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((onTrailApplications) => {
                        this.onTrailApplications = onTrailApplications
                            .sort((a, b) => {
                                const status = a.status - b.status;
                                if (status === 0) {
                                    const hasTrail = (a.trail ? 1 : 0) - (b.trail ? 1 : 0);
                                    if (hasTrail === 0) {
                                        return (a.timestamp - b.timestamp);
                                    }
                                    return hasTrail;
                                }
                                return status;
                            });

                        this.onTrailApplications.forEach((onTrailApplication) => {
                            if (onTrailApplication.trail) {
                                this.numberOfConnectedTrails++;
                                this.connectedTrailsDistance =
                                    Math.round(this.connectedTrailsDistance * 10 + onTrailApplication.trail.lengthInMeters / 100) / 10;
                                this.connectedTrailsHeightIncrease += parseInt(onTrailApplication.trail.heightIncrease, 10);
                                this.connectedTrailsHeightDecrease += parseInt(onTrailApplication.trail.heightDecrease, 10);
                            } else {
                                this.numberOfUnconnectedTrails++;
                            }
                            switch (onTrailApplication.status) {
                                case -1:
                                    this.numberOfRejected++;
                                    break;
                                case 0:
                                    this.numberOfNew++;
                                    break;
                                case 1:
                                    this.numberOfInProgress++;
                                    break;
                                case 2:
                                    this.numberOfApproved++;
                                    break;
                                default:
                                    console.error('Unhandled status state', onTrailApplication.status);
                                    break;
                            }
                        });
                        this.dataReady = true;
                    });
            });
        this.awardsService.getAwardFromKey(ON_TRAIL_AWARD_KEY)
            .pipe(takeUntil(this.destroy$))
            .subscribe((award) => {
                this.onTrailAward = award;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    setPercentageWidth(numerator: number, denominator: number) {
        return this.domSanitizer.bypassSecurityTrustStyle('width: ' + Math.round(100 * numerator / denominator) + '%');
    }

    setTrailOnOnTrailApplication(trailKey: string, onTrailApplicationKey: string): Promise<void> {
        this.dataReady = false;
        return this.onTrailService.setTrailOnApplication(onTrailApplicationKey, trailKey);
    }

    unlinkTrailFromOnTrailApplication(onTrailApplication: OnTrailApplication): Promise<void> {
        if (confirm('Are you sure you want to unlink this trail from the application?')) {
            this.dataReady = false;
            return this.onTrailService.unlinkTrailFromApplication(onTrailApplication);
        }
        return Promise.resolve();
    }

    updateStatus(application: OnTrailApplication, newStatus: number): Promise<void> {
        this.dataReady = false;
        application.status = newStatus;
        return this.onTrailService.updateOnTrailApplicationStatus(application);
    }

    deleteOnTrailApplication(application: OnTrailApplication): Promise<void> {
        if (confirm('Are you sure you want to delete this application? (' + application.trackName + ')')) {
            this.dataReady = false;
            return this.onTrailService.deleteOnTrailApplication(application);
        }
        return Promise.resolve();
    }

    duplicateApplication(application: OnTrailApplication): void {
        if (confirm('You should duplicate an application if it applies to several trails. ' +
            'Are you sure you want to duplicate the "' + (application.trackName || application.trail.name) + '"-application')) {
            this.dataReady = false;
            this.onTrailService.duplicateOnTrailApplication(application);
        }
    }
}
