import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import firebase from 'firebase/compat';

// Interfaces
import { DonationKpi, DonationMobilePay, TrailBuilderOrganisation } from '../interfaces/trail-builder-organisation';

@Injectable({
    providedIn: 'root'
})
export class TrailBuilderOrganisationService {

    trailBuilderOrganisationRef: firebase.database.Reference;
    donationsMobilePayRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
    ) {
        this.trailBuilderOrganisationRef = this.db.database.ref('trailBuilderOrganisations');
        this.donationsMobilePayRef = this.db.database.ref('donationsMobilePay');
    }

    getTrailBuilderOrganisation(trailAreaKey: string) {
        return this.db.object<TrailBuilderOrganisation>(this.trailBuilderOrganisationRef.child(trailAreaKey).ref).valueChanges()
            .pipe(
                map((trailBuilderOrg) => {
                    if (trailBuilderOrg === null) {
                        trailBuilderOrg = {};
                    }
                    return trailBuilderOrg;
                })
            );
    }

    update(trailBuilderOrg: TrailBuilderOrganisation, trailAreaKey: string): Promise<void> {
        const updateObject: TrailBuilderOrganisation = <TrailBuilderOrganisation>{
            name: trailBuilderOrg.name || null,
            email: trailBuilderOrg.email || null,
            address: trailBuilderOrg.address || null,
            phone: trailBuilderOrg.phone || null,
            cvr: trailBuilderOrg.cvr || null,
            mobilePay: trailBuilderOrg.mobilePay || null,
            bossName: trailBuilderOrg.bossName || null,
            bossEmail: trailBuilderOrg.bossEmail || null,
            bossPhone: trailBuilderOrg.bossPhone || null,
            cashierName: trailBuilderOrg.cashierName || null,
            cashierEmail: trailBuilderOrg.cashierEmail || null,
            cashierPhone: trailBuilderOrg.cashierPhone || null
        };
        return this.trailBuilderOrganisationRef.child(trailAreaKey).update(updateObject);
    }

    getDonationKpis(): Observable<DonationKpi> {
        const organisationsObservable = this.db.list<TrailBuilderOrganisation>(this.trailBuilderOrganisationRef.ref).valueChanges();
        const donationsObservable = this.db.list<DonationMobilePay>(this.donationsMobilePayRef.ref).valueChanges();
        return combineLatest([organisationsObservable, donationsObservable])
            .pipe(
                take(1),
                map(([organisations, donations]) => {
                    const beneficiaries = {};
                    const donors = {};
                    let amount = 0;
                    organisations.forEach((organisation) => {
                        if (organisation.mobilePay) {
                            beneficiaries[organisation.mobilePay] = true;
                        }
                    });
                    donations.forEach((donation) => {
                        if (donation.amount > 0) {
                            donors[donation.profileKey] = true;
                            amount += donation.amount;
                        }
                    });

                    return {
                        donors: Object.keys(donors).length,
                        beneficiaries: Object.keys(beneficiaries).length,
                        amount: amount / 100
                    };
                })
            );
    }
}
