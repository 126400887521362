import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

// Services @todo Avoid
import { KioskCustomerService } from './kiosk-customer.service';
import { KioskOrderService } from './kiosk-order.service';
import { UserService } from './user.service';

// Interfaces
import { PremiumKpi, PremiumMember } from '../interfaces/premium';

import firebase from 'firebase/compat';
import Hashids from 'hashids';

@Injectable({
    providedIn: 'root'
})
export class PremiumService {
    premiumMembersRef: firebase.database.Reference;
    premiumIdsRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private kioskCustomerService: KioskCustomerService,
        private kioskOrderService: KioskOrderService
    ) {
        this.premiumMembersRef = this.db.database.ref('premiumMembers');
        this.premiumIdsRef = this.db.database.ref('premiumIds');
    }

    private static getPremiumMemberFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<PremiumMember>>): PremiumMember {
        const premiumMember: PremiumMember = snapshot.payload.val();
        premiumMember.profileKey = snapshot.key;
        return premiumMember;
    }

    getPremiumExpiry(profileKey: string): Observable<number> {
        return this.db.object<number>(this.premiumMembersRef.child(profileKey).child('expires').ref).snapshotChanges()
            .pipe(
                map((expirySnap: AngularFireAction<DatabaseSnapshot<number>>) => {
                    if (!expirySnap.payload.exists()) {
                        return 0;
                    }
                    return expirySnap.payload.val();
                })
            );
    }

    getPremiumMembers(): Observable<PremiumMember[]> {
        return this.db
            .list<PremiumMember>(this.premiumMembersRef.ref, ref => ref.orderByChild('expires').startAt(Date.now()))
            .snapshotChanges()
            .pipe(
                take(1),
                switchMap((premiumMembersSnapshot) => {
                    const members: Observable<PremiumMember>[] = [];
                    premiumMembersSnapshot.forEach((premiumMemberSnapshot) => {
                        const premiumMember = PremiumService.getPremiumMemberFromSnap(premiumMemberSnapshot);
                        if (typeof premiumMember.memberId !== 'string') {
                            members.push(this.ensurePremiumMemberId(premiumMember));
                        } else {
                            members.push(of(premiumMember));
                        }

                    });
                    return combineLatest(members);
                })
            );
    }

    updateMembership(profileKey: string, expiry: number): void {
        const premiumMemberSubject = this.getPremiumMemberObject(profileKey)
            .subscribe((premiumMember) => {
                premiumMember.expires = expiry;
                this.premiumMembersRef.child(profileKey).set(premiumMember)
                    .then(() => premiumMemberSubject.unsubscribe());
            });

    }

    getPremiumKpis(): Observable<PremiumKpi> {
        const now = Date.now();
        const allMembersObservable = this.db.list<PremiumMember>(this.premiumMembersRef.ref).valueChanges();
        const lastWeekStatsObservable = this.kioskOrderService.getPremiumOrderStatsFromLastWeek();
        return combineLatest([allMembersObservable, lastWeekStatsObservable])
            .pipe(
                take(1),
                map(([allMembers, lastWeekStats]) => {
                    const allTimeMembers = allMembers.length;
                    let currentMembers = 0;
                    allMembers.forEach((member) => {
                        if (member.expires > now) {
                            currentMembers++;
                        }
                    });

                    return {
                        allTimeMembers: allTimeMembers,
                        currentMembers: currentMembers,
                        lastWeekNew: lastWeekStats.new,
                        lastWeekRenewals: lastWeekStats.renewals
                    };
                })
            );
    }

    private getPremiumMemberObject(profileKey: string): Observable<PremiumMember> {
        return this.db.object<PremiumMember>(this.premiumMembersRef.child(profileKey).ref).valueChanges()
            .pipe(
                take(1),
                map((member: PremiumMember) => {
                    return (!member) ? null : member;
                }),
                switchMap((existingMember) => {
                    if (existingMember !== null) {
                        return of(existingMember);
                    }
                    return this.kioskCustomerService.getKioskCustomer(profileKey)
                        .pipe(
                            switchMap((kioskCustomer) => {
                                const generatedMember = <PremiumMember>{
                                    profileKey: profileKey,
                                    expires: null,
                                    name: kioskCustomer.name,
                                    street: kioskCustomer.street,
                                    zip: kioskCustomer.zip,
                                    city: kioskCustomer.city,
                                    country: kioskCustomer.country
                                };
                                return this.premiumMembersRef.child(profileKey).update(generatedMember)
                                    .then(() => generatedMember);
                            })
                        );
                }),
                switchMap((memberFromCustomer) => {
                    if (memberFromCustomer.name !== null && memberFromCustomer.name !== '') {
                        return of(memberFromCustomer);
                    }
                    return this.userService.getUser(profileKey)
                        .pipe(
                            switchMap((user) => {
                                memberFromCustomer.name = user.name || 'No Name';
                                return this.premiumMembersRef.child(profileKey).child('name').set(memberFromCustomer.name)
                                    .then(() => memberFromCustomer);

                            })
                        );
                }),
                switchMap((member) => {
                    if (!member.memberId) {
                        return this.ensurePremiumMemberId(member);
                    }
                    return of(member);
                })
            );
    }

    private ensurePremiumMemberId(premiumMember: PremiumMember): Observable<PremiumMember> {
        return this.db.object<PremiumMember>(this.premiumMembersRef.child(premiumMember.profileKey).ref).valueChanges()
            .pipe(
                switchMap((member) => {
                    if (member === null) {
                        console.error('No premium member found for ' + premiumMember);
                        member = premiumMember;
                    }
                    if (typeof member.memberId === 'string') {
                        return of(member);
                    }
                    return this.userService.getUser(premiumMember.profileKey)
                        .pipe(
                            switchMap((user) => {
                                return this.generatePremiumMemberId(premiumMember.profileKey, user.deviceCountry)
                                    .pipe(
                                        map((memberId) => {
                                            member.memberId = memberId;
                                            return member;
                                        })
                                    );
                            })
                        );
                })
            );
    }

    private generatePremiumMemberId(profileKey, countryCode): Observable<string> {
        const hashids = new Hashids(profileKey, 6, 'abcdefghijkmnpqrstuvwxyz23456789');
        const hashId = hashids.encode(Date.now().toString());
        const premiumMemberIdCandidate = countryCode + '-' + hashId.substr(hashId.length - 6, 6);
        return this.db.object<string>(this.premiumIdsRef.child(premiumMemberIdCandidate).ref).snapshotChanges()
            .pipe(
                take(1),
                switchMap((snap) => {
                    if (snap.payload.exists()) {
                        console.log('Already exists! Trying again', profileKey, countryCode, premiumMemberIdCandidate);
                        return this.generatePremiumMemberId(profileKey, countryCode);
                    }
                    const premiumIdSetPromise = this.premiumIdsRef.child(premiumMemberIdCandidate).child(profileKey).set(true);
                    const premiumMemberIdSetPromise =
                        this.premiumMembersRef.child(profileKey).child('memberId').set(premiumMemberIdCandidate);
                    return Promise.all([premiumIdSetPromise, premiumMemberIdSetPromise])
                        .then(() => premiumMemberIdCandidate);
                })
            );
    }
}
