<div class="content-wrapper">
    <app-content-header-section [contentPageId]="contentPageId" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-6">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme(mediaTypes[MediaLibraryTypes.DEFAULT_IMAGES].theme)">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i [ngClass]="layout.getPluralIcon(mediaTypes[MediaLibraryTypes.DEFAULT_IMAGES].theme) + ' mr-2'"></i>
                                {{ mediaTypes[MediaLibraryTypes.DEFAULT_IMAGES].typeNamePlural }}
                            </h3>
                        </div>
                        <div class="card-body" *ngIf="defaultImages">
                            <div class="card" *ngFor="let defaultImage of defaultImages">
                                <div class="row no-gutters">
                                    <div class="col-sm-8">
                                        <img [src]="defaultImage.pngUrl" class="img-fluid rounded-left"
                                             [alt]="defaultImage.name">
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card-body">
                                            <h5 class="card-title">{{ defaultImage.name }}</h5>
                                            <p class="card-text">Download file here:
                                                <a target="_blank" [href]="defaultImage.pngUrl">PNG</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6">

                    <app-add-media-of-type [mediaTypeLabel]="MediaLibraryTypes.DEFAULT_IMAGES"
                                           [mediaType]="mediaTypes[MediaLibraryTypes.DEFAULT_IMAGES]"></app-add-media-of-type>

                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme(mediaTypes[MediaLibraryTypes.ICONS].theme)">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i [ngClass]="layout.getPluralIcon(mediaTypes[MediaLibraryTypes.ICONS].theme) + ' mr-2'"></i>
                                {{ mediaTypes[MediaLibraryTypes.ICONS].typeNamePlural }}
                            </h3>
                        </div>
                        <div class="card-body px-0">
                            <ul class="list-unstyled" *ngIf="mediaIcons">
                                <li *ngFor="let mediaIcon of mediaIcons" class="media">
                                    <img [src]="mediaIcon.pngUrl" class="mr-3" [alt]="mediaIcon.name">
                                    <div class="media-body">
                                        <h5 class="mt-0 mb-1">{{ mediaIcon.name }}</h5>
                                        <p>Download files here:
                                            <a target="_blank" [href]="mediaIcon.pdfUrl">PDF</a>,
                                            <a target="_blank" [href]="mediaIcon.pngUrl">PNG</a>,
                                            <a target="_blank" [href]="mediaIcon.svgUrl">SVG</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6">

                    <app-add-media-of-type [mediaTypeLabel]="MediaLibraryTypes.ICONS"
                                           [mediaType]="mediaTypes[MediaLibraryTypes.ICONS]"></app-add-media-of-type>

                </div>
            </div>
        </div>
    </section>
</div>
