<div class="content-wrapper">
    <app-content-header-section contentPageId="devTools" [pageParams]="{ }"></app-content-header-section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-file-import"></i> Import Scripts</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="importInputCountries"><i class="far fa-file"></i> Import
                                    Countries</label>
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="btn btn-default btn-file">
                                            Browse… <!--suppress TypeScriptUnresolvedVariable -->
                                            <input type="file" (change)="onCountriesFileSelected($event.target.files)"
                                                   id="importInputCountries">
                                        </span>
                                    </span>
                                    <input id="filenameCountries" aria-describedby="filenameCountriesHelp" type="text"
                                           class="form-control" readonly>
                                </div>
                                <small class="form-text text-muted" id="filenameCountriesHelp">Choose a local CSV-file
                                    with country data.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-success" [disabled]="!dataReadyForImport" aria-describedby="importCountriesHelp"
                                    id="importCountries" (click)="importAllCountries()">Run Script
                            </button>
                            <small class="form-text text-muted" id="importCountriesHelp"> Run this script to import
                                all countries in selected dataset into the country manager. Run only once and check console for
                                report after import finished.</small>
                            <small class="form-text text-danger" id="importCountriesWarning"><i class="fas fa-exclamation-triangle"></i> If you are not a
                                developer, please do not touch!</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>