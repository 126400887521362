<div class="content-wrapper">
    <app-content-header-section contentPageId="newsRoom" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3 col-12">

                    <div [ngClass]="'small-box bg-' + layout.getTheme('newsDraft')">
                        <div class="inner">
                            <h3>{{ draftCount }}</h3>
                            <p>Draft</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('newsDraft')"></i>
                        </div>
                        <a [routerLink]="layout.getUrl('newsRoomDrafts', { })" class="small-box-footer">
                            Go to Story Drafts <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <div *ngIf="draftCount < 0" class="overlay">
                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-3 col-12">

                    <div [ngClass]="'small-box bg-' + layout.getTheme('news')">
                        <div class="inner">
                            <h3>{{ storyCount }}</h3>
                            <p>Stories</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('news')"></i>
                        </div>
                        <a [routerLink]="layout.getUrl('newsRoomStories', { })" class="small-box-footer">
                            Go to Published Stories <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <div *ngIf="storyCount < 0" class="overlay">
                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-3 col-12">

                    <div [ngClass]="'small-box bg-' + layout.getTheme('newsArchive')">
                        <div class="inner">
                            <h3>{{ archiveCount }}</h3>
                            <p>Archived Stories</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('newsArchive')"></i>
                        </div>
                        <a [routerLink]="layout.getUrl('newsRoomArchive', { })" class="small-box-footer">
                            Go to Story Archive <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <div *ngIf="archiveCount < 0" class="overlay">
                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-3 col-12">

                    <div [ngClass]="'small-box bg-' + layout.getTheme('newsWelcome')">
                        <div class="inner">
                            <h3>{{ welcomeCount }}</h3>
                            <p>Stories are eligible for Welcome</p>
                        </div>
                        <div class="icon">
                            <i [ngClass]="layout.getPluralIcon('newsWelcome')"></i>
                        </div>
                        <a [routerLink]="layout.getUrl('newsRoomWelcome', { })" class="small-box-footer">
                            Go to Welcome Stories <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <div *ngIf="welcomeCount < 0" class="overlay">
                            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-6">

                    <app-news-story-add-box roomName="news-room" navigateTo="/root/news-room/drafts/"></app-news-story-add-box>

                </div>
                <div class="col-md-6">

                    <app-news-room-list storyType="reach" roomName="news-room" navigateTo="/root/news-room/stories/"
                                        listName="Published Stories by Reach"></app-news-room-list>

                </div>
            </div>
        </div>
    </section>

</div>
