import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Interfaces
import { Organisation } from '../interfaces/organisation';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService {

    organisationsRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
    ) {
        this.organisationsRef = this.db.database.ref('organisations');
    }

    private static getOrganisationFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<Organisation>>): Organisation {
        const organisation: Organisation = snapshot.payload.val();
        organisation.key = snapshot.key;
        return organisation;
    }

    getOrganisation(organisationKey: string): Observable<Organisation> {
        return this.db.object<Organisation>(this.organisationsRef.child(organisationKey).ref).snapshotChanges()
            .pipe(map((organisationSnapshot) => OrganisationService.getOrganisationFromSnap(organisationSnapshot)));
    }

    getAllOrganisations(): Observable<Organisation[]> {
        return this.db.list<Organisation>(this.organisationsRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((organisationSnaps) => {
                    if (organisationSnaps.length === 0) {
                        return null;
                    }
                    return organisationSnaps.map((organisationSnap) => OrganisationService.getOrganisationFromSnap(organisationSnap));
                })
            );
    }

    updateName(organisation: Organisation): Promise<void> {
        const updateElement = <Organisation>{
            name: organisation.name
        };
        return this.organisationsRef.child(organisation.key).update(updateElement);
    }

    updateKioskProduct(organisation: Organisation): Promise<void> {
        const updateElement = <Organisation>{
            membershipProductKey: organisation.membershipProductKey
        };
        return this.organisationsRef.child(organisation.key).update(updateElement);
    }
}
