<div class="content-wrapper">
  <app-content-header-section
    contentPageId="sponsor"
    [pageParams]="{}"
  ></app-content-header-section>
  <div>
    <!-- <button class="btn btn-xs btn-success " routerLink="/root/sponsor/sponsor-edit/"><i
            class="far fa-edit"></i> Edit
    </button>&nbsp; -->
    <button (click)="createNewCampaign()" class="btn btn-success">
      Create New Campaign
    </button>
    <!-- <button class="btn btn-xs btn-success " routerLink="/root/sponsor/sponsor-edit"><i
                class="far fa-edit"></i> Edit
        </button>&nbsp; -->
    <!-- <button class="btn btn-xs btn-info" (click)="copyTreat(treat)"><i class="far fa-copy"></i> Copy</button> -->
  </div>
  <section *ngIf="allIsLoaded" class="content">
    <div class="container-fluid">
      <div class="row">
        <!-- <div class="col-12">
                <div class="col-md-6 offset-md-3 col-xl-4 offset-xl-4">
                    <app-translatable-texts *ngIf="textObject" textsName="Treats" colorTheme="teal" infoTrigger="{{ textsChangedInfo }}"
                                            [textModel]="textModel"[textObject]="textObject" (alteredTextObject)="onAlteredTextObject($event)">
                    </app-translatable-texts>
                </div>
            </div> -->
        <div class="col-12">
          <div class="card card-outline card-teal">
            <div class="card-header">
              <h3 class="card-title"><i class="fa fa-gift"></i> Sponsor</h3>
            </div>
            <div class="card-body px-0">
              <table
                id="treatsDataTable"
                class="table row-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>Campaign</th>
                    <th>Target</th>
                    <th>Active</th>
                    <!-- <th>Top of Mind</th>
                                    <th>My Treats</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sponsor of sponsors">
                    <td>
                      <div>
                        <strong>{{ sponsor.alias }}</strong>
                      </div>
                      <img
                        src="{{ sponsor.imageURL }}"
                        width="50"
                        [alt]="sponsor.alias"
                      />
                      <div>
                        <button
                          class="btn btn-xs btn-success"
                          routerLink="/root/sponsor/{{ sponsor.key }}"
                        >
                          <i class="far fa-edit"></i> Edit</button
                        >&nbsp;
                        <button
                          class="btn btn-xs btn-info"
                          (click)="copyTreat(sponsor)"
                        >
                          <i class="far fa-copy"></i>Copy
                        </button>
                      </div>
                    </td>

                    <td>
                      <div>
                        <strong>
                          {{
                            sponsorTargetType2LabelMapping[sponsor.sponsorType]
                          }}
                        </strong>
                         <div [innerHtml]="getSponsorTargetSpec(sponsor)"></div>
                      </div>

                     
                    </td>

                    <!-- <td>
                                        <span class="badge badge-en">en</span>&nbsp;{{ treat.topOfMindTitle }}&nbsp;<small
                                            title="{{ treat.topOfMindSubHeader }}">&lt;sub header&gt;</small>&nbsp;<a href="{{ treat.link }}"
                                                                                                                      target="treatLink">Link</a>
                                        <div *ngIf="treat.lang">
                                            <div *ngFor="let lang of objectKeys(treat.lang)">
                                                <span class="badge badge-{{ lang }}">{{ lang }}</span>&nbsp;<span
                                                    *ngIf="treat.lang[lang].topOfMindTitle">{{ treat.lang[lang].topOfMindTitle }}</span>&nbsp;<small
                                                    *ngIf="treat.lang[lang].topOfMindSubHeader" title="{{ treat.lang[lang].topOfMindSubHeader }}">&lt;sub
                                                header&gt;</small>&nbsp;<a *ngIf="treat.lang[lang].link" href="{{ treat.lang[lang].link }}"
                                                                           target="treatLink">Link</a>
                                            </div>
                                        </div>
                                    </td> -->
                    <td [attr.data-order]="sponsor.activeTo">
                      From {{ sponsor.activeFrom | date : "dd/MM/yyy HH:mm"
                      }}<br />
                      To {{ sponsor.activeTo | date : "dd/MM/yyy HH:mm" }}
                      <div>
                        <span
                          *ngIf="now < sponsor.activeFrom"
                          class="badge badge-warning"
                          >Planned in the future</span
                        >
                        <div
                          *ngIf="
                            now > sponsor.activeFrom && now < sponsor.activeTo
                          "
                          class="progress"
                        >
                          <div
                            class="progress-bar progress-bar-green"
                            role="progressbar"
                            [style]="setProgressWidth(sponsor)"
                          >
                            <!-- aria-valuenow="{{}}" aria-valuemin="0" aria-valuemax="{{sponsor.activeTo - sponsor.activeFrom}}"-->
                            -->
                            <span class="sr-only">40% Complete (success)</span>
                          </div>
                        </div>
                        <span
                          *ngIf="now > sponsor.activeTo"
                          class="badge badge-danger"
                          >Treat is expired</span
                        >
                      </div>
                    </td>
                    <!-- <td [attr.data-order]="treat.targetTopOfMind">
                                        Weight: {{ treat.targetTopOfMind }}
                                        <div *ngIf="treat.topOfMindExposures > 0">
                                            Exposures: {{ treat.topOfMindExposures }}<br/>
                                            Hits: {{ treat.topOfMindHits }}<br/>
                                            <span class="badge badge-warning">Hit-rate: {{ 100 * treat.topOfMindHits / treat.topOfMindExposures | number:'1.2-2' }}</span>
                                        </div>
                                        <div *ngIf="!treat.topOfMindExposures">
                                            <button class="btn btn-gray" (click)="loadStatsForTreat(treat)">Load stats</button>
                                        </div>
                                    </td> -->
                    <!-- <td [attr.data-order]="treat.targetMyTreats">
                                        Weight: {{ treat.targetMyTreats }}
                                        <div *ngIf="treat.myTreatsExposures > 0">
                                            Exposures: {{ treat.myTreatsExposures}}<br/>
                                            Hits: {{ treat.myTreatsHits}}<br/>
                                            <span class="badge badge-primary">Hit-rate: {{ 100 * treat.myTreatsHits / treat.myTreatsExposures | number:'1.2-2' }}</span>
                                        </div>
                                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
