import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';

// Services
import { RideService } from '../../../firebase-services/ride.service';
import { TrailService } from '../../../firebase-services/trail.service';

// Interfaces
import { ProfileRide, Ride } from '../../../interfaces/ride';
import { Trail } from '../../../interfaces/trail';

import * as polyTool from '@pirxpilot/google-polyline';

@Component({
    selector: 'app-ride',
    templateUrl: './ride.component.html',
    styleUrls: ['./ride.component.css']
})
export class RideComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    ride: Ride = null;
    adventureKey = '';
    ridePath: Object = null;
    profileRide: ProfileRide = null;
    trails: Trail[] = null;
    trailPaths: Array<[number, number]>[] = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private trailService: TrailService,
        private rideService: RideService,
        private storage: AngularFireStorage
    ) {
        this.router.events.pipe()
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Load data for the current trail
     */
    init() {
        this.ride = null;
        this.adventureKey = '';
        this.ridePath = null;
        this.profileRide = null;
        this.trails = null;
        this.trailPaths = [];
        this.rideService.getRide(this.route.snapshot.paramMap.get('rideKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe(ride => {
                if (typeof ride.adventureKey === 'string') {
                    this.adventureKey = ride.adventureKey;
                }
                this.rideService.getProfileRide(ride.userId, ride.key)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((profileRide) => {
                        if (!profileRide.algorithmVersion) {
                            const path = polyTool.decode(ride.encodedPolyline, {factor: 1e6});
                            profileRide = <ProfileRide>{
                                story: [{firstIndex: 0, lastIndex: path.length - 1, path: path, color: 'blue'}],
                                legacyTrailKey: ride.trackId,
                                encodedPolyline: ride.encodedPolyline
                            };
                        } else {
                            const path = polyTool.decode(profileRide.encodedPolyline, {factor: 1e6});
                            if (!profileRide.story) {
                                if (typeof profileRide.encodedPolyline !== 'undefined') {
                                    profileRide.story = [{firstIndex: 0, lastIndex: path.length - 1, path: path, color: 'blue'}];
                                }
                            } else {
                                this.ridePath = {
                                    path: path,
                                    color: '#90FF41'
                                };
                                profileRide.story.forEach((chapter) => {
                                    chapter.path = path.slice(chapter.firstIndex, chapter.lastIndex + 1);
                                    if (chapter.trailKeys.length > 1) {
                                        chapter.color = '#90FF41';
                                    } else if (chapter.trailKeys.length === 1) {
                                        chapter.color = 'black';
                                    } else {
                                        chapter.color = 'red';
                                    }
                                });
                            }
                        }
                        const trailKeys = [];
                        if (typeof profileRide.trails === 'object' && profileRide.trails !== null) {
                            profileRide.trails.forEach((trail) => {
                                trailKeys.push(trail.trailKey);
                            });
                        }
                        if (trailKeys.length > 0) {
                            this.trailService.getTrails(trailKeys)
                                .pipe(takeUntil(this.destroy$))
                                .subscribe((trails) => {
                                    trails.forEach((trail) => {
                                        if (typeof trail.encodedPolyline !== 'undefined') {
                                            this.trailPaths[trail.key] = polyTool.decode(trail.encodedPolyline, {factor: 1e6});
                                        }
                                    });
                                    this.trails = trails;
                                    this.profileRide = profileRide;
                                    this.ride = ride;
                                });
                        } else {
                            this.ride = ride;
                            this.profileRide = profileRide;
                        }
                    });
            });
    }

    recalculate(): void {
        this.rideService.orderRestartRideHandling(this.ride.key, 0);
    }

    legacyValidation() {
        this.rideService.orderLegacyValidationRestart(this.ride.key);
    }

    downloadFile(): void {
        const rideRef = this.storage.storage.ref('rideGpxFiles/' + this.ride.key + '.gpx');
        rideRef.getDownloadURL()
            .then((url) => {
                window.open(url);
            });
    }

    connectAdventureAndRide(): void {
        this.rideService.setAdventureKey(this.ride.key, this.adventureKey)
            .then(() => this.init());
    }
}
