import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

// Interfaces
import { ModalSettings, PublishedStates, PublishedState } from '../../interfaces/general';
import { TextModel } from '../../interfaces/text-model';

@Component({
    selector: 'app-publishable',
    template: `<h1>Publishable</h1>`
})
export abstract class PublishableComponent implements OnDestroy {
    protected destroy$: Subject<boolean> = new Subject<boolean>();
    contentPageId: string;

    private canDeactivate$: Subject<boolean> = new Subject<boolean>();
    modalSettings: ModalSettings;

    textModel: TextModel = {items: []};
    originalAsString: string;

    state: PublishedState;
    newImageReady = false;
    doUploadImage = 0;

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.state.progress === PublishedStates.DRAFT_ALTERED) {
            this.modalSettings = {
                iconClasses: 'fas fa-save text-warning',
                title: 'Unsaved data',
                question: 'If you continue, you will lose your changes. Continue?',
                confirmText: 'Discard changes'
            };
            return this.canDeactivate$;
        } else if (this.state.progress !== PublishedStates.DRAFT_SAVED && this.state.progress !== PublishedStates.PUBLISHED_ONLINE) {
            this.modalSettings = {
                iconClasses: 'fas fa-globe-europe text-danger',
                title: 'Not published',
                question: 'You did not publish your latest changes. Continue anyway?',
                confirmText: 'Yes, I understand'
            };
            return this.canDeactivate$;
        }
        return true;
    }

    canDeactivateReply(canDeactivate: boolean): void {
        this.canDeactivate$.next(canDeactivate);
    }

    saveDraft(): Promise<any> {
        console.log('saveDraft - Implement in extended class.');
        return;
    }

    publish(): Promise<any> {
        console.log('publish - Implement in extended class.');
        return;
    }

    unpublish(): Promise<any> {
        console.log('unpublish - Implement in extended class.');
        return;
    }

}
