import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RandomNumberService {

    constructor(
        private client: HttpClient,
    ) {
    }

    private static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Random number generator returned code ${error.status}, ` + `body was: ${error.error}`, error);
        }
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    getRandomNumbers(quantity: number, fromInt: number, toInt: number): Observable<number[]> {
        return this.client.get(`https://www.random.org/integers/?num=${quantity}&min=${fromInt}&max=${toInt}&col=1&base=10&format=plain&rnd=new`, {responseType: 'text'})
            .pipe(
                map((randomNumbersResult) => {
                    const randomNumbers: number[] = [];
                    const splitNumbers: string[] = randomNumbersResult.split(/\s+/);
                    splitNumbers.forEach((randomText) => {
                        if (randomText.length > 0) {
                            randomNumbers.push(parseInt(randomText, 10));
                        }
                    });
                    return randomNumbers;
                }),
                retry(1),
                catchError(RandomNumberService.handleError)
            );
    }

}
