import { environment } from '../../../../environments/environment';

import { AfterViewChecked, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as $ from 'jquery';
import polyTool from '@pirxpilot/google-polyline';

// Services
import { LayoutService } from '../../../core/layout.service';
import { FileService } from '../../../services/file.service';
import { MapWorkerService } from '../../../services/map-worker.service';
import { ModelAssistantService } from '../../../services/model-assistant.service';
import { TrailAreaService } from '../../../firebase-services/trail-area.service';
import { TrailService } from '../../../firebase-services/trail.service';
import { AwardsService } from '../../../firebase-services/awards.service';
import { RideService } from '../../../firebase-services/ride.service';

// Interfaces
import { Award } from '../../../interfaces/award';
import { DefaultMapProp, TrailAreaOnMap } from '../../../interfaces/map';
import { ON_TRAIL_AWARD_KEY } from '../../../interfaces/on-trail';
import { TextModel, TextModelItemType, TextObject } from '../../../interfaces/text-model';
import {
    Trail,
    TrailColor,
    TrailColors,
    TrailGrading,
    TrailGradingWorld,
    TrailTypes,
    TRANSPORT_TRAIL_COLOR
} from '../../../interfaces/trail';
import { LatLngArray, TrailArea } from '../../../interfaces/trailArea';

@Component({
    selector: 'app-trail-edit',
    templateUrl: './trail-edit.component.html',
    styleUrls: ['./trail-edit.component.css']
})
export class TrailEditComponent implements AfterViewChecked, OnDestroy {
    @ViewChild('gmap') gmapElement: ElementRef;

    destroy$: Subject<boolean> = new Subject<boolean>();
    textModel: TextModel = {items: []};

    trailTypes: { key: number, value: string }[];
    trailGradings: TrailGrading[] = TrailGradingWorld;
    trailColors: TrailColor[] = TrailColors;

    trailArea: TrailArea = null;
    trail: Trail = null;
    onTrailAward: Award;
    trailType: string;

    tempFile: File = null;
    pathEditable = false;
    pathEdited = false;
    heatMapState = 'new';
    loadHeatMapLastIndex = 0;
    stepSize = 100;
    settingsChangedInfo = 0;
    textsChangedInfo = 0;
    gpxUploadedInfo = 0;
    disableSaveSettings = true;
    disableSaveTexts = true;
    disableUploadGpx = true;
    private map: google.maps.Map = null;
    private startMarker: google.maps.Marker = null;
    private endMarker: google.maps.Marker = null;
    private heatMapDataSet: google.maps.LatLng[] = [];
    private heatmap: google.maps.visualization.HeatmapLayer = null;
    private trailAreaOnMap: TrailAreaOnMap = null;

    constructor(
        public layout: LayoutService,
        private route: ActivatedRoute,
        private router: Router,
        private mapWorkerService: MapWorkerService,
        private trailAreaService: TrailAreaService,
        private trailService: TrailService,
        private modelAssistantService: ModelAssistantService,
        private awardsService: AwardsService,
        private rideService: RideService,
        private fileService: FileService,
        private domSanitizer: DomSanitizer
    ) {
        this.trailTypes = [
            {key: TrailTypes.COMMON_TRAIL, value: 'Common trail'},
            {key: TrailTypes.EVENT_TRAIL, value: 'Event trail'},
            {key: TrailTypes.TRANSPORT, value: 'Transport'}
        ];
        this.initTextModel();
        this.router.events.pipe()
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngAfterViewChecked() {
        if (this.map !== null || typeof this.gmapElement === 'undefined') {
            return;
        }
        this.initMap();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Load data for the current trail
     */
    init() {
        this.trailAreaOnMap = null;
        this.trailArea = null;
        this.trail = null;
        this.tempFile = null;
        this.pathEditable = false;
        this.pathEdited = false;
        this.heatMapState = 'new';
        this.map = null;
        this.startMarker = null;
        this.endMarker = null;
        this.heatmap = null;
        this.loadHeatMapLastIndex = 0;
        this.heatMapDataSet = [];
        this.disableUploadGpx = true;
        this.disableSaveSettings = true;
        this.trailAreaService.getTrailArea(this.route.snapshot.paramMap.get('areaKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailArea) => this.trailArea = trailArea);
        this.modelAssistantService.getTrail(this.route.snapshot.paramMap.get('trailKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe(trail => {
                if (trail.awards && trail.awards[ON_TRAIL_AWARD_KEY]) {
                    this.loadOnTrailAward();
                }
                this.trailType = trail.trackType.toString();
                this.trail = trail;
                this.ngAfterViewChecked();
            });
    }

    setPathEditable() {
        this.trailAreaOnMap.trailPolylines[this.trail.key].setEditable(!this.pathEditable);

        // Dim POIs
        if (this.trailAreaOnMap.poiMarkerClusters) {
            Object.values(this.trailAreaOnMap.poiMarkerClusters).forEach((cluster) => {
                cluster.forEach((marker) => marker.setOpacity(0.5));
            });
        }

        // Remove start and end markers
        this.startMarker.setVisible(false);
        this.endMarker.setVisible(false);
    }

    loadHeatMap(): void {
        if (this.heatMapState === 'loading') {
            return;
        }
        this.heatMapState = 'loading';
        const lastIndex = this.loadHeatMapLastIndex + this.stepSize;
        const subscription = this.rideService.getHeatMapDataForLegacyTrail(this.trail, this.loadHeatMapLastIndex, lastIndex)
            .subscribe((result) => {
                this.heatMapState = (this.loadHeatMapLastIndex + this.stepSize !== result.endIndex) ? 'completed' : 'ready';
                this.loadHeatMapLastIndex = result.endIndex;
                this.heatMapDataSet = this.heatMapDataSet.concat(result.data);
                this.heatmap.setData(this.heatMapDataSet);
                subscription.unsubscribe();
            });
    }

    savePath(): Promise<void> {
        const polylinePoints: google.maps.LatLng[] = this.trailAreaOnMap.trailPolylines[this.trail.key].getPath().getArray();
        const encodedPolyline = polyTool.encode(polylinePoints, {
            factor: 1e6,
            mapFn: (p: google.maps.LatLng) => [[p.lng()], [p.lat()]]
        });
        return this.trailService.updatePath(this.trail.key, encodedPolyline)
            .then(() => this.init());
    }

    /**
     * File selected for upload handling
     */
    onGpxFileSelected(files: FileList): void {
        this.tempFile = this.fileService.onFileSelected(files, 'application/gpx+xml');
        if (this.tempFile !== null) {
            $('#gpx_filename').val(this.tempFile.name);
            this.disableUploadGpx = false;
        } else {
            $('#gpx_filename').val('');
            this.disableUploadGpx = true;
        }
    }

    /**
     * GPX file upload
     */
    onGpxUpload(): void {
        this.disableUploadGpx = true;
        this.trailService.uploadGpx(this.trail, this.tempFile, this.tempFile.name)
            .then(() => {
                this.tempFile = null;
                this.gpxUploadedInfo++;
            })
            .catch(err =>
                console.log('GPX upload error occurred:', err.message)
            );
    }

    downloadFile(filename: string): void {
        window.open(filename);
    }

    updateTrailGrading(trailGrading: TrailGrading) {
        if (this.trail.iconUrl === trailGrading.iconURL) {
            return;
        }
        this.trail.iconUrl = trailGrading.iconURL;
        this.settingsChanged();
    }

    /**
     * Sets state-variable when settings are changed
     */
    settingsChanged(): void {
        this.disableSaveSettings = false;
    }

    /**
     * Save settings
     */
    saveSettings(): void {
        this.disableSaveSettings = true;
        this.trail.trackType = parseInt(this.trailType, 10);
        if (this.trail.trackType === TrailTypes.TRANSPORT) {
            this.trail.color = TRANSPORT_TRAIL_COLOR;
        }
        if (!this.trail.countrie && this.trailArea.country) {
            this.trail.countrie = this.trailArea.country;
        }

        this.trailService.updateSettings(this.trail)
            .then(() => this.settingsChangedInfo++)
            .catch((err) => console.error('Settings-Update error occurred:', err.message));
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.trailService.updateTexts(<Trail>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    onUploadedImageUrl(uploadedImageUrl: string): void {
        this.trail.imageUrl = uploadedImageUrl;
        this.trailService.uploadedImage(this.trail)
            .catch((err) => console.error('Image upload failure', err));
    }

    setStarWidth() {
        return this.domSanitizer.bypassSecurityTrustStyle('width: ' + Math.round(1000 * this.trail.avgRating / 5) / 10 + '%');
    }

    private initTextModel(): void {
        this.textModel.items.push({
            name: 'Trail Name',
            varName: 'name',
            help: 'The name of this trail.',
            placeholder: 'Trail name',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'About the Trail',
            varName: 'description',
            help: 'The description of this particular trail. Focus on what makes this trail special - not the trail area.',
            placeholder: 'A description of this particular trail',
            type: TextModelItemType.TEXT_AREA
        });
    }

    private initMap() {
        this.map = new google.maps.Map(this.gmapElement.nativeElement, DefaultMapProp);

        // End marker
        this.endMarker = new google.maps.Marker({
            position: {
                lat: this.trail.stopPoint.latitude,
                lng: this.trail.stopPoint.longitude
            },
            draggable: false,
            clickable: false,
            map: this.map,
            icon: {
                url: environment.www + 'img/marker-Ω-990000.svg',
                scaledSize: new google.maps.Size(27, 27)
            },
            title: 'End of ' + this.trail.name
        });

        // Start marker
        this.startMarker = new google.maps.Marker({
            position: {
                lat: this.trail.startPoint.latitude,
                lng: this.trail.startPoint.longitude
            },
            draggable: false,
            clickable: false,
            map: this.map,
            icon: this.trailService.getTrailIconUrl(this.trail, false),
            title: 'Start of ' + this.trail.name
        });

        // Bounds
        const mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        if (typeof this.trail.boundsNorth === 'number' &&
            this.trail.boundsNorth > -90 &&
            this.trail.boundsNorth !== this.trail.boundsSouth) {
            mapBounds.extend({lat: this.trail.boundsNorth, lng: this.trail.boundsEast});
            mapBounds.extend({lat: this.trail.boundsSouth, lng: this.trail.boundsWest});
        } else {
            // Polyline
            const trailLatLngArray: LatLngArray = polyTool.decode(this.trail.encodedPolyline, {factor: 1e6});
            trailLatLngArray.forEach((latLng) => {
                mapBounds.extend({lat: latLng[1], lng: latLng[0]});
            });
        }
        this.map.fitBounds(mapBounds, {bottom: 1, left: 1, right: 1, top: 1});

        this.heatmap = new google.maps.visualization.HeatmapLayer({
            map: this.map,
            data: []
        });

        if (this.trailArea !== null) {
            this.mapWorkerService.trailAreaToMap(this.trailArea, this.map)
                .pipe(takeUntil(this.destroy$))
                .subscribe((trailAreaOnMap) => {
                    if (this.trailAreaOnMap) {
                        this.trailAreaOnMap.centerMarker.setMap(null);
                        if (this.trailAreaOnMap.trailPolylines) {
                            Object.keys(this.trailAreaOnMap.trailPolylines).forEach((trailKey) => {
                                this.trailAreaOnMap.trailPolylines[trailKey].setMap(null);
                            });
                        }
                    }
                    this.trailAreaOnMap = trailAreaOnMap;
                    if (!this.trailAreaOnMap.trailPolylines) {
                        this.trailAreaOnMap.trailPolylines = {};
                    }
                    if (!this.trailAreaOnMap.trailPolylines[this.trail.key]) {
                        this.trailAreaOnMap.trailPolylines[this.trail.key] = MapWorkerService.trailToMap(this.trail, this.map);
                    }

                    // Dim other trails and set event listeners
                    Object.keys(this.trailAreaOnMap.trailPolylines).forEach((trailKey) => {
                        if (trailKey !== this.trail.key) {
                            this.trailAreaOnMap.trailPolylines[trailKey].setOptions({
                                strokeOpacity: 0.5
                            });
                        } else {
                            this.trailAreaOnMap.trailPolylines[trailKey].setOptions({zIndex: 20000});
                            // Dragged a vertex?
                            this.trailAreaOnMap.trailPolylines[trailKey].addListener('mouseup', (event: google.maps.PolyMouseEvent) => {
                                if (typeof event.vertex === 'number' || typeof event.edge === 'number') {
                                    this.pathEdited = true;
                                }
                            });
                            // Deleted a vertex?
                            this.trailAreaOnMap.trailPolylines[trailKey]
                                .addListener('contextmenu', (event: google.maps.PolyMouseEvent) => {
                                    if (typeof event.vertex === 'number') { // Delete vertex
                                        const path: google.maps.LatLng[] =
                                            this.trailAreaOnMap.trailPolylines[trailKey].getPath().getArray();
                                        path.splice(event.vertex, 1);
                                        this.trailAreaOnMap.trailPolylines[trailKey].setPath(path);
                                        this.pathEdited = true;
                                    }
                                });
                        }
                    });
                });
        }
    }

    private loadOnTrailAward() {
        this.awardsService.getAwardFromKey(ON_TRAIL_AWARD_KEY)
            .pipe(takeUntil(this.destroy$))
            .subscribe((award) => {
                this.onTrailAward = award;
            });
    }
}
