import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-news-room-modify-story',
    templateUrl: './news-room-modify-story.component.html',
    styleUrls: ['./news-room-modify-story.component.css']
})
export class NewsRoomModifyStoryComponent implements OnInit {

    newsStoryKey: string = null;

    constructor(
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.newsStoryKey = this.route.snapshot.paramMap.get('newsStoryKey');
    }

}
