import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';

import firebase from 'firebase/compat';

// Interfaces
import { KioskProduct, KioskProductVariant, PriceEntry, PriceStructure } from '../interfaces/kiosk';

@Injectable({
    providedIn: 'root'
})
export class KioskProductService {

    kioskProductsRef: firebase.database.Reference;
    kioskProductVariantsRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.kioskProductsRef = this.db.database.ref('kioskProducts');
        this.kioskProductVariantsRef = this.db.database.ref('kioskProductVariants');
    }

    private static rejectWithError(error: string, extraVars: any[]): Promise<any> {
        console.warn(error, ...extraVars);
        return Promise.reject(error);
    }

    private static kioskProductVariantFromSnap(snap: AngularFireAction<DatabaseSnapshot<KioskProductVariant>>): KioskProductVariant {
        const kioskProductVariant: KioskProductVariant = snap.payload.val();
        kioskProductVariant.key = snap.key;
        return kioskProductVariant;
    }

    getKioskProduct(kioskProductKey: string): Observable<KioskProduct> {
        return this.db.object<KioskProduct>(this.kioskProductsRef.child(kioskProductKey).ref).snapshotChanges()
            .pipe(
                take(1),
                map((kioskProductSnap) => {
                    if (!kioskProductSnap.payload.exists()) {
                        return null;
                    }
                    return this.kioskProductFromSnap(kioskProductSnap);
                })
            );
    }

    createKioskProduct(kioskProduct: KioskProduct): firebase.database.ThenableReference {
        return this.kioskProductsRef.push(kioskProduct);
    }

    updateKioskProduct(kioskProduct: KioskProduct): Promise<any> {
        // Promises for holding price bundles with price structures - init
        const defaultPriceBundlePromise = this.generatePriceBundle(kioskProduct.defaultPriceStructure, null);
        const countryPriceBundlePromises: Promise<{ priceStructure: PriceStructure, countryCode: string }>[] = [];

        // Generate legal price structures
        Object.keys(kioskProduct.countryPriceStructures).forEach((countryCode) => {
            countryPriceBundlePromises.push(this.generatePriceBundle(kioskProduct.countryPriceStructures[countryCode], countryCode));
        });

        const updateProductPromise = Promise.all([defaultPriceBundlePromise, ...countryPriceBundlePromises])
            .then((priceBundles) => {
                let defaultPriceStructure: PriceStructure = null;
                const countryPriceStructures: { [countryCode: string]: PriceStructure } = {};
                priceBundles.forEach((priceBundle) => {
                    if (priceBundle === null) {
                        return;
                    }
                    if (priceBundle.countryCode === null) {
                        defaultPriceStructure = priceBundle.priceStructure;
                    } else {
                        countryPriceStructures[priceBundle.countryCode] = priceBundle.priceStructure;
                    }
                });

                // Setting vars to update for kiosk product
                return <KioskProduct>{
                    defaultPriceStructure: defaultPriceStructure,
                    countryPriceStructures: countryPriceStructures
                };
            });

        return updateProductPromise
            .then((updateProduct) => this.kioskProductsRef.child(kioskProduct.key).update(updateProduct));
    }

    updateKioskProductUpsell(kioskProduct: KioskProduct): Promise<any> {
        const updateProduct: KioskProduct = <KioskProduct>{
            defaultUpSaleProduct: kioskProduct.defaultUpSaleProduct,
            countryUpSaleProducts: kioskProduct.countryUpSaleProducts
        };

        return this.kioskProductsRef.child(kioskProduct.key).update(updateProduct);
    }

    updateTexts(kioskProduct: KioskProduct): Promise<any> {
        // Delete texts that are blank
        Object.keys(kioskProduct.lang).forEach((lang) => {
            Object.keys(kioskProduct.lang[lang]).forEach((textKey) => {
                if (typeof kioskProduct.lang[lang][textKey] !== 'string' ||
                    kioskProduct.lang[lang][textKey] === '' || kioskProduct.lang[lang][textKey] === 'undefined') {
                    kioskProduct.lang[lang][textKey] = null;
                }
            });
        });
        const updateTexts: KioskProduct = <KioskProduct>{
            name: kioskProduct.name,
            header: kioskProduct.header || null,
            teaserTitle: kioskProduct.teaserTitle,
            teaserDescription: kioskProduct.teaserDescription,
            teaserDisclaimer: kioskProduct.teaserDisclaimer,
            teaserItems: kioskProduct.teaserItems,
            lang: kioskProduct.lang
        };
        return this.kioskProductsRef.child(kioskProduct.key).update(updateTexts);
    }

    getKioskProductVariants(productCategory: string): Observable<KioskProductVariant[]> {
        return this.db.list<KioskProductVariant>(this.kioskProductVariantsRef.child(productCategory).ref).snapshotChanges()
            .pipe(
                take(1),
                map((kioskProductSnaps) => {
                    return kioskProductSnaps.map((kioskProductSnap) => KioskProductService.kioskProductVariantFromSnap(kioskProductSnap));
                })
            );
    }

    private kioskProductFromSnap(kioskProductSnap: AngularFireAction<DatabaseSnapshot<KioskProduct>>): KioskProduct {
        const kioskProduct: KioskProduct = kioskProductSnap.payload.val();
        kioskProduct.key = kioskProductSnap.key;
        kioskProduct.lang = kioskProduct.lang || {};
        kioskProduct.teaserItems = kioskProduct.teaserItems || [];

        // Default price per variant
        kioskProduct.defaultPriceStructure = kioskProduct.defaultPriceStructure || {variants: {}, currency: null, currencyCode: null};
        kioskProduct.defaultPriceStructure.variants = kioskProduct.defaultPriceStructure.variants || {};
        Object.keys(kioskProduct.defaultPriceStructure.variants).forEach((variantKey) => {
            kioskProduct.defaultPriceStructure.variants[variantKey] =
                this.getPriceStructureFromSnap(kioskProduct.defaultPriceStructure.variants[variantKey]);
        });
        // Default up sale
        kioskProduct.defaultUpSaleProduct = kioskProduct.defaultUpSaleProduct || {referenceKey: null, productCategory: null};
        // Country price per variant and up sale per country
        kioskProduct.countryPriceStructures = kioskProduct.countryPriceStructures || {};
        kioskProduct.countryUpSaleProducts = kioskProduct.countryUpSaleProducts || {};
        Object.keys(kioskProduct.countryPriceStructures).forEach((countryCode) => {
            kioskProduct.countryUpSaleProducts[countryCode] = kioskProduct.countryUpSaleProducts[countryCode] || {
                referenceKey: null,
                productCategory: null
            };
            kioskProduct.countryPriceStructures[countryCode].variants = kioskProduct.countryPriceStructures[countryCode].variants || {};
            Object.keys(kioskProduct.countryPriceStructures[countryCode].variants).forEach((variantKey) => {
                kioskProduct.countryPriceStructures[countryCode].variants[variantKey] =
                    this.getPriceStructureFromSnap(kioskProduct.countryPriceStructures[countryCode].variants[variantKey]);
            });
        });

        return kioskProduct;
    }

    private getPriceStructureFromSnap(priceEntry: PriceEntry): PriceEntry {
        if (typeof priceEntry === 'undefined') {
            priceEntry = {};
        }
        priceEntry.amount = (typeof priceEntry.amount === 'number' ? priceEntry.amount / 100 : null);
        priceEntry.saleAmount = (typeof priceEntry.saleAmount === 'number' ? priceEntry.saleAmount / 100 : null);
        priceEntry.organisations = priceEntry.organisations || {};
        Object.keys(priceEntry.organisations).forEach((organisationKey) => {
            priceEntry.organisations[organisationKey] /= 100;
        });
        return priceEntry;
    }

    private generatePriceBundle(fromPriceStructure: PriceStructure,
                                countryCode: string): Promise<{ priceStructure: PriceStructure, countryCode: string }> {
        // Price structure for variants - init
        const variants: { [variantKey: string]: PriceEntry } = {};

        // Price structure (amount, sale and organisation prices per variant) - setting legal values.
        Object.keys(fromPriceStructure.variants).forEach((variantKey) => {
            const a: number = fromPriceStructure.variants[variantKey].amount;
            const amount: number = (typeof a === 'number' ? Math.round(a * 100) : null);
            if (amount !== null && amount < 0) {
                return KioskProductService.rejectWithError('Invalid default price for a variant' + (countryCode ? ` in "${countryCode}"` : '') + '. Negative numbers are not allowed. Leave blank to exclude.', [countryCode, variantKey, amount]);
            }
            let saleAmount: number = null;
            if (amount !== null) {
                const s: number = fromPriceStructure.variants[variantKey].saleAmount;
                saleAmount = (typeof s === 'number' ? Math.round(s * 100) : null);
                if (saleAmount !== null && saleAmount < 0) {
                    return KioskProductService.rejectWithError('Invalid sale price for a variant' + (countryCode ? ` in "${countryCode}"` : '') + '. Negative numbers are not allowed. Leave blank to exclude.', [countryCode, variantKey, saleAmount]);
                }
                if (saleAmount !== null && saleAmount >= amount) {
                    return KioskProductService.rejectWithError('Invalid sale price for a variant' + (countryCode ? ` in "${countryCode}"` : '') + '. Must be lower than the' + (countryCode ? ' country' : '') + ' default price of that variant. Leave blank to exclude.', [countryCode, variantKey, amount, saleAmount]);
                }
            }

            // Organisations:
            const organisations: { [organisationKey: string]: number } = {};
            Object.keys(fromPriceStructure.variants[variantKey].organisations).forEach((organisationKey) => {
                const o: number = fromPriceStructure.variants[variantKey].organisations[organisationKey];
                const organisationAmount: number = (typeof o === 'number' ? Math.round(o * 100) : null);
                if (organisationAmount !== null && organisationAmount < 0) {
                    return KioskProductService.rejectWithError('Invalid price for an organisation in a variant' + (countryCode ? ` for "${countryCode}"` : '') + '. Negative numbers are not allowed. Leave blank to exclude.', [countryCode, variantKey, organisationKey, organisationAmount]);
                }
                const otherPrices = (saleAmount !== null ? saleAmount : (amount !== null ? amount : (organisationAmount + 1)));
                if (organisationAmount !== null && organisationAmount >= otherPrices) {
                    return KioskProductService.rejectWithError('Invalid price for an organisation in a variant' + (countryCode ? ` for "${countryCode}"` : '') + '. Must be lower than both' + (countryCode ? ' country' : '') + ' the sale price and the' + (countryCode ? ' country' : '') + ' default price of that variant. Leave blank to exclude.', [countryCode, variantKey, organisationKey, organisationAmount, amount, saleAmount]);
                }
                organisations[organisationKey] = organisationAmount;
            });

            // Setting variant price structure
            variants[variantKey] = {
                amount: amount,
                saleAmount: saleAmount,
                organisations: organisations
            };
        });

        const result = {
            priceStructure: {
                currency: fromPriceStructure.currency,
                currencyCode: fromPriceStructure.currencyCode,
                variants: variants
            },
            countryCode: countryCode
        };
        return Promise.resolve(result);
    }

}
