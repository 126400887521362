import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Services
import { TrailService } from '../firebase-services/trail.service';
import { TrailAreaService } from '../firebase-services/trail-area.service';

// Interfaces
import { Trail } from '../interfaces/trail';
import { TrailArea } from '../interfaces/trailArea';

@Injectable({
    providedIn: 'root'
})
export class ModelAssistantService {

    constructor(
        private trailService: TrailService,
        private trailAreaService: TrailAreaService
    ) {
    }

    getTrail(trailKey): Observable<Trail> {
        return this.trailService.getTrail(trailKey)
            .pipe(
                switchMap((trail) => {
                    if (trail !== null && !trail.trailAreaKey) {
                        return this.handleTrailWithoutTrailArea(trailKey);
                    }
                    return of(trail);
                })
            );

    }

    private handleTrailWithoutTrailArea(trailKey: string): Observable<Trail> {
        return this.trailAreaService.getAllTrailAreas()
            .pipe(
                switchMap((trailAreas) => {
                    const trailAreasWithTrail: TrailArea[] = [];
                    trailAreas.forEach((trailArea) => {
                        if (Object.keys(trailArea.trailKeys).includes(trailKey)) {
                            trailAreasWithTrail.push(trailArea);
                        }
                    });
                    if (trailAreasWithTrail.length === 1) {
                        const trailAreaKeySet = from(this.trailService.setTrailAreaKey(trailKey, trailAreasWithTrail[0].key));
                        return trailAreaKeySet
                            .pipe(
                                switchMap(() => {
                                    return this.trailService.getTrail(trailKey);
                                })
                            );
                    }
                    console.log('None or multiple trail areas.', trailAreasWithTrail, trailKey);
                    return this.trailService.getTrail(trailKey);
                })
            );
    }
}
