import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

// Services
import { AuthService } from '../../core/auth.service';
import { CurrencyService } from '../../firebase-services/currency.service';
import { KioskProductService } from '../../firebase-services/kiosk-product.service';
import { CountryService } from '../../firebase-services/country.service';
import { OrganisationService } from '../../firebase-services/organisation.service';
import { ActivationCodeService } from '../../firebase-services/activation-code.service';

// Interfaces
import {
    Currency,
    KioskProduct,
    KioskProductVariant,
    KioskProductVariantReference,
    KioskReferenceType,
    PriceEntry,
    ProductCategory,
    ProductVariantType
} from '../../interfaces/kiosk';
import { TextModel, TextModelItemType, TextObject } from '../../interfaces/text-model';
import { Country } from '../../interfaces/countries';
import { Organisation } from '../../interfaces/organisation';
import {Roles} from "../../interfaces/role";

declare var $: any;

const noUpsellProduct: KioskProduct = {
    name: ' -- No up sale product -- ',
    key: null,
    referenceType: null,
    referenceKey: null,
    productCategory: null,
    recurring: null,
    defaultPriceStructure: null
};

@Component({
    selector: 'app-kiosk-product-handler',
    templateUrl: './kiosk-product-handler.component.html',
    styleUrls: ['./kiosk-product-handler.component.css']
})
export class KioskProductHandlerComponent implements OnInit, AfterViewChecked {
    @Input() kioskProductKey: string = null;
    _productCategory: ProductCategory = null;
    @Input() set productCategory(productCategory: ProductCategory) {
        this._productCategory = productCategory;
        this.productVariants = null;
        this.getProductVariants();
    }

    @Input() referenceType: KioskReferenceType;
    _referenceKey: string = null;
    @Input() set referenceKey(referenceKey: string) {
        this.kioskProductExists = null;
        this._referenceKey = referenceKey;
        this.kioskProduct = null;
        this.getKioskProduct();
    }

    @Input() recurring = false;
    @Input() handlerTitle: string = null;
    @Input() defaultName: string;
    @Input() colorTheme = 'maroon';
    @Input() enableActivationCodes: boolean = null;
    @Output() kioskProductCreated = new EventEmitter<string>();
    @Input() enableUpsell: boolean = null;
    _bundleWith: KioskProductVariantReference[] = null;
    selectedBundles: boolean[] = [];

    @Input() set bundleWith(bundle: KioskProductVariantReference[]) {
        this.selectedBundles.fill(false, 0, bundle.length);
        this._bundleWith = bundle;
    }

    kioskProductExists: boolean = null;
    kioskProduct: KioskProduct = null;
    productVariants: KioskProductVariant[] = null;

    textModel: TextModel = {items: []};
    textsChangedInfo = 0;

    currencies: Currency[] = [];
    defaultCurrency: Currency;

    // Prices
    disableSavePrices = true;
    priceTrigger = 0;

    // Up sale
    disableSaveUpsell = true;
    upsellTrigger = 0;
    upsellProductCandidates: KioskProduct[] = null;

    // Countries
    countries: Country[] = null;
    gotoCountryTab: string = null;
    gotoCountryTabUpsell: string = null;
    usedCountries: string[] = [];
    unusedCountries: string[] = [];
    countryNames: { [countryCode: string]: string } = {};
    countryInitialised = false;

    // Organisations
    organisations: Organisation[] = null;

    // Activation Codes
    activationCodeCount = 1;
    activationCodeLength = 8;
    activationCodeVariant: KioskProductVariant = null;
    newCodes: string[] = null;
    newCodesText: string = null;

    FOLLOW_MEMBERSHIP = ProductVariantType.FOLLOW_MEMBERSHIP;

    roles = Roles;

    constructor(
        public authService: AuthService,
        private kioskProductService: KioskProductService,
        private currencyService: CurrencyService,
        private countryService: CountryService,
        private organisationService: OrganisationService,
        private activationCodeService: ActivationCodeService
    ) {
        this.organisationService.getAllOrganisations()
            .pipe(take(1))
            .subscribe((organisations) => this.organisations = organisations);
    }

    ngOnInit() {
        if (this.enableActivationCodes === null) {
            this.enableActivationCodes = true;
        }
        this.getKioskProduct();
        this.countryService.getCountries()
            .pipe(take(1))
            .subscribe((countries) => {
                this.countries = countries;
                this.initCountriesInProduct();
            });
        this.getCurrencies();
        this.initTextModel();
    }

    ngAfterViewChecked() {
        if (this.gotoCountryTab) {
            $(this.gotoCountryTab).tab('show');
            this.gotoCountryTab = null;
        }
        if (this.gotoCountryTabUpsell) {
            $(this.gotoCountryTabUpsell).tab('show');
            this.gotoCountryTabUpsell = null;
        }
    }

    private getCurrencies() {
        this.currencyService.getCurrencies()
            .pipe(take(1))
            .subscribe((currencies) => {
                this.currencies = currencies;
                this.setDefaultCurrency();
            });
    }

    private initTextModel(): void {
        this.textModel = {items: [], subItems: {}};
        this.textModel.items.push({
            name: 'Name',
            varName: 'name',
            help: 'The name of this kiosk product.',
            placeholder: 'Product name',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'header',
            help: 'A short description of the product.',
            placeholder: 'This is an awesome product',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Teaser Title',
            varName: 'teaserTitle',
            help: 'Short teaser title for the product.',
            placeholder: 'Got to have this',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Teaser Description',
            varName: 'teaserDescription',
            help: 'A short teaser description for the product.',
            placeholder: 'Buy this product because it is awesome',
            type: TextModelItemType.INPUT
        });
        this.textModel.subItems['teaserItems'] = {
            name: 'Teaser items',
            items: [
                {
                    name: 'Title',
                    varName: 'title',
                    help: 'Item title',
                    placeholder: 'Reason title',
                    type: TextModelItemType.INPUT
                },
                {
                    name: 'Description',
                    varName: 'description',
                    help: 'Item description',
                    placeholder: 'This is a good reason',
                    type: TextModelItemType.INPUT
                }
            ]
        };
        this.textModel.items.push({
            name: 'Teaser Disclaimer',
            varName: 'teaserDisclaimer',
            help: 'Disclaimer for the promises made above.',
            placeholder: 'Awesome is of course a relative term and sometimes reality can be a bit boring.',
            type: TextModelItemType.INPUT
        });
    }

    private getKioskProduct(): void {
        if (!this.authService.isUser(this.roles.ADMIN) || typeof this.kioskProductKey !== 'string' || this.kioskProductKey === '') {
            this.kioskProductExists = false;
            return;
        }
        this.kioskProductService.getKioskProduct(this.kioskProductKey)
            .pipe(take(1))
            .subscribe((kioskProduct) => {
                if (kioskProduct) {
                    if (kioskProduct.productCategory !== this._productCategory) {
                        console.error('Kiosk product category mismatch', kioskProduct.productCategory, this._productCategory);
                        this.kioskProductExists = false;
                        return;
                    }
                    this.kioskProduct = kioskProduct;
                    this.productVariants.forEach((variant) => {
                        if (!this.kioskProduct.defaultPriceStructure.variants[variant.key]) {
                            this.kioskProduct.defaultPriceStructure.variants[variant.key] = {organisations: {}};
                        }
                        Object.keys(this.kioskProduct.countryPriceStructures).forEach((countryCode) => {
                            if (!this.kioskProduct.countryPriceStructures[countryCode].variants[variant.key]) {
                                this.kioskProduct.countryPriceStructures[countryCode].variants[variant.key] = {organisations: {}};
                            }
                        });
                    });
                    this.kioskProductExists = true;
                    this.setDefaultCurrency();
                    this.initCountriesInProduct();
                } else {
                    this.kioskProductExists = false;
                }
            });
    }

    private getProductVariants(): void {
        this.kioskProductService.getKioskProductVariants(this._productCategory)
            .subscribe((productVariants) => this.productVariants = productVariants);
    }

    private setDefaultCurrency(): void {
        if (this.kioskProductExists === true && this.currencies) {
            this.currencies.forEach((currency) => {
                if (currency.currencyCode === this.kioskProduct.defaultPriceStructure.currencyCode) {
                    this.defaultCurrency = currency;
                }
            });
        } else if (this.currencies) {
            this.defaultCurrency = this.currencies[0];
        }
    }

    private initCountriesInProduct() {
        // Called from two places. Both kiosk product and countries must be loaded.
        if (this.kioskProductExists === true && this.countries !== null) {
            const usedCountries = Object.keys(this.kioskProduct.countryPriceStructures || []);
            const unusedCountries = this.countries.map((country) => {
                this.countryNames[country.countryCode] = country.name;
                return country.countryCode;
            });
            usedCountries.forEach((usedCountry) => {
                const indexOfCountry = unusedCountries.indexOf(usedCountry);
                if (indexOfCountry !== -1) {
                    unusedCountries.splice(indexOfCountry, 1);
                }
            });

            this.usedCountries = usedCountries;
            this.unusedCountries = unusedCountries;
            this.countryInitialised = true;
            this.initUpsell();
        }
    }

    createKioskProduct(): void {
        switch (this._productCategory) {
            case ProductCategory.ADVENTURE_ACCESS:
                const adventureAccessProduct: KioskProduct = <KioskProduct>{
                    key: null,
                    productCategory: this._productCategory,
                    referenceType: this.referenceType,
                    referenceKey: this._referenceKey,
                    recurring: this.recurring,
                    defaultPriceStructure: {
                        currency: this.defaultCurrency.currency,
                        currencyCode: this.defaultCurrency.currencyCode,
                        variants: {}
                    },
                    name: this.defaultName,
                    header: null,
                    countryPriceStructures: {},
                    defaultUpSaleProduct: null,
                    countryUpSaleProducts: null,
                    lang: {}
                };
                this.kioskProduct = adventureAccessProduct;
                break;

            case ProductCategory.HQ_MAPS:
                const hqMapsProduct: KioskProduct = <KioskProduct>{
                    key: null,
                    productCategory: this._productCategory,
                    referenceType: this.referenceType,
                    referenceKey: this._referenceKey,
                    recurring: this.recurring,
                    defaultPriceStructure: {
                        currency: this.defaultCurrency.currency,
                        currencyCode: this.defaultCurrency.currencyCode,
                        variants: {}
                    },
                    name: this.defaultName,
                    header: null,
                    countryPriceStructures: {},
                    defaultUpSaleProduct: null,
                    countryUpSaleProducts: null,
                    lang: {},
                };
                this.kioskProduct = hqMapsProduct;
                break;

            case ProductCategory.ORGANISATION_MEMBERSHIP:
                const membershipProduct: KioskProduct = {
                    key: null,
                    productCategory: this._productCategory,
                    referenceType: this.referenceType,
                    referenceKey: this._referenceKey,
                    recurring: this.recurring,
                    defaultPriceStructure: {
                        currency: this.defaultCurrency.currency,
                        currencyCode: this.defaultCurrency.currencyCode,
                        variants: {}
                    },
                    name: this.defaultName,
                    header: null,
                    countryPriceStructures: {},
                    defaultUpSaleProduct: null,
                    countryUpSaleProducts: null,
                    lang: {}
                };
                this.kioskProduct = membershipProduct;
                break;

            default:
                console.error('Undefined reference type', this.referenceType);
                return;
        }
        this.productVariants.forEach((variant) => {
            if (variant.variantType !== this.FOLLOW_MEMBERSHIP) {
                this.kioskProduct.defaultPriceStructure.variants[variant.key] = {amount: 0, organisations: {}};
            }
        });
        const productThenable = this.kioskProductService.createKioskProduct(this.kioskProduct);
        this.kioskProductKey = productThenable.key;
        this.kioskProductCreated.emit(this.kioskProductKey);
        productThenable
            .then(() => this.getKioskProduct());
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.kioskProductService.updateTexts(<KioskProduct>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    addCountry(countryCode: string): void {
        const indexOfCountry = this.unusedCountries.indexOf(countryCode);
        if (indexOfCountry !== -1) {
            this.kioskProduct.countryPriceStructures[countryCode] = {
                currency: this.defaultCurrency.currency,
                currencyCode: this.defaultCurrency.currencyCode,
                variants: {}
            };
            const variants: { [variantKey: string]: PriceEntry } = {};
            this.productVariants.forEach((variant) => {
                variants[variant.key] = {organisations: {}};
            });
            this.kioskProduct.countryPriceStructures[countryCode].variants = variants;
            this.usedCountries.push(countryCode);
            this.unusedCountries.splice(indexOfCountry, 1);
        }
        this.gotoCountryTab = '#tab_' + countryCode + '_link';
        this.gotoCountryTabUpsell = '#tab_upsell' + countryCode + '_link';
    }

    updateCurrency(countryCode: string) {
        for (let i = 0; i < this.currencies.length; i++) {
            if (countryCode === null && this.kioskProduct.defaultPriceStructure.currency === this.currencies[i].currency) {
                this.kioskProduct.defaultPriceStructure.currencyCode = this.currencies[i].currencyCode;
                break;
            } else if (countryCode !== null &&
                this.kioskProduct.countryPriceStructures[countryCode].currency === this.currencies[i].currency) {
                this.kioskProduct.countryPriceStructures[countryCode].currencyCode = this.currencies[i].currencyCode;
                break;
            }
        }
        this.priceChanged();
    }

    priceChanged(): void {
        this.disableSavePrices = false;
    }

    savePrices(): void {
        this.disableSavePrices = true;
        this.kioskProductService.updateKioskProduct(this.kioskProduct)
            .then(() => this.priceTrigger++)
            .catch((error) => window.alert(error));
    }

    private initUpsell(): void {
        this.disableSaveUpsell = true;

        // Set up product candidates
        const upsellProductCandidates: KioskProduct[] = [];
        upsellProductCandidates.push(noUpsellProduct);

        const membershipProductsPromises: Promise<number>[] = [];
        this.organisations.forEach((organisation) => {

            if (organisation.membershipProductKey) {
                const membershipProduct$ = this.kioskProductService.getKioskProduct(organisation.membershipProductKey);
                const membershipProductPromise = lastValueFrom(membershipProduct$)
                    .then((kioskProduct) => upsellProductCandidates.push(kioskProduct));
                membershipProductsPromises.push(membershipProductPromise);
            }
        });

        Promise.all(membershipProductsPromises)
            .then(() => this.upsellProductCandidates = upsellProductCandidates);
    }

    private upsellChanged(): void {
        this.disableSaveUpsell = false;
    }

    updateUpsell(countryCode: string): void {
        if (countryCode === null && this.kioskProduct.defaultUpSaleProduct.referenceKey === 'null') {
            this.kioskProduct.defaultUpSaleProduct.referenceKey = null;
            this.kioskProduct.defaultUpSaleProduct.productCategory = null;
            return this.upsellChanged();
        }
        if (countryCode !== null && this.kioskProduct.countryUpSaleProducts[countryCode].referenceKey === 'null') {
            this.kioskProduct.countryUpSaleProducts[countryCode].referenceKey = null;
            this.kioskProduct.countryUpSaleProducts[countryCode].productCategory = null;
            return this.upsellChanged();
        }
        for (let i = 0; i < this.upsellProductCandidates.length; i++) {
            if (countryCode === null &&
                this.kioskProduct.defaultUpSaleProduct.referenceKey === this.upsellProductCandidates[i].referenceKey) {
                this.kioskProduct.defaultUpSaleProduct.productCategory = this.upsellProductCandidates[i].productCategory;
                break;
            } else if (countryCode !== null &&
                this.kioskProduct.countryUpSaleProducts[countryCode].referenceKey === this.upsellProductCandidates[i].referenceKey) {
                this.kioskProduct.countryUpSaleProducts[countryCode].productCategory = this.upsellProductCandidates[i].productCategory;
                break;
            }
        }
        this.upsellChanged();
    }

    saveUpsell(): void {
        this.disableSaveUpsell = true;
        this.kioskProductService.updateKioskProductUpsell(this.kioskProduct)
            .then(() => this.upsellTrigger++)
            .catch((error) => window.alert(error));
    }

    /**
     * Creates activation codes
     */
    createActivationCodes(): void {
        this.newCodes = null;
        this.newCodesText = null;
        if (this.activationCodeCount < 1 || this.activationCodeCount > 100 ||
            this.activationCodeLength < 6 || this.activationCodeLength > 10) {
            alert('Invalid settings for creating activation codes');
            return;
        }

        const products: KioskProductVariantReference[] = [];
        products.push({
            productKey: this.kioskProductKey,
            variantKey: this.activationCodeVariant.key,
            quantity: 1
        });

        for (let i = 0; i < this.selectedBundles.length; i++) {
            if (this.selectedBundles[i] === true) {
                products.push(this._bundleWith[i]);
            }
        }

        this.activationCodeService.generateActivationCodes(this.activationCodeCount, this.activationCodeLength, products)
            .pipe(take(1))
            .subscribe((newCodes) => {
                this.newCodes = newCodes;
                this.newCodesText = newCodes.join('\n');
            });
    }

    closeModal() {
        $('#activationCodesModal').modal('hide');
    }

    copyActivationCodes(): void {
        const textArea: HTMLTextAreaElement = <HTMLTextAreaElement>document.getElementById('newCodesTextArea');
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
    }
}
