<div class="content-wrapper">
    <app-content-header-section *ngIf="country" contentPageId="countryTrailAreasAdmin"
                                [pageParams]="{countryName: country.name}"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card card-outline card-primary" *ngIf="!trailAreaGrouping">
                        <div class="overlay">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                    <div class="card card-outline card-primary region-box" *ngFor="let region of trailAreaGrouping; index as i">
                        <div class="card-header">
                            <h3 class="card-title"><i class="far fa-map"></i> Trail Areas
                                <span *ngIf="region.regionName">in <strong>{{ region.regionName }}</strong></span>
                                <span *ngIf="!region.regionName">without region</span>
                            </h3>
                            <button class="btn btn-secondary btn-xs float-right" [id]="'location-texts-button-' + i"
                                    (click)="updateLocationTexts(i)">Update region
                            </button>
                        </div>
                        <div class="card-body no-padding">
                            <table *ngIf="trailAreaGrouping" class="table table-hover">
                                <tbody>
                                <ng-container *ngFor="let destination of region.destinations">
                                    <tr class="no-breaking-headers">
                                        <th>
                                            <span *ngIf="region.regionName"
                                                  [title]="'Region: ' + region.regionName">{{ region.regionName }}, </span>
                                            <span *ngIf="!region.regionName"><i>No region</i>, </span>
                                            <span *ngIf="destination.destination" [title]="'Destination: ' + destination.destination.name"><i
                                                    class="far fa-heart"></i> {{ destination.destination.name }}</span>
                                            <span *ngIf="!destination.destination"><i>No destination</i></span>
                                        </th>
                                        <th>H.Q. Maps</th>
                                        <th>Trails</th>
                                        <th>POIs</th>
                                        <th>Adventures</th>
                                        <th colspan="3">Managers</th>
                                        <th><i class="far fa-trash-alt"></i></th>
                                    </tr>
                                    <tr *ngFor="let trailArea of destination.trailAreas" id="tr_{{trailArea.key}}">
                                        <td>
                                            <i class="fas fa-map"></i> {{ trailArea.name }} &nbsp;
                                            <span *ngIf="onlineIsManager[trailArea.key]">
                                                <a href="/trail-area/{{ trailArea.key }}/settings" title="Go to Trail Area Settings"><i
                                                        class="fas fa-cogs"></i> &nbsp;</a>
                                            </span>
                                            <span class="badge badge-default">{{ trailArea.key }}</span>
                                        </td>
                                        <td class="td-min-width">
                                            <div *ngIf="hasHqMap[trailArea.key]">
                                                <a data-toggle="modal" data-target="#hqMapModal" href="#" (click)="onSetupHqMapClick(trailArea)"
                                                   title="Edit High Quality Map">
                                                    <i class="fas fa-tree text-green"></i>
                                                </a>
                                                <!--                                                <span *ngIf="!trailArea.destinationKey">-->
                                                <!--                                                    <span *ngIf="trailArea.hqMapProductKey">-->
                                                <!--                                                        <a data-toggle="modal" data-target="#kioskModal" href="#"-->
                                                <!--                                                           (click)="onSetupTrailAreaMapProductClick(trailArea)" title="Edit High Quality Map Product">-->
                                                <!--                                                            &nbsp; <i class="fas fa-cash-register text-green"></i>-->
                                                <!--                                                        </a>-->
                                                <!--                                                    </span>-->
                                                <!--                                                    <span *ngIf="!trailArea.hqMapProductKey">-->
                                                <!--                                                        <a data-toggle="modal" data-target="#kioskModal" href="#"-->
                                                <!--                                                           (click)="onSetupTrailAreaMapProductClick(trailArea)"-->
                                                <!--                                                           title="Setup High Quality Map Product">-->
                                                <!--                                                            &nbsp; +<i class="fas fa-cash-register"></i>-->
                                                <!--                                                        </a>-->
                                                <!--                                                    </span>-->
                                                <!--                                                </span>-->
                                            </div>
                                            <div *ngIf="!hasHqMap[trailArea.key] && trailArea.boundsNorth > -90">
                                                <a data-toggle="modal" data-target="#hqMapModal" href="#" (click)="onSetupHqMapClick(trailArea)"
                                                   title="Setup High Quality Map">
                                                    +<i class="fas fa-tree"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="td-min-width">
                                            <span *ngIf="numberOfTrails[trailArea.key]">
                                                <span *ngIf="onlineIsManager[trailArea.key]">
                                                    <a href="/trail-area/{{trailArea.key}}/trails" title="Go to Trails in Trail Area"><i
                                                            class="fas fa-route"></i></a>
                                                </span>
                                                <span *ngIf="!onlineIsManager[trailArea.key]">
                                                    <i class="fas fa-route"></i>
                                                </span>
                                                {{ numberOfTrails[trailArea.key] }}
                                            </span>
                                        </td>
                                        <td class="td-min-width">
                                            <span title="Currently not available">
                                                <span *ngIf="onlineIsManager[trailArea.key]">
                                                    <a href="/trail-area/{{trailArea.key}}/pois" title="Go to POIs in Trail Area"><i
                                                            class="fas fa-map-marker-alt"></i></a>
                                                </span>
                                                <span *ngIf="!onlineIsManager[trailArea.key]" title="Currently not available">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </span>
                                                &nbsp;?
                                            </span>
                                            <span *ngIf="numberOfPois[trailArea.key]">
                                                <span *ngIf="onlineIsManager[trailArea.key]">
                                                    <a href="/trail-area/{{trailArea.key}}/pois" title="Go to POIs in Trail Area"><i
                                                            class="fas fa-map-marker-alt"></i></a>
                                                </span>
                                                <span *ngIf="!onlineIsManager[trailArea.key]">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </span>
                                                {{ numberOfPois[trailArea.key] }}
                                            </span>
                                        </td>
                                        <td class="td-min-width">
                                            <span *ngIf="numberOfAdventures[trailArea.key]">
                                                <span *ngIf="onlineIsManager[trailArea.key] && trailArea.adventuresEnabled">
                                                    <a href="/trail-area/{{trailArea.key}}/adventures" title="Go to Adventures in Trail Area"><i
                                                            class="far fa-compass"></i></a>
                                                </span>
                                                <span *ngIf="!(onlineIsManager[trailArea.key] && trailArea.adventuresEnabled)">
                                                    <i class="fas fa-route"></i>
                                                </span>
                                                {{ numberOfAdventures[trailArea.key] }}
                                            </span>
                                        </td>
                                        <td class="td-min-width">
                                            <div role="group" class="btn-group" *ngIf="trailAreaManagers[trailArea.key]">
                                                <button type="button" class="btn btn-xs btn-default dropdown-toggle" data-toggle="dropdown"
                                                        aria-expanded="false">
                                                    <i class="fas fa-users-cog"></i> {{ numberOfAdmins[trailArea.key] }}
                                                    <span class="caret"></span>
                                                </button>
                                                <ul class="todo-list dropdown-menu dropdown-menu-right">
                                                    <li *ngFor="let trailAreaManager of trailAreaManagers[trailArea.key]"
                                                        [id]="trailArea.key + trailAreaManager.userID">
                                                        <div class="tools">
                                                            <button class="btn btn-xs btn-danger"
                                                                    (click)="removeManager(trailAreaManager.userID, trailArea.key)">Remove
                                                            </button>
                                                        </div>
                                                        <img class="img-circle img-sm" [src]="trailAreaManager.userPicture" alt="">
                                                        <div class="img-push">
                                                            <div class="text">{{ trailAreaManager.name }}</div>
                                                            <small class="badge badge-danger"
                                                                   title="Country info">{{ trailAreaManager.deviceCountry }}</small>
                                                            <small class="badge badge-warning"
                                                                   title="Last login time">{{ trailAreaManager.lastLoginTime | date:'dd/MM/yyy' }}</small>
                                                            <small class="badge badge-default pr-5 mr-5"
                                                                   title="Unique profile key">{{ trailAreaManager.userID }}</small>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="overlay" *ngIf="!numberOfAdmins[trailArea.key] && !(numberOfAdmins[trailArea.key] === 0)">
                                                <i class="fas fa-sync-alt fa-spin"></i>
                                            </div>
                                        </td>
                                        <td class="td-min-width">
                                            <span>
                                                <app-find-user *ngIf="numberOfAdmins[trailArea.key] >= 0" [modalId]="trailArea.key"
                                                               [modalTitle]="'Find Manager for ' + trailArea.name" btnClassType="default btn-xs"
                                                               (userSelected)="addAdmin($event, trailArea.key)">
                                                    <i class="fas fa-user-plus" [title]="'Add manager to ' + trailArea.name"></i>
                                                </app-find-user>
                                            </span>
                                        </td>
                                        <td class="td-min-width">
                                            <button *ngIf="numberOfAdmins[trailArea.key] >= 0 && !onlineIsManager[trailArea.key]"
                                                    (click)="addAdmin(authService.user.userID, trailArea.key)"
                                                    class="btn btn-xs btn-default addOnlineUserAsManagerButton" [style]="setOnlineBackgroundImage()"
                                                    title="Add yourself as manager">+ &nbsp;
                                            </button>
                                        </td>
                                        <td class="td-min-width">
                                            <span *ngIf="displayTrash[trailArea.key]">
                                                <a onclick="return false"><i class="far fa-trash-alt" (click)="deleteTrailArea(trailArea.key)"
                                                                             title="Delete Trail Area"></i></a>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>

<div class="modal fade" id="hqMapModal" tabindex="-1" role="dialog" aria-labelledby="hqMapModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content" *ngIf="hqMapTrailArea">
            <div class="modal-header">
                <h5 class="modal-title" id="hqMapModalLabel"><i class="fas fa-tree"></i> High Quality Maps for {{ hqMapTrailArea.name }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" *ngIf="!hqMapSaving">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="minimumZ" class="control-label">Minimum Z:</label>
                    <input type="number" min="11" step="1" max="20" [(ngModel)]="hqMapTrailArea.hqMapMinimumZ" (change)="hqMapChanged()"
                           (keyup)="hqMapChanged()" class="form-control text-right" id="minimumZ" aria-describedby="minimumZHelp">
                    <small class="form-text text-muted" id="minimumZHelp">Minimum zoom level to show high quality map from.</small>
                </div>
                <div class="form-group">
                    <label for="maximumZ" class="control-label">Maximum Z:</label>
                    <input type="number" min="16" step="1" max="21" [(ngModel)]="hqMapTrailArea.hqMapMaximumZ" (change)="hqMapChanged()"
                           (keyup)="hqMapChanged()" class="form-control text-right" id="maximumZ" aria-describedby="maximumZHelp">
                    <small class="form-text text-muted" id="maximumZHelp">>Maximum zoom level to show high quality map to.</small>
                </div>
                <div class="form-group">
                    <label for="urlTemplate" class="control-label">URL Template:</label>
                    <input type="text" [(ngModel)]="hqMapTrailArea.hqMapUrlTemplate" (change)="hqMapChanged()" (keyup)="hqMapChanged()"
                           class="form-control" id="urlTemplate" aria-describedby="urlTemplateHelp">
                    <small class="form-text text-muted" id="urlTemplateHelp">URL template for fetching map tiles - must be of the form:
                        "https://some.domain.tld/&#123;z&#125;/&#123;x&#125;/&#123;y&#125;.png".</small>
                </div>
                <div class="form-group  form-check">
                    <input type="checkbox" [(ngModel)]="hqMapTrailArea.hqMapIsGeneral" id="makeGeneral" aria-describedby="makeGeneralHelp"
                           class="form-check-input">
                    <label for="makeGeneral" class="form-check-label">Make generally available</label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" [disabled]="hqMapSaving" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="!hqMapSave || hqMapSaving" (click)="saveHqMap()">Save changes</button>
            </div>
        </div>
        <div class="overlay" *ngIf="hqMapSaving">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
    </div>
</div>

<!--<div class="modal fade" id="kioskModal" tabindex="-1" role="dialog" aria-labelledby="kioskModalLabel" data-backdrop="static" data-keyboard="false">-->
<!--    <div class="modal-dialog modal-max modal-maroon" role="document">-->
<!--        <div class="modal-content" *ngIf="referenceKey">-->
<!--            <div class="modal-header">-->
<!--                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
<!--                <h4 class="modal-title" id="kioskModalLabel"><i class="fas fa-tree"></i> Setup Product <small>for {{ defaultName }}</small></h4>-->
<!--            </div>-->
<!--            <div class="modal-body bg-gray">-->
<!--                <app-kiosk-product-handler [kioskProductKey]="kioskProductKey" [productCategory]="productCategory" [referenceType]="referenceType"-->
<!--                                           [referenceKey]="referenceKey" [recurring]="false" [defaultName]="defaultName"-->
<!--                                           [enableActivationCodes]="true" (kioskProductCreated)="onTrailAreaMapProductCreated($event)"-->
<!--                                           [enableUpsell]="false"></app-kiosk-product-handler>-->
<!--            </div>-->
<!--            <div class="modal-footer">-->
<!--                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
