<div *ngIf="authService.user as user" class="content-wrapper">

    <app-content-header-section contentPageId="userProfile" [pageParams]="{ profileName: user.name }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">

                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <img class="profile-user-img img-fluid img-circle" [src]="user.userPicture"
                                     alt="Your picture - change it in the app">
                            </div>

                            <h3 class="profile-username text-center">{{ user.name }}</h3>

                            <p class="text-muted text-center">{{ email }}</p>
                            <ul class="list-group list-group-unbordered mb-3">
                                <li class="list-group-item">
                                    <b>Member since </b> <span class="float-right">{{ user.createdTime | date: 'longDate' }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Country</b> <span class="float-right">{{ user.deviceCountry }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Phone OS</b> <span class="float-right">{{ user.deviceOS }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a (click)="authService.signOut()" href="#" class="btn btn-warning btn-block"><b>Sign out</b></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
