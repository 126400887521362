import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../core/layout.service';
import { TrailAreaService } from '../../firebase-services/trail-area.service';
import { AdventureService } from '../../firebase-services/adventure.service';

// Interfaces
import { TrailArea } from '../../interfaces/trailArea';
import { Adventure, ADVENTURE_TINT_COLOR } from '../../interfaces/adventure';
import { CreateOnMap } from '../../interfaces/map';

@Component({
    selector: 'app-trail-area-adventures',
    templateUrl: './trail-area-adventures.component.html',
    styleUrls: ['./trail-area-adventures.component.css']
})
export class TrailAreaAdventuresComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    area: TrailArea = null;
    adventures: Adventure[] = null;
    routerRoot: string;

    constructor(
        public layout: LayoutService,
        private route: ActivatedRoute,
        private router: Router,
        private trailAreaService: TrailAreaService,
        private adventureService: AdventureService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Load data for the current trail area
     */
    init() {
        this.area = null;
        this.adventures = null;
        this.trailAreaService.getTrailArea(this.route.snapshot.paramMap.get('areaKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailArea) => {
                this.getAdventures(trailArea);
                this.routerRoot = '/trail-area/' + trailArea.key;
                this.area = trailArea;
            });
    }

    /**
     * Loads the adventures for the trail area
     */
    private getAdventures(trailArea: TrailArea): void {
        this.adventureService.getAdventures(Object.keys(trailArea.adventureKeys))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventures) => this.adventures = adventures);
    }

    createNewAdventure(newAdventureOnMap: CreateOnMap): Promise<boolean> {
        const newAdventure = <Adventure>{
            name: newAdventureOnMap.name,
            latitude: newAdventureOnMap.lat,
            longitude: newAdventureOnMap.lng,
            geohash: newAdventureOnMap.geohash,
            tintColor: ADVENTURE_TINT_COLOR
        };
        const newKey = this.adventureService.createNewAdventure(newAdventure);
        // Fire and forget
        this.trailAreaService.addAdventureToTrailArea(this.area.key, newKey);

        return this.router.navigate([this.routerRoot + '/adventures/' + newKey]);
    }
}
