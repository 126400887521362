import { Component } from '@angular/core';
import { AuthService } from '../core/auth.service';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    website_url: string = environment.www;
    signingIn = false;

    user_email: string;
    user_password: string;
    errorMessage: string = null;

    constructor(
        public auth: AuthService
    ) {
    }

    emailLogin() {
        if (this.signingIn) {
            return;
        }
        this.signingIn = true;
        this.errorMessage = null;
        this.auth.emailLogin(this.user_email, this.user_password)
            .then(() => this.signingIn = false)
            .catch((err) => {
                console.log('tada', err);
                this.errorMessage = err.message;
                this.signingIn = false;
            });
    }
}
