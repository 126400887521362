export const GOOGLEAPIS_GEOCODE_STATUS_OK = 'OK';

interface GoogleAPILatLng {
    lat: number,
    lng: number
}

interface GoogleAPIBounds {
    northeast: GoogleAPILatLng,
    southwest: GoogleAPILatLng
}

export interface GoogleAPIsGeocode {
    results: [{
        address_components: {
            [index: number]: {
                long_name: string,
                short_name: string
            }
        },
        geometry: {
            bounds: GoogleAPIBounds,
            location: GoogleAPILatLng,
            viewport: GoogleAPIBounds
        }
    }],
    status: string
}
