import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../core/auth.service';
import { TrailAreaService } from '../../firebase-services/trail-area.service';
import { CountryService } from '../../firebase-services/country.service';
import { UserService } from '../../firebase-services/user.service';

// Interfaces
import { Country } from '../../interfaces/countries';

@Component({
    selector: 'app-country-new-trail-area',
    templateUrl: './country-new-trail-area.component.html',
    styleUrls: ['./country-new-trail-area.component.css']
})
export class CountryNewTrailAreaComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    trailAreaName: string = '';
    countryCode: string;
    country: Country = null;

    disableCreate: boolean = true;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private trailAreaService: TrailAreaService,
        private countryService: CountryService,
        private userService: UserService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.countryCode = this.route.snapshot.paramMap.get('countryCode');
        this.countryService.getCountry(this.countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => {
                this.country = country;
            });
    }

    valueChanged() {
        if (this.countryCode && this.countryCode.length && this.trailAreaName && this.trailAreaName.length) {
            this.disableCreate = false;
        } else {
            this.disableCreate = true;
        }
    }

    createTrailArea() {
        this.disableCreate = true;
        var newTrailAreaThenable = this.trailAreaService.createTrailArea(this.trailAreaName, this.countryCode);
        newTrailAreaThenable
            .then(() => {
                this.userService.addTrailAreaAdmin(this.authService.user.userID, newTrailAreaThenable.key, this.authService.user.userID)
                    .then((success) => {
                        if (success) {
                            this.router.navigate(['/trail-area/' + newTrailAreaThenable.key + '/settings']);
                        }
                    })
                    .catch((err) => alert(err));
            });
    }
}
