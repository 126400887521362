<div class="content-wrapper">
    <app-content-header-section *ngIf="country" contentPageId="countryContactResponsible"
                                [pageParams]="{ countryCode: country.countryCode, countryName: country.name }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid" *ngIf="countryTrailAreas && contacts && trailAreaManagers && emails">

            <div class="card card-outline card-primary">
                <div class="card-header">
                    <h3 class="card-title">Responsible per Trail Area</h3>
                </div>
                <div class="card-body px-0">
                    <table id="contactDataTable" class="table row-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Trail Area</th>
                            <th>Managers</th>
                            <th>OnTrail Contacts</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let trailArea of countryTrailAreas">
                            <td>
                                {{ trailArea.name }}
                            </td>
                            <td>
                                <div *ngFor="let trailAreaManager of trailAreaManagers[trailArea.key]">
                                    <span>{{ trailAreaManager.name }} &lt;{{ emails[trailAreaManager.userID] }}&gt;</span>
                                </div>
                            </td>
                            <td>
                                <div *ngFor="let contact of contacts[trailArea.key]">
                                    <span>{{ contact }}</span>
                                </div>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </section>
</div>
