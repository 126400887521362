<div class="content-wrapper">
    <app-content-header-section *ngIf="country && adventure && adventureItem" contentPageId="countryAdventureEdit"
                                [pageParams]="{ countryName: country.name, countryCode: country.countryCode, adventureName: adventure.name, adventureKey: adventureKey, adventureItemName: adventureItem.name, adventureItemKey: adventureItem.key }"></app-content-header-section>

    <section *ngIf="adventureItem && adventure && country" class="content-header">
        <h1>
            <i class="fa fa-puzzle-piece"></i> {{ adventureItem.name }}
            <small>Edit</small>
        </h1>
        <ol class="breadcrumb">
            <li><a routerLink="/dashboard"><i class="fas fa-flag"></i> Countries</a></li>
            <li><a routerLink="/country-manager/{{ country.countryCode }}/trail-areas-admin"><i class="far fa-map"></i> Trail Areas
                in {{ country.name }}</a></li>
            <li><a routerLink="/country-manager/{{ country.countryCode }}/adventures"><i class="far fa-compass"></i> Adventures in {{ country.name }}
            </a></li>
            <li><a routerLink="/country-manager/{{ country.countryCode }}/adventures/{{ adventure.key }}"><i
                    class="far fa-compass"></i> {{ adventure.name }}</a></li>
            <li class="active"><i class="fa fa-puzzle-piece"></i> {{ adventureItem.name }}</li>
        </ol>
    </section>

    <section class="content">
        <app-adventure-item-edit *ngIf="adventureItem && routerRoot && routerBack" [adventureItem]="adventureItem"
                                 [routerBack]="routerRoot + routerBack"></app-adventure-item-edit>
    </section>
</div>
