import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {take} from 'rxjs/operators';

// Services
// Interfaces
import {TrailAreaService} from "../../firebase-services/trail-area.service";
import {TrailArea} from "../../interfaces/trailArea";

declare var $: any;

@Component({
    selector: 'app-find-trail-area',
    templateUrl: './find-trail-area.component.html',
    styleUrls: ['./find-trail-area.component.css']
})
export class FindTrailAreaComponent implements AfterViewChecked {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Output() areaSelected = new EventEmitter<string>();

    SEARCH_TIMEOUT = 1000;
    searchTimeout: number;
    lastSearchTerm = '';
    disableSearch = false;
    searchInput: HTMLInputElement = null;
    foundTrailAreas: TrailArea[] = [];

    constructor(
        private trailAreaService: TrailAreaService
    ) {
    }

    ngAfterViewChecked() {
        if (this.searchInput === null) {
            this.searchInput = <HTMLInputElement>document.getElementById('searchForTrailAreas' + this.modalId);
        }
    }

    focusInSearch(): void {
        window.setTimeout(() => this.searchInput.focus(), 500);
    }

    searchForTrailAreas(): void {
        window.clearTimeout(this.searchTimeout);
        const searchTerm: string = this.searchInput.value;
        if (searchTerm === this.lastSearchTerm || searchTerm.length < 3) {
            return;
        }
        this.searchTimeout = window.setTimeout(() => this.startDatabaseSearch(searchTerm), this.SEARCH_TIMEOUT);
    }

    startDatabaseSearch(searchTerm: string): void {
        if (this.disableSearch) {
            return;
        }
        this.disableSearch = true;
        this.lastSearchTerm = searchTerm;
        const trails$ = this.trailAreaService.searchByName(searchTerm);
        trails$
            .pipe(take(1))
            .subscribe((trails) => {
                this.foundTrailAreas = trails;
                this.disableSearch = false;
                window.setTimeout(() => this.searchInput.focus(), 0);
            });
    }

    selectTrailArea(trailAreaKey: string): void {
        this.areaSelected.emit(trailAreaKey);
        $('#findTrailAreaModal_' + this.modalId).modal('hide');
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }
}
