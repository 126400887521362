<button type="button" class="btn btn-{{ btnClassType }}" data-toggle="modal" [attr.data-target]="'#findTrailAreaModal_'+modalId"
        (click)="focusInSearch()">
    <ng-content></ng-content>
</button>
<div class="modal fade" id="findTrailAreaModal_{{modalId}}" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <strong><i class="fas fa-search mr-2"></i> <label [for]="'searchForTrailAreas'+modalId">Search for trail areas by name, starting
                        with</label></strong>
                    <input (change)="searchForTrailAreas()" (keyup)="searchForTrailAreas()" [disabled]="disableSearch" [id]="'searchForTrailAreas'+modalId"
                           [attr.aria-describedby]="'searchForTrailAreasHelp' + modalId" class="form-control" placeholder="Enter trail area name here...">
                    <small class="form-text text-muted" [id]="'searchForTrailAreasHelp' + modalId">Enter at least 3 characters. Searches trail area names
                        starting with your search term.</small>
                    <small class="form-text text-red" [id]="'searchForTrailAreasWarning' + modalId"><i class="fas fa-exclamation-triangle"></i> The search is case sensitive.</small>
                </div>
                <div class="table-responsive">
                    <ul class="todo-list">
                        <li *ngFor="let trailArea of foundTrailAreas">
                            <img class="img-fluid img-circle img-sm" src="{{ trailArea.imageUrl }}" alt="">
                            <div class="tools">
                                <button class="btn btn-xs btn-success" (click)="selectTrailArea(trailArea.key)">Select</button> &nbsp;
                                <i (click)="copyText(trailArea.key)" class="far fa-copy black-todolist-item" title="Copy trail area key"></i>
                            </div>
                            <div class="img-push">
                                <span class="text">{{ trailArea.name }}</span>
                                <small class="badge badge-danger" title="Country info">{{ trailArea.country }}</small>
                                <small class="badge badge-default" title="Unique trail area key">{{ trailArea.key }}</small>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Cancel</button>
            </div>
            <div class="overlay" *ngIf="disableSearch">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
        </div>
    </div>
</div>
