export enum MediaLibraryTypes {
    ICONS = 'icons',
    DEFAULT_IMAGES = 'defaultImages'
}

export interface MediaFileType {
    acceptedFileType: string;
    disableUpload: boolean;
    fileName?: string;
    iconUrl: string;
    name: string;
    storageFileName?: string;
    storageFullPath?: string;
    tempFile?: File;
    uploadInfo: number;
}

export interface MediaType {
    disableCreate: boolean;
    fileTypes: { [fileType: string]: MediaFileType };
    newName: string;
    theme: string;
    typeKey: string;
    typeName: string;
    typeNamePlural: string;
}

interface MediaContentBase {
    key?: string;
    name: string;
}

export interface MediaIcon extends MediaContentBase {
    pdfUrl: string;
    pngUrl: string;
    svgUrl: string;
}

export interface DefaultImage extends MediaContentBase {
    pngUrl: string;
}
