<div class="card card-{{ themeColor }}">
    <div class="card-header">
        <h3 class="card-title">+&nbsp;<i class="{{ themeIcon}}"></i> Add new {{ typeName }}</h3>
    </div>

    <div class="card-body">
        <div class="form-group">
            <label for="name">Name (default, en)</label>
            <input [(ngModel)]="newName" id="name" aria-describedby="nameHelp" class="form-control" placeholder="{{ typeName }} name"/>
            <small class="form-text text-muted" id="nameHelp">Name your new {{ typeName }}.</small>
        </div>

        <div class="form-group">
            <label for="gmap">Map position</label>
            <div #gmap id="gmap"></div>
            <small class="form-text text-muted">Drag the marker to the position of the new {{ typeName }}.</small>
        </div>
    </div>

    <div class="card-footer">
        <button (click)="createNew()" class="btn btn-danger">Create new {{ typeName }}</button>
    </div>
</div>
