import { Pipe, PipeTransform } from '@angular/core';
import { KeyValueBasePipe } from './key-value-base.pipe';
import { PublicPoiCategory } from '../interfaces/poi-category';

interface KeyPublicPoiCategory extends PublicPoiCategory {
    key: string;
}

@Pipe({
    name: 'keyValuePublicPoiCategory'
})
export class KeyValuePublicPoiCategoryPipe extends KeyValueBasePipe implements PipeTransform {

    transform(input: { [p: string]: PublicPoiCategory }): KeyPublicPoiCategory[] {
        return <KeyPublicPoiCategory[]>super.transform(input);
    }

}
