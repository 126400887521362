import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Chart, LineControllerChartOptions } from 'chart.js';

// Services
import { LayoutService } from '../../core/layout.service';
import { KioskOrderService } from '../../firebase-services/kiosk-order.service';
import { MeetupService } from '../../firebase-services/meetup.service';
import { PremiumService } from '../../firebase-services/premium.service';
import { TrailBuilderOrganisationService } from '../../firebase-services/trail-builder-organisation.service';
import { UserService } from '../../firebase-services/user.service';

// Interfaces
import { LastWeekTotalKpi } from '../../interfaces/general';
import { Meetup, MeetUpStat } from '../../interfaces/meetup';
import { NewUsersStatistic } from '../../interfaces/newUsersStatistic';
import { PremiumKpi } from '../../interfaces/premium';
import { StravaSyncStatistic, StravaSyncUserStatistic } from '../../interfaces/stravaSyncStatistic';
import { DonationKpi } from '../../interfaces/trail-builder-organisation';
import { User } from '../../interfaces/user';

const lineChartOptions: LineControllerChartOptions = {
    showLine: true,
    spanGaps: true,
};

@Component({
    selector: 'app-root-dashboard',
    templateUrl: './root-dashboard.component.html',
    styleUrls: ['./root-dashboard.component.css']
})
export class RootDashboardComponent implements OnInit, OnDestroy {
    @ViewChild('kpiNoMeetUps', {static: true}) private noMeetUpsChartRef: ElementRef;
    @ViewChild('kpiNewUsers', {static: true}) private newUsersChartRef: ElementRef;
    @ViewChild('stravaSyncedRides', {static: true}) private noSyncedRidesChartRef: ElementRef;
    @ViewChild('stravaSyncButton', {static: true}) private stravaSyncButtonChartRef: ElementRef;

    destroy$: Subject<boolean> = new Subject<boolean>();

    donationKpi: DonationKpi = null;
    premiumKpi: PremiumKpi = null;
    kioskKpi: LastWeekTotalKpi = null;
    reviewKpi: LastWeekTotalKpi = null;

    noMeetUpsChart: any;
    noSyncedRidesChart: Chart;
    stravaSyncButtonChart: Chart;
    newUsersChart: any;

    private meetupStats: MeetUpStat[] = [];

    constructor(
        public layout: LayoutService,
        private trailBuilderOrganisationService: TrailBuilderOrganisationService,
        private userService: UserService,
        private meetUpService: MeetupService,
        private kioskOrderService: KioskOrderService,
        private premiumService: PremiumService,
        //        private ridesService: RidesService,
        private afFunctions: AngularFireFunctions
    ) {
    }

    ngOnInit() {
        this.trailBuilderOrganisationService.getDonationKpis()
            .pipe(take(1))
            .subscribe((kpis) => this.donationKpi = kpis);

        this.premiumService.getPremiumKpis()
            .pipe(take(1))
            .subscribe((kpis) => this.premiumKpi = kpis);

        this.userService.getReviewKpis()
            .pipe(take(1))
            .subscribe((kpis) => this.reviewKpi = kpis);

        this.kioskOrderService.getKioskKpis()
            .pipe(take(1))
            .subscribe((kpis) => this.kioskKpi = kpis);

        this.meetUpService.getAllMeetUps()
            .pipe(take(1))
            .subscribe((meetUps) => this.handleMeetUps(meetUps));

        this.afFunctions.httpsCallable('userCreationStatistics')({limit: 105})
            .pipe(take(1))
            .subscribe((result) => this.handleUsers(result));

        this.afFunctions.httpsCallable('getStravaSyncStatistics')({limit: 105})
            .pipe(take(1))
            .subscribe((statistics) => this.handleSyncStatistics(statistics));

        this.afFunctions.httpsCallable('getStravaSyncUserStatistics')({limit: 105})
            .pipe(take(1))
            .subscribe((statistics) => this.handleSyncUserStatistics(statistics));
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    handleSyncStatistics(statistics: StravaSyncStatistic[]): void {
        const data = {
            labels: statistics.map(stat => stat.label),
            datasets: [{
                label: 'Total',
                borderColor: 'rgba(60, 180, 75, 0.9)',
                borderWidth: 4,
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.rideCount)
            }, {
                label: 'Android',
                borderColor: 'rgba(67, 99, 216, 0.9)',
                borderWidth: 2,
                borderDash: [9, 1],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.rideCountAndroid)
            }, {
                label: 'iOS',
                borderColor: 'rgba(145, 30, 180, 0.9)',
                borderWidth: 2,
                borderDash: [9, 1],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.rideCountIos)
            }, {
                label: 'Strava',
                borderColor: 'rgba(252, 76, 2, 0.9)',
                borderWidth: 2,
                borderDash: [9, 1],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.rideCountStrava)
            }, {
                label: 'Android to Strava',
                borderColor: 'rgba(167, 199, 255, 0.9)',
                borderWidth: 2,
                borderDash: [5, 10],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.syncedRideCountAndroid),
                hidden: true
            }, {
                label: 'iOS to Strava',
                borderColor: 'rgba(245, 130, 255, 0.9)',
                borderWidth: 2,
                borderDash: [5, 10],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.syncedRideCountIos),
                hidden: true
                // }, {
                //     label: 'Rides (Other)',
                //     borderColor: 'rgba(200, 200, 200, 0.9)',
                //     borderWidth: 1,
                //     backgroundColor: 'rgba(0,0,0, 0)',
                //     data: statistics.map(stat => stat.rideCountOther)
                // }, {
                //     label: 'Other to Strava',
                //     borderColor: 'rgba(220, 220, 220, 0.9)',
                //     borderWidth: 1,
                //     backgroundColor: 'rgba(0,0,0, 0)',
                //     data: statistics.map(stat => stat.syncedRideCountOther)
            }]
        };

        this.noSyncedRidesChart = new Chart(this.noSyncedRidesChartRef.nativeElement, {
            type: 'line',
            data: data,
            options: lineChartOptions
        });
    }

    handleSyncUserStatistics(statistics: StravaSyncUserStatistic[]): void {
        const data = {
            labels: statistics.map(stat => stat.label),
            datasets: [{
                label: 'Changed setting',
                borderColor: 'rgba(252, 76, 2, 0.9)',
                borderWidth: 4,
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.syncClickedCount)
            }, {
                label: 'Enabled upload (Sync value: true)',
                borderColor: 'rgba(30, 130, 30, 0.9)',
                borderDash: [9, 1],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.uploadEnabledCount)
            }, {
                label: 'Enabled download (Sync value: true)',
                borderColor: 'rgba(30, 30, 130, 0.9)',
                borderDash: [9, 1],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.downloadEnabledCount)
            }, {
                label: 'Disabled upload (Sync value: false)',
                borderColor: 'rgba(80, 230, 80, 0.9)',
                borderWidth: 2,
                borderDash: [5],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.uploadDisabledCount),
                hidden: true
            }, {
                label: 'Disabled download (Sync value: false)',
                borderColor: 'rgba(80, 80, 230, 0.9)',
                borderWidth: 2,
                borderDash: [5],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.downloadDisabledCount),
                hidden: true
            }, {
                label: 'Failed (Clicked, sync value: null)',
                borderColor: 'rgba(255, 50, 50, 1)',
                borderWidth: 2,
                borderDash: [10],
                backgroundColor: 'rgba(0,0,0, 0)',
                data: statistics.map(stat => stat.failedSyncClickedCount),
                hidden: true
            }]
        };

        this.stravaSyncButtonChart = new Chart(this.stravaSyncButtonChartRef.nativeElement, {
            type: 'line',
            data: data,
            options: lineChartOptions
        });
    }

    handleUsers(stats: NewUsersStatistic[]): void {
        const data = {
            labels: stats.map(stat => stat.label),
            datasets: [
                {
                    label: 'Users',
                    borderColor: 'rgba(0,255,0, 0.6)',
                    backgroundColor: 'rgba(0,255,0, 0.2)',
                    data: stats.map(stat => stat.userCreatedCount)
                }
            ]
        };

        this.newUsersChart = new Chart(this.newUsersChartRef.nativeElement, {
            type: 'line',
            data: data,
            options: lineChartOptions
        });
    }

    private handleMeetUps(meetups: Meetup[]): void {
        meetups.forEach((meetup) => {
            let found = false;
            for (const i in this.meetupStats) {
                if (this.meetupStats[i].year === meetup.year && this.meetupStats[i].week === meetup.week) {
                    found = true;
                    if (meetup.public) {
                        this.meetupStats[i].publicCount++;
                    } else {
                        this.meetupStats[i].privateCount++;
                    }
                    break;
                }
            }
            if (!found) {
                const newStat: MeetUpStat = {
                    year: meetup.year,
                    week: meetup.week,
                    yearWeekLabel: meetup.year + '-' + meetup.week,
                    publicCount: (meetup.public ? 1 : 0),
                    privateCount: (meetup.public ? 0 : 1)
                };
                this.meetupStats.push(newStat);
            }
        });
        this.meetupStats.sort((a, b) => {
            const yearABiggest = a.year - b.year;
            if (yearABiggest) {
                return yearABiggest;
            }
            return a.week - b.week;
        });
        const publicData: {}[] = [];
        const privateData: {}[] = [];
        const labels: string[] = [];
        this.meetupStats.forEach((meetupStat) => {
            privateData.push({x: meetupStat.yearWeekLabel, y: meetupStat.privateCount});
            publicData.push({x: meetupStat.yearWeekLabel, y: meetupStat.publicCount});
            labels.push(meetupStat.yearWeekLabel);
        });
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Private',
                    borderColor: 'rgba(255,0,0, 0.6)',
                    backgroundColor: 'rgba(255,0,0, 0.2)',
                    data: privateData
                },
                {
                    label: 'Public',
                    borderColor: 'rgba(0,0,255, 0.6)',
                    backgroundColor: 'rgba(0,0,255, 0.2)',
                    data: publicData
                }
            ]
        };

        this.noMeetUpsChart = new Chart(this.noMeetUpsChartRef.nativeElement, {
            type: 'line',
            data: data,
            options: lineChartOptions
        });

    }
}
