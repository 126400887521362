//import { Component, OnInit } from '@angular/core';
import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

// Services
import { SponsorService } from 'src/app/firebase-services/sponsor.service';
import { AuthService } from 'src/app/core/auth.service'; 
import { UserService } from 'src/app/firebase-services/user.service'; 
import { CountryService } from 'src/app/firebase-services/country.service';
import { DestinationService } from 'src/app/firebase-services/destination.service';
import { TrailAreaService } from 'src/app/firebase-services/trail-area.service'; 

// Interfaces
import { Country } from 'src/app/interfaces/countries'; 
import { Destination } from 'src/app/interfaces/destination';
import { TrailArea, TrailAreaGroup, TrailAreaGroupCandidate, TrailAreaGroupCandidateDestination } from 'src/app/interfaces/trailArea'; 

// Interfaces
import { MINIMUM_TIME_SLOT_MINUTES, Sponsor, SponsorTargetType, SponsorTargetType2LabelMapping } from '../../../interfaces/sponsor';
import {  TextModel, TextModelItemType, TextObject } from '../../../interfaces/text-model';

import * as moment from 'moment';
import { takeCoverage } from 'v8';

declare var $: any;


@Component({
  selector: 'app-sponsor-edit',
  templateUrl: './sponsor-edit.component.html',
  styleUrls: ['./sponsor-edit.component.css']
})
export class SponsorEditComponent implements OnInit, AfterViewChecked, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
 // numberOfSelelctedTraialAreasPlusOne: Number = 1

 public sponsorTargetType2LabelMapping = SponsorTargetType2LabelMapping;

public sponsorTargetType = Object.values(SponsorTargetType);

selectedSponsorTargetType: SponsorTargetType = null

  textModel: TextModel = {items: []};

  sponsor: Sponsor = null;
  country: Country = null;
  
  //trailAreaGrouping: TrailAreaGroup[] = null;
  trailAreas: TrailArea[] = null;
  countries: Country[] = [];

  status = 0;
  settingsChangedInfo = 0;
  textsChangedInfo = 0;
  disableSaveSettings = true;
  selectedCountry: Country = null
  selectedTrailAreas: TrailArea[] = [];
  selectedTrailArea: TrailArea = null
  isMainSponsor = 'false';

  activePeriodIsLoaded = false;
  activePeriodName = '#activePeriod';
  timeFormat = 'DD-MM-YYYY HH:mm';

  constructor(
    public authService: AuthService,
    private countryService: CountryService,
    private destinationService: DestinationService,
    private trailAreaService: TrailAreaService,
    private userService: UserService,
    private route: ActivatedRoute,
    private sponsorService: SponsorService
) {

    this.initTextModel();
   }

//    init() {
   

//    }

    ngOnInit() {
        
        // this.trailAreas = null;
        // this.country = null;
        //this.trailAreaGrouping = null;
 this.disableSaveSettings = true;
 this.isMainSponsor = 'false';

      this.sponsorService.getSponsor(this.route.snapshot.paramMap.get('sponsorKey'))
            .pipe(takeUntil(this.destroy$))
            // .subscribe((sponsor) => this.sponsor = sponsor);
            .subscribe((sponsor) => {
      
                // // this.userLoaded();
                // if (sponsor.country) {
                //     //this.selectedCountry = 
                //     console.log("sponsor.country", sponsor.country)
                //     this.countryService.getCountry(sponsor.country)
                //      .pipe(take(1))
                //     .subscribe((country) => {
                //         console.log("country2", country)
                //         this.selectedCountry = country
                //     });
                    
                // }

                if (sponsor.trailTargetKeys) {
                    this.trailAreaService.getTrailAreas(sponsor.trailTargetKeys)
                    .pipe(take(1))
                    .subscribe((trailAreas) => this.selectedTrailAreas = trailAreas);
                }
                
                this.isMainSponsor = sponsor.isMainSponsor !== false ? 'true' : 'false';

              
                this.sponsor = sponsor
            });
            
   

        
        // const countryCode = this.route.snapshot.paramMap.get('countryCode');
        // this.countryService.getCountry(countryCode)
        //     .pipe(takeUntil(this.destroy$))
        //     .subscribe((country) => this.loadCountry(country));
        // this.trailAreaService.getImportedTrailAreas()
        // .pipe(takeUntil(this.destroy$))
        // .subscribe((trailArea) => this.trailAreas = trailArea);


    

        this.countryService.getCountries()
        .pipe(take(1))
        .subscribe((countries) => this.countries = countries);

        if (this.sponsor !== null ) {
            console.log("Lommer jeg herdf")
                  this.countryService.getCountry(this.sponsor.country)
                             .pipe(take(1))
                            .subscribe((country) => this.selectedCountry = country);
                }
            
        // if (this.sponsor.country) {
        //     this.selectedCountry = this.countryService.getCountry(this.sponsor.country)
        // }

        // if (sponsor.country) {
        //     //this.selectedCountry = 
        //     console.log("sponsor.country", sponsor.country)
        //     this.countryService.getCountry(sponsor.country)
        //      .pipe(takeUntil(this.destroy$) )
        //     .subscribe((country) => this.selectedCountry = country);
            
        // }

         this.authService.countries

         //All the users trailAreas
         //this.trailAreas = this.authService.trailAreas


         this.trailAreaService.getFreeTrailAreas()
         .pipe(take(1))
         .subscribe((freeTrailAreas) => this.trailAreas = freeTrailAreas);
        
        
        
        console.log("trailAreaGrouping", this.authService.trailAreas)

        console.log("trailAreaGrouping", this.authService.destinations)


       
  

           
    }


    // loadCountry(country: Country): void {

    //     this.trailAreaService.getImportedTrailAreas()
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe((trailArea) => this.trailAreas = trailArea);
        
        
    //     console.log("trailAreaGrouping", this.trailAreas)

        
        

    //     this.trailAreaService.getTrailAreasForCountry(country.countryCode)
    //         .pipe(
    //             take(1),
    //            // map((trailAreas) => this.getTrailAreasDetail(trailAreas)),
    //            // map((trailAreas) => this.splitTrailAreas(trailAreas))
    //         )
    //         .subscribe();
    //     this.country = country;
    // }
    
    ngAfterViewChecked() {
        if (this.sponsor !== null && !this.activePeriodIsLoaded && $(this.activePeriodName).length > 0) {
            const sponsorEditComponent = this;
            $(function () {
                $(sponsorEditComponent.activePeriodName).daterangepicker(
                    {
                        timePicker: true,
                        timePicker24Hour: true,
                        timePickerIncrement: MINIMUM_TIME_SLOT_MINUTES,
                        startDate: moment(sponsorEditComponent.sponsor.activeFrom),
                        endDate: moment(sponsorEditComponent.sponsor.activeTo),
                        locale: {
                            format: sponsorEditComponent.timeFormat
                        }
                    },
                    function (start: moment.Moment, end: moment.Moment) {
                        sponsorEditComponent.sponsor.activeFrom = start.unix() * 1000;
                        sponsorEditComponent.sponsor.activeTo = end.unix() * 1000;
                    }
                );
            });
            this.activePeriodIsLoaded = true;
            
        } 
        
        // if (this.sponsor !== null) {
        //     if (this.sponsor.country ) {
          
        //          this.countryService.getCountry(this.sponsor.country)
        //                      .pipe(take(1))
        //                     .subscribe((country) => this.selectedCountry = country);
        //         }
        //         //     this.countryService.getCountry(countryCode)
        //         //              .pipe(take(1))
        //         //             .subscribe((country) => countryNew = country);
        //         // }
                
        //         //         // if (sponsor.country && this.selectedCountry === null) {
        //         //         //     //this.selectedCountry = 
        //         //         //     console.log("sponsor.country", sponsor.country)
        //         //         //     this.countryService.getCountry(sponsor.country)
        //         //         //      .pipe(take(1))
        //         //         //     .subscribe((country) => this.selectedCountry = country);
                            
        //         //         // }
        //         //       return  countryNew
        //         //     }

        // }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


//     public getSelectedCountry(countryCode?: string): Country {
// var countryNew: Country = null
// if (countryCode) {
//     this.countryService.getCountry(countryCode)
//              .pipe(take(1))
//             .subscribe((country) => countryNew = country);
// }

//         // if (sponsor.country && this.selectedCountry === null) {
//         //     //this.selectedCountry = 
//         //     console.log("sponsor.country", sponsor.country)
//         //     this.countryService.getCountry(sponsor.country)
//         //      .pipe(take(1))
//         //     .subscribe((country) => this.selectedCountry = country);
            
//         // }
//       return  countryNew
//     }

mainSponsorStatusChanged(): void {
    this.sponsor.isMainSponsor = (this.isMainSponsor === 'true');
    this.disableSaveSettings = false;
}

    deleteSelectedArea(trailArea: TrailArea) {
        for (var i = 0; i < this.selectedTrailAreas.length; i++) {
          if (trailArea.key == this.selectedTrailAreas[i].key) {
            this.selectedTrailAreas.splice(i, 1);
          //  this.numberOfSelelctedTraialAreasPlusOne =- 1
          }
        }
      }

      selectArea(trailArea: TrailArea) {
        this.selectedTrailAreas.push(trailArea);

      }

    //   selectCountry(country: Country) {
    //     this.selectedCountry = country
    //   }

    //   addTrailAreaSlot() {
    //     this.numberOfSelelctedTraialAreasPlusOne =+ 1
    //   }


    initTextModel(): void {
        this.textModel.items.push({
            name: 'Title',
            varName: 'topOfMindTitle',
            help: 'The treat title - keep it very short.',
            placeholder: 'Adventurous title',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'topOfMindSubHeader',
            help: 'The sub title or description of the treat.',
            placeholder: 'This treat will give you more adventure',
            type: TextModelItemType.TEXT_AREA
        });
        this.textModel.items.push({
            name: 'Link',
            varName: 'link',
            help: 'Web page to link to.',
            placeholder: 'www.mtbutd.com/treatTargetURL',
            type: TextModelItemType.INPUT
        });
    }

    /**
     * Sets state-variable when settings are changed
     */
    settingsChanged(): void {
        this.disableSaveSettings = false;
    }

    /**
     * Save settings
     */
    saveSettings(): void {
        this.disableSaveSettings = true;
       if (this.selectedCountry) {
        console.log("this.selectedCountry", this.selectedCountry)
        this.sponsor.country = this.selectedCountry.countryCode
        this.sponsor.countryFilter = this.selectedCountry.countryCode
       // this.selectedCountry.countryCode
       }
        
        const trailAreaKeys = this.selectedTrailAreas.map(a => a.key);

        this.sponsor.trailTargetKeys = trailAreaKeys
        console.log("sponnsosnr", this.sponsor)
        this.sponsorService.updateSettings(this.sponsor)
            .then(() => this.settingsChangedInfo++)
            .catch((err) => console.error('Settings-Update error occurred:', err.message));
    }

    // onAlteredTextObject(alteredTextObject: TextObject): void {
    //     this.sponsorService.updateTexts(<Sponsor>alteredTextObject)
    //         .then(() => this.textsChangedInfo++)
    //         .catch((err) => console.error('Text-Update error occurred:', err.message));
    // }



    setImageSize(size: {width: number, height:number} ) {
        console.log("width",size.width)
        console.log("height",size.height)
        this.sponsor.imageWidth = size.width
        this.sponsor.imageHeight = size.height
        this.sponsorService.uploadedImageSize(this.sponsor)
        .catch((err) => console.error('ImageSize failure', err));
    }

    onUploadedImageUrl(uploadedImageUrl: string): void {
        this.sponsor.imageURL = uploadedImageUrl;
        this.sponsorService.uploadedImage(this.sponsor)
            .catch((err) => console.error('Image upload failure', err));
    }
}