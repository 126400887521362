<div class="card card-outline card-success" [class.box-solid]="_nextImage">
    <div class="card-header">
        <h3 class="card-title"><i class="far fa-image mr-2"></i> <span *ngIf="_nextImage">New </span>{{ imageName }}<span
                *ngIf="!_nextImage"> #{{ imageIndex }}</span></h3>
    </div>
    <div class="card-body">

        <app-alert-info-box [infoTrigger]="imageUploadedInfo" *ngIf="!disableSave">
            <h4>New {{ imageName }} successfully uploaded.</h4>
        </app-alert-info-box>

        <div class="form-group">
            <label><i class="far fa-image mr-2"></i> {{ imageName }} (click "Upload" to replace this)</label>
            <div *ngIf="!_readonly" class="input-group">
                <div class="custom-file">
                    <!--suppress TypeScriptUnresolvedVariable -->
                    <input type="file" class="custom-file-input" (change)="onImageFileSelected($event.target.files)" [id]="'fileInput'+imageIndex">
                    <label class="custom-file-label" [for]="'fileInput' + imageIndex">Choose file</label>
                </div>
                <div class="input-group-append" *ngIf="!disableSave">
                    <button [disabled]="disableUploadImage" class="btn btn-info" (click)="uploadImage()">Upload</button>
                </div>
                <div class="input-group-append">
                    <button [disabled]="disableUploadImage" class="btn btn-secondary" (click)="reset()">Reset</button>
                </div>
            </div>
            <img [id]="'img-upload'+imageIndex" [attr.aria-describedby]="'img-upload-help'+imageIndex" [src]="_imageUrl" class="img-fluid"
                 [class.d-none]="!_imageUrl" [alt]="'Image ' + imageIndex">
            <div *ngIf="required && !_imageUrl && disableUploadImage" class="form-text text-danger text-sm">
                <i class="fas fa-exclamation-triangle mr-1"></i> An image is required.
            </div>
            <div class="form-text text-muted text-sm" [id]="'img-upload-help' + imageIndex"><strong>Note</strong>: {{ sizeNote }}</div>
            <div *ngIf="imageUrlOnline && imageUrlOnline !== _imageUrl" class="text-danger text-sm">
                <i class="fas fa-globe-europe"></i> <span *ngIf="!imageUrlOnline">No image is published</span>
                <span *ngIf="imageUrlOnline">Published Image:</span>
                <img *ngIf="imageUrlOnline" [src]="imageUrlOnline" class="img-fluid" alt="Published Image">
     
            </div>
            <ng-container *ngIf="uploadSuccess">
                Upload Successful of file with size : {{size}} bytes <br>
                The image height is : {{imageHeight}} <br>
                The image width is : {{imageWidth}} <br>
              </ng-container> 
        </div>

    </div>
</div>
