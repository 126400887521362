<div class="content-wrapper">
    <app-content-header-section contentPageId="rtwEvents" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid" *ngIf="rtwEvents">
            <div [ngClass]="'card card-outline card-' + layout.getTheme('rtwEvent')">
                <div class="card-header">
                    <h3 class="card-title"><i [ngClass]="layout.getPluralIcon('rtwEvent')"></i> RtW Events</h3>
                </div>
                <div class="card-body px-0">
                    <table id="rtwEventsDataTable" class="table row-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Collection Key</th>
                            <th>Hero Name</th>
                            <th>Participants</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let rtwEvent of rtwEvents">
                            <td [attr.data-order]="rtwEvent.title">{{ rtwEvent.title }} <i class="far fa-edit" (click)="editRtwEvent(rtwEvent)"></i>
                            </td>
                            <td>{{ rtwEvent.startTime | date:'dd/MM/yyy HH:mm' }}</td>
                            <td>{{ rtwEvent.endTime | date:'dd/MM/yyy HH:mm' }}</td>
                            <td>{{ rtwEvent.collectionKey }}</td>
                            <td>{{ rtwEvent.heroName }}</td>
                            <td><a [href]="'/root/rtw/events/' + rtwEvent.key + '/participants'">Participants</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!rtwEvents" class="overlay">
                    <i class="fas fa-spinner fa-3x fa-spin"></i>
                </div>
            </div>

        </div>
    </section>
</div>
