<div class="content-wrapper">
    <app-content-header-section *ngIf="country" contentPageId="countryNewTrailArea"
                                [pageParams]="{countryName: country.name}"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-6">

                    <div class="card card-outline card-primary">
                        <div class="card-header">
                            <h3 *ngIf="country" class="card-title">Create new Trail Area in {{ country.name }}</h3>
                        </div>

                        <div class="card-body">
                            <div class="form-group">
                                <label for="name">Trail Area name</label>
                                <input [(ngModel)]="trailAreaName" (change)="valueChanged()" (keyup)="valueChanged()" id="name"
                                       aria-describedby="nameHelp" class="form-control" placeholder="Name of the Trail Area"/>
                                <small class="form-text text-muted" id="nameHelp">This is the main name of the trail area. If there are several names
                                    write them like "Name 1 - Name 2" where Name 1 is the most widely known.</small>
                            </div>
                        </div>

                        <div class="card-footer">
                            <button [disabled]="disableCreate" (click)="createTrailArea()" class="btn btn-primary">
                                Create new Trail Area
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
