import { Injectable } from '@angular/core';

/** @see https://www.npmjs.com/package/ngeohash */
import Geohash from 'ngeohash';

@Injectable({
    providedIn: 'root'
})
export class GeoService {

    constructor() {
    }

    static geohashToLatLngLiteral(geohash: string): google.maps.LatLngLiteral {
        const geoLatitudeLongitude = Geohash.decode(geohash);
        return {lat: geoLatitudeLongitude.latitude, lng: geoLatitudeLongitude.longitude};
    }

    static latLngLiteralToGeohash(latLngLiteral: google.maps.LatLngLiteral): string {
        return Geohash.encode(latLngLiteral.lat, latLngLiteral.lng);
    }

}
