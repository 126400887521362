import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

// Interfaces
import { RestCountryV2Languages } from '../interfaces/rest-country-v2-languages';

@Injectable({
    providedIn: 'root'
})
export class RestCountriesService {

    constructor(
        private client: HttpClient,
    ) {
    }

    private static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Rest Countries Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    getLanguageCodesInCountry(countryCode: string): Observable<string[]> {
        return this.client.get<RestCountryV2Languages>(`https://restcountries.com/v2/alpha/${countryCode}?fields=languages`)
            .pipe(
                map((restCountry) => {
                    const languageCodes: string[] = [];
                    restCountry.languages.forEach((langObject) => {
                        if (typeof langObject.iso639_1 === 'string' && langObject.iso639_1.length === 2) {
                            languageCodes.push(langObject.iso639_1);
                        }
                    });
                    return languageCodes;
                }),
                retry(1), // retry a failed request up to 3 times
                catchError(RestCountriesService.handleError) // then handle the error
            );
    }

}
