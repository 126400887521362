import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { UserService } from '../../firebase-services/user.service';
import { AuthService } from '../../core/auth.service';
import { CountryService } from '../../firebase-services/country.service';

// Interfaces
import { Country } from '../../interfaces/countries';

@Component({
    selector: 'app-country-administrators',
    templateUrl: './country-administrators.component.html',
    styleUrls: ['./country-administrators.component.css']
})
export class CountryAdministratorsComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    countries: Country[] = [];
    countryManagers: any = {};

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private countryService: CountryService
    ) {
        this.countryService.getCountries()
            .pipe(takeUntil(this.destroy$))
            .subscribe((countries) => {
                for (const country of countries) {
                    this.userService.getCountryManagers(country.countryCode)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((managers) => this.countryManagers[country.countryCode] = managers);
                }
                this.countries = countries;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    appointCountryManager(profileKey: string, countryCode: string): Promise<void> {
        return this.userService.appointCountryManager(profileKey, countryCode, this.authService.user.userID);
    }

    revokeCountryManager(profileKey: string, countryCode: string): Promise<void> {
        return this.userService.revokeCountryManager(profileKey, countryCode);
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }

}
