<div class="content-wrapper">
    <app-content-header-section contentPageId="premiumMembers" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-user"></i> Adjust Membership</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="findProfile">Search for a profile to adjust</label>
                                <div class="input-group">
                                    <app-find-user id="findProfile" modalId="premiumMembershipAdjustment" modalTitle="Find profile"
                                                   btnClassType="default" (userSelected)="foundProfile($event)">
                                        <i class="fa fa-user" title="Find profile to adjust"></i> Find profile
                                    </app-find-user>
                                </div>
                            </div>
                            <div *ngIf="adjustProfile" class="form-group">
                                <label>Adjust profile</label>
                                <div class="input-group">
                                    <input type="text" disabled class="form-control" [value]="adjustProfile.name"/>
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ adjustExpires | date:'dd/MM/yyy HH:mm:ss' }}</span>
                                        <button type="button" class="btn btn-outline-danger" (click)="endMembership()">End</button>
                                        <button type="button" class="btn btn-outline-info" (click)="addTime(1, 'months')">+1 month</button>
                                        <button type="button" class="btn btn-outline-info" (click)="addTime(1, 'years')">+1 year</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showReloadMessage" class="alert alert-warning">Notice: You need to reload this page in order to see the update in the table below.</div>
                        </div>
                    </div>

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-users"></i> Members</h3>
                        </div>
                        <div *ngIf="!members" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="members" id="membersDataTable" class="table row-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Expires</th>
                                    <th>Member ID</th>
                                    <th>Name</th>
                                    <th>Street</th>
                                    <th>Postal code</th>
                                    <th>City</th>
                                    <th>Country</th>
                                    <th>e-mail</th>
                                    <th>Profile Key</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let member of members | keyvalue: orderByNumericKey">
                                    <td [attr.data-order]="member.key">
                                        {{ plusOne(member.key) }}
                                    </td>
                                    <td [attr.data-order]="member.value.expires">
                                        {{ member.value.expires | date:'dd/MM/yyy HH:mm:ss' }}
                                        <a (click)="foundProfile(member.value.profileKey)" title="Adjust expiry"><i class="fa fa-edit"></i></a>
                                    </td>
                                    <td>
                                        {{ member.value.memberId }}
                                    </td>
                                    <td [attr.data-order]="member.value.name">
                                        {{ member.value.name }}
                                    </td>
                                    <td [attr.data-order]="member.value.street">
                                        {{ member.value.street }}
                                    </td>
                                    <td [attr.data-order]="member.value.zip">
                                        {{ member.value.zip }}
                                    </td>
                                    <td [attr.data-order]="member.value.city">
                                        {{ member.value.city }}
                                    </td>
                                    <td [attr.data-order]="member.value.country">
                                        {{ member.value.country }}
                                    </td>
                                    <td [attr.data-order]="member.value.email">
                                        {{ member.value.email }}
                                    </td>
                                    <td class="small">
                                        {{ member.value.profileKey }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
