<div [ngClass]="'card card-outline card-tabs card-' + colorTheme">
    <div class="card-header border-bottom-0">
        <h3 class="card-title"><i class="fas fa-language mr-2"></i> {{ textsName }} Texts</h3>
    </div>

    <div class="card-header p-0 pt-1 border-bottom-0">
        <ul class="nav nav-tabs" [id]="'translatable_texts_tabs_' + cId" role="tablist">
            <li class="nav-item">
                <a [id]="'tab_default_link_' + cId" [href]="'#tab_default_' + cId"
                   [attr.aria-controls]="'#tab_default_' + cId" aria-selected="true" data-toggle="pill" role="tab" class="nav-link active">English</a>
            </li>
            <li *ngFor="let language of objectKeys(_textObject.lang)" class="nav-item">
                <a [id]="'tab_' + language + '_link_' + cId" [href]="'#tab_' + language + '_' + cId"
                   [attr.aria-controls]="'#tab_' + language + '_' + cId" aria-selected="false" data-toggle="pill" role="tab"
                   class="nav-link">{{ language }}</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" [id]="'translatable_texts_dropdown_' + cId" role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-language"></i>+
                </a>
                <div [attr.aria-labelledby]="'translatable_texts_dropdown_' + cId" class="dropdown-menu">
                    <span class="dropdown-header">Add another language</span>
                    <ng-container *ngFor="let language of objectKeys(languageNames)">

                        <a *ngIf="!objectKeys(_textObject.lang).includes(language)" class="dropdown-item"
                           (click)="addLanguage(language)">Add {{ languageNames[language] }}</a>
                    </ng-container>
                </div>
            </li>
        </ul>
    </div>

    <div class="card-body">
        <div class="tab-content" *ngIf="textModel">

            <div [id]="'tab_default_' + cId" class="tab-pane fade active show" role="tabpanel">
                <div *ngFor="let item of textModel.items" class="form-group">
                    <label [for]="item.varName + cId" [innerHTML]="item.html ?? item.name"></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" title="By default texts are in english">English (default)</span>
                        </div>
                        <input *ngIf="item.type === inputType" [disabled]="item.readonly" [(ngModel)]="_textObject[item.varName]"
                               (change)="textsChanged(item)" (keyup)="textsChanged(item)" [id]="item.varName + cId" class="form-control"
                               [placeholder]="item.placeholder">
                        <textarea *ngIf="item.type === textareaType" [disabled]="item.readonly" [(ngModel)]="_textObject[item.varName]"
                                  (change)="textsChanged(item)" (keyup)="textsChanged(item)" [id]="item.varName + cId"
                                  [attr.aria-describedby]="item.varName + 'Help' + cId" class="form-control"
                                  [placeholder]="item.placeholder"></textarea>
                    </div>
                    <small class="form-text text-danger" *ngIf="item.required && !_textObject[item.varName]">
                        <i class="fas fa-exclamation-triangle mr-1"></i> This field is required.</small>
                    <small class="form-text text-muted" [id]="item.varName + 'Help' + cId">{{ item.help }}</small>
                    <div *ngIf="showDefaultPublishedValue(item)"
                         class="text-danger text-sm">
                        <i class="fas fa-globe-europe"></i> Published value: "{{ getDefaultPublishedValue(item) }}"
                    </div>
                </div>
            </div>

            <div *ngFor="let language of objectKeys(_textObject.lang)" class="tab-pane fade" [id]="'tab_' + language + '_' + cId" role="tabpanel">
                <div *ngFor="let item of textModel.items" class="form-group">
                    <label [for]="item.varName + language + cId" [innerHTML]="item.html ?? item.name"></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" [title]="'Translate to ' + language">{{ language }}</span>
                        </div>
                        <input *ngIf="item.type === inputType" [disabled]="item.readonly"
                               [(ngModel)]="_textObject.lang[language][item.varName]"
                               (change)="textsChanged(item, language)" (keyup)="textsChanged(item, language)" [id]="item.varName + language + cId"
                               class="form-control"
                               [placeholder]="_textObject[item.varName] || item.placeholder">
                        <textarea *ngIf="item.type === textareaType" [disabled]="item.readonly"
                                  [(ngModel)]="_textObject.lang[language][item.varName]"
                                  (change)="textsChanged(item, language)" (keyup)="textsChanged(item, language)" [id]="item.varName + language + cId"
                                  [attr.aria-describedby]="'help_' + item.varName + language + cId" class="form-control"
                                  [placeholder]="_textObject[item.varName] || item.placeholder"></textarea>
                        <span class="input-group-append" [class.hidden]="item.readonly">
                            <button type="button" class="btn btn-info" [title]="'Copy default ' + item.name"
                                    (click)="copyDefaultText(item.varName, language)"><i class="far fa-copy"></i></button>
                        </span>
                    </div>
                    <small class="form-text text-muted" [id]="'help_' + item.varName + language + cId">
                        <span *ngIf="_textObject[item.varName]">Default: <i>{{ _textObject[item.varName] }}</i><br></span>
                        {{ item.help }}
                    </small>
                    <div *ngIf="showPublishedValue(item, language)"
                         class="text-danger text-sm">
                        <i class="fas fa-globe-europe"></i> Published value: "{{ getPublishedValue(item, language) }}"
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
