<div class="content-wrapper">
    <app-content-header-section [contentPageId]="contentPageId" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-6">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('poi')"
                         *ngIf="poiClusters && mediaIcons">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getPluralIcon('poi') + ' mr-2'"></i>
                                Migrate existing POIs to icon</h3>
                        </div>
                        <div class="card-body">
                            <div class="card-text">If you don't know what this is, then please leave it alone!</div>
                            <div class="form-group">
                                <label for="poiClusterSelectOne">Migrate from</label>
                                <select id="poiClusterSelectOne" [(ngModel)]="migratePoiClusterIndex"
                                        class="form-control">
                                    <!--suppress TypeScriptValidateTypes -->
                                    <option *ngFor="let poiCluster of poiClusters | keyvalue" [value]="poiCluster.key">
                                        {{ iconNameFromKey(poiCluster.value.mediaIconKey) }},
                                        Hits: {{ poiCluster.value.amount}},
                                        IconUrl: {{ poiCluster.value.iconUrl | slice:-100 }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="migrateToSelectOne">Migrate to</label>
                                <select id="migrateToSelectOne" [(ngModel)]="migratePoisToIconIndex"
                                        class="form-control">
                                    <!--suppress TypeScriptValidateTypes -->
                                    <option *ngFor="let mediaIcon of mediaIcons | keyvalue" [value]="mediaIcon.key">
                                        Icon: {{ mediaIcon.value.name}}
                                    </option>
                                </select>
                            </div>

                            <button [disabled]="!migratePoiClusterIndex || !migratePoisToIconIndex" type="submit"
                                    class="btn btn-primary" (click)="migratePoiClusterToIcon()">Migrate
                            </button>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('category')"
                         *ngIf="poiClusters && publicPoiCategories">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getPluralIcon('poi') + ' mr-2'"></i>
                                Migrate existing POIs to POI categories</h3>
                        </div>
                        <div class="card-body">
                            <div class="card-text">If you don't know what this is, then please leave it alone!</div>
                            <div class="form-group">
                                <label for="poiClusterSelect">Migrate from</label>
                                <select id="poiClusterSelect" [(ngModel)]="clusterForCategoryIndex" class="form-control">
                                    <!--suppress TypeScriptValidateTypes -->
                                    <option *ngFor="let poiCluster of poiClusters | keyvalue" [value]="poiCluster.key">
                                        {{ iconNameFromKey(poiCluster.value.mediaIconKey) }},
                                        Hits: {{ poiCluster.value.amount}},
                                        IconUrl: {{ poiCluster.value.iconUrl | slice:-100 }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="migrateToSelect">Migrate to</label>
                                <select id="migrateToSelect" [(ngModel)]="addCategoriesLabel"
                                        class="form-control">
                                    <!--suppress TypeScriptValidateTypes -->
                                    <ng-container *ngFor="let category of publicPoiCategories | keyvalue">
                                        <option [value]="category.key"
                                                [disabled]="poiCategories[category.key].accessLevel !== PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN && poiCategories[category.key].accessLevel !== PoiCategoriesAccessLevels.ROOT">
                                            {{ category.value.name}}
                                        </option>
                                        <ng-container *ngIf="category.value.subCategories">
                                            <option *ngFor="let subCategory of category.value.subCategories | keyvalue"
                                                    [disabled]="poiCategories[category.key].subCategories[subCategory.key].accessLevel !== PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN && poiCategories[category.key].subCategories[subCategory.key].accessLevel !== PoiCategoriesAccessLevels.ROOT"
                                                    [value]="subCategory.key">
                                                {{ category.value.name}} -> {{ subCategory.value.name }}
                                            </option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                            </div>

                            <button [disabled]="!clusterForCategoryIndex || !addCategoriesLabel" type="submit"
                                    class="btn btn-primary" (click)="addCategoryToPoiCluster()">Migrate
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
