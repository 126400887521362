import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import firebase from 'firebase/compat';
import { Observable, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// Services
import { PremiumService } from './premium.service';

// Interfaces
import { OrganisationMember } from '../interfaces/organisation';

@Injectable({
    providedIn: 'root'
})
export class OrganisationMemberService {

    organisationMembersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private premiumService: PremiumService
    ) {
        this.organisationMembersRef = this.db.database.ref('organisationMembers');

    }

    private static getOrganisationMemberFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<OrganisationMember>>): OrganisationMember {
        const organisationMember: OrganisationMember = snapshot.payload.val();
        organisationMember.profileKey = snapshot.key;
        return organisationMember;
    }

    getOrganisationMembers(organisationKey: string): Observable<OrganisationMember[]> {
        // Hardcoded to mud (and mudKey for beta).
        const basedOnPremiumStatus = (organisationKey === 'mud' || organisationKey === 'mudKey');

        let membersObservable;
        if (basedOnPremiumStatus) {
            membersObservable = this.db.list<OrganisationMember>(this.organisationMembersRef.child(organisationKey).ref,
                ref => ref.orderByChild('expires')).snapshotChanges()
                .pipe(
                    switchMap((organisationMemberSnaps) => {
                        const memberCandidates: Observable<OrganisationMember | null>[] = [];
                        organisationMemberSnaps.forEach((organisationMemberSnap) => {
                            memberCandidates.push(this.getOrganisationMemberIfPremium(organisationMemberSnap));
                        });
                        return zip(...memberCandidates);
                    }),
                    map((membersWithNull) => membersWithNull.filter((m) => m !== null))
                );
        } else {
            membersObservable = this.db.list<OrganisationMember>(this.organisationMembersRef.child(organisationKey).ref,
                ref => ref.orderByChild('expires').startAt(Date.now())).snapshotChanges()
                .pipe(
                    map((organisationMembersSnapshot) => {
                        const members: OrganisationMember[] = [];
                        organisationMembersSnapshot.forEach((organisationMemberSnapshot) => {
                            members.push(OrganisationMemberService.getOrganisationMemberFromSnap(organisationMemberSnapshot));
                        });
                        return members;
                    })
                );
        }
        return membersObservable;
    }

    private getOrganisationMemberIfPremium(memberSnap: AngularFireAction<DatabaseSnapshot<OrganisationMember>>)
        : Observable<OrganisationMember | null> {
        return this.premiumService.getPremiumExpiry(memberSnap.key)
            .pipe(
                map((expiry) => {
                    if (expiry > Date.now()) {
                        return OrganisationMemberService.getOrganisationMemberFromSnap(memberSnap);
                    }
                    return null;
                })
            );
    }
}
