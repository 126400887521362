<div class="content-wrapper">
    <app-content-header-section contentPageId="dmoManagers" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-plus"></i> Add trail area to DMO manager
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list" *ngIf="dmoManagerListItems">
                                    <li class="primaryListItem" *ngFor="let manager of dmoManagerListItems">
                                        <img class="img-fluid img-circle img-sm"
                                             src="{{ manager.user.userPicture }}" alt="">
                                        <span class="text">{{ manager.user.name }}</span>
                                        <div class="toolsStaticBlue">
                                            <app-find-trail-area *ngIf="authService.isUser(roles.ADMIN)"
                                                                 modalId="findTrailAreaFor{{manager.user.userID}}"
                                                                 modalTitle="Find trail area" id="selectTrailArea"
                                                                 (areaSelected)="onAreaAdded($event, manager.user.userID)"
                                                                 btnClassType="-xs">
                                                <i title="Add trail area to user"
                                                   class="fas fa-route mr-2 blueTrail"></i>
                                            </app-find-trail-area>
                                        </div>
                                        <ul class="secondaryList" *ngIf="manager.trailAreas">
                                            <li class="secondaryListItem" *ngFor="let area of manager.trailAreas">
                                                <span class="text-secondary">{{ area.name }}</span>
                                                <small class="badge badge-danger">{{ area.country }}</small>
                                                <small class="badge badge-default">{{ area.key }}</small>
                                                <div class="tools">
                                                    <i *ngIf="authService.isUser(roles.ADMIN)"
                                                       title="Remove trail area from user"
                                                       (click)="onAreaRemoved(manager.user.userID, area.key)"
                                                       class="far fa-trash-alt mr-2"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="authService.isUser(roles.ADMIN)">
                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-plus"></i> Assign DMO Manager</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ state }}">
                                <h4>DMO Manager saved successfully</h4>
                            </app-alert-info-box>
                            <div class="form-group">
                                <label for="selectdmoManager"><i class="fas fa-user"></i> Select User</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-user modalId="selectdmoManagerUser"
                                                           modalTitle="Find user to assign as DMO manager"
                                                           id="selectdmoManager"
                                                           (userSelected)="onUserSelected($event)"
                                                           btnClassType="-smaller custom-btn">
                                                <i title="Search for user" class="fas fa-search mr2"></i>
                                            </app-find-user>
                                        </span>
                                    </div>
                                    <input id="selectedUserText" aria-describedby="userHelp"
                                           class="form-control custom-input" value="{{ selectedUserName }}"
                                           placeholder="No user selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectUserHelp">Select a user to assign as
                                    DMO Manager.</small>
                            </div>
                            <div class="form-group">

                                <label for="selectTrailArea"><i class="fas fa-route"></i> Select Trail Area</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-trail-area modalId="selectTrailAreaEm"
                                                                 modalTitle="Find trail area to assign to DMO manager"
                                                                 id="selectTrailAreaEm"
                                                                 (areaSelected)="onAreaSelected($event)"
                                                                 btnClassType="-smaller custom-btn">
                                                <i title="Search for trail area" class="custom-i fas fa-search"></i>
                                            </app-find-trail-area>
                                        </span>
                                    </div>
                                    <input id="selectedAreaText" aria-describedby="areaHelp"
                                           class="form-control custom-input" value="{{ selectedTrailAreaName }}"
                                           placeholder="No trail area selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectAreaHelp">Select a trail area to assign to
                                    a DMO Manager.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="saveRoleDisabled" type="submit" class="btn btn-primary"
                                    (click)="assignDmoManager()">Save
                            </button>
                            <button type="reset" class="btn btn-default discard-btn"
                                    (click)="resetAssignDmoManager()">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>