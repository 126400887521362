<div class="content-wrapper">
    <app-content-header-section contentPageId="newsRoomStories" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">

            <app-news-room-list storyType="published" roomName="news-room" navigateTo="/root/news-room/stories/"
                                listName="Published News Stories"></app-news-room-list>

        </div>
    </section>

</div>
