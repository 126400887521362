import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from "rxjs";
import {AddRoleEntry, AssignableRole, Role, SaveRoleEntry} from "../interfaces/role";
import {map, switchMap, take} from "rxjs/operators";
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import firebase from "firebase/compat";
import {User} from "../interfaces/user";
import {UserService} from "../firebase-services/user.service";

@Injectable({providedIn: 'root'})
export class RoleService {
    rolesRef: firebase.database.Reference;
    userRolesRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService
    ) {
        this.rolesRef = this.db.database.ref('roles')
        this.userRolesRef = this.db.database.ref('userRoles');
    }

    public getAuthRoles(userKey: string): Observable<string[]> {
        return this.db.list<string>(this.userRolesRef.child(userKey).ref).snapshotChanges()
            .pipe(
                map((roleSnapShots) => {
                    const authRoles = [];
                    for (const snapShot of roleSnapShots) {
                        authRoles.push(snapShot.key);
                    }
                    return authRoles;
                })
            );
    }

    public getRoles(userKey: string): Observable<Role[]> {
        return this.db.list<Role>(this.userRolesRef.child(userKey).ref).snapshotChanges()
            .pipe(
                map((roleSnapshots) => {
                    const returnRoles: Role[] = [];
                    for (const roleSnapshot of roleSnapshots) {
                        returnRoles.push(this.roleFromSnap(roleSnapshot));
                    }
                    return returnRoles;
                })
            );
    }

    getUsersByRole(role: string): Observable<User[]> {
        return this.db.list(this.userRolesRef, ref => ref.orderByChild(`${role}/by`).startAt('')).snapshotChanges()
            .pipe(switchMap(userRoleSnapshots => {
                const userKeys = userRoleSnapshots.map(snapshot => snapshot.key);
                const userObservables = userKeys.map(userKey => this.userService.getUser(userKey)
                    .pipe(take(1)));
                return userKeys.length ? forkJoin(userObservables) : of([]);
            }));
    }

    public getAssignableRoles(): Observable<AssignableRole[]> {
        return this.db.list<AssignableRole>(this.rolesRef.ref).snapshotChanges()
            .pipe(
                map((roleSnapshots) => {
                    const returnRoles: AssignableRole[] = [];
                    for (const roleSnapshot of roleSnapshots) {
                        returnRoles.push(this.assignableRoleFromSnap(roleSnapshot));
                    }
                    return returnRoles;
                })
            );
    }

    public assignUserRole(profileKey: string, role: string, currentProfileKey: string): Promise<void> {
        const userRoleEntry: AddRoleEntry = {
            at: Date.now(),
            by: currentProfileKey
        };
        return this.userRolesRef.child(profileKey).child(role).update(userRoleEntry);
    }

    public revokeUserRole(userKey: string, role: string) {
        return this.userRolesRef.child(userKey).child(role).remove();
    }

    public saveNewRole(roleName: string, roleDescription: string) {
        let newRoleName = this.roleNameToUpperCase(roleName);
        let newRoleId = this.roleIdToLowerCase(newRoleName);
        newRoleId = newRoleId.replace(/ /g, '');
        const roleEntry: SaveRoleEntry = {
            name: newRoleName,
            description: roleDescription
        };
        return this.rolesRef.child(newRoleId).update(roleEntry);
    }

    public updateRoleDescription(role: string, description: string): Promise<void> {
        return this.rolesRef.child(role).child('description').update(description);
    }

    private roleFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<Role>>): Role {
        const role: Role = snapshot.payload.val();
        role.id = snapshot.key;
        role.at = role.at || null;
        role.by = role.by || null;
        return role;
    }

    private assignableRoleFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<AssignableRole>>): AssignableRole {
        const role: AssignableRole = snapshot.payload.val();
        role.id = snapshot.key;
        role.description = role.description || null;
        role.name = role.name || null;
        return role;
    }

    private roleNameToUpperCase(str) {
        let splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    private roleIdToLowerCase(str) {
        let splitStr = str.split(' ');
        splitStr[0] = splitStr[0].toLowerCase();
        return splitStr.join(' ');
    }
}

