import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../../core/layout.service';
import { RtwEventService } from '../../../firebase-services/rtw-event.service';

// Interfaces
import { RtwEvent } from '../../../interfaces/rtw-event';

declare var $: any;

@Component({
    selector: 'app-rtw-events',
    templateUrl: './rtw-events.component.html',
    styleUrls: ['./rtw-events.component.css']
})
export class RtwEventsComponent implements OnInit, AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    rtwEvents: RtwEvent[];

    // Datatable
    dataTableName: string = '#rtwEventsDataTable';
    dataTable: any = null;
    dataTableCreated: boolean = false;

    constructor(
        public layout: LayoutService,
        private router: Router,
        private rtwEventService: RtwEventService
    ) {
    }

    ngOnInit(): void {
        this.loadEvents();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.dataTableName + '>tbody>tr').length > 0)) {
            this.dataTable = $(this.dataTableName).DataTable({
                'order': [[0, 'desc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private loadEvents(): void {
        this.rtwEventService.getRtwEvents()
            .pipe(takeUntil(this.destroy$))
            .subscribe((rtwEvents) => {
                this.rtwEvents = rtwEvents;
            });
    }

    editRtwEvent(rtwEvent: RtwEvent): void {
        this.router.navigate([this.layout.getUrl('editRtwEvent', {rtwEventKey: rtwEvent.key})]);
    }
}
