import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

// Services
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

// Interfaces
import { ProfileRide, Ride, RIDE_HANDLING_DELAY } from '../interfaces/ride';
import { Trail } from '../interfaces/trail';
import { TrailArea } from '../interfaces/trailArea';

import firebase from 'firebase/compat';
import polyTool from '@pirxpilot/google-polyline';

@Injectable({
    providedIn: 'root'
})
export class RideService {
    ridesRef: firebase.database.Reference;
    profileRidesRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private afFunctions: AngularFireFunctions
    ) {
        this.ridesRef = this.db.database.ref('rides');
        this.profileRidesRef = this.db.database.ref('profileRides');
    }

    private static rideOnTrailArea(trailArea: TrailArea, ride: Ride) {
        return ((trailArea.boundsEast > ride.boundsWest) &&
            (trailArea.boundsWest < ride.boundsEast) &&
            (trailArea.boundsNorth > ride.boundsSouth) &&
            (trailArea.boundsSouth < ride.boundsNorth));
    }

    getRide(rideKey: string): Observable<Ride> {
        return this.db.object<Ride>(this.ridesRef.ref.child(rideKey)).valueChanges()
            .pipe(take(1));
    }

    getProfileRidesInDateRange(startTime: number, endTime: number): Observable<ProfileRide[]> {
        return this.db.list(this.ridesRef.ref, ref => ref.orderByChild('startTime').startAt(startTime).endAt(endTime)).snapshotChanges()
            .pipe(
                take(1),
                switchMap((ridesSnapshot) => {
                    return this.getProfileRidesFromRidesSnapshot(ridesSnapshot);
                })
            );
    }

    getProfileRidesForProfile(profileKey: string): Observable<ProfileRide[]> {
        return this.db.list(this.ridesRef.ref, ref => ref.orderByChild('userId').equalTo(profileKey)).snapshotChanges()
            .pipe(
                take(1),
                switchMap((ridesSnapshot) => {
                    return this.getProfileRidesFromRidesSnapshot(ridesSnapshot);
                })
            );
    }

    getRidesOnTrailAreaInDateRange(trailArea: TrailArea, startTime: number, endTime: number): Observable<{ [key: string]: any }> {
        return this.db.list(this.ridesRef.ref, ref => ref.orderByChild('startTime').startAt(startTime).endAt(endTime)).snapshotChanges()
            .pipe(
                take(1),
                map((ridesSnapshot) => {
                    const ridesWithBounds: Ride[] = [];
                    const onTrailRidesSnapshot: AngularFireAction<DatabaseSnapshot<{}>>[] = [];
                    let rideHandlingOrderedCount = 0;
                    ridesSnapshot.forEach((rideSnap) => {
                        const ride: Ride = <Ride>rideSnap.payload.val();

                        if (ride.boundsNorth > -90) {
                            if (typeof ride.userId !== 'string') {
                                console.error('Fix this rideKey:', rideSnap.key, ride.userId);
                                return;
                            }
                            if (RideService.rideOnTrailArea(trailArea, ride)) {
                                ridesWithBounds.push(ride);
                                onTrailRidesSnapshot.push(rideSnap);
                            }
                        } else {
                            if (ride.key !== rideSnap.key) {
                                ride.key = rideSnap.key;
                                console.log('Key set for:', rideSnap.key);
                            }
                            rideHandlingOrderedCount++;
                            this.orderRestartRideHandling(ride.key, rideHandlingOrderedCount);
                        }
                    });
                    let profileRidesObservable: Observable<ProfileRide[]> = null;
                    if (rideHandlingOrderedCount === 0) {
                        profileRidesObservable = this.getProfileRidesFromRidesSnapshot(onTrailRidesSnapshot);
                    }
                    return {
                        ridesOK: ridesWithBounds,
                        orderedRestarts: rideHandlingOrderedCount,
                        profileRidesObservable: profileRidesObservable
                    };
                })
            );
    }

    orderRestartRideHandling(rideKey: string, delayFactor: number): void {
        setTimeout(() => {
            console.log('RestartRideHandling ordered', rideKey, delayFactor);
            return this.ridesRef.child(rideKey).child('restartRideHandling').remove()
                .then(() => this.ridesRef.child(rideKey).child('restartRideHandling').set(true));
        }, RIDE_HANDLING_DELAY * delayFactor);
    }

    orderLegacyValidationRestart(rideKey: string): void {
        const data = {
            rideKey: rideKey
        };
        this.afFunctions.httpsCallable('restartLegacyValidation')(data)
            .subscribe();
    }

    getHeatMapDataFromRides(rides: Ride[]): google.maps.LatLng[] {
        const points: google.maps.LatLng[] = <google.maps.LatLng[]>[];
        rides.forEach((ride) => {
            const encodedPolyline = ride.encodedPolyline;
            if (encodedPolyline) {
                const arrayPoints: [number, number][] = polyTool.decode(encodedPolyline, {factor: 1e6});
                arrayPoints.forEach((arrayPoint) => {
                    const lat: number = arrayPoint[1];
                    const lng: number = arrayPoint[0];
                    points.push(new google.maps.LatLng(lat, lng));
                });
            }
        });
        return points;
    }

    getHeatMapDataForLegacyTrail(trail: Trail, firstIndex: number, lastIndex: number):
        Observable<{ endIndex: number, data: google.maps.LatLng[] }> {
        return this.db.list<Ride>(this.ridesRef.ref, ref => ref.orderByChild('trackId').equalTo(trail.key)).valueChanges().pipe(
            take(1),
            map((rideSnapsInDb) => {
                const points: google.maps.LatLng[] = <google.maps.LatLng[]>[];
                const padding = 0.001;
                const n: number = trail.boundsNorth + padding;
                const s: number = trail.boundsSouth - padding;
                const e: number = trail.boundsEast + padding;
                const w: number = trail.boundsWest - padding;
                let lastI: number;
                for (let i = rideSnapsInDb.length - 1 - firstIndex; i > Math.max(-1, rideSnapsInDb.length - 1 - lastIndex); i--) {
                    lastI = i;
                    const encodedPolyline = rideSnapsInDb[i].encodedPolyline;
                    if (encodedPolyline) {
                        const arrayPoints: [number, number][] = polyTool.decode(encodedPolyline, {factor: 1e6});
                        arrayPoints.forEach((arrayPoint) => {
                            const lat: number = arrayPoint[1];
                            const lng: number = arrayPoint[0];
                            if (n > lat && s < lat && e > lng && w < lng) {
                                points.push(new google.maps.LatLng(lat, lng));
                            }
                        });
                    }
                }
                //                console.log('returning', firstIndex, lastIndex, i, rideSnapsInDb.length, points.length);
                return {endIndex: rideSnapsInDb.length - lastI - 1, data: points};
            })
        );
    }

    getProfileRide(profileKey: string, rideKey: string): Observable<ProfileRide> {
        return this.db.object<ProfileRide>(this.profileRidesRef.child(profileKey).child(rideKey).ref).snapshotChanges()
            .pipe(
                map((profileRideSnapshot) => {
                    let profileRide: ProfileRide;
                    let orderCounter = 1;
                    if (!profileRideSnapshot.payload.exists()) {
                        console.log('No profile ride for ride', rideKey, profileKey);
                        profileRide = <ProfileRide>{rideKey: rideKey};
                        this.orderRestartRideHandling(rideKey, orderCounter);
                        orderCounter++;
                    } else {
                        profileRide = profileRideSnapshot.payload.val();
                    }
                    profileRide.profileKey = profileKey;
                    if (typeof profileRide.trailAreas !== 'object' || profileRide.trailAreas === null) {
                        profileRide.trailAreas = [];
                    } else {
                        Object.keys(profileRide.trailAreas).forEach((trailAreaKey) => {
                            profileRide.trailAreas[trailAreaKey] = {
                                trailAreaKey: trailAreaKey,
                                ...profileRide.trailAreas[trailAreaKey]
                            };
                        });
                        profileRide.trailAreas = Object.values(profileRide.trailAreas);
                    }
                    if (typeof profileRide.trails !== 'object' || profileRide.trails === null) {
                        profileRide.trails = [];
                    } else {
                        Object.keys(profileRide.trails).forEach((trailKey) => {
                            profileRide.trails[trailKey] = {
                                trailKey: trailKey,
                                isLegacy: (trailKey === profileRide.legacyTrailKey),
                                ...profileRide.trails[trailKey]
                            };
                        });
                        profileRide.trails = Object.values(profileRide.trails);
                    }
                    if (typeof profileRide.story === 'object' && profileRide.story !== null) {
                        Object.keys(profileRide.story).forEach((chapterIndex) => {
                            if (typeof profileRide.story[chapterIndex].trailKeys !== 'object' ||
                                profileRide.story[chapterIndex].trailKeys === null) {
                                profileRide.story[chapterIndex].trailKeys = [];
                            } else {
                                profileRide.story[chapterIndex].trailKeys = Object.keys(profileRide.story[chapterIndex].trailKeys);
                            }
                        });
                    }
                    return profileRide;
                })
            );
    }

    setAdventureKey(rideKey: string, adventureKey: string): Promise<any> {
        return this.ridesRef.child(rideKey).child('adventureKey').set(adventureKey)
            .then(() => this.orderRestartRideHandling(rideKey, 0));
    }

    private getProfileRidesFromRidesSnapshot(ridesSnapshot: AngularFireAction<DatabaseSnapshot<{}>>[]): Observable<ProfileRide[]> {
        const profileRidesObservables: Observable<ProfileRide>[] = [];
        ridesSnapshot.forEach((rideSnap) => {
            const ride: Ride = <Ride>rideSnap.payload.val();
            if (ride.key !== rideSnap.key) {
                ride.key = rideSnap.key;
                console.log('Key set for :', rideSnap.key);
            }
            if (typeof ride.userId !== 'string') {
                console.error('Fix this rideKey:', rideSnap.key, ride.userId);
                return;
            }
            profileRidesObservables.push(this.getProfileRideFromRide(ride));
        });
        if (profileRidesObservables.length === 0) {
            return of([]);
        }
        return zip(...profileRidesObservables).pipe(
            map((profileRides) => {
                return profileRides.filter((r) => r);
            })
        );
    }

    private getProfileRideFromRide(ride: Ride): Observable<ProfileRide> {
        return this.getProfileRide(ride.userId, ride.key).pipe(
            map(profileRideSnapshot => {
                if (!profileRideSnapshot.startTime) {
                    profileRideSnapshot.startTime = ride.startTime;
                }
                return profileRideSnapshot;
            }));
    }

}
