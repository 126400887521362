import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { DestinationService } from '../../../firebase-services/destination.service';
import { AdventureService } from '../../../firebase-services/adventure.service';

// Interfaces
import { Destination } from '../../../interfaces/destination';
import { Adventure } from '../../../interfaces/adventure';

@Component({
    selector: 'app-destination-adventure-explorers',
    templateUrl: './destination-adventure-explorers.component.html',
    styleUrls: ['./destination-adventure-explorers.component.css']
})
export class DestinationAdventureExplorersComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    destination: Destination = null;
    adventure: Adventure = null;

    constructor(
        private route: ActivatedRoute,
        private destinationService: DestinationService,
        private adventureService: AdventureService
    ) {
    }

    ngOnInit() {
        this.destinationService.getDestination(this.route.snapshot.paramMap.get('destinationKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((destination) => this.destination = destination);
        this.adventureService.getAdventure(this.route.snapshot.paramMap.get('adventureKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventure) => this.adventure = adventure);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
