import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

// Services
import { DawaService } from '../services/dawa.service';
import { GoogleGeocodeService } from '../services/google-geocode.service';

// Interfaces
import { AwardCategory, AwardLog } from '../interfaces/award';
import {Trail, TrailImport, TrailImportGb, TrailTypes} from '../interfaces/trail';

import firebase from 'firebase/compat';

@Injectable({
    providedIn: 'root'
})
export class TrailService {

    trailRef: firebase.database.Reference;
    trailPathTriggerRef: firebase.database.Reference;
    lostTrailsRef: firebase.database.Reference;

    TRAIL_META_LOOKUP_DELAY: 311;

    constructor(
        private db: AngularFireDatabase,
        private storage: AngularFireStorage,
        private geocodeService: GoogleGeocodeService,
        private dawaService: DawaService
    ) {
        this.trailRef = this.db.database.ref('tracks');
        this.trailPathTriggerRef = this.db.database.ref('trailPathModified');
        this.lostTrailsRef = this.db.database.ref('lostTrails');
    }

    private static ensureTrailModelValues(trailVal: any): any {
        if (!trailVal.name) {
            trailVal.name = 'NN';
        }
        // Height at start:
        if (typeof trailVal.startPoint === 'object' && trailVal.startPoint !== null &&
            trailVal.startPoint.latitude && trailVal.startPoint.longitude) {
            let heightAtStart: number = null;
            if (typeof trailVal.startPoint.height === 'number') {
                heightAtStart = trailVal.startPoint.height;
            } else if (typeof trailVal.startPoint.height === 'string') {
                heightAtStart = Number.parseFloat(trailVal.startPoint.height);
            } else if (typeof trailVal.startPoint.height !== 'undefined') {
                console.log('What is height: ', typeof trailVal.startPoint.height, trailVal);
            }
            const startLat = Number.parseFloat(trailVal.startPoint.latitude.toString());
            const startLng = Number.parseFloat(trailVal.startPoint.longitude.toString());
            trailVal.startPoint = {
                latitude: startLat,
                longitude: startLng,
                height: heightAtStart
            };
            if (typeof trailVal.boundsNorth !== 'number') {
                trailVal.boundsNorth = startLat;
                trailVal.boundsSouth = startLat;
                trailVal.boundsEast = startLng;
                trailVal.boundsWest = startLng;
            }
        } else {
            console.error('No start point?', trailVal);
        }

        if (typeof trailVal.stopPoint === 'object' && trailVal.stopPoint !== null &&
            trailVal.stopPoint.latitude && trailVal.stopPoint.longitude) {
            let stopPointHeight = null;
            if (typeof trailVal.stopPoint.height === 'number') {
                stopPointHeight = trailVal.stopPoint.height;
            } else if (typeof trailVal.stopPoint.height === 'string') {
                stopPointHeight = Number.parseFloat(trailVal.stopPoint.height);
            } else if (typeof trailVal.stopPoint.height !== 'undefined') {
                console.log('What is stop-height: ', typeof trailVal.stopPoint.height, trailVal);
            }

            trailVal.stopPoint = {
                latitude: Number.parseFloat(trailVal.stopPoint.latitude.toString()),
                longitude: Number.parseFloat(trailVal.stopPoint.longitude.toString()),
                height: stopPointHeight
            };
        } else {
            console.error('No stop point?', trailVal);
        }

        if (!trailVal.lang) {
            trailVal.lang = {};
        }
        return trailVal;
    }

    private static getTrailFromSnapshot(trailSnap: SnapshotAction<Trail>): Trail {
        if (!trailSnap.payload.exists()) {
            console.error('Couldn\'t load trail', trailSnap.key);
            return null;
        }
        let trailVal = trailSnap.payload.val();
        trailVal = TrailService.ensureTrailModelValues(trailVal);
        return <Trail>{
            key: trailSnap.key,
            ...trailVal
        };
    }

    private static getTrailsFromSnapshot(trailSnapshots: SnapshotAction<Trail>[]): Trail[] {
        const returnTrails = [];
        trailSnapshots.forEach((trailSnapshot) => {
            returnTrails.push(TrailService.getTrailFromSnapshot(trailSnapshot));
        });
        return returnTrails;
    }

    getTrail(trailKey: string): Observable<Trail> {
        return this.db.object<Trail>(this.trailRef.child(trailKey).ref).snapshotChanges()
            .pipe(
                map((trailSnap) => {
                    if (!trailSnap.payload.exists()) {
                        console.error('Trail does not exist anymore', trailKey);
                        return null;
                    }
                    return TrailService.getTrailFromSnapshot(trailSnap);
                })
            );
    }

    getTrails(trailKeys: string[]): Observable<Trail[]> {
        if (trailKeys.length === 0) {
            return of([]);
        }
        const returnTrails: Observable<Trail>[] = [];
        trailKeys.forEach((trailKey) => returnTrails.push(this.getTrail(trailKey)));
        return combineLatest(returnTrails)
            .pipe(
                map((trails) => trails.filter((t) => t))
            );
    }

    getTrailsOnTrailArea(trailAreaKey: string): Observable<Trail[]> {
        return this.db.list<Trail>(`tracks`, ref => ref.orderByChild('trailAreaKey').equalTo(trailAreaKey)).snapshotChanges().pipe(
            map((trailsDataSnapshot) => TrailService.getTrailsFromSnapshot(trailsDataSnapshot))
        );
    }

    /**
     * Loads the trails for the trail area
     */
    getTrailsForCountry(countryCode: string): Observable<Trail[]> {
        this.handleTrailsWithoutCountry();
        return this.db.list<Trail>(`tracks`, ref => ref.orderByChild('countrie').equalTo(countryCode)).snapshotChanges().pipe(
            take(1),
            map((trailsDataSnapshot) => TrailService.getTrailsFromSnapshot(trailsDataSnapshot)),
            map((trails) => {
                return trails.filter((t) => t.active === true);
            })
        );
    }

    applyDanishMetaDataToTrails(trails: Trail[]): void {
        let requestCounter = 0;
        trails.forEach((trail) => {
            if (typeof trail.municipality === 'undefined') {
                if (typeof trail.startPoint !== 'undefined' &&
                    typeof trail.startPoint.longitude === 'number' &&
                    typeof trail.startPoint.latitude === 'number') {
                    return this.applyDanishMetaDataToTrail(trail, requestCounter++);
                }

                console.warn('applyDanishMetaData on trail without start point', trail.key, trail);
                if (typeof trail.encodedPolyline === 'string') {
                    return this.updatePath(trail.key, trail.encodedPolyline);
                }

                console.warn('Moving danish trail to lost trails', trail.key, trail);
                return this.lostTrailsRef.child(trail.key).update(trail)
                    .then(() => this.trailRef.child(trail.key).remove());
            }
        });
    }

    /**
     * Get imported trails
     * @returns {Observable<Trail[]>} An observable with an array of all trails with the importKey defined.
     */
    getImportedTrails(): Observable<Trail[]> {
        return this.db.list<Trail>(`tracks`).snapshotChanges().pipe(
            take(1),
            map(trailSnapshots => {
                const returnTrails = [];
                trailSnapshots.forEach((trailSnapshot) => {
                    if (typeof trailSnapshot.payload.val().importKey !== 'undefined') {
                        returnTrails.push(TrailService.getTrailFromSnapshot(trailSnapshot));
                    }
                });
                console.log(returnTrails)
                return returnTrails;
            })
        );
    }

    setTrailAreaKey(trailKey: string, trailAreaKey: string): Promise<void> {
        return this.trailRef.child(trailKey).child('trailAreaKey').set(trailAreaKey);
    }

    removeTrailAreaKey(trailKey: string): Promise<void> {
        return this.trailRef.child(trailKey).child('trailAreaKey').remove();
    }

    /**
     * Updates the settings of the trail
     */
    updateSettings(trail: Trail): Promise<void> {
        const updateObject: Trail = <Trail>{
            active: trail.active,
            trackType: trail.trackType,
            iconUrl: trail.iconUrl,
            color: trail.color,
            countrie: trail.countrie,
            expirationDate: null
        };
        return this.trailRef.child(trail.key).update(updateObject);
    }

    /**
     * Updates the texts of the trail
     */
    updateTexts(trail: Trail): Promise<void> {
        // Delete texts that are blank
        Object.keys(trail.lang).forEach((lang) => {
            Object.keys(trail.lang[lang]).forEach((textKey) => {
                if (typeof trail.lang[lang][textKey] !== 'string' ||
                    trail.lang[lang][textKey] === '' || trail.lang[lang][textKey] === 'undefined') {
                    trail.lang[lang][textKey] = null;
                }
            });
        });
        return this.trailRef.child(trail.key).update({
            name: trail.name,
            description: trail.description || null,
            status: null,
            lang: trail.lang
        });
    }

    /**
     * @todo - retire and use central file service.
     * Uploads a GPX-file to fire storage and attaches it to the specified trail.
     * @param {Trail} trail The trail that the GPX should be attached to.
     * @param {Blob} fileBlob A blob with the GPX-file contents.
     * @param {string} filename A pseudo filename for the file - used on Cloud Storage.
     * @returns {Promise<string>} A promise of the newGPXFileUploadData-key when the file has been uploaded and the database updated.
     */
    uploadGpx(trail: Trail, fileBlob: Blob, filename: string): Promise<string> {
        const fileUploadedPromise = this.storage.storage.ref().child('newGPXfiles/' + trail.key + '/' + filename).put(fileBlob);
        return fileUploadedPromise
            .then(res => {
                return res.ref.getDownloadURL();
            })
            .then(fileURL => {
                return this.db.list(`/newGPXFileUploadData/${trail.key}`).push({
                    startIndex: 0,
                    stopIndex: 999999,
                    gpxUrl: fileURL
                }).key;
            });
    }

    uploadedImage(trail: Trail): Promise<void> {
        return this.trailRef.child(trail.key).update({
            imageUrl: trail.imageUrl
        });
    }

    /**
     * Creates a trail from a (handmade) trail draft, also generates GPX-file for the trail from its bastard polyline property.
     * @param {TrailImport} trailDraft A (handmade) trail draft.
     * @returns {Promise<Trail>} A promise containing the newly created trail, incl. the new key.
     */
    createTrailFromDraftWithPolyline(trailDraft: TrailImportGb): Promise<TrailImportGb> {
        // Detach bastard polyline property while creating trail
        const polyline = trailDraft['polyline'];
        trailDraft['polyline'] = null;

        const trailCreatedPromise = this.createTrailFromDraft(trailDraft, polyline);

        return trailCreatedPromise
            .then((trail) => {
                // Reattach the bastard polyline property to the newly created trail
                trail['polyline'] = polyline;
                console.log('imported trail: ', trail);
                return trail;
            });
    }

    createNewTrail(newTrail: Trail): string {
        const trailThenableRef = this.trailRef.push(newTrail);
        return trailThenableRef.key;
    }

    updatePath(trailKey: string, encodedPolyline: string): Promise<any> {
        const updateObject: {} = {
            [trailKey]: encodedPolyline
        };
        return this.trailPathTriggerRef.update(updateObject);
    }

    searchByName(searchTerm: string): Observable<Trail[]> {
        return this.db.list<Trail>(`tracks`, ref => ref.orderByChild('name').startAt(searchTerm).endAt(searchTerm + '\uf8ff'))
            .valueChanges();
    }

    updateAward(awardLog: AwardLog, category: AwardCategory): Promise<any> {
        const updateObject: Trail = <Trail>{
            trackType: TrailTypes.COMMON_TRAIL,
            color: category.colorCode,
            iconUrl: category.signUrl,
            awards: {
                [awardLog.awardKey]: awardLog.categoryKey
            }
        };
        return this.trailRef.child(awardLog.trailKey).update(updateObject);
    }

    removeAward(awardKey: string, trailKey: string): Promise<any> {
        const updateObject: {} = {[awardKey]: null};
        return this.trailRef.child(trailKey).child('awards').update(updateObject);
    }

    getTrailIconUrl(trail: Trail, reduceSize: boolean = false): google.maps.Icon | string {
        if (!trail.name) {
            console.error('Trail does not have a name?', trail.key);
            return null;
        }
        if (!trail.color) {
            console.warn('No color set on trail', trail.name, trail.key);
            return 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + trail.name.charAt(0) + '|00FF00';
        }
        const char = trail.name.replace(/[^a-z0-9]/i, '').charAt(0).toUpperCase();
        return {
            url: environment.www + 'img/marker-' + char + '-' + trail.color.substr(1).toUpperCase() + '.svg',
            scaledSize: new google.maps.Size(reduceSize ? 23 : 29, reduceSize ? 24 : 32),
            anchor: new google.maps.Point(reduceSize ? 12 : 15, reduceSize ? 24 : 32),
            size: new google.maps.Size(29, 32)
        };
    }

    getUnlinkedTrailKeys(): Observable<string[]> {
        return this.db.list<Trail>(this.trailRef.ref, ref => ref.orderByChild('trailAreaKey').equalTo(null)).snapshotChanges()
            .pipe(
                map((trailsSnap) => {
                    const trailKeys: string[] = [];
                    trailsSnap.forEach((trailSnap) => {
                        trailKeys.push(trailSnap.key);
                    });
                    return trailKeys;
                })
            );
    }

    private handleTrailsWithoutCountry(): void {
        this.db.list<Trail>(`tracks`, ref => ref.orderByChild('countrie').equalTo(null)).snapshotChanges().pipe(
            take(1),
            switchMap((trailSnapshots) => {
                let delayCounter = 0;
                trailSnapshots.forEach((trailSnapshot) => {
                    const rawTrail: Trail = trailSnapshot.payload.val();
                    if (typeof rawTrail.startPoint === 'undefined') {
                        console.error('Start point not set for trail', rawTrail);
                        if (typeof rawTrail.encodedPolyline === 'string') {
                            return this.updatePath(trailSnapshot.key, rawTrail.encodedPolyline);
                        }

                        console.warn('Moving trail to lost trails', trailSnapshot.key, rawTrail);
                        return this.lostTrailsRef.child(trailSnapshot.key).update(rawTrail)
                            .then(() => {
                                return this.trailRef.child(trailSnapshot.key).remove();
                            });
                    }
                    this.applyGeoMetaToTrail(rawTrail, delayCounter++);
                });
                return of(null);
            })
        ).subscribe(() => null);
    }

    private applyGeoMetaToTrail(rawTrail: Trail, delayCounter: number): void {
        setTimeout(() => {
            if (typeof rawTrail.boundsNorth !== 'number') {
                return;
            }
            console.log('applyGeoMetaToTrail', rawTrail.key, delayCounter);
            const latLng: string = rawTrail.startPoint.latitude + ',' + rawTrail.startPoint.longitude;
            this.geocodeService.lookupLatLng(latLng)
                .subscribe((latLngLookup) => {
                    if (latLngLookup === null) {
                        console.error('Not able to determine country on trail');
                        return;
                    }
                    const updateTrail = <Trail>{
                        countrie: latLngLookup.countryCode
                    };
                    return this.trailRef.child(rawTrail.key).update(updateTrail);
                });
        }, this.TRAIL_META_LOOKUP_DELAY * delayCounter + 31);
    }

    private applyDanishMetaDataToTrail(trail: Trail, delayCounter: number): void {
        setTimeout(() => {
            console.log('applyDanishMetaDataToTrail', trail.key, delayCounter, this.TRAIL_META_LOOKUP_DELAY);
            this.dawaService.lookupAddress(trail.startPoint.latitude, trail.startPoint.longitude)
                .subscribe((addressLookup) => {
                    if (addressLookup === null) {
                        console.error('Error looking up DAWA address', trail);
                        return;
                    }
                    const updateTrail: Trail = <Trail>{
                        municipality: addressLookup.municipalityName,
                        municipalityCode: addressLookup.municipalityCode,
                        region: addressLookup.regionName,
                        regionCode: addressLookup.regionCode,
                        postalCode: addressLookup.postalCode,
                        city: addressLookup.city
                    };

                    console.log(trail.key, updateTrail);
                    return this.trailRef.child(trail.key).update(updateTrail);
                });
        }, this.TRAIL_META_LOOKUP_DELAY * delayCounter + 17);
    }

    /**
     * Generates a GPX-file from a polyline and adds it the given trail.
     * @param {Trail} trail The trail to attach the GPX to.
     * @param {Array<any>} polyline An array of path coordinates.
     * @returns {Promise<void>} A promise when the file has been uploaded and attached to the trail.
     */
    private generateGPXFileFromPolyline(trail: Trail, polyline: Array<any>): Promise<string> {
        let gpxString = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>\n<gpx xmlns="https://www.topografix.com/GPX/1/1" creator="Mountainbike United GPX Creator" version="1.0" xmlns:xsi="https://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="https://www.topografix.com/GPX/1/1/gpx.xsd">\n<trk>\n<name>${trail.name}</name>\n<trkseg>\n`;
        for (let m = 0; m < polyline.length; m++) {
            gpxString += `<trkpt lat="${polyline[m][1]}" lon="${polyline[m][0]}">\n</trkpt>\n`;
        }
        gpxString += `</trkseg>\n</trk>\n</gpx>`;
        const blob = new Blob([gpxString], {type: 'application/gpx+xml'});
        return this.uploadGpx(trail, blob, trail.key + '.gpx');
    }

    /**
     * Creates a trail from a (handmade) trail draft, also generates GPX-file for the trail.
     * @param {Trail} trailDraft A (handmade) trail draft.
     * @param {any[]} polyline An array of path coordinates.
     * @returns {Promise<TrailImport>} A promise containing the newly created trail, incl. the new key.
     */
    private createTrailFromDraft(trailDraft: TrailImportGb, polyline: any[]): Promise<TrailImportGb> {
        trailDraft = <TrailImportGb>TrailService.ensureTrailModelValues(trailDraft);
        const trailCreatedThenableReference = this.db.list(this.trailRef.ref).push(trailDraft);
        const gpxFileCreatedPromise = trailCreatedThenableReference
            .then(() => {
                trailDraft.key = trailCreatedThenableReference.key;
                return this.generateGPXFileFromPolyline(trailDraft, polyline);
            });
        return gpxFileCreatedPromise.then(() => trailDraft);
    }

}
