import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { open, Openable, Source } from 'shapefile';

// Services
import { FileService } from '../../../services/file.service';
import { MediaLibraryService } from '../../../firebase-services/media-library.service';

// Interfaces
import { DefaultMapProp } from '../../../interfaces/map';

@Component({
    selector: 'app-shapefile-import',
    templateUrl: './shapefile-import.component.html',
    styleUrls: ['./shapefile-import.component.css']
})
export class ShapefileImportComponent implements OnInit {
    contentPageId = 'shapefileImport';
    @ViewChild('gmap') outputMapElement: ElementRef;

    loadingShapefile = false;
    shapefileName: string;
    dBaseFileName: string;
    flagIconUrl$: Observable<string>;
    flagIconUrl: string;

    private featureCounter: number;
    private shapefileStream: Openable;
    private dBaseStream: Openable;
    private map: google.maps.Map = null;
    private infoWindow: google.maps.InfoWindow = null;

    constructor(
        private fileService: FileService,
        private mediaLibraryService: MediaLibraryService
    ) {
        this.flagIconUrl$ = this.mediaLibraryService.getMediaIconUrlFromName('Flag')
            .pipe(take(1));
    }

    ngOnInit(): void {
        this.flagIconUrl$.subscribe((url) => this.flagIconUrl = url);
    }

    onShapefileSelected(event: any): void {
        const filesEvent: JQuery.ChangeEvent = event; // Manual conversion from Event to JQuery.ChangeEvent. Why must I do this?
        const shapefile = this.fileService.onFileSelected(filesEvent.target.files, 'x-gis/x-shapefile');
        if (shapefile !== null) {
            if (shapefile.name.endsWith('.shp')) {
                this.shapefileName = shapefile.name;
                this.shapefileStream = <Openable>shapefile.stream();
                if (this.dBaseStream) {
                    this.openShapefile();
                }
            }
        }
    }

    resetShapefile(): void {
        delete this.shapefileName;
        delete this.shapefileStream;
    }

    onDBaseFileSelected(files: FileList): void {
        const dBaseFile: File = this.fileService.onFileSelected(files, 'application/dbf');
        if (dBaseFile !== null) {
            if (dBaseFile.name.endsWith('.dbf')) {
                this.dBaseFileName = dBaseFile.name;
                this.dBaseStream = <Openable>dBaseFile.stream();
                if (this.shapefileStream) {
                    this.openShapefile();
                }
            }
        }
    }

    resetDBaseFile(): void {
        delete this.dBaseFileName;
        delete this.dBaseStream;
    }

    private openShapefile() {
        this.loadingShapefile = true;
        open(this.shapefileStream, this.dBaseStream)
            .then((src) => {
                this.initMap({
                    east: src.bbox[2],
                    north: src.bbox[3],
                    west: src.bbox[0],
                    south: src.bbox[1]
                });
                this.featureCounter = 0;
                this.parseFeature(src);
            })
            .catch((err) => console.error(err));
    }


    private parseFeature(source: Source<any>) {
        source.read()
            .then((result) => {
                if (result.done) {
                    console.log('Total features in ', this.shapefileName, this.featureCounter);
                    this.loadingShapefile = false;
                    return;
                }
                this.featureCounter++;
                switch (result.value.geometry.type) {
                    case 'MultiPolygon':
                        const polygonsPaths: google.maps.LatLngLiteral[][] = [];
                        result.value.geometry.coordinates.forEach((pathArray) => {
                            pathArray.forEach((coordinates) => {
                                const subPath: google.maps.LatLngLiteral[] = [];
                                coordinates.forEach((coords) => subPath.push({lat: coords[1], lng: coords[0]}));
                                polygonsPaths.push(subPath);
                            });
                        });
                        const polygons: google.maps.Polygon = new google.maps.Polygon({
                            paths: polygonsPaths,
                            map: this.map
                        });

                        const polygonsAnchor: google.maps.Marker = new google.maps.Marker({
                            map: this.map,
                            position: polygonsPaths[0][0],
                            visible: false
                        });

                        this.addInfoWindowListener(polygons, polygonsAnchor, 'Areas', result.value.properties);
                        break;

                    case 'Polygon':
                        const polygonPaths: google.maps.LatLngLiteral[][] = [];
                        result.value.geometry.coordinates.forEach((coordinates) => {
                            const subPath: google.maps.LatLngLiteral[] = [];
                            coordinates.forEach((coords) => subPath.push({lat: coords[1], lng: coords[0]}));
                            polygonPaths.push(subPath);
                        });
                        const polygon: google.maps.Polygon = new google.maps.Polygon({
                            paths: polygonPaths,
                            map: this.map
                        });

                        const polygonAnchor: google.maps.Marker = new google.maps.Marker({
                            map: this.map,
                            position: polygonPaths[0][0],
                            visible: false
                        });

                        this.addInfoWindowListener(polygon, polygonAnchor, 'Area', result.value.properties);
                        break;

                    case 'LineString':
                        const path: google.maps.LatLngLiteral[] = [];
                        result.value.geometry.coordinates.forEach((coords) => path.push({lat: coords[1], lng: coords[0]}));
                        const defaultPathColour = 'gray';
                        let pathColour = defaultPathColour;
                        if (result.value.properties['Kleur rout']) {
                            switch (result.value.properties['Kleur rout']) {
                                case 'Blauw':
                                    pathColour = 'blue';
                                    break;
                                case 'Bruin':
                                    pathColour = 'brown';
                                    break;
                                case 'Geel':
                                    pathColour = 'yellow';
                                    break;
                                case 'Groen':
                                    pathColour = 'blue';
                                    break;
                                case 'Kids':
                                    pathColour = 'cyan';
                                    break;
                                case 'Oranje':
                                    pathColour = 'orange';
                                    break;
                                case 'Paars':
                                    pathColour = 'purple';
                                    break;
                                case 'Rood':
                                    pathColour = 'red';
                                    break;
                                case 'Wit':
                                    pathColour = 'white';
                                    break;
                                case 'Zwart':
                                    pathColour = 'black';
                                    break;
                                default:
                                    console.log('Unhandled Dutch colour', result.value.properties['Kleur rout']);
                            }
                        }
                        if (pathColour !== defaultPathColour) {
                            delete result.value.properties['Kleur rout'];
                        }
                        let pathWeight = 4;
                        let pathOpacity = 1;
                        if (result.value.properties['Status'] === 'Concept') {
                            pathWeight = 3;
                            pathOpacity = 0.7;
                        } else if (result.value.properties['Status'] === 'Ontwikkeling') {
                            pathWeight = 3;
                            pathOpacity = 0.9;
                        }

                        const polyline: google.maps.Polyline = new google.maps.Polyline({
                            path: path,
                            map: this.map,
                            strokeColor: pathColour,
                            strokeWeight: pathWeight,
                            strokeOpacity: pathOpacity
                        });

                        const polylineAnchor: google.maps.Marker = new google.maps.Marker({
                            map: this.map,
                            position: path[0],
                            visible: false
                        });

                        this.addInfoWindowListener(polyline, polylineAnchor, 'Path', result.value.properties);
                        break;

                    case 'Point':
                        const point: google.maps.LatLngLiteral = {
                            lat: result.value.geometry.coordinates[1],
                            lng: result.value.geometry.coordinates[0]
                        };
                        const iconSize = 18;
                        const icon: google.maps.Icon = {
                            anchor: new google.maps.Point(iconSize / 2, iconSize),
                            scaledSize: new google.maps.Size(iconSize, iconSize),
                            url: this.flagIconUrl
                        };
                        const pointMarker: google.maps.Marker = new google.maps.Marker({
                            icon: icon,
                            map: this.map,
                            position: point,
                            opacity: 0.8,
                            draggable: true,
                            clickable: true
                        });

                        this.addInfoWindowListener(pointMarker, pointMarker, 'Marker', result.value.properties);
                        break;

                    default:
                        console.log('Unhandled geometry type', result.value.geometry.type);
                }

                return this.parseFeature(source);
            });
    }

    private addInfoWindowListener(
        mapElement: google.maps.Polyline | google.maps.Marker, anchor: google.maps.Marker, type: string, properties: { [p: string]: any }
    ) {
        const nameArray = [];
        if (properties['Routenaam']) {
            nameArray.push(properties['Routenaam']);
            delete properties['Routenaam'];
        }
        if (properties['Naam']) {
            nameArray.push(properties['Naam']);
            delete properties['Naam'];
        }
        if (properties['Segment na']) {
            nameArray.push(properties['Segment na']);
            delete properties['Segment na'];
        }
        if (properties['Name']) {
            nameArray.push(properties['Name']);
            delete properties['Name'];
        }
        const noTimestamp = -2211757200000;
        if (properties['timestamp'] && typeof properties['timestamp'] === 'object' && properties['timestamp'].getTime() === noTimestamp) {
            properties['timestamp'] = null;
        }
        if (properties['begin'] && typeof properties['begin'] === 'object' && properties['begin'].getTime() === noTimestamp) {
            properties['begin'] = null;
        }
        if (properties['end'] && typeof properties['end'] === 'object' && properties['end'].getTime() === noTimestamp) {
            properties['end'] = null;
        }
        let length: string;
        if (properties['length']) {
            length = (Math.floor(properties['length']) / 1000).toString();
            delete properties['length'];
        }
        let status: string;
        if (properties['Status'] === 'Definitief') {
            status = 'Final';
            delete properties['Status'];
        } else if (properties['Status'] === 'Ontwikkeling') {
            status = 'Under development';
            delete properties['Status'];
        } else if (properties['Status'] === 'Concept') {
            status = 'Draft';
            delete properties['Status'];
        } else if (properties['Status']) {
            console.log('Unhandled Dutch status', properties['Status']);
        }
        mapElement.addListener('click', () => {
            let content = '<strong>' + type + ': ' + nameArray.join(', ') + '</strong>';
            if (status) {
                content += '<div>' + type + ' status: ' + status + '</div>';
            }
            if (length) {
                content += '<div>' + type + ' length: ' + length + ' km</div>';
            }
            Object.entries(properties).forEach(([key, val]) => {
                if (val !== null) {
                    content += '<div>' + key + ': ' + val + '</div>';
                }
            });
            content += '<div>--- Unknowns ---</div>';
            Object.entries(properties).forEach(([key, val]) => {
                if (val === null) {
                    content += '<div>' + key + '</div>';
                }
            });
            this.infoWindow.setContent(content);
            this.infoWindow.open({
                anchor: anchor
            });
        });
    }

    private initMap(bounds: google.maps.LatLngBoundsLiteral): void {
        if (this.map === null) {
            this.map = new google.maps.Map(this.outputMapElement.nativeElement, DefaultMapProp);
            this.map.fitBounds(bounds, 1);

            // Info window
            this.infoWindow = new google.maps.InfoWindow({
                disableAutoPan: false,
                zIndex: 10001,
                content: 'blank'
            });
        }
    }

}
