import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Extends
import { PublishableComponent } from '../../../../base/publishable/publishable.component';

// Services
import { LayoutService } from '../../../../core/layout.service';
import { FirebaseObjectService } from '../../../../services/firebase-object.service';
import { MediaLibraryService } from '../../../../firebase-services/media-library.service';
import { PoiCategoryService } from '../../../../firebase-services/poi-category.service';

// Interfaces
import { PublishedStates } from '../../../../interfaces/general';
import { DefaultImage, MediaIcon } from '../../../../interfaces/media-library';
import { PoiCategory, PoiCategoryBase, PublicPoiCategory, PublicPoiSubCategory } from '../../../../interfaces/poi-category';
import { TextModelItem, TextModelItemType } from '../../../../interfaces/text-model';
import { PoiCategoryStateService } from '../../../../services/poi-category-state.service';

@Component({
    selector: 'app-edit-poi-category',
    templateUrl: './edit-poi-category.component.html',
    styleUrls: ['./edit-poi-category.component.css']
})
export class EditPoiCategoryComponent extends PublishableComponent implements OnInit {
    poiCategoryLabel: string;
    poiCategory: PoiCategory;
    publicPoiCategory: PublicPoiCategory;

    defaultImage: DefaultImage;
    subCategoryDefaultImages: { [label: string]: DefaultImage } = {};
    private defaultImages$: Observable<DefaultImage[]>;
    defaultImages: DefaultImage[];

    mediaIcon: MediaIcon;
    private mediaIcons$: Observable<MediaIcon[]>;
    mediaIcons: MediaIcon[];

    constructor(
        private route: ActivatedRoute,
        public layout: LayoutService,
        private mediaLibraryService: MediaLibraryService,
        private poiCategoryService: PoiCategoryService
    ) {
        super();
        this.contentPageId = 'editPoiCategory';
        this.mediaIcons$ = this.mediaLibraryService.getMediaIcons()
            .pipe(takeUntil(this.destroy$));
        this.defaultImages$ = this.mediaLibraryService.getDefaultImages()
            .pipe(takeUntil(this.destroy$));
    }

    ngOnInit(): void {
        this.poiCategoryLabel = this.route.snapshot.paramMap.get('poiCategoryLabel');
        combineLatest([this.mediaIcons$, this.defaultImages$])
            .subscribe(([mediaIcons, defaultImages]) => {
                this.mediaIcons = mediaIcons;
                this.defaultImages = defaultImages;
                this.loadPoiCategory();
            });
    }

    handlePoiCategoryAltered() {
        this.clearSupportVars(this.poiCategory);
        this.state.altered = JSON.stringify(FirebaseObjectService.prepareSetObject(this.poiCategory)) !== this.originalAsString;
        this.setSupportVars(this.poiCategory);
        PoiCategoryStateService.updateState<PoiCategory, PublicPoiCategory>(this.poiCategory, this.publicPoiCategory, this.state);
    }

    handleMediaIconUpdated(markAltered = true) {
        if (this.poiCategory.mediaIconKey) {
            this.mediaIcons.forEach((mediaIcon) => {
                if (mediaIcon.key === this.poiCategory.mediaIconKey) {
                    this.mediaIcon = mediaIcon;
                }
            });
            this.poiCategory.mediaIconUrl = this.mediaIcon.pngUrl;
        } else {
            delete this.poiCategory.mediaIconKey;
            delete this.poiCategory.mediaIconUrl;
            this.mediaIcon = null;
        }

        if (markAltered) {
            this.handlePoiCategoryAltered();
        }
    }

    handleDefaultImageUpdated(markAltered = true) {
        if (this.poiCategory.mediaDefaultImageKey) {
            for (const defaultImage of this.defaultImages) {
                if (defaultImage.key === this.poiCategory.mediaDefaultImageKey) {
                    this.defaultImage = defaultImage;
                    break;
                }
            }
            this.poiCategory.mediaDefaultImageUrl = this.defaultImage.pngUrl;
        } else {
            delete this.poiCategory.mediaDefaultImageKey;
            delete this.poiCategory.mediaDefaultImageUrl;
            this.defaultImage = null;
        }

        if (this.poiCategory.subCategories) {
            Object.keys(this.poiCategory.subCategories).forEach((subLabel) => {
                if (this.poiCategory.subCategories[subLabel].mediaDefaultImageKey) {
                    for (const defaultImage of this.defaultImages) {
                        if (defaultImage.key === this.poiCategory.subCategories[subLabel].mediaDefaultImageKey) {
                            this.subCategoryDefaultImages[subLabel] = defaultImage;
                            break;
                        }
                    }
                    this.poiCategory.subCategories[subLabel].mediaDefaultImageUrl = this.subCategoryDefaultImages[subLabel].pngUrl;
                } else {
                    delete this.poiCategory.subCategories[subLabel].mediaDefaultImageKey;
                    delete this.poiCategory.subCategories[subLabel].mediaDefaultImageUrl;
                    this.subCategoryDefaultImages[subLabel] = null;
                }
            });
        }

        if (markAltered) {
            this.handlePoiCategoryAltered();
        }
    }

    saveDraft(): Promise<any> {
        this.clearSupportVars(this.poiCategory);
        return this.poiCategoryService.savePoiCategoryDraft(this.poiCategoryLabel, this.poiCategory);
    }

    publish(): Promise<any> {
        this.clearSupportVars(this.poiCategory);
        return this.poiCategoryService.publish(this.poiCategoryLabel, this.poiCategory)
            .then(() =>
                PoiCategoryStateService.updateState<PoiCategory, PublicPoiCategory>(this.poiCategory, this.publicPoiCategory, this.state));
    }

    unpublish(): Promise<any> {
        this.clearSupportVars(this.poiCategory);
        return this.poiCategoryService.unpublish(this.poiCategoryLabel)
            .then(() =>
                PoiCategoryStateService.updateState<PoiCategory, PublicPoiCategory>(this.poiCategory, this.publicPoiCategory, this.state));
    }

    subCategoryNotLikePublic(subCategoryKey: string): boolean {
        if (!this.publicPoiCategory) {
            return false;
        }
        const backendValue: boolean = this.poiCategory.subCategories[subCategoryKey].enabled ?? false;
        const publicValue: boolean = (typeof this.publicPoiCategory.subCategories !== 'undefined' &&
            typeof this.publicPoiCategory.subCategories[subCategoryKey] !== 'undefined');

        return (backendValue !== publicValue);
    }

    private loadPoiCategory() {
        const poiCategory$ = this.poiCategoryService.getPoiCategory(this.poiCategoryLabel)
            .pipe(takeUntil(this.destroy$));
        const publicPoiCategory$ = this.poiCategoryService.getPublicPoiCategory(this.poiCategoryLabel)
            .pipe(takeUntil(this.destroy$));
        combineLatest([poiCategory$, publicPoiCategory$])
            .subscribe(([poiCat, publicPoiCat]) => {
                this.poiCategory = poiCat;
                this.publicPoiCategory = publicPoiCat;

                // Secure original string for change detection
                this.originalAsString = JSON.stringify(this.poiCategory);

                // Add support vars to POI category objects
                this.setSupportVars(this.poiCategory);
                if (this.publicPoiCategory) {
                    this.setSupportVars(this.publicPoiCategory);
                }

                this.initTextModel();

                // Update image selections
                this.handleMediaIconUpdated(false);
                this.handleDefaultImageUpdated(false);

                // Set state
                this.state = {
                    altered: false,
                    canPublish: false,
                    icon: 'fas fa-sync-alt fa-spin',
                    isPublished: false,
                    outOfSync: false,
                    progress: PublishedStates.DRAFT_SAVED,
                    theme: 'warning'
                };
                PoiCategoryStateService.updateState<PoiCategory, PublicPoiCategory>(this.poiCategory, this.publicPoiCategory, this.state);
            });
    }

    private initTextModel(): void {
        const orderedTextModel: TextModelItem[] = [];
        const varName = 'name';
        const sort = this.poiCategory.sortOrder * 100;
        orderedTextModel.push({
            name: 'Category: ' + (this.poiCategory.name ?? this.poiCategoryLabel),
            varName: varName,
            help: this.poiCategory.definition,
            placeholder: this.poiCategoryLabel,
            type: TextModelItemType.INPUT,
            sort: sort
        });

        if (this.poiCategory.subCategories) {
            Object.keys(this.poiCategory.subCategories).forEach((subLabel) => {
                orderedTextModel.push({
                    name: 'Sub-category: ' + (this.poiCategory.subCategories[subLabel].name ?? subLabel),
                    varName: subLabel + '_' + varName,
                    help: this.poiCategory.subCategories[subLabel].definition,
                    placeholder: subLabel,
                    type: TextModelItemType.INPUT,
                    sort: this.poiCategory.sortOrder * 100 + this.poiCategory.subCategories[subLabel].sortOrder + 1,
                    ignorePublic: !this.poiCategory.subCategories[subLabel].enabled
                });
            });
        }

        this.textModel = {items: []};
        orderedTextModel.sort((a, b) => a.sort - b.sort);
        orderedTextModel.forEach((textItem) => {
            delete textItem.sort;
            this.textModel.items.push(textItem);
        });
    }

    private clearSupportVars(poiObj: PoiCategory | PublicPoiCategory) {
        // Move texts from support vars to base
        const toName = 'name';
        if (poiObj.subCategories) {
            Object.keys(poiObj.subCategories).forEach((subLabel) => {
                this.moveTextVar(poiObj.subCategories[subLabel], poiObj, toName, subLabel + '_' + toName, true);
            });
        }
    }

    private setSupportVars(poiObj: PoiCategory | PublicPoiCategory) {
        // Move texts from base to support vars
        const fromName = 'name';
        if (poiObj.subCategories) {
            Object.keys(poiObj.subCategories).forEach((subLabel) => {
                this.moveTextVar(poiObj, poiObj.subCategories[subLabel], subLabel + '_' + fromName, fromName);
            });
        }
    }

    private moveTextVar(
        to: PoiCategoryBase | PublicPoiSubCategory, from: PoiCategoryBase | PublicPoiSubCategory,
        toName: string, fromName: string, deleteOrigin = false) {
        if (from[fromName]) {
            to[toName] = from[fromName];
        } else {
            delete to[toName];
        }
        if (deleteOrigin) {
            delete from[fromName];
        }
        if (from.lang) {
            Object.keys(from.lang).forEach((countryCode) => {
                if (from.lang[countryCode][fromName]) {
                    if (!to.lang) {
                        to.lang = {};
                    }
                    if (!to.lang[countryCode]) {
                        to.lang[countryCode] = {};
                    }
                    to.lang[countryCode][toName] = from.lang[countryCode][fromName];
                } else if (to.lang && to.lang[countryCode]) {
                    delete to.lang[countryCode][toName];
                    if (Object.keys(to.lang[countryCode]).length === 0) {
                        delete to.lang[countryCode];
                        if (Object.keys(to.lang).length === 0) {
                            delete to.lang;
                        }
                    }
                }
                if (deleteOrigin) {
                    delete from.lang[countryCode][fromName];
                }
            });
        }
    }

}
