import { Component, AfterViewChecked, Input, EventEmitter, Output } from '@angular/core';
import { take } from 'rxjs/operators';

// Services
import { TrailService } from '../../firebase-services/trail.service';

// Interfaces
import { Trail } from '../../interfaces/trail';

declare var $: any;

@Component({
    selector: 'app-find-trail',
    templateUrl: './find-trail.component.html',
    styleUrls: ['./find-trail.component.css']
})
export class FindTrailComponent implements AfterViewChecked {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Output() trailSelected = new EventEmitter<string>();

    SEARCH_TIMEOUT = 1000;
    searchTimeout: number;
    lastSearchTerm = '';
    disableSearch = false;
    searchInput: HTMLInputElement = null;
    foundTrails: Trail[] = [];

    constructor(
        private trailService: TrailService
    ) {
    }

    ngAfterViewChecked() {
        if (this.searchInput === null) {
            this.searchInput = <HTMLInputElement>document.getElementById('searchForTrails' + this.modalId);
        }
    }

    focusInSearch(): void {
        window.setTimeout(() => this.searchInput.focus(), 500);
    }

    searchForTrails(): void {
        window.clearTimeout(this.searchTimeout);
        const searchTerm: string = this.searchInput.value;
        if (searchTerm === this.lastSearchTerm || searchTerm.length < 3) {
            return;
        }
        this.searchTimeout = window.setTimeout(() => this.startDatabaseSearch(searchTerm), this.SEARCH_TIMEOUT);
    }

    startDatabaseSearch(searchTerm: string): void {
        if (this.disableSearch) {
            return;
        }
        this.disableSearch = true;
        this.lastSearchTerm = searchTerm;
        const trails$ = this.trailService.searchByName(searchTerm);
        trails$
            .pipe(take(1))
            .subscribe((trails) => {
                this.foundTrails = trails;
                this.disableSearch = false;
                window.setTimeout(() => this.searchInput.focus(), 0);
            });
    }

    selectTrail(trailKey: string): void {
        this.trailSelected.emit(trailKey);
        $('#findTrailModal_' + this.modalId).modal('hide');
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }
}
