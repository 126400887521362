<div class="content-wrapper">

    <section class="content-header">
        <h1>
            <i class="far fa-file-alt"></i> OnTrail Application
            <small>Details</small>
        </h1>
        <ol class="breadcrumb">
            <li><i class="fas fa-certificate"></i> OnTrail</li>
            <li><a routerLink="/on-trail/status"><i class="fas fa-sign"></i> Status</a></li>
            <li class="active"><i class="far fa-file-alt"></i> Application</li>
        </ol>
    </section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-lg-4">

                    <div *ngIf="application" class="card card-outline card-danger">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-sign"></i> Application Status</h3>
                        </div>
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ (application.trackName ? application.trackName : (application.trail ? application.trail.name : '*No Name*')) }}</h3>

                            <ul class="list-group list-group-unbordered">
                                <li class="list-group-item">
                                    <b>Application Key</b> <span class="float-right">{{ application.key }}</span>
                                </li>
                                <li *ngIf="application.timestamp" class="list-group-item">
                                    <b>Created</b> <span class="float-right">{{ application.timestamp | date:'dd/MM/yyy HH:mm:ss' }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Status</b>
                                    <div class="btn-group float-right">
                                        <button type="button"
                                                [ngClass]="'btn btn-xs dropdown-toggle btn-' + (application.status === rejected.value ? rejected.theme : (application.status === approved.value ? approved.theme : (application.status === beingProcessed.value ? beingProcessed.theme : applied.theme)))"
                                                data-toggle="dropdown">{{ statusOptions[application.status + 1].text }} <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu" role="menu">
                                            <li *ngFor="let statusOption of statusOptions">
                                                <a *ngIf="statusOption.value !== application.status"
                                                   (click)="updateStatus(application, statusOption.value)">{{ statusOption.text }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-danger" (click)="deleteOnTrailApplication(application)"
                                    [disabled]="(application.trail || (application.status !== applied.value && application.status !== rejected.value))">
                                <i class="far fa-trash-alt"></i> Delete Application
                            </button>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-4">

                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-user"></i> Applicant Info</h3>
                        </div>
                        <div *ngIf="application" class="card-body box-profile">
                            <h3 *ngIf="application.name" class="profile-username text-center">{{ application.name }}</h3>

                            <ul class="list-group list-group-unbordered">
                                <li *ngIf="application.address" class="list-group-item">
                                    <b>Address</b> <span class="float-right">{{ application.address }}</span>
                                </li>
                                <li *ngIf="application.phone" class="list-group-item">
                                    <b>Phone</b>
                                    <span class="float-right">
                                        {{ application.phone }}
                                        <a [href]="'https://www.krak.dk/' + application.phone.replace('+', '%2b') + '/firmaer'" target="krak">
                                            <i class="fas fa-search"></i></a>
                                    </span>
                                </li>
                                <li *ngIf="application.email" class="list-group-item">
                                    <b>E-mail</b> <a class="float-right" href="mailto:{{ application.email }}">{{ application.email }}</a>
                                </li>
                                <li *ngIf="application.club" class="list-group-item">
                                    <b>Club Name</b> <span class="float-right">{{ application.club }}</span>
                                </li>
                                <li *ngIf="application.clubCvr" class="list-group-item">
                                    <b>VAT-number</b>
                                    <span class="float-right">
                                        {{ application.clubCvr }}
                                        <a [href]="'https://datacvr.virk.dk/data/visninger?soeg=' + application.clubCvr" target="virk"><i
                                                class="fas fa-search"></i></a>
                                    </span>
                                </li>
                                <li *ngIf="application.ownerEmail" class="list-group-item">
                                    <b>Owner E-mail</b> <a class="float-right"
                                                           href="mailto:{{ application.ownerEmail }}">{{ application.ownerEmail }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-4">

                    <div class="card card-outline card-purple">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-files-o"></i> Attached Documents</h3>
                        </div>
                        <div *ngIf="application" class="card-body">
                            <div *ngIf="application.note">
                                <label>Note</label>
                                <p>{{ application.note }}</p>
                                <hr>
                            </div>
                            <div *ngIf="application.gpxUrl">
                                <label>GPX</label>
                                <span class="float-right">
                                    <a href="https://ontrail.dk/wp-content/uploads/{{application.gpxUrl}}" target="_blank">
                                        {{ application.gpxUrl }}
                                        <i class="fa fa-external-link"></i>
                                    </a>
                                </span>
                            </div>
                            <div *ngIf="application.contractUrl">
                                <label>Contract</label>
                                <span class="float-right">
                                    <a href="https://ontrail.dk/wp-content/uploads/{{application.contractUrl}}" target="_blank">
                                        {{ application.contractUrl }}
                                        <i class="fa fa-external-link"></i>
                                    </a>
                                </span>
                            </div>
                            <div>
                                <hr>
                                <i>&#64;Todo: Add more documents here</i>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="clearfix visible-md-block visible-lg-block"></div>
                <div class="col-md-6 col-lg-4">

                    <div class="card card-outline card-danger">
                        <div class="card-header">
                            <h3 class="card-title"><i class="far fa-file-alt"></i> Application Trail Description</h3>
                        </div>
                        <div *ngIf="application" class="card-body box-profile">
                            <div *ngIf="application.imgUrl" class="text-center">
                                <img class="trail-img img-fluid" src="https://ontrail.dk/wp-content/uploads/{{ application.imgUrl }}"
                                     [alt]="application.trackName">
                                <small class="text-muted">
                                    <a href="https://ontrail.dk/wp-content/uploads/{{ application.imgUrl }}" target="_blank">Open in new window<i
                                            class="fa fa-external-link"></i></a><br>
                                </small>
                            </div>
                            <h3 *ngIf="application.trackName" class="profile-username text-center">{{ application.trackName }}</h3>
                            <p *ngIf="application.trackHeader" class="text-muted text-center">{{ application.trackHeader }}</p>
                            <ul class="list-group list-group-unbordered">
                                <li *ngIf="application.trackDescription" class="list-group-item">
                                    {{ application.trackDescription }}
                                </li>
                                <li *ngIf="application.trackLocated" class="list-group-item">
                                    <b>Located</b> <span class="float-right">{{ application.trackLocated }}</span>
                                </li>
                                <li *ngIf="application.difficulty" class="list-group-item">
                                    <b>Difficulty</b> <span class="float-right">{{ application.difficulty }}</span>
                                </li>
                                <li *ngIf="application.length" class="list-group-item">
                                    <b>Length</b>&nbsp;<span [class.float-right]="application.length.length < 40">{{ application.length }}</span>
                                </li>
                                <li *ngIf="application.heightMeters" class="list-group-item">
                                    <b>Elevation</b> <span class="float-right">{{ application.heightMeters }}</span>
                                </li>
                                <li *ngIf="application.start" class="list-group-item">
                                    <b>Start Point</b> <span class="float-right">({{ application.start.lat }}, {{ application.start.lon }})</span>
                                </li>
                                <li *ngIf="application.stop" class="list-group-item">
                                    <b>End Point</b> <span class="float-right">({{ application.stop.lat }}, {{ application.stop.lon }})</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-4">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-route"></i> Linked Trail</h3>
                        </div>
                        <div *ngIf="application?.trail" class="card-body">
                            <div *ngIf="application.trail.imageUrl" class="text-center">
                                <img class="trail-img img-fluid" src="{{  application.trail.imageUrl }}" [alt]="application.trail.name">
                                <small class="text-muted">
                                    <a href="{{  application.trail.imageUrl }}" target="_blank">Open in new window<i class="fa fa-external-link"></i></a><br>
                                </small>
                            </div>
                            <h3 *ngIf="application.trail.name" class="profile-username text-center">{{ application.trail.name }}</h3>
                            <!--                            <p *ngIf="application.trail.header" class="text-muted text-center">{{ application.trail.header }}</p>-->
                            <ul class="list-group list-group-unbordered">
                                <li *ngIf="application.trail.description" class="list-group-item">
                                    {{ application.trail.description }}
                                </li>
                                <li class="list-group-item">
                                    <b>Trail Key</b> <span class="float-right">{{ application.trail.key }} <small><a
                                        [href]="webBaseUrl + 'da/tracks/admin-link/' + application.trail.key" target="_blank"><i
                                        class="fa fa-external-link"></i></a></small></span>
                                </li>
                                <li *ngIf="application.trail.iconUrl" class="list-group-item">
                                    <b>Difficulty</b> <span class="float-right"><img width="24" src="{{ application.trail.iconUrl }}"
                                                                                     alt="Difficulty"></span>
                                </li>
                                <li *ngIf="application.trail.lengthInMeters" class="list-group-item">
                                    <b>Length</b> <span class="float-right">{{ application.trail.lengthInMeters }} m</span>
                                </li>
                                <li *ngIf="application.trail.heightIncrease" class="list-group-item">
                                    <b>Elevation</b>
                                    <span class="float-right">
                                        <i class="fas fa-ruler"></i> {{ application.trail.heightIncrease }} m
                                        <span *ngIf="application.trail.heightDecrease"><i
                                                class="fas fa-ruler fa-flip-horizontal"></i> {{ application.trail.heightDecrease }} m</span>
                                    </span>
                                </li>
                                <li *ngIf="application.trail.startPoint" class="list-group-item">
                                    <b>Start Point</b> <span class="float-right">({{ application.trail.startPoint.latitude }}
                                    , {{ application.trail.startPoint.longitude }})</span>
                                </li>
                                <li *ngIf="application.trail.stopPoint" class="list-group-item">
                                    <b>End Point</b> <span class="float-right">({{ application.trail.stopPoint.latitude }}
                                    , {{ application.trail.stopPoint.longitude }})</span>
                                </li>
                                <li *ngIf="trailArea" class="list-group-item">
                                    <b>Trail Area</b>
                                    <div class="float-right">
                                        <a *ngIf="adminOnTrailArea" [routerLink]="'/trail-area/' + trailArea.key">
                                            <i class="far fa-map"></i> {{ trailArea.name }}
                                        </a>
                                        <span *ngIf="!adminOnTrailArea">
                                            <i class="far fa-map"></i> {{ trailArea.name }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                            <div *ngIf="!trailArea" class="callout callout-warning">
                                <h4>Trail not connected to a trail area!</h4>
                                <p>Please contact the developers at <a href="mailto:frederik@mtbutd.com">frederik&#64;mtbutd.com</a> to correct this.</p>
                            </div>
                        </div>
                        <div *ngIf="!application?.trail" class="card-body">
                            <p><i class="fas fa-unlink"></i> This application is not linked to a trail yet.</p>
                            <p><i>&#64;Todo: These two functions would be nice.</i></p>
                            <button class="btn btn-gray" disabled>Create Trail on existing Trail Area<i class="fas fa-route"></i></button>&nbsp;
                            <button class="btn btn-gray" disabled>Create Trail Area with Trail <i class="far fa-map"> + </i><i
                                    class="fas fa-route"></i></button>
                        </div>
                        <div *ngIf="application" class="card-footer">
                            <div *ngIf="application.trail">
                                <button class="btn btn-danger" (click)="unlinkTrailFromOnTrailApplication()"><i class="fas fa-unlink"></i> Unlink
                                    Trail
                                </button>
                                <button *ngIf="countryManager && !adminOnTrailArea && trailArea" class="btn btn-default" (click)="promoteToManager()">
                                    <i class="fa fa-user"></i> Become Manager
                                </button>
                                <button *ngIf="adminOnTrailArea && trailArea" class="btn btn-default"
                                        [routerLink]="'/trail-area/' + trailArea.key + '/trails/' + application.trail.key"><i class="far fa-edit"></i>
                                    Edit Trail
                                </button>
                            </div>
                            <div *ngIf="!application.trail">
                                <app-find-trail modalId="{{ application.key }}" modalTitle="Find trail for {{ application.trackName }}"
                                                (trailSelected)="setTrailOnOnTrailApplication($event, application.key)" btnClassType="primary"><i
                                        class="fas fa-link"></i> Link to existing Trail
                                </app-find-trail>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-4">

                    <div class="card card-outline card-navy">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-certificate"></i> OnTrail Award</h3>
                        </div>
                        <div *ngIf="application" class="card-body">
                            <div *ngIf="!application.trail" class="text-muted">
                                Trail not linked yet.
                            </div>
                            <div *ngIf="application.trail && !(application.trail?.awards?.OnTrail || application.status === approved.value)"
                                 class="text-muted">
                                <span>Application status not approved yet.</span>
                            </div>
                            <div *ngIf="application.trail && (application.trail?.awards?.OnTrail || application.status === approved.value)">
                                <div *ngIf="application.trail?.awards?.OnTrail && onTrailAward">
                                    <img [src]="onTrailAward.logoUrl" class="img-fluid" alt="OnTrail">
                                    <h3>{{ onTrailAward.header }}</h3>
                                    <p>{{ onTrailAward.description }}</p>
                                    <p>
                                        <img [src]="onTrailAward.categories[application.trail.awards.OnTrail].signUrl" height="40"
                                             alt="OnTrail Grading">
                                        {{ onTrailAward.categories[application.trail.awards.OnTrail].name }}
                                        - {{ onTrailAward.categories[application.trail.awards.OnTrail].nickName }}<br>
                                        <i>{{ onTrailAward.categories[application.trail.awards.OnTrail].description }}</i>
                                    </p>
                                </div>
                                <div *ngIf="!application.trail?.awards?.OnTrail">
                                    <h3 class="text-center">OnTrail not awarded yet</h3>
                                </div>
                                <div class="form-group">
                                    <label for="datepicker">Award date</label>
                                    <div class="input-group date">
                                        <div class="input-group-prepend">
                                            <i class="input-group-text fas fa-calendar-day"></i>
                                        </div>
                                        <input [(ngModel)]="formattedDate" class="form-control float-right" id="datepicker"
                                               aria-describedby="datepickerHelp" placeholder="Click to select a date">
                                    </div>
                                    <small class="form-text text-muted" id="datepickerHelp">The registered date OnTrail is awarded.</small>
                                </div>
                                <div *ngIf="awardLog" class="form-group">
                                    <label for="awardCategory">Award Category</label>
                                    <div class="dropdown">
                                        <button class="btn btn-default dropdown-toggle" type="button" id="awardCategory"
                                                aria-describedby="awardCategoryHelp" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            <span *ngIf="awardLog.categoryKey">
                                                <img [src]="onTrailAward.categories[awardLog.categoryKey].signUrl" class="select-image"
                                                     alt="OnTrail Grading">
                                                {{ onTrailAward.categories[awardLog.categoryKey].name }}
                                                ({{ onTrailAward.categories[awardLog.categoryKey].nickName }})
                                            </span>
                                            <span *ngIf="!awardLog.categoryKey">
                                                - - Please choose the correct award category - -
                                            </span>
                                            &nbsp;<span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li *ngFor="let category of awardCategories">
                                                <a (click)="updateAwardCategory(category)">
                                                    <img [src]="category.signUrl" class="select-image" alt="OnTrail Grading"> {{ category.name }}
                                                    - {{ category.nickName }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <small class="form-text text-muted" id="awardCategoryHelp">Select the proper trail difficulty grading.</small>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="application?.trail && (application.trail?.awards?.OnTrail || application.status === approved.value)"
                             class="card-footer">
                            <button class="btn bg-navy" (click)="setAward()"><i class="fas fa-certificate"></i> Award</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
