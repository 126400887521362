import { Observable } from 'rxjs';

import { AreaObject } from './general';

enum DeviceType {
    ANDROID = 'Android',
    IOS = 'iOS'
}

export const RIDE_HANDLING_DELAY = 797;

interface Chapter {
    firstIndex: number;
    lastIndex: number;
    path?: Array<[number, number]>;
    color?: string;
    trailKeys?: Array<string>;
    noHit?: boolean;
}

export interface Ride extends AreaObject {
    OrgTotalDistance?: number;
    key: string;
    avgSpeed: number;
    backendMessage?: string;
    encodedPolyline: string;
    deviceType?: DeviceType;
    gpxUrlTotal: string;
    maxSpeed: number;
    sessionSeconds?: number;
    sessionDistance?: number;
    startTime: number;
    stravaActivityId?: number;
    stopTime: number;
    totalDistance?: number;
    totalSeconds?: number;
    trackId: string;
    userId: string;
    valid?: boolean;
    validatedAt?: number;
    validNew?: boolean;
    error?: string;
    path?: Array<[number, number]>;
    story: Array<Chapter>;
    profileRide?: Observable<ProfileRide>;
    adventureKey?: string;
}

export interface RideData {
    timestamps: number[];
    batteryData: any[];
    gpsData: any[];
    connectivityData: any[];
    tds: any[];
}

interface ProfileRideTrailArea {
    name: string;
    trailAreaKey: string;
    trails: boolean[];
}

interface ProfileRideTrail {
    trailKey: string;
    isLegacy: boolean;
    distance: number;
    time: number;
    imageURL: string;
    name: string;
    percentageVisited: number;
}

export interface ProfileRide {
    algorithmVersion: number;
    encodedPolyline: string;
    imageURL: string;
    legacyTrailKey: string;
    metricsTimestamp: number;
    onTrailDistance: number;
    onTrailTime: number;
    primaryTrailAreaName: string;
    profileKey: string;
    rideKey: string;
    startTime: number;
    story?: Array<Chapter>;
    totalDistance: number;
    totalTime: number;
    trailAreas: Array<ProfileRideTrailArea>;
    trails: Array<ProfileRideTrail>;
}
