<div *ngIf="newsStory" class="container-fluid">
    <div class="row">
        <div class="col-md-8 col-lg-6 offset-md-2 offset-lg-3">

            <div class="card card-outline card-dark">
                <div class="card-header">
                    <h3 class="card-title"><i class="far fa-newspaper"></i> Action</h3>
                </div>
                <div class="card-body">
                    <strong *ngIf="!newsStory.publishedAt"><i class="fa fa-tag mr-2"></i> Draft Created</strong>
                    <strong *ngIf="newsStory.publishedAt && !newsStory.archivedAt"><i class="fa fa-tag mr-2"></i> Story Published</strong>
                    <strong *ngIf="newsStory.archivedAt"><i class="fa fa-tag mr-2"></i> Story Archived</strong>
                    <div>
                        <div>Created: {{ newsStory.createdAt | date:'dd/MM/yyy HH:mm:ss' }}</div>
                        <div>Created by: {{ newsStory.creatorName }}</div>
                        <div *ngIf="newsStory.publishedAt">Published: {{ newsStory.publishedAt | date:'dd/MM/yyy HH:mm:ss' }}</div>
                        <div *ngIf="newsStory.publishedAt">Published by: {{ newsStory.publisherName }}</div>
                        <div *ngIf="newsStory.archivedAt">Archived: {{ newsStory.archivedAt | date:'dd/MM/yyy HH:mm:ss' }}</div>
                        <div *ngIf="newsStory.archivedAt">Archivist: {{ newsStory.archivistName }}</div>
                    </div>

                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between">
                        <button *ngIf="!newsStory.publishedAt" (click)="deleteDraft()" class="btn btn-danger">Delete</button>
                        <button *ngIf="!newsStory.publishedAt" (click)="publish()" [ngClass]="'btn btn-' + layout.getTheme('news')">Publish</button>
                        <button *ngIf="newsStory.publishedAt && !newsStory.archivedAt" (click)="archive()"
                                [ngClass]="'btn btn-' + layout.getTheme('newsArchive')">Archive
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-4">

            <div class="card card-outline card-warning">
                <div class="card-header">
                    <h3 class="card-title"><i class="fas fa-cogs"></i> Settings</h3>
                </div>
                <div class="card-body">
                    <app-alert-info-box infoTrigger="{{ settingsInfo }}">
                        <h4>News story settings updated successfully</h4>
                    </app-alert-info-box>

                    <div class="form-group">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" [disabled]="newsStory.publishedAt > -1" [(ngModel)]="enableInteractions"
                                   (change)="settingsChanged()" id="disableInteractions" class="custom-control-input"
                                   aria-describedby="disableInteractionsHelp">
                            <label class="custom-control-label" for="disableInteractions">Comments & Likes enabled</label>
                        </div>
                        <small class="form-text text-muted" id="disableInteractionsHelp">By turning the above switch on you will enable user
                            interactions like comments and likes on the news story.</small>
                    </div>

                </div>
                <div class="card-footer" *ngIf="!newsStory.publishedAt">
                    <button [disabled]="disableSettings" (click)="setSettings()" class="btn btn-primary">Save setting</button>
                </div>
            </div>


            <app-translatable-texts readonly="{{ newsStory.publishedAt > 0 }}" textsName="News Story" infoTrigger="{{ textsChangedInfo }}"
                                    [textModel]="textModel" [textObject]="newsStory"
                                    (alteredTextObject)="onAlteredTextObject($event)"></app-translatable-texts>

        </div>
        <div class="col-md-6 col-lg-4">

            <div *ngIf="!hideTrailsBox" class="card card-outline card-info">
                <div class="card-header">
                    <h3 class="card-title"><i class="far fa-map"></i> Target trails</h3>
                </div>
                <div class="card-body">
                    <app-alert-info-box infoTrigger="{{ trailsSetInfo }}">
                        <h4>Target trails set successfully</h4>
                    </app-alert-info-box>
                    <div class="form-group">
                        <label for="allTrails">
                            <input type="checkbox" [disabled]="newsStory.publishedAt > -1" [(ngModel)]="allTrails" (change)="trailsChanged()"
                                   id="allTrails" aria-describedby="allTrailsHelp"> All trails
                        </label>
                        <small id="allTrailsHelp" class="form-text text-muted">Target all trails in {{ _trailArea.name }}.</small>
                    </div>
                    <div *ngIf="allTrails === false" class="form-group">
                        <label for="someTrails">Select specific trails</label>
                        <select id="someTrails" aria-describedby="someTrailsHelp" [disabled]="newsStory.publishedAt > -1"
                                [(ngModel)]="selectedTrailKeys" (change)="trailsChanged()" class="form-control" multiple="multiple">
                            <option *ngFor="let trail of trails" [value]="trail.key">{{ trail.name }}</option>
                        </select>
                        <small id="someTrailsHelp" class="form-text text-muted">Select specific trails in {{ _trailArea.name }} to target with the
                            news.</small>
                    </div>
                </div>
                <div class="card-footer" [class.hidden]="newsStory.publishedAt">
                    <button [disabled]="disableSetTrails" (click)="setTrails()" class="btn btn-primary">Set target trails</button>
                </div>
            </div>

        </div>
        <div class="col-md-6 col-lg-4">

            <app-image-handler [readonly]="newsStory.publishedAt > 0" *ngFor="let imageUrl of imageUrls; let i = index" imageName="News Story Image"
                               [imageIndex]="i" [imageUrl]="imageUrls[i]" [imagePath]="'newsStoryImages/' + newsStory.key + '/'"
                               (uploadedImageUrl)="onUploadedImageUrl(i, $event)"></app-image-handler>
            <app-image-handler *ngIf="!newsStory.publishedAt" nextImage="true" imageName="News Story Image" [imageIndex]="imageUrls.length"
                               [imagePath]="'newsStoryImages/' + newsStory.key + '/'"
                               (uploadedImageUrl)="onUploadedImageUrl(imageUrls.length, $event)"></app-image-handler>

        </div>
    </div>
</div>
