import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../core/auth.service';

// Interfaces
import { TrailArea } from '../../interfaces/trailArea';
import { NewsStory } from '../../interfaces/news-story';

@Component({
    selector: 'app-trail-area-news',
    templateUrl: './trail-area-news.component.html',
    styleUrls: ['./trail-area-news.component.css']
})
export class TrailAreaNewsComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    area: TrailArea = null;
    newNewsStory: NewsStory = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private init(): void {
        this.area = null;
        let trailAreaKey = this.route.snapshot.paramMap.get('trailAreaKey');
        this.newNewsStory = <NewsStory>{
            trailAreaKey: trailAreaKey
        };
        this.area = this.authService.getTrailArea(trailAreaKey);
    }
}
