import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';

import firebase from 'firebase/compat';

@Injectable({
    providedIn: 'root'
})
export class CollectionService {
    updatedCollectionRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
    ) {
        this.updatedCollectionRef = this.db.database.ref('updates').child('rideTheWorld');
    }

    getCollectionUpdated(collectionKey: string): Observable<number> {
        return this.db.object<number>(this.updatedCollectionRef.child(collectionKey).ref).valueChanges();
    }

}
