import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Services
import { TrailService } from './trail.service';

// Interfaces
import { Award, AwardCategory, AwardLog } from '../interfaces/award';

@Injectable({
    providedIn: 'root'
})
export class AwardsService {
    awardsRef: firebase.database.Reference;
    awardLogsRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private trailService: TrailService
    ) {
        this.awardsRef = this.db.database.ref('awards');
        this.awardLogsRef = this.db.database.ref('awardLogs');
    }

    getAwardFromKey(awardKey: string): Observable<Award> {
        return this.db.object<Award>(this.awardsRef.child(awardKey)).valueChanges();
    }

    loadTrailAwardLog(awardKey: string, trailKey: string): Observable<AwardLog> {
        return this.db.list<AwardLog>(this.awardLogsRef.child(awardKey).child(trailKey), ref => ref.orderByKey().limitToLast(1))
            .valueChanges()
            .pipe(
                map((awardLogs) => awardLogs.pop())
            );
    }

    setAward(awardLog: AwardLog, category: AwardCategory): Promise<any> {
        return this.awardLogsRef.child(awardLog.awardKey).child(awardLog.trailKey).child(Date.now().toString()).set(awardLog)
            .then(() => this.trailService.updateAward(awardLog, category));
    }

}
