import { Pipe, PipeTransform } from '@angular/core';
import { PublicPoiSubCategory } from '../interfaces/poi-category';
import { KeyValueBasePipe } from './key-value-base.pipe';

interface KeyPublicPoiSubCategory extends PublicPoiSubCategory {
    key: string;
}

@Pipe({
    name: 'keyValuePublicPoiSubCategory'
})
export class KeyValuePublicPoiSubCategoryPipe extends KeyValueBasePipe implements PipeTransform {

    transform(input: { [p: string]: PublicPoiSubCategory }): KeyPublicPoiSubCategory[] {
        return <KeyPublicPoiSubCategory[]>super.transform(input);
    }

}
