<div class="content-wrapper">
    <app-content-header-section [contentPageId]="contentPageId" [pageParams]="{}"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6 col-xl-4">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-file-upload mr-2"></i> Load Shapefile and dBase-file</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label><i class="fas fa-database mr-2"></i> Select dBase-file</label>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <!--suppress TypeScriptUnresolvedVariable -->
                                        <input type="file" class="custom-file-input" (change)="onDBaseFileSelected($event.target.files)"
                                               id="dBaseInput" [disabled]="dBaseFileName">
                                        <label class="custom-file-label" for="dBaseInput">{{ dBaseFileName ?? 'Choose dBase file (*.dbf)' }}</label>
                                    </div>
                                    <div class="input-group-append" *ngIf="dBaseFileName">
                                        <button class="btn btn-secondary" (click)="resetDBaseFile()">Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label><i class="fas fa-shapes mr-2"></i> Select shapefile</label>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" (change)="onShapefileSelected($event)" id="shapefileInput"
                                               [disabled]="shapefileName">
                                        <label class="custom-file-label"
                                               for="shapefileInput">{{ shapefileName ?? 'Choose shapefile (*.shp)' }}</label>
                                    </div>
                                    <div class="input-group-append" *ngIf="shapefileName">
                                        <button class="btn btn-secondary" (click)="resetShapefile()">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay" *ngIf="loadingShapefile">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-xl-8">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-draw-polygon mr-2"></i> Shapefile on map</h3>
                        </div>
                        <div class="card-body">
                            <div #gmap id="gmap"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
