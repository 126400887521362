import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../core/layout.service';
import { AuthService } from '../../core/auth.service';
import { TrailService } from '../../firebase-services/trail.service';
import { NewsStoryService } from '../../firebase-services/news-story.service';

// Interfaces
import { Trail } from '../../interfaces/trail';
import { TextModel, TextModelItemType, TextObject } from '../../interfaces/text-model';
import { NewsStory } from '../../interfaces/news-story';
import { TrailArea } from '../../interfaces/trailArea';

@Component({
    selector: 'app-news-edit',
    templateUrl: './news-edit.component.html',
    styleUrls: ['./news-edit.component.css']
})
export class NewsEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    @Input() newsStoryKey: string;

    @Input() set trailArea(trailArea: TrailArea) {
        this._trailArea = trailArea;
        this._roomKey = trailArea.key;
        this.hideTrailsBox = false;
        this.applyToTrailArea();
    }

    _trailArea: TrailArea = null;

    @Input() set roomName(roomName: string) {
        this._roomKey = roomName;
    }

    _roomKey: string = null;

    settingsInfo = 0;
    disableSettings = true;
    enableInteractions = false;

    textModel: TextModel = {items: []};
    textsChangedInfo = 0;

    newsStory: NewsStory = null;

    hideTrailsBox = true;
    allTrails = true;
    trails: Trail[] = [];
    selectedTrailKeys: string[] = [];
    trailsSetInfo = 0;
    disableSetTrails = true;

    imageUrls: string[] = null;

    constructor(
        public layout: LayoutService,
        private authService: AuthService,
        private trailService: TrailService,
        private newsStoryService: NewsStoryService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.initTextModel();
        this.getNewsStory();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getNewsStory(): void {
        this.newsStoryService.getNewsStoryFromKey(this._roomKey, this.newsStoryKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((newsStory) => {
                this.enableInteractions = (newsStory.disableInteractions === false);
                if (typeof newsStory.trails === 'object') {
                    this.allTrails = false;
                    this.selectedTrailKeys = [...newsStory.trails];
                }
                this.imageUrls = [];
                if (typeof newsStory.content === 'object') {
                    newsStory.content.forEach((content) => {
                        if (content.type === 'image') {
                            this.imageUrls.push(content.url);
                        }
                    });
                }
                this.newsStory = newsStory;
            });
    }

    applyToTrailArea(): void {
        this.trailService.getTrailsOnTrailArea(this._trailArea.key)
            .pipe(takeUntil(this.destroy$))
            .subscribe((trails) => this.trails = trails.filter((trail) => trail.active));
    }

    settingsChanged(): void {
        this.disableSettings = false;
    }

    setSettings(): void {
        this.disableSettings = true;
        this.newsStory.disableInteractions = (this.enableInteractions === false);
        this.newsStoryService.updateSettings(this._roomKey, this.newsStory)
            .then(() => this.settingsInfo++)
            .catch((err) => console.error('Settings error occurred:', err.message));
    }

    initTextModel(): void {
        this.textModel.items.push({
            name: 'Title',
            varName: 'title',
            help: 'The title of the news story.',
            placeholder: 'Title of news story',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'description',
            help: 'Your news story text.',
            placeholder: 'New cool feature, trail closed sunday, trail building tuesday...',
            type: TextModelItemType.TEXT_AREA
        });
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.newsStoryService.updateTexts(this._roomKey, <NewsStory>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    /**
     * Set trails functions
     */
    trailsChanged(): void {
        this.disableSetTrails = false;
    }

    setTrails(): void {
        this.disableSetTrails = true;
        if (this.allTrails) {
            this.selectedTrailKeys = null;
        }
        console.log(this.allTrails, this.selectedTrailKeys);
        this.newsStoryService.setTrails(this.newsStory, this.selectedTrailKeys)
            .then((newsStory) => {
                this.newsStory = newsStory;
                this.trailsSetInfo++;
            })
            .catch((err) => console.error('Set trail update error occurred:', err));
    }

    onUploadedImageUrl(index: number, uploadedImageUrl: string): void {
        this.newsStoryService.uploadedImage(this.newsStory, this._roomKey, index, uploadedImageUrl)
            .then((newsStory) => this.newsStory = newsStory)
            .catch((err) => console.error('Image upload failure', err));
    }

    deleteDraft(): void {
        if (confirm('Are you sure, you want to delete this draft?')) {
            this.destroy$.next(true);
            this.newsStoryService.deleteDraft(this.newsStory, this._roomKey)
                .then(() => {
                    return this.router.navigate(['..'], {relativeTo: this.activatedRoute});
                })
                .catch((err) => console.error('News story delete error', err));
        }
    }

    publish(): void {
        if (confirm('Are you sure, you want to publish now?')) {
            this.newsStoryService.publish(this.newsStory, this._roomKey, this.authService.user)
                .then(() => {
                    return this.router.navigate(['../../stories/' + this.newsStoryKey], {relativeTo: this.activatedRoute});
                })
                .catch((err) => console.error('News story publish error', err));
        }
    }

    archive(): void {
        if (confirm('Remove story from all feeds and archive it?')) {
            this.newsStoryService.archiveStory(this.newsStory, this._roomKey, this.authService.user)
                .then(() => {
                    return this.router.navigate(['../../archive'], {relativeTo: this.activatedRoute});
                })
                .catch((err) => {
                    window.alert(err);
                    console.error('News story archive error', err);
                });
        }
    }
}
