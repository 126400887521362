import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { DestinationService } from '../../../firebase-services/destination.service';
import { AdventureService } from '../../../firebase-services/adventure.service';

// Interfaces
import { Destination } from '../../../interfaces/destination';
import { Adventure, AdventureItem } from '../../../interfaces/adventure';

@Component({
    selector: 'app-destination-adventure-item-edit',
    templateUrl: './destination-adventure-item-edit.component.html',
    styleUrls: ['./destination-adventure-item-edit.component.css']
})
export class DestinationAdventureItemEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    destination: Destination = null;
    adventure: Adventure = null;
    adventureItem: AdventureItem = null;
    routerRoot: string;
    routerBack: string;

    constructor(
        private route: ActivatedRoute,
        private destinationService: DestinationService,
        private adventureService: AdventureService
    ) {
    }

    ngOnInit() {
        this.destinationService.getDestination(this.route.snapshot.paramMap.get('destinationKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((destination) => {
                this.routerRoot = '/destination/' + destination.key;
                this.destination = destination;
            });
        this.adventureService.getAdventure(this.route.snapshot.paramMap.get('adventureKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventure) => {
                this.routerBack = '/adventures/' + adventure.key;
                this.adventure = adventure;
            });
        this.adventureService.getAdventureItem(this.route.snapshot.paramMap.get('adventureItemKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventureItem) => this.adventureItem = adventureItem);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
