<div class="content-wrapper">
    <app-content-header-section contentPageId="eventManagerDashboard" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-route"></i> Go to events on trail area</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list" *ngIf="!eventManagerTrailAreas">
                                    <li>
                                        <span class="text">No trail areas available, please contact an administrator</span>
                                    </li>
                                </ul>
                                <ul class="todo-list" *ngIf="eventManagerTrailAreas">
                                    <li class="primaryListItem"
                                        *ngFor="let area of eventManagerTrailAreas"
                                        [routerLink]="layout.getUrl('trailAreaAdventures', { trailAreaKey: area.key })">
                                        <span class="text">{{ area.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
