import { Component } from '@angular/core';

// Interfaces
import { ProductCategory } from '../../../interfaces/kiosk';

@Component({
    selector: 'app-premium-product',
    templateUrl: './premium-product.component.html',
    styleUrls: ['./premium-product.component.css']
})
export class PremiumProductComponent {
    // Payment vars
    productCategory: ProductCategory = ProductCategory.PREMIUM;
    kioskProductKey = 'premium';

    constructor() {
    }
}
