import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../../core/layout.service';
import { RtwEventService } from '../../../firebase-services/rtw-event.service';
import { CountryService } from '../../../firebase-services/country.service';
import { UserService } from '../../../firebase-services/user.service';

// Interfaces
import { RtwEvent } from '../../../interfaces/rtw-event';
import { TextModel, TextModelItemType, TextObject } from '../../../interfaces/text-model';
import { FileService } from '../../../services/file.service';

// const timeFormat = 'DD-MM-YYYY HH:mm';

@Component({
    selector: 'app-edit-rtw-event',
    templateUrl: './edit-rtw-event.component.html',
    styleUrls: ['./edit-rtw-event.component.css']
})
export class EditRtwEventComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    textModel: TextModel = {items: []};

    rtwEvent: RtwEvent;
    collectionName: string;

    settingsChangedInfo = 0;
    disableSaveSettings = true;
    textsChangedInfo = 0;
    heroSettingsChangedInfo = 0;
    disableHeroSaveSettings = true;
    newHeroImage = false;

    // Widget vars
    colorPickerIsLoaded = false;
    // eventPeriodIsLoaded = false;

    constructor(
        public layout: LayoutService,
        private activatedRoute: ActivatedRoute,
        private rtwEventService: RtwEventService,
        private countryService: CountryService,
        private userService: UserService,
        private fileService: FileService
    ) {
        this.initTextModel();
    }

    ngOnInit(): void {
        this.rtwEvent = null;
        this.loadRtwEvent(this.activatedRoute.snapshot.paramMap.get('rtwEventKey'));
    }

    // ngAfterViewChecked() {
    //     var thisComponent = this;
    //     if (!this.colorPickerIsLoaded && $('#colorPickerGroup').length > 0) {
    //         $(function () {
    //             $('#colorPickerGroup').colorpicker({
    //                 format: 'hex'
    //             });
    //             $('#colorPickerGroup').on('colorpickerChange', (colorPickerChangeEvent: any) => {
    //                 thisComponent.rtwEvent.color = colorPickerChangeEvent.color.toString();
    //                 thisComponent.settingsChanged();
    //             });
    //         });
    //         this.colorPickerIsLoaded = true;
    //     }
    //
    //     if (!this.eventPeriodIsLoaded && $('#eventPeriod').length > 0) {
    //         $(function () {
    //             $('#eventPeriod').daterangepicker(
    //                 {
    //                     timePicker: true,
    //                     timePicker24Hour: true,
    //                     timePickerIncrement: 30,
    //                     locale: {
    //                         format: timeFormat,
    //                         cancelLabel: 'Clear'
    //                     },
    //                     startDate: moment(thisComponent.rtwEvent.startTime),
    //                     endDate: moment(thisComponent.rtwEvent.endTime)
    //                 },
    //                 function (start: any, end: any, label: any) {
    //                     $('#eventPeriod').data('daterangepicker').setStartDate(start);
    //                     $('#eventPeriod').data('daterangepicker').setEndDate(end);
    //                     thisComponent.rtwEvent.startTime = start.unix() * 1000;
    //                     thisComponent.rtwEvent.endTime = end.unix() * 1000;
    //                     thisComponent.settingsChanged();
    //                 }
    //             );
    //             $('#eventPeriod').on('apply.daterangepicker', function (ev: any, picker: any) {
    //                 $(this).val(picker.startDate.format(timeFormat) + ' - ' + picker.endDate.format(timeFormat));
    //             });
    //         });
    //         this.eventPeriodIsLoaded = true;
    //     }
    //
    // }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    loadRtwEvent(rtwEventKey: string): void {
        this.rtwEventService.getRtwEvent(rtwEventKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((rtwEvent) => {
                this.loadCollectionName(rtwEvent.collectionKey);
                this.rtwEvent = rtwEvent;
            });
    }

    loadCollectionName(collectionKey: string): void {
        this.countryService.getCountry(collectionKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => this.collectionName = country.name);
    }

    settingsChanged(): void {
        this.disableSaveSettings = false;
    }

    saveSettings(): void {
        this.disableSaveSettings = true;
        this.rtwEventService.updateSettings(this.rtwEvent)
            .then(() => this.settingsChangedInfo++)
            .catch((err) => console.error('Settings-Update error occurred:', err));
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.rtwEventService.updateTexts(<RtwEvent>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    onUploadedImageUrl(uploadedImageUrl: string) {
        this.rtwEventService.updateImage(this.rtwEvent, uploadedImageUrl)
            .then(() => this.rtwEvent.imageUrl = uploadedImageUrl);
    }

    heroSettingsChanged(): void {
        this.disableHeroSaveSettings = false;
    }

    foundHero(profileKey: string): void {
        this.userService.getUser(profileKey)
            .subscribe((user) => {
                const filePromise = fetch(user.userPicture)
                    .then((e) => {
                        return e.blob();
                    })
                    .then((blob) => {
                        const b: any = blob;
                        b.lastModifiedDate = new Date();
                        b.name = '';
                        return <File>b;
                    });
                return filePromise
                    .then((file) => {
                        this.fileService.uploadFileToStorage(null, 'rtwEvents/' + this.rtwEvent.key + '/', 1, file)
                            .then((uploadFileResult) => {
                                this.heroSettingsChanged();
                                this.rtwEvent.heroKey = profileKey;
                                this.rtwEvent.heroName = user.name;
                                this.rtwEvent.heroImageUrl = uploadFileResult.url;
                                this.newHeroImage = true;
                            })
                            .catch((err) => console.log('Image upload error occurred:', err.message));
                    });
            });
    }

    saveHeroSettings(): void {
        this.disableHeroSaveSettings = true;
        this.rtwEventService.updateHeroSettings(this.rtwEvent, this.newHeroImage)
            .then(() => {
                this.heroSettingsChangedInfo++;
                this.newHeroImage = false;
            })
            .catch((err) => console.error('Hero settings-Update error occurred:', err));
    }

    onUploadedHeroImageUrl(uploadedImageUrl: string) {
        this.newHeroImage = false;
        this.rtwEventService.updateHeroImage(this.rtwEvent, uploadedImageUrl)
            .then(() => this.rtwEvent.heroImageUrl = uploadedImageUrl);
    }

    private initTextModel(): void {
        this.textModel.items.push({
            name: 'Title',
            varName: 'title',
            help: 'The official name of the Ride the World Event.',
            placeholder: 'Ride the World Title',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Feed Entry Title',
            varName: 'feedEntryTitle',
            help: 'Short title on the feed entry card.',
            placeholder: 'Ride the World Event Conquest!',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Feed Entry Description',
            varName: 'feedEntryDescription',
            help: 'Description of the achievement on the feed entry card. NOTE: add the name of the conquered trail area with this code: "{{areaName}}"',
            placeholder: '{{areaName}} was won and is added to the map collection',
            type: TextModelItemType.INPUT
        });
    }

}
