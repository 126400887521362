import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KeyValue } from '@angular/common/';

import { AvailableLanguages } from '../../interfaces/language';
import { TextModel, TextModelItemType, TextObject } from '../../interfaces/text-model';

declare var $: any;

@Component({
    selector: 'app-translatable-texts',
    templateUrl: './translatable-texts.component.html',
    styleUrls: ['./translatable-texts.component.css']
})
export class TranslatableTextsComponent implements OnInit, AfterViewChecked {
    @Input() textsName: string;
    @Input() colorTheme = 'primary';
    @Input() infoTrigger: number;

    subItemKeys: string[] = null;
    _textModel: TextModel = null;
    @Input() set textModel(textModel: TextModel) {
        if (typeof textModel.subItems === 'object' && textModel.subItems !== null) {
            this.subItemKeys = Object.keys(textModel.subItems);
        }
        this._textModel = textModel;
    }

    private textObjectString: string = null;
    _textObject: TextObject = null;
    @Input() set textObject(textObject: TextObject) {
        this._textObject = textObject;
        this.textObjectString = JSON.stringify(this._textObject);
    }

    _readonly = false;
    @Input() set readonly(readonly: string) {
        this._readonly = (readonly === 'true');
    }

    @Output() alteredTextObject = new EventEmitter<TextObject>();
    @Output() languageAdded = new EventEmitter<string>();
    @Output() editedText = new EventEmitter<boolean>();

    componentInitialised = false;

    languageNames = AvailableLanguages;
    inputType = TextModelItemType.INPUT;
    textareaType = TextModelItemType.TEXT_AREA;

    usedLanguages: string[] = [];
    unusedLanguages: string[] = [];

    disableSaveTexts = true;
    componentId: string = null;
    gotoLanguageTab: string = null;

    constructor() {
    }

    ngOnInit() {
        this.componentId = new Date().valueOf().toString();
        this.init();
    }

    ngAfterViewChecked() {
        if (this.gotoLanguageTab) {
            $(this.gotoLanguageTab).tab('show');
            this.gotoLanguageTab = null;
        }
    }

    init(): void {
        if (!this.componentInitialised && this._textModel !== null && this._textObject !== null) {
            const usedLanguagesSet: Set<string> = new Set(Object.keys(this._textObject.lang || []));
            // Search for language use in subItems
            if (this.subItemKeys !== null) {
                this.subItemKeys.forEach((subItemKey) => {
                    this._textObject[subItemKey].forEach((subItemObject: any) => {
                        if (typeof subItemObject.lang === 'object' && subItemObject.lang !== null) {
                            Object.keys(subItemObject.lang).forEach((usedLang) => {
                                if (!usedLanguagesSet.has(usedLang)) {
                                    // Make sure to set the used lang on general level
                                    this._textObject.lang[usedLang] = {};
                                    usedLanguagesSet.add(usedLang);
                                }
                            });
                        }
                    });
                });
                // Make sure set the used lang on all sub item levels
                usedLanguagesSet.forEach((usedLang) => {
                    this.subItemKeys.forEach((subItemKey) => {
                        for (let i = 0; i < this._textObject[subItemKey].length; i++) {
                            if (typeof this._textObject[subItemKey][i].lang !== 'object' || this._textObject[subItemKey][i].lang === null) {
                                this._textObject[subItemKey][i].lang = {};
                            }
                            if (typeof this._textObject[subItemKey][i].lang[usedLang] !== 'object' ||
                                this._textObject[subItemKey][i].lang[usedLang] === null) {
                                this._textObject[subItemKey][i].lang[usedLang] = {};
                            }
                        }
                    });
                });
            }

            // Update unused languages by removing used
            const unusedLanguages = Object.keys(AvailableLanguages);
            usedLanguagesSet.forEach((usedLang) => {
                const indexOfLang = unusedLanguages.indexOf(usedLang);
                if (indexOfLang !== -1) {
                    unusedLanguages.splice(indexOfLang, 1);
                }
            });

            this.usedLanguages = [...usedLanguagesSet];
            this.unusedLanguages = unusedLanguages;
            this.componentInitialised = true;
        }
    }

    textsChanged(): void {
        this.disableSaveTexts = JSON.stringify(this._textObject) === this.textObjectString;
        this.editedText.emit(!this.disableSaveTexts);
    }

    saveTexts(): void {
        this.disableSaveTexts = true;
        for (const lang in this._textObject.lang) {
            if (this._textObject.lang.hasOwnProperty(lang) && typeof this._textObject.lang[lang] === 'object') {
                let removeLanguage = true;
                for (const textKey in this._textObject.lang[lang]) {
                    if (this._textObject.lang[lang].hasOwnProperty(textKey) && typeof this._textObject.lang[lang][textKey] === 'string') {
                        removeLanguage = false;
                        break;
                    }
                }
                // Check for usage of language in sub items
                if (removeLanguage && this.subItemKeys !== null) {
                    this.subItemKeys.forEach((subItemKey) => {
                        if (removeLanguage === false) {
                            return;
                        }
                        for (let i = 0; i < this._textObject[subItemKey].length; i++) {
                            for (const textKey in this._textObject[subItemKey][i].lang[lang]) {
                                if (this._textObject[subItemKey][i].lang[lang].hasOwnProperty(textKey) &&
                                    typeof this._textObject[subItemKey][i].lang[lang][textKey] === 'string') {
                                    removeLanguage = false;
                                    break;
                                }
                            }
                            if (removeLanguage === false) {
                                break;
                            }
                        }
                    });
                }
                if (removeLanguage === true) {
                    this.removeLanguage(lang);
                }
            }
        }
        this.alteredTextObject.emit(this._textObject);
        this.textObjectString = JSON.stringify(this._textObject);
    }

    addLanguage(language: string): void {
        const indexOfLang = this.unusedLanguages.indexOf(language);
        if (indexOfLang !== -1) {
            this._textObject.lang[language] = {};
            this.usedLanguages.push(language);
            this.unusedLanguages.splice(indexOfLang, 1);
            this.languageAdded.emit(language);
            // Init language on sub items
            if (this.subItemKeys !== null) {
                this.subItemKeys.forEach((subItemKey) => {
                    for (let i = 0; i < this._textObject[subItemKey].length; i++) {
                        if (typeof this._textObject[subItemKey][i].lang !== 'object' || this._textObject[subItemKey][i].lang === null) {
                            this._textObject[subItemKey][i].lang = {};
                        }
                        this._textObject[subItemKey][i].lang[language] = {};
                    }
                });
            }
            this.disableSaveTexts = false;
        }
        this.gotoLanguageTab = '#tab_' + language + '_link_' + this.componentId;
    }

    private removeLanguage(language: string): void {
        const indexOfLang = this.usedLanguages.indexOf(language);
        if (indexOfLang !== -1) {
            this._textObject.lang[language] = null;
            this.unusedLanguages.push(language);
            this.usedLanguages.splice(indexOfLang, 1);
            if ($('#translatable_texts_tabs > li.active > a').text() === language) {
                this.gotoLanguageTab = '#tab_default_link';
            }
        }
    }

    copyDefaultText(param: string, language: string): void {
        this._textObject.lang[language][param] = this._textObject[param];
        this.textsChanged();
    }

    copySubItemDefaultText(param: string, index: number, subParam: string, language: string): void {
        this._textObject[param][index].lang[language][subParam] = this._textObject[param][index][subParam];
        this.textsChanged();
    }

    addSubItem(subItemKey: string): void {
        const newItem: TextObject = {lang: {}};
        this.usedLanguages.forEach((lang) => {
            newItem.lang[lang] = {};
        });
        this._textModel.subItems[subItemKey].items.forEach((item) => {
            newItem[item.varName] = '';
        });
        this._textObject[subItemKey].push(newItem);
    }

    removeSubItem(subItemKey: string, index: number): void {
        if (confirm('Are you sure you wish to delete this teaser item for all languages?\nNotice: You still have to save the texts for this to have effect.')) {
            this._textObject[subItemKey].splice(index, 1);
            this.textsChanged();
        }
    }

    plusOne(itemNumber: string): number {
        return parseInt(itemNumber, 10) + 1;
    }

    orderByNumericKey(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
        return a.key - b.key;
    }
}
