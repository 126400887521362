import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../../core/layout.service';
import { PoiCategoryStateService } from '../../../services/poi-category-state.service';
import { MediaLibraryService } from '../../../firebase-services/media-library.service';
import { PoiCategoryService } from '../../../firebase-services/poi-category.service';

// Interfaces
import { PublishedStates, StatesBundle } from '../../../interfaces/general';
import { PoiCategory, PublicPoiCategory } from '../../../interfaces/poi-category';
import { TextModel, TextObject } from '../../../interfaces/text-model';


@Component({
    selector: 'app-poi-categories',
    templateUrl: './poi-categories.component.html',
    styleUrls: ['./poi-categories.component.css']
})
export class PoiCategoriesComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    contentPageId = 'poiCategories';
    poiCategoryPageId = 'editPoiCategory';

    textModels: { [label: string]: TextModel } = {};
    poiCategoriesTextObjects: { [label: string]: TextObject } = {};

    poiCategories: { [label: string]: PoiCategory } = null;
    poiCategoryStates: StatesBundle = {};
    publicPoiCategories: { [label: string]: PublicPoiCategory } = null;

    isPublic: { [categoryKey: string]: boolean } = {};

    constructor(
        public layout: LayoutService,
        private mediaLibraryService: MediaLibraryService,
        private poiCategoryService: PoiCategoryService
    ) {
    }

    ngOnInit(): void {
        this.loadPoiCategories();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    poiCategoryCompareFunction(a: KeyValue<string, PoiCategory>, b: KeyValue<string, PoiCategory>): number {
        return a.value.sortOrder - b.value.sortOrder;
    }

    numberOfSubCategories(label: string): string {
        return Object.keys(this.poiCategories[label].subCategories).length.toString();
    }

    updateBase(): Promise<void> {
        return this.poiCategoryService.updateBaseCategories()
            .then(() => this.ngOnInit());
    }

    private loadPoiCategories() {
        const poiCategoriesObservable = this.poiCategoryService.getPoiCategories()
            .pipe(takeUntil(this.destroy$));
        const publicPoiCategoriesObservable = this.poiCategoryService.getPublicPoiCategories()
            .pipe(takeUntil(this.destroy$));
        combineLatest([poiCategoriesObservable, publicPoiCategoriesObservable])
            .subscribe(([poiCats, publicPoiCats]) => {
                this.publicPoiCategories = publicPoiCats;
                Object.entries(poiCats).forEach(([label, poiCat]) => {
                    this.poiCategoryStates[label] = {
                        altered: false,
                        canPublish: false,
                        icon: 'fas fa-sync-alt fa-spin',
                        isPublished: false,
                        outOfSync: false,
                        progress: PublishedStates.DRAFT_ALTERED,
                        theme: 'warning'
                    };
                    PoiCategoryStateService.updateState<PoiCategory, PublicPoiCategory>(
                        poiCat, this.publicPoiCategories[label], this.poiCategoryStates[label]);
                });
                this.poiCategories = poiCats;
                if (!this.isPublic) {
                    this.isPublic = {};
                }
                Object.keys(poiCats).forEach((label) => {
                    this.updateIsPublic(label);
                });
            });
    }

    private updateIsPublic(label: string) {
        this.isPublic[label] = (typeof this.publicPoiCategories[label] !== 'undefined');
    }

}
