<div [ngClass]="'card card-' + layout.getTheme(mediaType.theme)">
    <div class="card-header">
        <h3 class="card-title">
            + <i [ngClass]="layout.getIcon(mediaType.theme) + ' mr-2'"></i>
            Add {{ mediaType.typeName }}
        </h3>
    </div>
    <div class="card-body">
        <ng-container *ngFor="let fileType of mediaType.fileTypes | keyvalue: fileTypeCompareFunction">
            <app-alert-info-box [infoTrigger]="fileType.value.uploadInfo">
                <h4>{{ fileType.value.name }}-file successfully uploaded.</h4>
            </app-alert-info-box>

            <div class="form-group">
                <label><i [ngClass]="fileType.value.iconUrl + ' mr-2'"></i>
                    Upload {{ fileType.value.name }}                    -version</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fas fa-file-upload"
                               [class.text-danger]="fileType.value.disableUpload && !fileType.value.storageFileName"
                               [class.text-warning]="!fileType.value.disableUpload && !fileType.value.storageFileName"
                               [class.text-success]="fileType.value.storageFileName">
                            </i>
                        </span>
                    </div>
                    <div class="custom-file">
                        <!--suppress TypeScriptUnresolvedVariable -->
                        <input type="file" class="custom-file-input" [id]="'fileInput' + fileType.key"
                               [attr.aria-describedby]="'file-upload-help-' + fileType.key"
                               (change)="onFileSelected(fileType.key, $event.target.files)"/>
                        <label class="custom-file-label" [for]="'fileInput' + fileType.key">
                            {{ fileType.value.fileName || 'Choose ' + fileType.value.name + '-file' }}
                        </label>
                    </div>
                    <div class="input-group-append">
                        <button [disabled]="fileType.value.disableUpload" class="btn btn-info"
                                (click)="onFileUpload(fileType.key)">Upload
                        </button>
                    </div>
                </div>
                <small class="form-text text-muted" [id]="'file-upload-help-' + fileType.key">
                    Upload {{ fileType.value.name }} file.
                </small>
            </div>
        </ng-container>

        <div class="form-group">
            <label [for]="'name' + mediaTypeLabel">{{ mediaType.typeName }}-name</label>
            <input type="text" class="form-control" [(ngModel)]="mediaType.newName" [id]="'name' + mediaTypeLabel"
                   (keyup)="updateDisableCreate()" [attr.aria-describedby]="'name-help-' + mediaTypeLabel"/>
            <small class="form-text text-muted" [id]="'name-help-' + mediaTypeLabel">
                Name of the {{ mediaType.typeName }}.
            </small>
        </div>

        <button [disabled]="mediaType.disableCreate" type="submit" class="btn btn-primary" (click)="createMedia()">
            Create {{ mediaType.typeName }}
        </button>
    </div>
</div>
