<div class="content-wrapper">
    <app-content-header-section contentPageId="createRtwEvent" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('rtwEvent')">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getIcon('rtwEvent')"></i> RtW Event Details</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="title">Title (default, en)</label>
                                <input [(ngModel)]="rtwEvent.title" id="title" aria-describedby="titleHelp" class="form-control"
                                       placeholder="Ride the World Event Title"/>
                                <small class="form-text text-muted" id="titleHelp">The title of the event in english.</small>
                            </div>
                            <div class="form-group">
                                <label for="collectionKey">Area Collection</label>
                                <select [(ngModel)]="rtwEvent.collectionKey" id="collectionKey" aria-describedby="collectionKeyHelp"
                                        class="form-control">
                                    <option *ngFor="let collection of collections | keyvalue" [value]="collection.key">{{ collection.value }}</option>
                                </select>
                                <small class="form-text text-muted" id="collectionKeyHelp">Select the collection where the event is held.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button (click)="createRtwEvent()" [ngClass]="'btn btn-' + layout.getTheme('rtwEvent')">Create</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
