import { KeyValue } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Chart, registerables } from 'chart.js';

// Services
import { TrailAreaService } from '../../firebase-services/trail-area.service';
import { TrailService } from '../../firebase-services/trail.service';
import { RideService } from '../../firebase-services/ride.service';
import { UserService } from '../../firebase-services/user.service';

// Interfaces
import { DefaultMapProp } from '../../interfaces/map';
import { ProfileRide, Ride, RIDE_HANDLING_DELAY } from '../../interfaces/ride';
import { PopularStatisticsTrail, Trail } from '../../interfaces/trail';
import { TrailArea } from '../../interfaces/trailArea';
import { User } from '../../interfaces/user';

import * as moment from 'moment';
import { Moment } from 'moment';

declare var $: any;

Chart.register(...registerables);

@Component({
    selector: 'app-ride-statistics',
    templateUrl: './ride-statistics.component.html',
    styleUrls: ['./ride-statistics.component.css']
})
export class RideStatisticsComponent implements AfterViewChecked, OnDestroy {
    @ViewChild('gmap') gmapElement: ElementRef;
    destroy$: Subject<boolean> = new Subject<boolean>();
    periodInputName = '#periodInput';
    timeFormat = 'WW-YYYY';
    trailArea: TrailArea = null;
    trails: Trail[] = [];
    periodInputIsLoaded = false;
    startTime: Moment = moment().subtract(6, 'months').startOf('isoWeek');
    endTime: Moment = moment().subtract(32, 'days').endOf('isoWeek');
    dataReady = true;
    ridesInAreaCount: number;
    profilesInAreaCount: number;
    countries: [string, number][] = [];
    platforms: [string, number][] = [];
    newInPeriod = 0;
    ridesInAreaChart: Chart = null;
    popularTrails: PopularStatisticsTrail[] = [];
    trailsChart: Chart = null;
    @ViewChild('ridesInAreaChartCanvas') private ridesInAreaChartElement: ElementRef;
    @ViewChild('trailsChartCanvas') private trailsChartElement: ElementRef;
    private heatMapDataSet: google.maps.LatLng[] = [];
    private map: google.maps.Map = null;
    private heatmap: google.maps.visualization.HeatmapLayer = null;

    private gracefulTimer = 3000;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private trailAreaService: TrailAreaService,
        private trailService: TrailService,
        private rideService: RideService,
        private userService: UserService,
        private domSanitizer: DomSanitizer
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    private static getYearAndWeek(momentInstance: Moment): string {
        return momentInstance.isoWeek().toString() + '-' + momentInstance.year().toString();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewChecked() {
        if (!this.periodInputIsLoaded && $(this.periodInputName).length > 0) {
            const rideStatisticsComponent = this;
            $(function () {
                $(rideStatisticsComponent.periodInputName).daterangepicker(
                    {
                        timePicker: false,
                        showWeekNumbers: true,
                        startDate: moment(rideStatisticsComponent.startTime),
                        endDate: moment(rideStatisticsComponent.endTime),
                        minDate: moment('2019-04-01').startOf('isoWeek'),
                        maxDate: moment().subtract(14, 'days').endOf('isoWeek'),
                        locale: {
                            format: rideStatisticsComponent.timeFormat,
                            firstDay: 1
                        }
                    },
                    function (start: Moment, end: Moment) {
                        rideStatisticsComponent.startTime = start.startOf('isoWeek');
                        rideStatisticsComponent.endTime = end.endOf('isoWeek');
                        rideStatisticsComponent.getStatisticsForPeriod();
                    }
                );
            });
            this.periodInputIsLoaded = true;
        }
    }

    init() {
        this.periodInputIsLoaded = false;
        this.ridesInAreaCount = NaN;
        this.profilesInAreaCount = NaN;
        this.countries = [];
        this.platforms = [];
        this.newInPeriod = NaN;
        this.popularTrails = [];
        this.trailArea = null;
        this.trails = [];
        this.map = null;
        this.heatmap = null;
        this.getTrailAreaAndTrails(this.route.snapshot.paramMap.get('trailAreaKey'));
    }

    getStatisticsForPeriod() {
        if (this.ridesInAreaChart !== null) {
            this.ridesInAreaChart.destroy();
        }
        if (this.trailsChart !== null) {
            this.trailsChart.destroy();
        }
        this.ridesInAreaCount = NaN;
        this.profilesInAreaCount = NaN;
        this.countries = [];
        this.platforms = [];
        this.newInPeriod = NaN;
        this.popularTrails = [];
        this.dataReady = false;
        //        console.log(this.startTime.format('YYYY-MM-DD HH:mm'), 'to', this.endTime.format('YYYY-MM-DD HH:mm'));
        this.rideService.getRidesOnTrailAreaInDateRange(this.trailArea, this.startTime.unix() * 1000, this.endTime.unix() * 1000)
            .pipe(take(1))
            .subscribe((ridesInPeriod) => {
//                console.log('Rides in period = ', ridesInPeriod['ridesOK'].length, ridesInPeriod['orderedRestarts']);
                if (ridesInPeriod['orderedRestarts'] > 0 && this.gracefulTimer < 60000) {
                    setTimeout(() => {
                        this.getStatisticsForPeriod();
                        this.gracefulTimer *= 2;
                    }, this.gracefulTimer + RIDE_HANDLING_DELAY * (ridesInPeriod['orderedRestarts'] + 4));
                } else {
                    this.ridesInAreaCount = ridesInPeriod['ridesOK'].length;
                    if (this.ridesInAreaCount > 0 && ridesInPeriod['profileRidesObservable'] !== null) {
                        const profileRidesObservable: Observable<ProfileRide[]> = ridesInPeriod['profileRidesObservable'];
                        const profileRidesSubscription = profileRidesObservable
                            .subscribe((profileRides) => {
                                this.loadHeatMap(ridesInPeriod['ridesOK']);
                                this.createRidesGraph(ridesInPeriod['ridesOK'], profileRides);
                                this.dataReady = true;
                                profileRidesSubscription.unsubscribe();
                            });
                    } else {
                        this.gracefulTimer = 3000;
                        this.dataReady = true;
                        if (ridesInPeriod['profileRidesObservable'] !== null) {
                            window.alert('Problem occurred with data. Please contact us to resolve this issue.');
                        }
                    }
                }
            });
    }

    setColor(trail: Trail) {
        return this.domSanitizer.bypassSecurityTrustStyle('color: ' + trail.color + '%');
    }

    setStarWidth(trail: Trail) {
        return this.domSanitizer.bypassSecurityTrustStyle('width: ' + Math.round(1000 * trail.avgRating / 5) / 10 + '%');
    }

    popularCompareFunction(a: KeyValue<number, PopularStatisticsTrail>, b: KeyValue<number, PopularStatisticsTrail>): number {
        return a.key - b.key;
    }

    plusOne(itemNumber: string): number {
        return parseInt(itemNumber, 10) + 1;
    }

    private getTrailAreaAndTrails(trailAreaKey: string): void {
        this.trailAreaService.getTrailArea(trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailArea) => {
                this.trailArea = trailArea;
                this.trailService.getTrailsOnTrailArea(this.trailArea.key)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((trails) => {
                        this.trails = trails;
                        this.initMap();
                    });
            });
    }

    private createRidesGraph(rides: Ride[], profileRides: ProfileRide[]): void {
        // Initialising temp vars...
        let lastWeek: Moment = this.startTime.clone();
        const rideMetrics: { [yearAndWeek: string]: number } = {};
        const profileMetrics: { [yearAndWeek: string]: { [profileRideKey: string]: boolean } } = {total: {}};
        while (lastWeek.valueOf() < this.endTime.valueOf()) {
            const yearAndWeek: string = RideStatisticsComponent.getYearAndWeek(lastWeek);
            rideMetrics[yearAndWeek] = 0;
            profileMetrics[yearAndWeek] = {};
            lastWeek = lastWeek.add(1, 'week');
        }
        const visitedTrails: { [visitedTrailKey: string]: PopularStatisticsTrail } = {};

        // Looping through rides/profileRides to get stats
        for (let i = 0; i < rides.length; i++) {
            const yearAndWeek: string = RideStatisticsComponent.getYearAndWeek(moment(rides[i].startTime).startOf('isoWeek'));
            // Ride stats
            rideMetrics[yearAndWeek]++;

            // Users stats
            profileMetrics[yearAndWeek][profileRides[i].profileKey] = true;
            profileMetrics['total'][profileRides[i].profileKey] = true;

            // Trail stats
            for (let j = 0; j < profileRides[i].trails.length; j++) {
                const visitedTrail = profileRides[i].trails[j];
                if (!visitedTrails[visitedTrail.trailKey]) {
                    for (let k = 0; k < this.trails.length; k++) {
                        if (this.trails[k].key === visitedTrail.trailKey) {
                            visitedTrails[visitedTrail.trailKey] = {
                                distance: visitedTrail.distance,
                                time: visitedTrail.time,
                                visits: 1,
                                trail: this.trails[k]
                            };
                            break;
                        }
                    }
                } else {
                    visitedTrails[visitedTrail.trailKey]['distance'] += visitedTrail.distance;
                    visitedTrails[visitedTrail.trailKey]['time'] += visitedTrail.time;
                    visitedTrails[visitedTrail.trailKey]['visits']++;
                }
            }
        }

        // Get profile info for more user stats
        const profileKeys = Object.keys(profileMetrics['total']);
        const profilesObservable = this.userService.getUsers(profileKeys);
        profilesObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((profiles) => this.getProfileStats(profiles.filter((p) => p)));
        this.profilesInAreaCount = profileKeys.length;

        // Convert ride and profile stats to chart-data
        const rideDataRidesChart: number[] = [];
        const profileDataRidesChart: number[] = [];
        const labelsRidesChart: string[] = [];
        for (const i in rideMetrics) {
            if (typeof rideMetrics[i] === 'number') {
                rideDataRidesChart.push(rideMetrics[i]);
                profileDataRidesChart.push(Object.keys(profileMetrics[i]).length || 0);
                labelsRidesChart.push(i);
            }
        }
        const lineChartData = {
            labels: labelsRidesChart,
            datasets: [{
                label: 'Rides',
                borderColor: 'rgba(90,255,41, 0.6)',
                backgroundColor: 'rgba(0,255,0, 0.2)',
                data: rideDataRidesChart
            }, {
                label: 'Unique riders',
                borderColor: 'rgba(0,0,255, 0.6)',
                backgroundColor: 'rgba(0,0,255, 0.2)',
                data: profileDataRidesChart
            }]
        };

        // Draw rides chart
        this.ridesInAreaChart = new Chart(this.ridesInAreaChartElement.nativeElement, {
            type: 'line',
            data: lineChartData,
            options: {
                elements: {line: {tension: 0}},
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    y: {
                        stacked: false,
                        beginAtZero: true
                    }
                }
            }
        });

        // Summon popular trail stats
        this.popularTrails = Object.values(visitedTrails)
            .sort((a, b) => {
                const visits = b.visits - a.visits;
                const dist = b.distance - a.distance;
                return (visits !== 0 ? visits : dist !== 0 ? dist : b.time - a.time);
            });

        const dataTrailsChart: number[] = [];
        const backgroundColorsTrailsChart: string[] = [];
        const labelsTrailsChart: string[] = [];
        for (const i in this.popularTrails) {
            if (this.popularTrails[i]) {
                if (labelsTrailsChart.length === 10) {
                    break;
                }
                dataTrailsChart.push(this.popularTrails[i].visits);
                backgroundColorsTrailsChart.push(this.popularTrails[i].trail.color);
                labelsTrailsChart.push(('#' + (parseInt(i, 10) + 1) + ' ' + this.popularTrails[i].trail.name).substr(0, 16));
            }
        }

        // TODO Can we get away with ignoring this TS Error // CS - 2024-10-29
        // Draw doughnut chart
        //@ts-ignore
        this.trailsChart = new Chart(this.trailsChartElement.nativeElement, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: dataTrailsChart,
                    backgroundColor: backgroundColorsTrailsChart
                }],
                labels: labelsTrailsChart
            },
        });
    }

    private getProfileStats(profiles: User[]): void {
        this.newInPeriod = 0;
        const profileCountries: { [profileCountryCode: string]: number } = {};
        const profilePlatforms: { [profileOS: string]: number } = {};
        for (const profile of profiles) {
            const profileCountry = profile.deviceCountry.substr(profile.deviceCountry.length - 2).toUpperCase();
            if (!profileCountries[profileCountry]) {
                profileCountries[profileCountry] = 0;
            }
            profileCountries[profileCountry]++;
            const profileOS: string = profile.deviceOS;
            if (!profilePlatforms[profileOS]) {
                profilePlatforms[profileOS] = 0;
            }
            profilePlatforms[profileOS]++;
            const profileIsNew: boolean = moment(profile.createdTime).isBetween(this.startTime, this.endTime);
            this.newInPeriod += (profileIsNew ? 1 : 0);
        }
        this.countries = Object.entries(profileCountries);
        this.countries = this.countries.sort((a, b) => {
            return b[1] - a[1];
        });
        this.platforms = Object.entries(profilePlatforms);
        this.platforms = this.platforms.sort((a, b) => {
            return b[1] - a[1];
        });
    }

    private initMap() {
        this.map = new google.maps.Map(this.gmapElement.nativeElement, DefaultMapProp);
        this.map.fitBounds({
            north: this.trailArea.boundsNorth,
            south: this.trailArea.boundsSouth,
            east: this.trailArea.boundsEast,
            west: this.trailArea.boundsWest
        }, {bottom: 1, left: 1, right: 1, top: 1});
        this.heatMapDataSet = null;
        this.heatmap = new google.maps.visualization.HeatmapLayer({
            map: this.map,
            data: []
        });
    }

    private loadHeatMap(rides: Ride[]): void {
        this.heatMapDataSet = this.rideService.getHeatMapDataFromRides(rides);
        this.heatmap.setData(this.heatMapDataSet);
    }

}
