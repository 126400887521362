<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">

                <h1 *ngIf="icon && title"><i class="{{ icon }}"></i> {{ title }} <small class="lead">{{ titleLead }}</small></h1>

            </div>
            <div class="col-sm-6">

                <ol *ngIf="breadcrumbs && title" class="breadcrumb float-sm-right">
                    <ng-container *ngFor="let breadcrumb of breadcrumbs">
                        <li *ngIf="breadcrumb.url !== null" class="breadcrumb-item"><a routerLink="{{ breadcrumb.url }}">{{ breadcrumb.linkText }}</a>
                        </li>
                        <li *ngIf="breadcrumb.url === null" class="breadcrumb-item">{{ breadcrumb.linkText }}</li>
                    </ng-container>
                    <li class="breadcrumb-item active">{{ linkText }}</li>
                </ol>

            </div>
        </div>
    </div>
</section>
