import { AfterContentChecked, Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

// Services
import { AdventureService } from '../../firebase-services/adventure.service';

// Interfaces
import { ADVENTURE_ITEM_TIME_FORMAT, AdventureItem } from '../../interfaces/adventure';
import { TextModel, TextModelItemType, TextObject } from '../../interfaces/text-model';

import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-adventure-item-edit',
    templateUrl: './adventure-item-edit.component.html',
    styleUrls: ['./adventure-item-edit.component.css']
})
export class AdventureItemEditComponent implements AfterContentChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    textModel: TextModel = {items: []};

    @Input() routerBack: string;

    @Input() set adventureItem(adventureItem: AdventureItem) {
        this._adventureItem = adventureItem;
        this.init();
    }

    _adventureItem: AdventureItem;

    originalName = '';
    disableSaveItem = true;
    alertTimeout: number;
    pin1 = 0;
    pin2 = 0;
    pin3 = 0;
    pin4 = 0;
    adventureItemIsTimeBoxed = false;
    timeboxIsLoaded = false;

    settingsChangedInfo = 0;
    textsChangedInfo = 0;

    constructor(
        private router: Router,
        private adventureService: AdventureService
    ) {
    }

    ngAfterContentChecked() {
        const adventureItemEditComponent = this;
        if (this.adventureItemIsTimeBoxed && !this.timeboxIsLoaded && $('#timebox').length > 0) {
            $(function () {
                const daterangepickeroptions = {
                    timePicker: true,
                    timePicker24Hour: true,
                    timePickerIncrement: 30,
                    locale: {
                        format: ADVENTURE_ITEM_TIME_FORMAT
                    },
                    startDate: moment(adventureItemEditComponent._adventureItem.timeboxStart),
                    endDate: moment(adventureItemEditComponent._adventureItem.timeboxEnd)
                };
                $('#timebox').daterangepicker(
                    daterangepickeroptions,
                    function (start: moment.Moment, end: moment.Moment) {
                        adventureItemEditComponent._adventureItem.timeboxStart = start.valueOf();
                        adventureItemEditComponent._adventureItem.timeboxEnd = end.valueOf();
                        const timeBox = $('#timebox');
                        timeBox.data('daterangepicker').setStartDate(start);
                        timeBox.data('daterangepicker').setEndDate(end);
                        adventureItemEditComponent.disableSaveItem = false;
                    }
                );
            });
            this.timeboxIsLoaded = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.initTextModel();
        this.adventureService.getAdventureElementOriginalName(this._adventureItem)
            .pipe(takeUntil(this.destroy$))
            .subscribe((name) => this.originalName = name);
        if (this._adventureItem.voucherPin) {
            this.pin1 = parseInt(this._adventureItem.voucherPin.charAt(0), 10);
            this.pin2 = parseInt(this._adventureItem.voucherPin.charAt(1), 10);
            this.pin3 = parseInt(this._adventureItem.voucherPin.charAt(2), 10);
            this.pin4 = parseInt(this._adventureItem.voucherPin.charAt(3), 10);
        }
        if (this._adventureItem.timeboxStart && this._adventureItem.timeboxEnd) {
            this.adventureItemIsTimeBoxed = true;
        }
    }

    private initTextModel(): void {
        this.textModel = {items: []};
        this.textModel.items.push({
            name: 'Name',
            varName: 'name',
            help: 'The name of the adventure element.',
            placeholder: 'Adventure element name',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Header',
            varName: 'header',
            help: 'A subheader for the adventure element.',
            placeholder: 'Header text',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'description',
            help: 'Describe the purpose of the adventure item.',
            placeholder: 'Adventure element description',
            type: TextModelItemType.TEXT_AREA
        });
    }

    /**
     * Sets state-variable when adventure item is changed
     */
    itemChanged(): void {
        this.disableSaveItem = false;
    }

    /**
     * Save adventure item
     */
    saveItem(goBack: boolean): void {
        this.disableSaveItem = true;
        if (this._adventureItem.voucherPin) {
            this._adventureItem.voucherPin = '' + this.pin1 + this.pin2 + this.pin3 + this.pin4;
        }
        if (!this.adventureItemIsTimeBoxed) {
            this._adventureItem.timeboxStart = this._adventureItem.timeboxEnd = null;
        }
        this.adventureService.updateAdventureItemSettings(this._adventureItem)
            .then(() => {
                if (goBack) {
                    this.router.navigate([this.routerBack]);
                } else {
                    this.settingsChangedInfo++;
                }
            })
            .catch(err =>
                console.log('About-Update error occurred:', err.message)
            );
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.adventureService.updateAdventureItemTexts(<AdventureItem>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }
}
