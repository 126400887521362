import { AfterViewChecked, Component, Input, OnChanges, SimpleChange } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-alert-info-box',
    templateUrl: './alert-info-box.component.html',
    styleUrls: ['./alert-info-box.component.css']
})
export class AlertInfoBoxComponent implements AfterViewChecked, OnChanges {
    @Input() infoTrigger: number;

    hiddenInitially = false;
    alertTimeout: number;
    myId: string;
    myElement: any = null;

    constructor() {
        this.myId = Math.floor(Math.random() * Date.now()) + 'aib';
    }

    _showAlertSeconds = 8;
    @Input() set showAlertSeconds(milliseconds: number) {
        this._showAlertSeconds = milliseconds;
    }

    ngAfterViewChecked() {
        if (!this.hiddenInitially) {
            this.myElement = $(`#${this.myId}`);
            if (this.myElement && this.myElement.length > 0) {
                this.myElement.hide();
                this.hiddenInitially = true;
            }
        }
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (const propName in changes) {
            if (!changes[propName].isFirstChange() && propName === 'infoTrigger') {
                this.showAlert();
            }
        }
    }

    /**
     * Show a specific alert info box
     */
    private showAlert(): void {
        window.clearTimeout(this.alertTimeout);
        this.hideAlert();
        this.myElement.slideDown();
        this.alertTimeout = window.setTimeout(() => this.hideAlert(), this._showAlertSeconds * 1000);
    }

    /**
     * Hide alert info boxes
     */
    private hideAlert(): void {
        this.myElement.slideUp();
    }
}
