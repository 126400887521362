import { environment } from '../../../environments/environment';

import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Observable, of, Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as polyTool from '@pirxpilot/google-polyline';

// Services
import { AuthService } from '../../core/auth.service';
import { GeoService } from '../../services/geo.service';
import { LayoutService } from '../../core/layout.service';
import { AdventureService } from '../../firebase-services/adventure.service';
import { TrailService } from '../../firebase-services/trail.service';
import { PoiService } from '../../firebase-services/poi.service';

// Interfaces
import { Adventure, ADVENTURE_ITEM_TIME_FORMAT, ADVENTURE_REF_TYPE_TRAIL, AdventureItem, InfoletType } from '../../interfaces/adventure';
import { KioskReferenceType, ProductCategory } from '../../interfaces/kiosk';
import { DefaultMapProp } from '../../interfaces/map';
import { DraftPoi } from '../../interfaces/poi';
import { TextModel, TextModelItemType, TextObject } from '../../interfaces/text-model';
import { Trail } from '../../interfaces/trail';
import { LatLngArray, TrailArea } from '../../interfaces/trailArea';

declare var $: any;

@Component({
    selector: 'app-adventure-edit',
    templateUrl: './adventure-edit.component.html',
    styleUrls: ['./adventure-edit.component.css']
})
export class AdventureEditComponent implements AfterViewChecked, OnDestroy {
    @ViewChild('gMarkerMap') gMarkerMapElement: ElementRef;
    @ViewChild('gElementsMap') gElementsMapElement: ElementRef;

    destroy$: Subject<boolean> = new Subject<boolean>();
    textModel: TextModel = {items: []};
    Editor = ClassicEditor;

    @Input() routerRoot: string;

    @Input() set trailAreas(trailAreas: TrailArea[]) {
        this._trailAreas = trailAreas;
        this._trails = null;
        this._pois = null;
    }

    @Input() set trails(trails: Trail[]) {
        this._trailAreas = null;
        this._trails = trails;
    }

    @Input() set pois(pois: DraftPoi[]) {
        this._trailAreas = null;
        this._pois = pois;
    }

    @Input() set adventure(adventure: Adventure) {
        this._adventure = adventure;
        this.init();
    }

    _adventure: Adventure;
    _trailAreas: TrailArea[];
    _pois: DraftPoi[];
    _trails: Trail[];

    // Collections in the adventure
    adventureItems: AdventureItem[];
    itemsMapOnly: AdventureItem[];

    // For Adventure edit
    settingsChangedInfo = 0;
    textsChangedInfo = 0;
    infoletChangedInfo: number[] = [0, 0, 0];
    disableSaveSettings = true;
    disableSaveInfolet: boolean[] = [true, true, true];
    removeElementCandidate: AdventureItem = null;

    // For showing map
    private markerMap: google.maps.Map = null;
    private elementsMap: google.maps.Map = null;
    private markerLatLngLiteral: google.maps.LatLngLiteral = null;
    mapTrails: Trail[] = null;
    mapOnlyTrails: Trail[] = null;
    mapPois: DraftPoi[] = null;
    mapOnlyPois: DraftPoi[] = null;
    drawnMapElements: { [key: string]: google.maps.Marker | google.maps.Polyline } = {};

    // For creating new elements
    newItemType = '';
    newItemTrailsTrailAreaKey = 'none';
    newItemTrailKey = 'none';
    newItemPoisTrailAreaKey = 'none';
    newItemPoiKey = 'none';
    newItemVoucherPin = false;
    pin1 = 0;
    pin2 = 0;
    pin3 = 0;
    pin4 = 0;
    nextSortOrder = 1;
    newItemName = '';
    newItemDescription = '';
    newItemAdventurePoints = 0;
    newItemMapOnly = false;
    newItemIsTimeBoxed = false;
    newItemTimeBox: { start: number, end: number } = null;

    // Widget vars
    colorPickerIsLoaded = false;
    timeboxIsLoaded = false;
    adventureItemListLoaded = false;

    // Payment vars
    productCategory: ProductCategory = ProductCategory.ADVENTURE_ACCESS;
    referenceType: KioskReferenceType = KioskReferenceType.ADVENTURE;

    constructor(
        public layout: LayoutService,
        private authService: AuthService,
        private adventureService: AdventureService,
        private trailService: TrailService,
        private poiService: PoiService
    ) {
    }

    private static overwriteWithItemName(trailOrPoi: Trail | DraftPoi, adventureItems: AdventureItem[]): string {
        for (const j in adventureItems) {
            if (adventureItems[j].refKey === trailOrPoi.key) {
                if (adventureItems[j].name) {
                    return adventureItems[j].name;
                } else {
                    console.error('No name on item', adventureItems[j]);
                    break;
                }
            }
        }
        console.warn('Could not set name', trailOrPoi);
        return trailOrPoi.name; // Fallback
    }

    ngAfterViewChecked() {
        const adventureEditComponent = this;
        if (!this.colorPickerIsLoaded && $('#tintColorPickerGroup').length > 0) {
            $(function () {
                const tintColorPickerGroup = $('#tintColorPickerGroup');
                tintColorPickerGroup.colorpicker({
                    format: 'hex'
                });
                tintColorPickerGroup.on('colorpickerChange', (colorPickerChangeEvent: any) => {
                    adventureEditComponent._adventure.tintColor = colorPickerChangeEvent.color.toString();
                    adventureEditComponent.settingsChanged();
                });
            });
            this.colorPickerIsLoaded = true;
        }

        const timeBox = $('#timebox');
        if (!this.timeboxIsLoaded && timeBox.length > 0) {
            $(function () {
                timeBox.daterangepicker(
                    {
                        autoUpdateInput: false,
                        timePicker: true,
                        timePicker24Hour: true,
                        timePickerIncrement: 30,
                        locale: {
                            format: ADVENTURE_ITEM_TIME_FORMAT,
                            cancelLabel: 'Clear'
                        }
                    },
                    function (start: any, end: any) {
                        timeBox.data('daterangepicker').setStartDate(start);
                        timeBox.data('daterangepicker').setEndDate(end);
                        adventureEditComponent.newItemTimeBox = {
                            start: start.unix() * 1000,
                            end: end.unix() * 1000
                        };
                    }
                );
                timeBox.on('apply.daterangepicker', function (ev: any, picker: any) {
                    $(this).val(
                        picker.startDate.format(ADVENTURE_ITEM_TIME_FORMAT) +
                        ' - ' +
                        picker.endDate.format(ADVENTURE_ITEM_TIME_FORMAT)
                    );
                });
            });
            this.timeboxIsLoaded = true;
        }

        if (!this.adventureItemListLoaded && this.adventureItems && this.adventureItems.length > 0 && $('#adventureItemList').length > 0) {
            $(function () {
                $('#adventureItemList').sortable({
                    placeholder: 'sort-highlight',
                    handle: '.handle',
                    forcePlaceholderSize: true,
                    zIndex: 999999,
                    update: function (event: any) {
                        adventureEditComponent.adventureItems.forEach((adventureItem) => {
                            for (const j in event.target.children) {
                                if (event.target.children[j].id === adventureItem.key && adventureItem.order !== (parseInt(j, 10) + 1)) {
                                    $('#' + event.target.children[j].id + ' > .element-order').text(parseInt(j, 10) + 1);
                                    adventureItem.order = parseInt(j, 10) + 1;
                                    adventureEditComponent.adventureService.updateAdventureItemSettings(adventureItem).then();
                                }
                            }
                        });
                    }
                });
            });
            this.adventureItemListLoaded = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Init functions
     */
    private init() {
        this.removeElementCandidate = null;
        this.markerMap = null;
        this.elementsMap = null;
        this.mapTrails = null;
        this.mapOnlyTrails = null;
        this.mapPois = null;
        this.mapOnlyPois = null;
        this.drawnMapElements = {};
        this.nextSortOrder = 1;
        this.initTextModel();
        this.getAdventureItems();
    }

    private initTextModel(): void {
        this.textModel = {items: []};
        this.textModel.items.push({
            name: 'Name',
            varName: 'name',
            help: 'The name of this adventure.',
            placeholder: 'Adventure name',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Header',
            varName: 'header',
            help: 'A header-text used as a header for the description.',
            placeholder: 'Header text',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'description',
            help: 'A short description of the adventure',
            placeholder: 'Adventure description',
            type: TextModelItemType.TEXT_AREA
        });
    }

    private getAdventureItems(): void {
        this.adventureService.getAdventureItems(this._adventure.key)
            .pipe(takeUntil(this.destroy$))
            .subscribe((items) => {
                // Initialising lists for arranging items
                const adventureItems: AdventureItem[] = [];
                const itemsMapOnly: AdventureItem[] = [];
                const mapTrailObservables: Observable<Trail>[] = [];
                const mapOnlyTrailObservables: Observable<Trail>[] = [];
                const mapPoiObservables: Observable<DraftPoi>[] = [];
                const mapOnlyPoiObservables: Observable<DraftPoi>[] = [];

                // Arrange items into lists
                for (const i in items) {
                    if (!items[i].mapOnly) {
                        if (items[i].refType === ADVENTURE_REF_TYPE_TRAIL) {
                            mapTrailObservables.push(this.trailService.getTrail(items[i].refKey));
                        } else {
                            mapPoiObservables.push(this.poiService.getPoi(items[i].refKey));
                        }
                        adventureItems.push(items[i]);
                        this.nextSortOrder++;
                    } else {
                        if (items[i].refType === ADVENTURE_REF_TYPE_TRAIL) {
                            mapOnlyTrailObservables.push(this.trailService.getTrail(items[i].refKey));
                        } else {
                            mapOnlyPoiObservables.push(this.poiService.getPoi(items[i].refKey));
                        }
                        itemsMapOnly.push(items[i]);
                    }
                }

                // Set adventure items and map only items
                adventureItems.sort((a, b) => a.order - b.order);
                this.adventureItems = adventureItems;
                this.itemsMapOnly = itemsMapOnly;

                // Map elements observables - make sure everything is observed, even if a list has zero entries.
                const mapTrailsObservable: Observable<Trail[]> = (
                    (mapTrailObservables.length === 0) ? of([]) : zip(...mapTrailObservables)
                );
                const mapOnlyTrailsObservable: Observable<Trail[]> = (
                    (mapOnlyTrailObservables.length === 0) ? of([]) : zip(...mapOnlyTrailObservables)
                );
                const mapPoisObservable: Observable<DraftPoi[]> = (
                    (mapPoiObservables.length === 0) ? of([]) : zip(...mapPoiObservables)
                );
                const mapOnlyPoisObservable: Observable<DraftPoi[]> = (
                    (mapOnlyPoiObservables.length === 0) ? of([]) : zip(...mapOnlyPoiObservables)
                );

                // Init map when all observables are observed
                zip(mapTrailsObservable, mapOnlyTrailsObservable, mapPoisObservable, mapOnlyPoisObservable)
                    .subscribe(([mapTrails, mapOnlyTrails, mapPois, mapOnlyPois]) => {
                        // Set successful elements
                        this.mapTrails = mapTrails.filter((t) => t);
                        this.mapTrails.forEach((trail) => {
                            trail.name = AdventureEditComponent.overwriteWithItemName(trail, this.adventureItems);
                        });
                        this.mapOnlyTrails = mapOnlyTrails.filter((t) => t);
                        this.mapOnlyTrails.forEach((trail) => {
                            trail.name = AdventureEditComponent.overwriteWithItemName(trail, this.itemsMapOnly);
                        });
                        this.mapPois = mapPois.filter((p) => p);
                        this.mapPois.forEach((poi) => {
                            poi.name = AdventureEditComponent.overwriteWithItemName(poi, this.adventureItems);
                        });
                        this.mapOnlyPois = mapOnlyPois.filter((p) => p);
                        this.mapOnlyPois.forEach((poi) => {
                            poi.name = AdventureEditComponent.overwriteWithItemName(poi, this.itemsMapOnly);
                        });

                        // Draw maps
                        this.initMaps();
                    });
            });
    }

    private initMaps(): void {
        // Calculate map bounds from pois and trails.
        const mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        if (this.mapPois.length > 0 || this.mapTrails.length > 0 || this.mapOnlyTrails.length > 0) {
            this.mapPois.map((mapPoi) => mapBounds.extend({lat: mapPoi.latitude, lng: mapPoi.longitude}));
            this.mapTrails.concat(this.mapOnlyTrails).forEach((trail) => {
                mapBounds.extend({lat: trail.boundsNorth, lng: trail.boundsEast});
                mapBounds.extend({lat: trail.boundsSouth, lng: trail.boundsWest});
            });
        } else {
            const userPos1: google.maps.LatLngLiteral = GeoService.geohashToLatLngLiteral(this.authService.user.geohash.substr(0, 5) + '1');
            const userPos2: google.maps.LatLngLiteral = GeoService.geohashToLatLngLiteral(this.authService.user.geohash.substr(0, 5) + 'z');
            mapBounds.extend(userPos1);
            mapBounds.extend(userPos2);
        }

        // Adventure marker position
        if (typeof this._adventure.latitude === 'number' && this._adventure.latitude > -90 && this._adventure.latitude < 90) {
            this.markerLatLngLiteral = {
                lat: this._adventure.latitude,
                lng: this._adventure.longitude
            };
            mapBounds.extend(this.markerLatLngLiteral);
        } else {
            this.markerLatLngLiteral = {
                lat: mapBounds.getCenter().lat(),
                lng: mapBounds.getCenter().lng()
            };
        }
        // Marker map
        const markerMapProp: google.maps.MapOptions = Object.assign({}, DefaultMapProp);
        markerMapProp.fullscreenControl = false;
        this.markerMap = new google.maps.Map(this.gMarkerMapElement.nativeElement, markerMapProp);
        this.markerMap.fitBounds(mapBounds, {bottom: 1, left: 1, right: 1, top: 1});

        // Adventure marker for marker map
        const marker = new google.maps.Marker({
            position: this.markerLatLngLiteral,
            draggable: true,
            clickable: false,
            map: this.markerMap
        });
        marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
            this.markerLatLngLiteral = {lat: event.latLng.lat(), lng: event.latLng.lng()};
            this._adventure.latitude = event.latLng.lat();
            this._adventure.longitude = event.latLng.lng();
            this.settingsChanged();
        });

        // Elements map
        this.elementsMap = new google.maps.Map(this.gElementsMapElement.nativeElement, DefaultMapProp);
        this.elementsMap.fitBounds(mapBounds, {bottom: 1, left: 1, right: 1, top: 1});

        // Draw on map
        this.mapOnlyTrails.forEach((trail) => this.drawTrailOnElementsMap(trail, true));
        this.mapOnlyPois.forEach((poi) => this.drawPoiOnElementsMap(poi, true));
        this.mapTrails.forEach((trail) => this.drawTrailOnElementsMap(trail, false));
        this.mapPois.forEach((poi) => this.drawPoiOnElementsMap(poi, false));
    }

    private drawTrailOnElementsMap(trail: Trail, mapOnly: boolean) {
        // Start marker
        this.drawnMapElements[trail.key + 'm'] = new google.maps.Marker({
            position: {
                lat: trail.startPoint.latitude,
                lng: trail.startPoint.longitude
            },
            draggable: false,
            clickable: false,
            map: this.elementsMap,
            icon: this.trailService.getTrailIconUrl(trail, mapOnly),
            title: 'Start of ' + trail.name
        });

        // Polyline
        const trailLatLngArray: LatLngArray = polyTool.decode(trail.encodedPolyline, {factor: 1e6});
        const trailLatLngLiteralArray: google.maps.LatLngLiteral[] = [];
        trailLatLngArray.forEach((latLng) => {
            trailLatLngLiteralArray.push({lat: latLng[1], lng: latLng[0]});
        });
        this.drawnMapElements[trail.key + 'p'] = new google.maps.Polyline({
            path: trailLatLngLiteralArray,
            strokeColor: trail.color,
            strokeOpacity: mapOnly ? 0.4 : null,
            strokeWeight: mapOnly ? 2 : null,
            map: this.elementsMap,
            icons: [{
                fixedRotation: false,
                icon: {
                    strokeColor: mapOnly ? '#DDDDDD' : '#CCCCCC',
                    path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
                },
                repeat: '100px'
            }]
        });
    }

    private drawPoiOnElementsMap(poi: DraftPoi, mapOnly: boolean) {
        this.drawnMapElements[poi.key] = new google.maps.Marker({
            position: {
                lat: poi.latitude,
                lng: poi.longitude
            },
            draggable: false,
            clickable: false,
            map: this.elementsMap,
            icon: this.poiService.getPoiIconUrl(poi, mapOnly),
            title: poi.name
        });
    }

    toggleFeature(event: Event, key: string) {
        const currentTargetChecked: boolean = (event.currentTarget['checked'] === true);
        if (typeof this.drawnMapElements[key] === 'object' && this.drawnMapElements[key] !== null) {
            this.drawnMapElements[key].setVisible(currentTargetChecked);
        } else {
            this.drawnMapElements[key + 'p'].setVisible(currentTargetChecked);
            this.drawnMapElements[key + 'm'].setVisible(currentTargetChecked);
        }
    }

    /**
     * Settings functions
     */
    settingsChanged(): void {
        this.disableSaveSettings = false;
    }

    saveSettings(): void {
        this.disableSaveSettings = true;
        this.adventureService.updateSettings(this._adventure)
            .then((adventure) => {
                this._adventure = adventure;
                this.settingsChangedInfo++;
            })
            .catch((err) =>
                console.error('Settings-Update error occurred:', err.message)
            );
    }

    /**
     * Image uploaded
     */
    onUploadedImageUrl(uploadedImageUrl: string): void {
        this._adventure.imageUrl = uploadedImageUrl;
        this.adventureService.updateImage(this._adventure)
            .catch((err) => console.error('Image update error occurred:', err.message));
    }

    /**
     * Adventure Elements/Items functions
     */
    copyElementLink(element: AdventureItem): Promise<void> {
        let text: string;
        switch (element.refType) {
            case 1:
                text = 'poi:';
                break;
            default:
                text = 'trail:';
                break;
        }
        text += element.key;
        return navigator.clipboard.writeText(text);
    }

    setRemoveElementCandidate(element: AdventureItem) {
        this.removeElementCandidate = element;
        $('#modal-remove').modal('show');
    }

    removeElementFromAdventure(elementKey: string) {
        this.adventureService.deleteAdventureItem(elementKey)
            .then(() => {
                this.removeElementCandidate = null;
                $('#modal-remove').modal('hide');
            });
    }

    loadTrails() {
        this._trailAreas.forEach((trailArea) => {
            if (trailArea.key === this.newItemTrailsTrailAreaKey) {
                this.trailService.getTrailsOnTrailArea(trailArea.key)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((trails) => this._trails = trails);
            }
        });
    }

    loadPois() {
        this._trailAreas.forEach((trailArea) => {
            if (trailArea.key === this.newItemPoisTrailAreaKey) {
                this.poiService.getPoisForTrailArea(trailArea.key)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((pois) => this._pois = pois);
            }
        });
    }

    createNewItem(): void {
        const newItem: AdventureItem = <AdventureItem>{
            adventureKey: this._adventure.key,
            mapOnly: this.newItemMapOnly,
            name: this.newItemName,
            description: this.newItemDescription
        };

        if (!this.newItemMapOnly) {
            newItem.order = this.nextSortOrder;
            newItem.adventurePoints = this.newItemAdventurePoints;
        }

        if (this.newItemIsTimeBoxed) {
            if (this.newItemTimeBox === null) {
                alert('Please choose dates and times for the time box');
                return;
            }
            newItem.timeboxStart = this.newItemTimeBox.start;
            newItem.timeboxEnd = this.newItemTimeBox.end;
        }

        switch (this.newItemType) {
            case 'trail':
                if (this.newItemTrailKey === 'none') {
                    alert('Please choose a specific trail.');
                    return;
                }
                newItem.refType = 2;
                newItem.refKey = this.newItemTrailKey;
                break;

            case 'poi':
                if (this.newItemPoiKey === 'none') {
                    alert('Please choose a specific point of interest.');
                    return;
                }
                newItem.refType = 1;
                newItem.refKey = this.newItemPoiKey;
                if (this.newItemVoucherPin) {
                    const newPin = '' + this.pin1 + this.pin2 + this.pin3 + this.pin4;
                    if (newPin.length !== 4) {
                        alert('Please ensure that the pin-values are correctly filled.');
                        return;
                    }
                    newItem.voucherPin = '' + this.pin1 + this.pin2 + this.pin3 + this.pin4;
                }
                break;

            default:
                alert('Please choose an adventure item type.');
                return;
        }
        this.adventureService.createNewAdventureItem(newItem);
        this.getAdventureItems();
    }

    /**
     * Save texts
     */
    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.adventureService.updateTexts(<Adventure>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    /**
     * Infolet functions
     */
    infoletChanged(infoletIndex: number, usesEditor: boolean = false, event: any = null): void {
        const {editor} = <ChangeEvent>event;
        if (usesEditor) {
            this._adventure.infolets[infoletIndex].bodyContent = editor.getData();
        }
        this.disableSaveInfolet[infoletIndex] = false;
    }

    saveInfolet(infoletIndex: number): void {
        this.disableSaveInfolet[infoletIndex] = true;
        switch (this._adventure.infolets[infoletIndex].type) {
            case InfoletType.EDITOR:
                this._adventure.infolets[infoletIndex].content = null;
                break;
            case InfoletType.RANKING:
                const url = environment.www + 'adventure-ranking/' + this._adventure.key;
                this._adventure.infolets[infoletIndex].content = '<!DOCTYPE html><html lang="en">'
                    + '<head><title>Ranking</title><meta http-equiv="Refresh" content="0; url=' + url + '" /></head>'
                    + '<body><h1>Ranking on the ' + this._adventure.name + ' adventure</h1>'
                    + '<p>Redirecting to ranking - or click <a href="' + url + '">this link</a>.</p></body>'
                    + '</html>';
                this._adventure.infolets[infoletIndex].bodyContent = null;
                break;
            case InfoletType.RAW:
                this._adventure.infolets[infoletIndex].bodyContent = null;
                break;
            default:
                alert('No infolet type selected. Please select an infolet type and save the infolet again.');
                return;
        }
        this.adventureService.updateInfolet(this._adventure.key, infoletIndex, this._adventure.infolets[infoletIndex])
            .then(() => {
                this.infoletChangedInfo[infoletIndex]++;
            })
            .catch((err) =>
                console.error('Infolet-Update error occurred:', infoletIndex, err.message)
            );
    }

    onKioskProductCreated(kioskProductKey: string): Promise<void> {
        this._adventure.accessProductKey = kioskProductKey;
        return this.adventureService.updatePayment(this._adventure);
    }
}
