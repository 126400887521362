import { Component } from '@angular/core';

// Services
import { AuthService } from '../core/auth.service';

@Component({
    selector: '.main-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {

    constructor(
        public authService: AuthService
    ) {
    }

}
