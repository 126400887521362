<div class="content-wrapper">
    <app-content-header-section contentPageId="emailExtract" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">

            <div class="card card-outline card-secondary">
                <div class="card-header">
                    <h3 class="card-title"><i class="fas fa-mail-bulk"></i>Emails</h3>
                </div>
                <div class="card-body px-0">
                    <table id="emailsDataTable" class="table row-bordered table-striped" *ngIf="emailUsers">
                        <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <!-- <th>Last Login Time</th>
                            <th>Device OS</th>
                            <th>Device App Version</th>
                            <th>Device Country</th>
                            <th>Device Locale</th>
                            <th>Navn</th>
                            <th>Profile Key</th> -->
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let emailUser of emailUsers">
                            <td>{{ emailUser.email }}</td>
                            <td>{{ emailUser.name }}</td>
                            <!-- <td [attr.data-order]="emailUser.lastLoginTime">{{ emailUser.lastLoginTime | date:'dd/MM/yyy' }}</td>
                            <td>{{ emailUser.deviceOS }}</td>
                            <td>{{ emailUser.deviceAppVersion }}</td>
                            <td>{{ emailUser.deviceCountry }}</td>
                            <td>{{ emailUser.deviceLocale }}</td>
                            <td>{{ emailUser.name }}</td>
                            <td>{{ emailUser.userID }}</td> -->
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!emailUsers && orderedEmails" class="overlay">
                    <i class="fas fa-spinner fa-3x fa-spin"></i>
                </div>
                <div class="card-footer" *ngIf="!orderedEmails">
                    <button (click)="loadEmails()" class="btn btn-secondary">Load emails</button>
                </div>
            </div>

        </div>
    </section>
</div>
