import { Component, Input } from '@angular/core';

// Services
import { LayoutService } from '../../core/layout.service';

// Interfaces
import { Breadcrumb, PageParameters } from '../../interfaces/layout';

@Component({
    selector: 'app-content-header-section',
    templateUrl: './content-header-section.component.html',
    styleUrls: ['./content-header-section.component.css']
})
export class ContentHeaderSectionComponent {
    title = 'loading';
    titleLead = '...';
    icon = 'fas fa-spinner fa-2x fa-spin';
    breadcrumbs: Breadcrumb[] = [];
    linkText = 'loading';

    constructor(
        private layoutService: LayoutService
    ) {
    }

    _pageParams: PageParameters = {};

    @Input() set pageParams(params: PageParameters) {
        this._pageParams = params;
        this.updateState();
    }

    _contentPageId: string;

    @Input() set contentPageId(id: string) {
        this._contentPageId = id;
        this.updateState();
    }

    private updateState() {
        if (this._contentPageId) {
            this.icon = this.layoutService.getContentPageIcon(this._contentPageId);
            this.title = this.layoutService.getTitle(this._contentPageId, this._pageParams);
            this.titleLead = this.layoutService.getTitleLead(this._contentPageId, this._pageParams);
            this.breadcrumbs = this.layoutService.getBreadcrumbs(this._contentPageId, this._pageParams);
            this.linkText = this.layoutService.getLinkText(this._contentPageId, this._pageParams);
        }
    }

}
