import { Component, AfterViewChecked, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// Interfaces
import { EmailExtractUser, EmailExtractUserAddress } from '../../interfaces/user';

// Services
import { UserService } from '../../firebase-services/user.service';

declare var $: any;

@Component({
    selector: 'app-email-extract',
    templateUrl: './email-extract.component.html',
    styleUrls: ['./email-extract.component.css']
})
export class EmailExtractComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    orderedEmails: boolean = false;

    emailUsers: EmailExtractUserAddress[] = null;

    // Datatable
    dataTableName: string = '#emailsDataTable';
    dataTable: any = null;
    dataTableCreated: boolean = false;

    constructor(
        private userService: UserService
    ) {
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.dataTableName + '>tbody>tr').length > 0)) {
            this.dataTable = $(this.dataTableName).DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'csvHtml5',
                    'excelHtml5'
                ],
                'order': [[0, 'asc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    loadEmails() {
        this.orderedEmails = true;
        var emailsSubscription = this.userService.loadEmails()
            .subscribe((emailUsers) => {
                this.emailUsers = emailUsers;
                emailsSubscription.unsubscribe();
            });
    }

}
