import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TrailAreaService } from '../../../../firebase-services/trail-area.service';
import { AdventureService } from '../../../../firebase-services/adventure.service';

// Interfaces
import { TrailArea } from '../../../../interfaces/trailArea';
import { Adventure, AdventureItem } from '../../../../interfaces/adventure';

@Component({
    selector: 'app-trail-area-adventure-item-edit',
    templateUrl: './trail-area-adventure-item-edit.component.html',
    styleUrls: ['./trail-area-adventure-item-edit.component.css']
})
export class TrailAreaAdventureItemEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    area: TrailArea = null;
    adventure: Adventure = null;
    adventureItem: AdventureItem = null;
    routerRoot: string;
    routerBack: string;

    constructor(
        private route: ActivatedRoute,
        private trailAreaService: TrailAreaService,
        private adventureService: AdventureService
    ) {
    }

    ngOnInit() {
        this.trailAreaService.getTrailArea(this.route.snapshot.paramMap.get('areaKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((area) => {
                this.routerRoot = '/trail-area/' + area.key;
                this.area = area;
            });
        this.adventureService.getAdventure(this.route.snapshot.paramMap.get('adventureKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventure) => {
                this.routerBack = '/adventures/' + adventure.key;
                this.adventure = adventure;
            });
        this.adventureService.getAdventureItem(this.route.snapshot.paramMap.get('adventureItemKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((adventureItem) => this.adventureItem = adventureItem);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
