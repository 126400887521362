import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TrailAreaService } from '../../firebase-services/trail-area.service';
import { UserService } from '../../firebase-services/user.service';

// Interfaces
import { TrailArea } from '../../interfaces/trailArea';
import { User } from '../../interfaces/user';

@Component({
    selector: 'app-area-managers',
    templateUrl: './area-managers.component.html',
    styleUrls: ['./area-managers.component.css']
})
export class AreaManagersComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    trailArea: TrailArea = null;
    admins: User[] = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private trailAreaService: TrailAreaService,
        private userService: UserService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Load data for the current trail area
     */
    init() {
        this.trailArea = null;
        this.admins = null;
        this.getTrailArea(this.route.snapshot.paramMap.get('trailAreaKey'));
        this.getAdministrators(this.route.snapshot.paramMap.get('trailAreaKey'));
    }

    private getTrailArea(trailAreaKey: string): void {
        this.trailAreaService.getTrailArea(trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailArea) => {
                this.trailArea = trailArea;
            });
    }

    /**
     * Loads the trail area administrators
     */
    private getAdministrators(trailAreaKey: string): void {
        this.userService.getTrailAreaManagers(trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((admins) => this.admins = admins.filter((a) => a));
    }

}
