import { Component, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

// Services
import { AuthService } from '../../core/auth.service';
import { LayoutService } from '../../core/layout.service';
import { RemoteConfigService } from '../../firebase-services/remote-config.service';
import { NewsStoryService } from '../../firebase-services/news-story.service';

// Interfaces
import { NewsStory, NewsStoryOnList } from '../../interfaces/news-story';

const platforms = [
    'iOS',
    'Android'
];

@Component({
    selector: 'app-news-room-list',
    templateUrl: './news-room-list.component.html',
    styleUrls: ['./news-room-list.component.css']
})
export class NewsRoomListComponent implements OnDestroy {
    objectKeys = Object.keys;
    destroy$: Subject<boolean> = new Subject<boolean>();

    @Input() navigateTo: string;
    @Input() listName = 'News Stories';
    @Input() storyType: string;
    @Input() theme = 'news';

    @Input() set roomName(roomName: string) {
        this._roomOrListKey = roomName;
        this.getNewsStories();
    }

    @Input() set listKey(listKey: string) {
        this.showListRemove = true;
        this._roomOrListKey = listKey;
        this.getNewsStoriesFromList();
    }

    _roomOrListKey: string;
    newsStories: NewsStoryOnList[];
    applicable = false;
    showStoryColumn = false;
    showListRemove = false;
    minimumAppVersions: { [platform: string]: string } = null;

    constructor(
        public authService: AuthService,
        public layout: LayoutService,
        private remoteConfig: RemoteConfigService,
        private newsStoryService: NewsStoryService
    ) {
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private getNewsStories(): void {
        this.newsStoryService.getNewsStoriesIn(this._roomOrListKey)
            .pipe(take(1))
            .subscribe((newsStories) => {
                // sort stories by descending date
                newsStories.sort(function(a,b){
                    return b.publishedAt - a.publishedAt;
                });
                this.filterListElements(newsStories);
            });
    }

    private filterListElements(newsStories: NewsStory[]) {
        const storiesOfType: NewsStoryOnList[] = [];
        switch (this.storyType) {
            case 'draft':
                // Filter drafts
                newsStories.forEach((story) => {
                    if (!story.publishedAt) {
                        storiesOfType.push(<NewsStoryOnList>story);
                    }
                });
                this.newsStories = storiesOfType;

                // Draft options
                this.showStoryColumn = true;
                break;

            case 'published':
                // Filter published
                newsStories.forEach((story) => {
                    if (story.publishedAt && !story.archivedAt) {
                        storiesOfType.push(<NewsStoryOnList>story);
                    }
                });

                // Get min-app-version from remote config
                const remoteMinimumPromise = this.remoteConfig.getMinimumVersions()
                    .then((minimumAppVersionString) => {
                        const minimumAppVersions: { [platform: string]: string } = {};
                        platforms.forEach((platform) => {
                            const minimumAppVersionObject = JSON.parse(minimumAppVersionString)[platform];
                            minimumAppVersions[platform] = minimumAppVersionObject['major'] + '.' + minimumAppVersionObject['minor'] + '.';
                        });
                        return minimumAppVersions;
                    });
                remoteMinimumPromise
                    .then((minimum) => this.minimumAppVersions = minimum);

                // Add reach to stories (NOTE: currently disabled)
                this.newsStoryService.addReachToList(storiesOfType, false)
                    .pipe(take(1))
                    .subscribe((reachStories) => {
                        // Published options
                        this.showStoryColumn = true;
                        this.applicable = true;

                        this.newsStories = reachStories;
                    });
                break;

            case 'archived':
                // Filter archived
                newsStories.forEach((story) => {
                    if (story.archivedAt) {
                        storiesOfType.push(<NewsStoryOnList>story);
                    }
                });
                // Archived options
                this.showStoryColumn = true;

                this.newsStories = storiesOfType;
                break;

            case 'reach':
                // Filter published
                newsStories.forEach((story) => {
                    if (story.publishedAt && !story.archivedAt) {
                        storiesOfType.push(<NewsStoryOnList>story);
                    }
                });

                // Add reach to stories (NOTE: currently disabled)
                this.newsStoryService.addReachToList(storiesOfType, true)
                    .pipe(take(1))
                    .subscribe((reachStories) => {
                        this.newsStories = reachStories;
                    });
                break;

            default:
                break;
        }
    }

    private getNewsStoriesFromList(): void {
        this.newsStoryService.getNewsStoriesFromList(this._roomOrListKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((newsStories) => this.newsStories = newsStories);
    }

    removeFromList(newsStory: NewsStoryOnList): Promise<void> {
        return this.newsStoryService.removeWelcomeStoryFromList(this._roomOrListKey, newsStory);
    }

    addToFeed(story: NewsStory, profileKey: string): Promise<any> {
        if (!story.key) {
            alert('Please reload page - and tell Frederik you saw this message!');
        }
        return this.newsStoryService.addStoryToFeedList(story.feedEntryKey || story.key, profileKey);
    }

    addToFeeds(story: NewsStory, profileKeys: string[]): Promise<any> {
        if (!story.key) {
            alert('Please reload page - and tell Frederik you saw this message!');
        }
        return this.newsStoryService.addStoryToFeedsList(story.feedEntryKey || story.key, profileKeys);
    }

}
