import { Pipe, PipeTransform } from '@angular/core';
import { KeyValueBasePipe } from './key-value-base.pipe';
import { PoiCategory } from '../interfaces/poi-category';

interface KeyPoiCategory extends PoiCategory {
    key: string;
}

@Pipe({
    name: 'keyValuePoiCategory'
})
export class KeyValuePoiCategoryPipe extends KeyValueBasePipe implements PipeTransform {

    transform(input: { [p: string]: PoiCategory }): KeyPoiCategory[] {
        const result = <KeyPoiCategory[]>super.transform(input);
        return result.sort((a, b) => a.sortOrder - b.sortOrder);
    }

}
