import { Injectable } from '@angular/core';

// Extends
import { StateService } from './state.service';

// Services
import { PoiService } from '../firebase-services/poi.service';

// Interfaces
import { DraftPoi, PublicPoi } from '../interfaces/poi';
import { PublicPoiCategory } from '../interfaces/poi-category';

@Injectable({
    providedIn: 'root'
})
export class PoiStateService extends StateService {

    protected static canPublish(element: DraftPoi, publicPoiCategories?: { [label: string]: PublicPoiCategory }): boolean {
        if (!publicPoiCategories) {
            return false;
        }
        // Required location
        if (typeof element.latitude !== 'number' || typeof element.longitude !== 'number') {
            return false;
        }
        // Required name
        if (typeof element.name !== 'string' || element.name.trim() === '') {
            return false;
        }
        // Check that categories are selected
        if (!Array.isArray(element.categories) || element.categories.length === 0) {
            return false;
        }
        // Check if selected categories all exists as public categories
        for (const category of element.categories) {
            if (!publicPoiCategories[category.label]) {
                return false;
            }
            if (category.subLabel && !publicPoiCategories[category.label] &&
                !publicPoiCategories[category.label].subCategories[category.subLabel]) {
                return false;
            }
        }

        return true;
    }

    protected static isOutOfSync(
        poi: DraftPoi,
        publicPoi: PublicPoi,
        publicPoiCategories?: { [label: string]: PublicPoiCategory }
    ): boolean {
        if (!publicPoiCategories) {
            return false;
        }
        // Can't be out sync without public.
        if (!publicPoi) {
            // Unless the POI is marked as active...
            return poi.active ?? false;
        }
        // Name and description
        if (poi.name !== publicPoi.name || poi.description !== publicPoi.description) {
            return true;
        }
        // Image
        if (poi.imageUrl !== publicPoi.imageUrl) {
            return true;
        }
        // Location
        if (poi.latitude !== publicPoi.latitude || poi.longitude !== publicPoi.longitude) {
            return true;
        }
        // First icon
        if (poi.iconUrl !== publicPoi.categoryIconUrls[0]) {
            return true;
        }
        // Extras
        if (poi.facebookLink !== publicPoi.facebookUrl ||
            poi.mail !== publicPoi.mail ||
            poi.phone !== publicPoi.phone ||
            poi.www !== publicPoi.websiteUrl) {
            return true;
        }
        // Language differences
        const poiLang = JSON.parse(JSON.stringify(poi.lang ?? {}));
        const publicLang = JSON.parse(JSON.stringify(publicPoi.lang ?? {}));
        const allUsedLanguages = Object.keys(Object.assign(Object.assign({}, poiLang), publicLang));
        for (const languageCode of allUsedLanguages) {
            poiLang[languageCode] ??= {};
            publicLang[languageCode] ??= {};
            if (poiLang[languageCode]['name'] !== publicLang[languageCode]['name'] ||
                poiLang[languageCode]['facebookLink'] !== publicLang[languageCode]['facebookUrl'] ||
                poiLang[languageCode]['www'] !== publicLang[languageCode]['websiteUrl'] ||
                poiLang[languageCode]['description'] !== publicLang[languageCode]['description']) {
                return true;
            }
        }
        if (PoiStateService.categoriesOutOfSync(poi, publicPoi, publicPoiCategories)) {
            return true;
        }

        // In sync
        return false;
    }

    protected static isPublished(publicPoi: PublicPoi, active?: boolean): boolean {
        return (typeof publicPoi === 'object' && publicPoi !== null || active);
    }

    static categoriesOutOfSync(poi: DraftPoi, publicPoi: PublicPoi, publicPoiCategories: { [label: string]: PublicPoiCategory }): boolean {
        // Can't be out sync without public.
        if (!publicPoi) {
            return false;
        }
        // No categories selected
        if (!Array.isArray(poi.categories) || poi.categories.length === 0) {
            return true;
        }
        // Difference in icons
        const clusterIcons = PoiService.getPoiClusterIcons(poi, publicPoiCategories);
        if (JSON.stringify(clusterIcons ?? []) !== JSON.stringify(publicPoi.categoryIconUrls ?? [])) {
            return true;
        }
        // Categories in sync
        return false;
    }

}
