<div class="content-wrapper">
    <app-content-header-section contentPageId="onTrailStatus" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div *ngIf="onTrailApplications" class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">

                    <div class="info-box bg-green">
                        <span class="info-box-icon"><i class="far fa-thumbs-up"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Approved Applications</span>
                            <span class="info-box-number">{{ numberOfApproved }}</span>
                            <div class="progress">
                                <div class="progress-bar" [style]="setPercentageWidth(numberOfApproved, onTrailApplications.length)"></div>
                            </div>
                            <span class="progress-description">
                                {{ numberOfNew }} new, {{ numberOfInProgress }} in progress and {{ numberOfRejected }} rejected.
                            </span>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">

                    <div class="info-box bg-info">
                        <span class="info-box-icon"><i class="fas fa-link"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Applications Linked to Trails</span>
                            <span class="info-box-number">{{ numberOfConnectedTrails }}</span>
                            <div class="progress">
                                <div class="progress-bar" [style]="setPercentageWidth(numberOfConnectedTrails, onTrailApplications.length)"></div>
                            </div>
                            <span class="progress-description">
                                {{ onTrailApplications.length }} applications. <i class="fas fa-unlink"></i> {{ numberOfUnconnectedTrails }} unlinked.
                            </span>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">

                    <div class="info-box bg-yellow">
                        <span class="info-box-icon"><i class="fas fa-arrows-alt-h"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Distance for Linked Applications</span>
                            <span class="info-box-number">{{ connectedTrailsDistance }} km</span>
                            <div class="progress">
                                <div class="progress-bar" [style]="setPercentageWidth(numberOfConnectedTrails, onTrailApplications.length)"></div>
                            </div>
                            <span class="progress-description">
                                <i class="fas fa-ruler"></i> Ascent: {{ connectedTrailsHeightIncrease }} m. <i
                                    class="fas fa-ruler fa-flip-horizontal"></i> Descent: {{ connectedTrailsHeightDecrease }} m.
                            </span>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">

                    <div class="info-box bg-red">
                        <span class="info-box-icon"><i class="fas fa-route"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Avg. Distance per Linked Application</span>
                            <span class="info-box-number">{{ (connectedTrailsDistance / numberOfConnectedTrails) | number:'1.1-1' }} km</span>
                            <div class="progress">
                                <div class="progress-bar" [style]="setPercentageWidth(numberOfConnectedTrails, onTrailApplications.length)"></div>
                            </div>
                            <span class="progress-description">
                                <i class="fas fa-ruler"></i> Avg. ascent: {{ (connectedTrailsHeightIncrease / numberOfConnectedTrails) | number:'1.0-0' }}
                                m. <i
                                    class="fas fa-ruler fa-flip-horizontal"></i> Avg. descent: {{ (connectedTrailsHeightDecrease / numberOfConnectedTrails) | number:'1.0-0' }}
                                m.
                            </span>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">

                    <div class="info-box bg-purple">
                        <span class="info-box-icon"><i class="fas fa-chart-pie"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Total Distance Estimate</span>
                            <span class="info-box-number">~{{ onTrailApplications.length * (connectedTrailsDistance / numberOfConnectedTrails) | number:'1.0-0' }}
                                km</span>
                            <div class="progress">
                                <div class="progress-bar" style="width: 100%"></div>
                            </div>
                            <span class="progress-description">
                                <i class="fas fa-ruler"></i> Est. ascent: {{ onTrailApplications.length * (connectedTrailsHeightIncrease / numberOfConnectedTrails) / 1000 | number:'1.1-1' }}
                                km. <i
                                    class="fas fa-ruler fa-flip-horizontal"></i> Est. descent: {{ onTrailApplications.length * (connectedTrailsHeightDecrease / numberOfConnectedTrails) / 1000 | number:'1.1-1' }}
                                km.
                            </span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-12">

                    <div class="card card-outline card-danger">
                        <div class="card-header">
                            <h3 class="card-title">List of OnTrail Applications</h3>
                        </div>

                        <div *ngIf="!dataReady" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>

                        <div class="card-body pb-0">
                            <div class="alert alert-info alert-dismissible">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <h4><i class="icon fa fa-info"></i> About this list</h4>
                                <div class="row">
                                    <div class="col-lg-4 col-md-5 col-sm-12">
                                        <div>The list of applications are sorted by</div>
                                        <ol>
                                            <li>The current status-state</li>
                                            <li>Whether it is linked to a Mountainbike United Trail</li>
                                            <li>Application time</li>
                                        </ol>
                                    </div>
                                    <div class="col-lg-8 col-md-7 col-sm-12">
                                        <div>The action buttons:</div>
                                        <ul>
                                            <li>
                                                The
                                                <button type="button"
                                                        class="btn btn-xs btn-warning mb-1">{{ statusOptions[applied.value + 1]['text'] }} <span
                                                        class="caret"></span></button>
                                                ,
                                                <button type="button"
                                                        class="btn btn-xs btn-info mb-1">{{ statusOptions[beingProcessed.value + 1]['text'] }} <span
                                                        class="caret"></span></button>
                                                ,
                                                <button type="button"
                                                        class="btn btn-xs btn-success mb-1">{{ statusOptions[approved.value + 1]['text'] }} <span
                                                        class="caret"></span></button>
                                                and
                                                <button type="button"
                                                        class="btn btn-xs btn-default mb-1">{{ statusOptions[rejected.value + 1]['text'] }} <span
                                                        class="caret"></span></button>
                                                buttons allows you to change application <strong>status</strong> state.
                                            </li>
                                            <li>
                                                <button class="btn btn-primary btn-xs mb-1"><i class="fas fa-link"></i> Setup Link</button>
                                                or
                                                <button class="btn btn-xs btn-danger mb-1"><i class="fas fa-unlink"></i> Unlink</button>
                                                will link or unlink the application to or from a Mountainbike United Trail.
                                            </li>
                                            <li>
                                                <button class="btn btn-xs btn-primary mb-1"><i class="fa fa-files-o"></i> Duplicate Application
                                                </button>
                                                will create a new application based on the current application. Useful when one application covers
                                                several trails; simply duplicate the application and link each trail.
                                            </li>
                                            <li>
                                                <button class="btn btn-xs btn-danger mb-1"><i class="far fa-trash-alt"></i> Delete</button>
                                                will delete the application. Only possible for applications that are both unlinked and new.
                                            </li>
                                            <!--li>*WORK IN PROGRESS* <button class="btn btn-xs btn-default"><i class="fa fa-user"></i> Become Trail Area Manager</button> will make you a Trail Area Manager for the Trail Area in which the linked Trail belongs.</li>
                                            <li>*WORK IN PROGRESS* <button class="btn btn-xs btn-default"><i class="far fa-edit"></i> Edit</button> allows you to edit the linked Trail.</li-->
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body px-0">

                            <table *ngIf="onTrailApplications" class="table row-bordered table-striped table-hover">
                                <tbody>
                                <tr>
                                    <th>#</th>
                                    <th>Application Name</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Link to Trail</th>
                                    <th>OnTrail Award</th>
                                    <th>Actions</th>
                                </tr>
                                <tr *ngFor="let application of onTrailApplications; let i = index">
                                    <td>{{ i + 1 }}.</td>
                                    <td>
                                        <a routerLink="/on-trail/application/{{ application.key }}">
                                            <i class="far fa-file-alt"></i> {{ (application.trackName ? application.trackName : (application.trail ? application.trail.name : '*No Name*')) }}
                                        </a>
                                    </td>
                                    <td>
                                        <div *ngIf="application.timestamp">
                                            {{ application.timestamp | date:'dd/MM/yyy HH:mm:ss' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="btn-group">
                                            <button type="button"
                                                    [ngClass]="'btn btn-xs dropdown-toggle btn-' + (application.status === rejected.value ? rejected.theme : (application.status === approved.value ? approved.theme : (application.status === beingProcessed.value ? beingProcessed.theme : applied.theme)))"
                                                    data-toggle="dropdown">{{ statusOptions[application.status + 1].text }} <span
                                                    class="caret"></span></button>
                                            <ul class="dropdown-menu" role="menu">
                                                <li *ngFor="let statusOption of statusOptions">
                                                    <a *ngIf="statusOption.value !== application.status"
                                                       (click)="updateStatus(application, statusOption.value)">{{ statusOption.text }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="application.trail">
                                            {{ application.trail.name }}
                                            <button *ngIf="!application.trail?.awards?.OnTrail" class="btn btn-xs btn-danger"
                                                    (click)="unlinkTrailFromOnTrailApplication(application)"><i class="fas fa-unlink"></i> Unlink
                                            </button>
                                        </div>
                                        <div *ngIf="!application.trail">
                                            <app-find-trail modalId="{{ application.key }}" modalTitle="Find trail for {{ application.trackName }}"
                                                            (trailSelected)="setTrailOnOnTrailApplication($event, application.key)"
                                                            btnClassType="primary btn-xs"><i class="fas fa-link"></i> Setup Link
                                            </app-find-trail>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="application.trail?.awards?.OnTrail && onTrailAward">
                                            <img [src]="onTrailAward.logoUrl" alt="Logo" class="row-image">&nbsp;
                                            <img [src]="onTrailAward.categories[application.trail?.awards?.OnTrail].signUrl" class="row-image"
                                                 alt="Sign">
                                            <span>{{ onTrailAward.categories[application.trail?.awards?.OnTrail].name }}</span>
                                        </div>
                                        <div *ngIf="!application.trail" class="text-muted">
                                            Trail not linked
                                        </div>
                                        <div *ngIf="application.trail && !application.trail?.awards?.OnTrail && application.status !== approved.value"
                                             class="text-muted">
                                            <span>Not yet approved</span>
                                        </div>
                                        <div *ngIf="application.trail && !application.trail?.awards?.OnTrail && application.status === approved.value">
                                            <a routerLink="/on-trail/application/{{ application.key }}"><i class="fas fa-certificate"></i> Set
                                                award</a>
                                        </div>
                                    </td>
                                    <td>
                                        <button class="btn btn-xs btn-primary mb-1" (click)="duplicateApplication(application)"><i
                                                class="fa fa-files-o"></i> Duplicate
                                        </button>
                                        <span *ngIf="!application.trail && (application.status === rejected.value || application.status === applied.value)">&nbsp;
                                                <button class="btn btn-xs btn-danger" (click)="deleteOnTrailApplication(application)"><i
                                                        class="far fa-trash-alt"></i> Delete</button>
                                            </span>
                                        <!--div *ngIf="application.trail" class="mb-1">
                                            *WORK IN PROGRESS*
                                            <button class="btn disabled btn-xs btn-default"><i class="fa fa-user"></i> Become Trail Area Manager</button>
                                            <button class="btn disabled btn-xs btn-default"><i class="far fa-edit"></i> Edit</button>
                                        </div-->
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
