import { AfterViewChecked, Component, OnDestroy } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../../core/layout.service';
import { MediaLibraryService } from '../../../firebase-services/media-library.service';
import { PoiService } from '../../../firebase-services/poi.service';
import { PoiCategoryService } from '../../../firebase-services/poi-category.service';
import { PoiStateService } from '../../../services/poi-state.service';

// Interfaces
import { PublishedStates, StatesBundle } from '../../../interfaces/general';
import { DraftPoi, PublicPoi } from '../../../interfaces/poi';
import { PublicPoiCategory } from '../../../interfaces/poi-category';

declare var $: any;

@Component({
    selector: 'app-poi-validator',
    templateUrl: './poi-validator.component.html',
    styleUrls: ['./poi-validator.component.css']
})
export class PoiValidatorComponent implements AfterViewChecked, OnDestroy {
    PublishedStates = PublishedStates;
    objectValues = Object.values;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    contentPageId = 'poiValidator';

    decisionMade = false;
    allPois: { [poiKey: string]: DraftPoi } = {};
    poiStates: StatesBundle = {};
    trailAreaKeys: { [poiKey: string]: string[] } = {};
    statistics = {
        [PublishedStates.DRAFT_ALTERED]: 0,
        [PublishedStates.DRAFT_SAVED]: 0,
        [PublishedStates.DRAFT_READY]: 0,
        [PublishedStates.PUBLISHED_NOT_READY]: 0,
        [PublishedStates.PUBLISHED_OUT_OF_SYNC]: 0,
        [PublishedStates.PUBLISHED_ONLINE]: 0
    };
    showOutOfSyncPublish = false;
    private popoversEnabled = false;

    private publicPoiCategories$: Observable<{ [label: string]: PublicPoiCategory }>;

    constructor(
        public layout: LayoutService,
        private afFunctions: AngularFireFunctions,
        private mediaLibraryService: MediaLibraryService,
        private poiCategoryService: PoiCategoryService,
        private poiService: PoiService
    ) {
        this.publicPoiCategories$ = this.poiCategoryService.getPublicPoiCategories()
            .pipe(takeUntil(this.destroy$));
    }

    ngAfterViewChecked() {
        const allPopovers = $('[data-toggle="popover"]');
        if (!this.popoversEnabled && allPopovers.length > 0 && Object.keys(this.allPois).length === allPopovers.length) {
            allPopovers.popover({html: true, placement: 'top', trigger: 'hover'});
            this.popoversEnabled = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    validateAllPois() {
        this.decisionMade = true;
        const allPois$ = this.poiService.getAllPois()
            .pipe(takeUntil(this.destroy$));

        allPois$
            .subscribe((pois) => {
                pois.forEach((poi) => {
                    if (this.allPois[poi.key] && JSON.stringify(poi) === JSON.stringify(this.allPois[poi.key])) {
                        return; // No changes -> no action needed.
                    }
                    this.statistics[PublishedStates.DRAFT_ALTERED]++;
                    this.poiStates[poi.key] = {
                        altered: false,
                        canPublish: false,
                        icon: 'fas fa-sync-alt fa-spin',
                        isPublished: false,
                        outOfSync: false,
                        progress: PublishedStates.DRAFT_ALTERED,
                        theme: 'warning'
                    };
                    this.loadPoiState(poi);
                    this.allPois[poi.key] = poi;
                });
            });
    }

    private loadPoiState(poi: DraftPoi) {
        const publicPoi$ = this.poiService.getPublicPoi(poi.key)
            .pipe(takeUntil(this.destroy$));
        const trailAreaKeys$ = this.poiService.getTrailAreaKeysForPoi(poi.key)
            .pipe(takeUntil(this.destroy$));

        combineLatest([publicPoi$, trailAreaKeys$, this.publicPoiCategories$])
            .subscribe(([publicPoi, trailAreaKeys, publicPoiCategories]) => {
                this.trailAreaKeys[poi.key] = trailAreaKeys;
                const oldProgress = this.poiStates[poi.key].progress;
                this.statistics[oldProgress]--;
                PoiStateService.updateState<DraftPoi, PublicPoi>(
                    poi, publicPoi, this.poiStates[poi.key], publicPoiCategories, poi.active);
                if (this.poiStates[poi.key].progress !== oldProgress &&
                    this.poiStates[poi.key].progress === PublishedStates.PUBLISHED_OUT_OF_SYNC) {
                    this.showOutOfSyncPublish = true;
                }
                this.statistics[this.poiStates[poi.key].progress]++;
            });
    }

    popoverContent(poi: DraftPoi): string {
        let content = '<div>POI key: ' + poi.key + '</div>';
        content += '<div>Trail Area keys: ';
        if (this.trailAreaKeys[poi.key] && this.trailAreaKeys[poi.key].length) {
            this.trailAreaKeys[poi.key].forEach((trailAreaKey, i) => {
                content += '<br> ' + (i + 1) + ') <span class="text-muted">' + trailAreaKey + '</span>';
            });
        } else {
            content += '<span class="text-danger">Not in a trail area</span>';
        }
        content += '</div>';
        let state = 'Error occurred';
        switch (this.poiStates[poi.key].progress) {
            case PublishedStates.DRAFT_SAVED:
                state = 'A required field is missing in draft';
                break;
            case PublishedStates.DRAFT_READY:
                state = 'Draft ready to be published';
                break;
            case PublishedStates.PUBLISHED_NOT_READY:
                state = 'Missing required field - online version exists';
                break;
            case PublishedStates.PUBLISHED_OUT_OF_SYNC:
                state = 'Draft and public versions are out of sync';
                break;
            case PublishedStates.PUBLISHED_ONLINE:
                state = 'Online';
                break;
        }
        content += '<div>State: <span class="text-' + this.poiStates[poi.key].theme + '">' + state + '</span></div>';
        if (poi.description) {
            content += '<div class="p-1 font-italic">' + poi.description.substr(0, 200) + '</div>';
        }
        return content;
    }

    publishOutOfSync() {
        this.showOutOfSyncPublish = false;
        let i = 0;
        Object.entries(this.poiStates).forEach(([poiKey, state]) => {
            if (state.progress === PublishedStates.PUBLISHED_OUT_OF_SYNC) {
                setTimeout(() => {
                    this.afFunctions.httpsCallable('publishPoi')({poiKey: poiKey})
                        .pipe(take(1))
                        .subscribe({
                            next: () => this.poiService.setActive(poiKey, true),
                            error: (err) => window.alert(poiKey + ': ' + JSON.parse(err.message).message)
                        });
                }, i * 809);
                i++;
            }
        });
    }

}
