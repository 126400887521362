import { Component, AfterViewChecked, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../../core/layout.service';
import { RandomNumberService } from '../../../services/random-number.service';
import { RtwEventService } from '../../../firebase-services/rtw-event.service';
import { PremiumService } from '../../../firebase-services/premium.service';

// Interfaces
import { RtwEvent, RtwEventParticipant } from '../../../interfaces/rtw-event';

import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-rtw-event-participants',
    templateUrl: './rtw-event-participants.component.html',
    styleUrls: ['./rtw-event-participants.component.css']
})
export class RtwEventParticipantsComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    rtwEvent: RtwEvent;
    participants: RtwEventParticipant[] = null;
    participantExtra: { [profileKey: string]: { isPremium: string, tickets: string } } = null;

    drawCount: number = 1;
    drawnNumbers: number[] = null;
    lastTicketNumber: number = 0;

    // Datatable
    dataTableName: string = '#participantsDataTable';
    dataTable: any = null;
    dataTableCreated: boolean = false;

    constructor(
        public layout: LayoutService,
        public randomNumberService: RandomNumberService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private rtwEventService: RtwEventService,
        private premiumService: PremiumService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.dataTableName + '>tbody>tr').length > 0)) {
            this.dataTable = $(this.dataTableName).DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'csvHtml5',
                    'excelHtml5'
                ],
                'order': [[2, 'desc'], [3, 'desc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init(): void {
        this.rtwEvent = null;
        this.participants = null;
        this.participantExtra = null;
        this.drawCount = 1;
        this.drawnNumbers = null;
        this.loadRtwEvent(this.activatedRoute.snapshot.paramMap.get('rtwEventKey'));
        this.getParticipants(this.activatedRoute.snapshot.paramMap.get('rtwEventKey'));
    }

    private loadRtwEvent(rtwEventKey: string): void {
        this.rtwEventService.getRtwEvent(rtwEventKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((rtwEvent) => {
                this.rtwEvent = rtwEvent;
            });
    }

    private getParticipants(rtwEventKey: string) {
        this.rtwEventService.getRtwEventParticipants(rtwEventKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((participants) => {
                this.participantExtra = {};
                participants.forEach((participant) => {
                    this.getParticipantExtra(participant);
                });
                this.participants = participants;
            });
    }

    private getParticipantExtra(participant: RtwEventParticipant) {
        var ticketFrom: number = this.lastTicketNumber + 1;
        var ticketTo: number = ticketFrom + (participant.conqueredAreas - 1);
        this.lastTicketNumber = ticketTo;
        this.participantExtra[participant.profileKey] = {
            isPremium: '?',
            tickets: ticketFrom.toString() + (ticketTo > ticketFrom ? ' - ' + ticketTo : '')
        };

        this.premiumService.getPremiumExpiry(participant.profileKey)
            .subscribe((expiry) => {
                this.participantExtra[participant.profileKey].isPremium = (expiry > moment.now() ? 'YES' : 'No');
            });
    }

    drawWinners() {
        this.randomNumberService.getRandomNumbers(this.drawCount, 1, this.lastTicketNumber)
            .subscribe((drawnNumbers) => {
                console.log(drawnNumbers);
                this.drawnNumbers = drawnNumbers;
            });
    }

}
