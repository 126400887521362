import { AfterViewChecked, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { OrganisationService } from '../../firebase-services/organisation.service';
import { OrganisationMemberService } from '../../firebase-services/organisation-member.service';

// Interfaces
import { Organisation, OrganisationMember } from '../../interfaces/organisation';

declare var $: any;

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.css']
})
export class MembersComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    organisation: Organisation = null;
    members: OrganisationMember[] = null;

    // Datatable
    dataTableName = '#membersDataTable';
    dataTable: any = null;
    dataTableCreated = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private organisationService: OrganisationService,
        private organisationMemberService: OrganisationMemberService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.dataTableName + '>tbody>tr').length > 0)) {
            this.dataTable = $(this.dataTableName).DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'csvHtml5',
                    'excelHtml5'
                ],
                'order': [[0, 'asc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.organisation = null;
        this.getOrganisation(this.route.snapshot.paramMap.get('organisationKey'));
        this.getMembers(this.route.snapshot.paramMap.get('organisationKey'));
    }

    private getOrganisation(organisationKey: string) {
        this.organisationService.getOrganisation(organisationKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((organisation) => this.organisation = organisation);
    }

    private getMembers(organisationKey: string) {
        this.organisationMemberService.getOrganisationMembers(organisationKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((members) => this.members = members);
    }

}
