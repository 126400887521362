<div class="content-wrapper">
    <app-content-header-section *ngIf="area" contentPageId="trailAreaAdventures"
                                [pageParams]="{ trailAreaName: area.name, trailAreaKey: area.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div *ngIf="adventures" class="col-md-6">

                    <app-adventure-list [routerRoot]="routerRoot" [adventures]="adventures"></app-adventure-list>

                </div>
                <div *ngIf="area" class="col-md-6">

                    <app-create-on-map [themeColor]="layout.getTheme('adventure')" [themeIcon]="layout.getIcon('adventure')" typeName="Adventure"
                                       [areaObject]="area" (createdOnMap)="createNewAdventure($event)"></app-create-on-map>

                </div>
            </div>
        </div>
    </section>

</div>
