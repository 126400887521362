import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../core/auth.service';
import { UserService } from '../../firebase-services/user.service';
import { DestinationService } from '../../firebase-services/destination.service';

// Interfaces
import { Destination } from '../../interfaces/destination';
import { User } from '../../interfaces/user';

declare var $: any;

@Component({
    selector: 'app-destination-administrators',
    templateUrl: './destination-administrators.component.html',
    styleUrls: ['./destination-administrators.component.css']
})
export class DestinationAdministratorsComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    destinations: Destination[];
    destinationManagers: { [trailAreaKey: string]: User[] } = {};
    onlineIsManager: { [destinationKey: string]: boolean } = {};
    numberOfTrailAreas: { [trailAreaKey: string]: number } = {};
    numberOfAdventures: { [trailAreaKey: string]: number } = {};
    numberOfAdmins: { [trailAreaKey: string]: number } = {};
    displayTrash: { [trailAreaKey: string]: boolean } = {};

    constructor(
        public authService: AuthService,
        private userService: UserService,
        private destinationService: DestinationService,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.destinationService.getAllDestinations()
            .pipe(takeUntil(this.destroy$))
            .subscribe((destinations) => this.getDestinationsDetail(destinations));
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getDestinationsDetail(destinations: Destination[]): void {
        this.loadDestinationManagers(destinations);
        for (const destination of destinations) {
            // Number of Trail Areas
            if (destination.trailAreaKeys) {
                this.numberOfTrailAreas[destination.key] = Object.keys(destination.trailAreaKeys).length;
            }
            // Number of Adventures
            if (destination.adventureKeys) {
                this.numberOfAdventures[destination.key] = Object.keys(destination.adventureKeys).length;
            }
            // Online is Manager?
            this.onlineIsManager[destination.key] = false;
            this.authService.destinations.forEach((onlineUserDestination) => {
                if (onlineUserDestination.key === destination.key) {
                    this.onlineIsManager[destination.key] = true;
                }
            });
        }
        this.destinations = destinations;
    }

    loadDestinationManagers(destinations: Destination[]): void {
        // @todo Observable with observables? Rework!
        this.userService.getDestinationManagersForDestinations(destinations)
            .pipe(take(1))
            .subscribe((destinationsManagers) => {
                for (const destinationKey of Object.keys(destinationsManagers)) {
                    destinationsManagers[destinationKey]
                        .pipe(take(1))
                        .subscribe((managers) => {
                            managers.filter((m) => m);
                            this.numberOfAdmins[destinationKey] = managers.length;
                            if (managers.length > 0) {
                                this.destinationManagers[destinationKey] = managers;
                            } else {
                                this.destinationManagers[destinationKey] = null;
                                if (!this.numberOfTrailAreas[destinationKey] && !this.numberOfAdventures[destinationKey]) {
                                    this.displayTrash[destinationKey] = true;
                                }
                            }
                        });
                }
            });
    }

    addAdmin(profileKey: string, destinationKey: string): Promise<boolean> {
        return this.userService.addDestinationAdmin(profileKey, destinationKey, this.authService.user.userID);
    }

    removeManager(profileKey: string, destinationKey: string): Promise<void> {
        $('#' + destinationKey + profileKey).remove();
        return this.userService.revokeDestinationAdministrator(profileKey, destinationKey);
    }

    deleteDestination(key: string): Promise<void> {
        for (const i in this.destinations) {
            if (this.destinations[i].key === key) {
                this.destinations.splice(+i, 1);
                break;
            }
        }
        return this.destinationService.deleteDestination(key);
    }

    setOnlineBackgroundImage() {
        return this.domSanitizer.bypassSecurityTrustStyle('background-image: url(' + this.authService.user.userPicture + ')');
    }

}
