<div class="modal fade" [id]="dialogId" aria-hidden="true" *ngIf="dialogId">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i [ngClass]="iconClasses + ' mr-2'"></i> {{ title }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{ question }}</p>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-default" (click)="reply(false)" data-dismiss="modal">{{ cancelText }}</button>
                <button type="button" class="btn btn-primary" (click)="reply(true)">{{ confirmText }}</button>
            </div>
        </div>
    </div>
</div>
