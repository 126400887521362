<div [ngClass]="'card card-outline card-' + layout.getTheme(contentType)">
    <div class="card-header">
        <h3 class="card-title"><i class="fas fa-globe-europe mr-2"></i> Published State of {{ contentName }}</h3>
    </div>
    <div class="card-body">
        <div class="text-center position-relative mt-3">
            <ul class="pl-0" id="progressbar">
                <li [class.active]="_publishedState === publishedStates.DRAFT_ALTERED" id="editing"><strong>Editing draft</strong></li>
                <li [class.active]="_publishedState === publishedStates.DRAFT_SAVED" id="draft"><strong>Draft Saved</strong></li>
                <li [class.active]="_publishedState === publishedStates.DRAFT_READY" id="canPublish"><strong>Draft Ready</strong></li>
                <li [class.active]="_publishedState === publishedStates.PUBLISHED_NOT_READY" id="notReady"><strong>Cannot sync</strong></li>
                <li [class.active]="_publishedState === publishedStates.PUBLISHED_OUT_OF_SYNC" id="outOfSync"><strong>Out of sync</strong></li>
                <li [class.active]="_publishedState === publishedStates.PUBLISHED_ONLINE" id="online"><strong>Online</strong></li>
            </ul>
        </div>
        <div class="text-center">
            <p [ngClass]="'text-' + theme">
                <i [ngClass]="icon + ' mr-2'"></i>{{ statusText }} {{ actionText }}</p>
        </div>
        <div class="d-flex justify-content-around">
            <button [disabled]="_publishedState !== publishedStates.DRAFT_ALTERED" (click)="doSaveDraft()" class="btn btn-warning btn-lg mr-4">
                Save Draft
            </button>
            <button [disabled]="_publishedState !== publishedStates.PUBLISHED_NOT_READY && _publishedState !== publishedStates.PUBLISHED_OUT_OF_SYNC && _publishedState !== publishedStates.PUBLISHED_ONLINE"
                    (click)="doUnpublish()" class="btn btn-danger btn-lg mr-4">Unpublish
            </button>
            <button [disabled]="_publishedState !== publishedStates.DRAFT_READY && _publishedState !== publishedStates.PUBLISHED_OUT_OF_SYNC"
                    (click)="doPublish()" class="btn btn-success btn-lg">Publish
            </button>
        </div>
    </div>
</div>

<app-confirm-dialog [modalSettings]="modalSettings" (confirmed)="confirmed($event)"></app-confirm-dialog>

<div id="publishStateToastContainer" class="top-center position-fixed mt-1">
    <div id="publishStateToast" [ngClass]="'toast bg-' + theme" role="alert" aria-live="assertive" aria-atomic="true" data-delay="20000">
        <div class="toast-header">
            <strong class="mr-auto">{{ header }}</strong>
            <small>{{ time | date:'HH:mm:ss' }}</small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="toast-body" style="min-height: 80px">
            <i [ngClass]="icon + ' fa-4x mr-2 float-left'"></i>
            {{ statusText }}<br>
            {{actionText}}
        </div>
    </div>
</div>

<div id="fullscreenSpinner" class="loading bg-dark position-fixed">
    <div class="spinner-border position-absolute m-auto" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
