import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';

// Services
import { LayoutService } from '../../../core/layout.service';
import { MediaLibraryService } from '../../../firebase-services/media-library.service';
import { PoiService } from '../../../firebase-services/poi.service';
import { PoiCategoryService } from '../../../firebase-services/poi-category.service';

import { MediaIcon } from '../../../interfaces/media-library';
import { PoiCluster } from '../../../interfaces/poi';
import { PoiCategoriesAccessLevels, PoiCategory, PublicPoiCategory } from '../../../interfaces/poi-category';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-poi-import',
    templateUrl: './poi-import.component.html',
    styleUrls: ['./poi-import.component.css']
})
export class PoiImportComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    contentPageId = 'poiImport';

    PoiCategoriesAccessLevels = PoiCategoriesAccessLevels;

    poiClusters: PoiCluster[] = null;
    migratePoiClusterIndex: number = null;
    migratePoisToIconIndex: number = null;
    clusterForCategoryIndex: number = null;
    addCategoriesLabel: string = null;

    poiCategories: { [label: string]: PoiCategory } = null;
    publicPoiCategories: { [label: string]: PublicPoiCategory } = null;
    mediaIcons: MediaIcon[] = [];

    constructor(
        public layout: LayoutService,
        private mediaLibraryService: MediaLibraryService,
        private poiCategoryService: PoiCategoryService,
        private poiService: PoiService
    ) {
    }

    ngOnInit(): void {
        this.loadFromFirebase();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    migratePoiClusterToIcon(): Promise<void> {
        let confirmText = 'Are you sure you wish to migrate:';
        this.poiClusters[this.migratePoiClusterIndex].pois.forEach((poi) => {
            confirmText += '\n' + poi.name + ' (' + poi.key + ')';
        });
        confirmText += '\nto:\n' + this.mediaIcons[this.migratePoisToIconIndex].name + '?';
        if (window.confirm(confirmText)) {
            const hits = this.poiClusters[this.migratePoiClusterIndex].amount;
            window.alert('Skal gentænkes før brug');
            return Promise.reject('Not handled at this time.');
            // return this.poiService.migrateClusterToMediaIcon(this.poiClusters[this.migratePoiClusterIndex],
            //     this.mediaIcons[this.migratePoisToIconIndex])
            //     .then(() => window.alert('Migrated ' + hits + ' POIs'));
        }
        return Promise.resolve();
    }

    addCategoryToPoiCluster(): Promise<void> {
        let label: string;
        let sortOrder: number;
        let subLabel: string = null;
        let subSortOrder: number = null;
        let confirmText = 'Are you sure you wish to expand:';
        this.poiClusters[this.clusterForCategoryIndex].pois.forEach((poi) => {
            confirmText += '\n' + poi.name + ' (' + poi.key + ')';
        });
        if (this.publicPoiCategories[this.addCategoriesLabel]) {
            label = this.addCategoriesLabel;
            sortOrder = this.publicPoiCategories[label].sortOrder;
            confirmText += '\nwith:\n' + this.publicPoiCategories[label].name + '?';
        } else {
            for (const tmpLabel in this.publicPoiCategories) {
                if (this.publicPoiCategories[tmpLabel].subCategories &&
                    this.publicPoiCategories[tmpLabel].subCategories[this.addCategoriesLabel]) {
                    label = tmpLabel;
                    sortOrder = this.publicPoiCategories[label].sortOrder;
                    subLabel = this.addCategoriesLabel;
                    subSortOrder = this.publicPoiCategories[label].subCategories[subLabel].sortOrder;
                    confirmText += '\nwith:\n' + this.publicPoiCategories[label].subCategories[subLabel].name + '?';
                    break;
                }
            }
        }
        if (window.confirm(confirmText)) {
            const hits = this.poiClusters[this.clusterForCategoryIndex].amount;
            window.alert('Skal gentænkes før brug');
            return Promise.reject('Not handled at this time.');
            // return this.poiService
            //     .addCategoryToCluster(this.poiClusters[this.clusterForCategoryIndex], label, sortOrder, subLabel, subSortOrder)
            //     .then(() => window.alert('Category added to ' + hits + ' POIs'));
        }
        return Promise.resolve();
    }

    iconNameFromKey(mediaIconKey: string): string {
        let mediaIconName = '*Unknown*';
        for (const mediaIcon of this.mediaIcons) {
            if (mediaIcon.key === mediaIconKey) {
                mediaIconName = 'Icon: ' + mediaIcon.name;
                break;
            }
        }
        return mediaIconName;
    }

    private loadFromFirebase() {
        this.poiService.getPoiClusters()
            .pipe(takeUntil(this.destroy$))
            .subscribe(poiClusters => this.poiClusters = poiClusters);

        const poiCategoriesObservable = this.poiCategoryService.getPoiCategories()
            .pipe(takeUntil(this.destroy$));
        const publicPoiCategoriesObservable = this.poiCategoryService.getPublicPoiCategories()
            .pipe(takeUntil(this.destroy$));
        combineLatest([poiCategoriesObservable, publicPoiCategoriesObservable])
            .subscribe(([poiCats, publicPoiCats]) => {
                this.poiCategories = poiCats;
                this.publicPoiCategories = publicPoiCats;
            });

        this.mediaLibraryService.getMediaIcons()
            .pipe(takeUntil(this.destroy$))
            .subscribe(mediaIcons => this.mediaIcons = mediaIcons);
    }

}
