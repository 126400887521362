<div class="content-wrapper">
    <app-content-header-section contentPageId="onTrailContacts" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="card card-outline card-danger">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-address-book"></i> OnTrail Contacts</h3>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="contacts" id="contactsDataTable" class="table row-bordered table-striped">
                                <thead>
                                <tr>
                                    <th *ngFor="let header of tableHeaders">{{ header }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let contact of contacts">
                                    <td [attr.data-order]="contact.key">
                                        {{ contact.key }}
                                    </td>
                                    <td [attr.data-order]="contact.name">
                                        {{ contact.name }}
                                    </td>
                                    <td [attr.data-order]="contact.email">
                                        {{ contact.email }}
                                    </td>
                                    <td [attr.data-order]="contact.address">
                                        {{ contact.address }}
                                    </td>
                                    <td [attr.data-order]="contact.phone">
                                        {{ contact.phone }}
                                    </td>
                                    <td>
                                        <span *ngIf="contact.trail">{{ contact.trail.name }} <a
                                                [href]="webBaseUrl + 'da/tracks/admin-link/' + contact.trail.key" target="_blank"><i
                                                class="fa fa-external-link"></i></a></span>
                                        <span *ngIf="!contact.trail">{{ contact.trackName }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
