<div class="content-wrapper">
    <app-content-header-section contentPageId="premiumProduct" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-6 offset-lg-3">

                    <div class="alert alert-danger alert-dismissible">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                        <h4><i class="icon fa fa-warning"></i> Prices are not synced</h4>
                        When you set prices on this product, you will enable the variants in-app. Make sure to create and maintain the products
                        variants at Apple and Googles IAP.
                    </div>

                </div>
            </div>

            <app-kiosk-product-handler [productCategory]="productCategory" [kioskProductKey]="kioskProductKey" handlerTitle="Premium product"
                                       [enableActivationCodes]="true" [enableUpsell]="true"></app-kiosk-product-handler>

        </div>
    </section>
</div>
