import { TranslatableObject } from './general';

export const MINIMUM_TIME_SLOT_MINUTES = 15;
export const MINIMUM_TIME_SLOT_MILLISECONDS = MINIMUM_TIME_SLOT_MINUTES * 60000;

export interface Sponsor extends TranslatableObject {
    key?: string;
    alias?: string;
    link?: string;
    activeFrom?: number;
    activeTo?: number;
    imageURL?: string;
    imageWidth?: number;
    imageHeight?: number;
    sponsorType: SponsorTargetType;
    autoRenewable?: Boolean;
    longitude?: number;
    latitude?: number;
    radius?: number;
    country?: string;
    trailTargetKeys?: Array<string>;
    countryFilter?: string;
    isMainSponsor?: boolean; 

}

export enum SponsorTargetType {
    NO_SPONSOR_TARGET_TYPE_SELECTED = 'noSponsorTargetTypeSelected',
    TRAIL_SPECIFIC_SPONSOR = 'trailSpecificSponsor',
    AREA_SPECIFIG_SPONSOR = 'areaSpecifigSponsor',
    COUNTRY_SPECIFIG_SPONSOR = 'countrySpecifigSponsor'
}


// optional: Record type annotation guaranties that 
// all the values from the enum are presented in the mapping
export const SponsorTargetType2LabelMapping: Record<SponsorTargetType, string> = {
    [SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED]: "No type chosen",
    [SponsorTargetType.TRAIL_SPECIFIC_SPONSOR]: "Sponsorship for trailArea",
    [SponsorTargetType.AREA_SPECIFIG_SPONSOR]: "Sponsorship for area",
    [SponsorTargetType.COUNTRY_SPECIFIG_SPONSOR]: "Sponsorship for country",
};

// export interface Banner {
//         key: string;
//         imageURL: string;
//     }


// export enum ExposureType {
//     MYTREATS = 'myTreats',
//     TOPOFMIND = 'topOfMind'
// }

// export interface TreatExposure {
//     key: string;
//     exposureType: ExposureType;
//     type: ExposureType;
// }

// export interface TreatHit {
//     treatExposureKey: string;
// }

// export interface TreatsTabTexts extends TranslatableObject {
//     title: string;
//     description: string;
// }
