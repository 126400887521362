import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keyValueBase'
})
export class KeyValueBasePipe implements PipeTransform {

    transform(input: unknown): unknown[] {
        if (!input) {
            return [];
        }

        const output = [];
        Object.keys(input).forEach((key) => {
            output.push({
                key: key,
                ...input[key]
            });
        });
        return output;
    }

}
