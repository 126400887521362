import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';

// Interfaces
import { AvailableLanguages } from '../../interfaces/language';
import { TextModel, TextModelItem, TextModelItemType, TextObject } from '../../interfaces/text-model';

declare var $: any;

@Component({
    selector: 'app-translatable-text-edit',
    templateUrl: './translatable-text-edit.component.html',
    styleUrls: ['./translatable-text-edit.component.css']
})
export class TranslatableTextEditComponent implements AfterViewChecked {
    @Input() colorTheme = 'primary';
    @Input() publishedObject: TextObject;
    @Input() textModel: TextModel;
    @Input() textsName: string;
    @Output() textChanged = new EventEmitter<void>();

    cId: string = null;
    languageNames = AvailableLanguages;
    inputType = TextModelItemType.INPUT;
    textareaType = TextModelItemType.TEXT_AREA;
    objectKeys = Object.keys;
    gotoLanguageTab: string = null;

    constructor() {
        this.cId = new Date().valueOf().toString();
    }

    _textObject: TextObject;

    @Input() set textObject(textObject: TextObject) {
        if (typeof textObject.lang !== 'object' || textObject.lang === null) {
            textObject.lang = {};
        }
        // Check whether active language is still in the text object. If not -> select default tab.
        if (!Object.keys(textObject.lang).includes($('#translatable_texts_tabs_' + this.cId + ' > li > a.active').text())) {
            this.gotoLanguageTab = '#tab_default_link_' + this.cId;
        }
        this._textObject = textObject;
    }

    ngAfterViewChecked() {
        if (this.gotoLanguageTab) {
            $(this.gotoLanguageTab).tab('show');
            this.gotoLanguageTab = null;
        }
    }

    addLanguage(language: string): void {
        this._textObject.lang[language] = {};
        this.gotoLanguageTab = '#tab_' + language + '_link_' + this.cId;
    }

    copyDefaultText(param: string, language: string): void {
        this._textObject.lang[language][param] = this._textObject[param];
        this.textChanged.emit();
    }

    textsChanged(item: TextModelItem, language?: string) {
        if (typeof language === 'string' && this._textObject.lang[language][item.varName] === '') {
            delete this._textObject.lang[language][item.varName];
        } else if (language !== null && this._textObject[item.varName] === '') {
            delete this._textObject[item.varName];
        }
        this.textChanged.emit();
    }

    showDefaultPublishedValue(item: TextModelItem): boolean {
        return !item.ignorePublic && this.publishedObject &&
            ((this._textObject[item.varName]) !== this.publishedObject[item.publicVarName ?? item.varName]);
    }

    getDefaultPublishedValue(item: TextModelItem): string {
        return this.publishedObject[item.publicVarName ?? item.varName] ?? '';
    }

    showPublishedValue(item: TextModelItem, language: string): boolean {
        return !item.ignorePublic && this.publishedObject && (!this.publishedObject.lang || !this.publishedObject.lang[language] ||
            ((this._textObject.lang[language][item.varName]) !== this.publishedObject.lang[language][item.publicVarName ?? item.varName]));
    }

    getPublishedValue(item: TextModelItem, language: string): string {
        if (!this.publishedObject.lang || !this.publishedObject.lang[language]) {
            return '';
        }
        return this.publishedObject.lang[language][item.publicVarName ?? item.varName] ?? '';
    }
}
