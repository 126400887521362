import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { CountryService } from '../../../firebase-services/country.service';
import { NewsStoryService } from '../../../firebase-services/news-story.service';

// Interfaces
import { Country } from '../../../interfaces/countries';
import { NewsStory } from '../../../interfaces/news-story';

@Component({
    selector: 'app-news-room-welcome',
    templateUrl: './news-room-welcome.component.html',
    styleUrls: ['./news-room-welcome.component.css']
})
export class NewsRoomWelcomeComponent implements OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();

    selectStory: string = null;
    selectCountry: string = null;

    countries: Country[] = [];
    publishedStories: NewsStory[];

    constructor(
        private countryService: CountryService,
        private newsStoryService: NewsStoryService
    ) {
        this.countryService.getCountries()
            .pipe(takeUntil(this.destroy$))
            .subscribe((countries) => this.countries = countries);

        this.newsStoryService.getNewsStoriesIn('news-room', true)
            .pipe(takeUntil(this.destroy$))
            .subscribe((publishedNewsStories) => this.publishedStories = publishedNewsStories.reverse());
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    addWelcomeToList(): void {
        var feedEntryKey: string = null;
        this.publishedStories.forEach((story) => {
            if (story.key === this.selectStory) {
                feedEntryKey = story.feedEntryKey;
            }
        });
        this.newsStoryService.addWelcomeStoryToList(this.selectStory, this.selectCountry, feedEntryKey);
        this.selectStory = null;
        this.selectCountry = null;
    }

}
