import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

// Interfaces
import { DawaAddress } from '../interfaces/dawa';
import { AddressLookup } from '../interfaces/address-lookup';

@Injectable({
    providedIn: 'root'
})
export class DawaService {

    constructor(
        private client: HttpClient,
    ) {
    }

    private static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    lookupAddress(lat: number, lng: number): Observable<AddressLookup> {
        return this.client.get<DawaAddress>('https://dawa.aws.dk/adgangsadresser/reverse?x=' + lng.toString() + '&y=' + lat.toString())
            .pipe(
                map((data) => {
                    const addressLookup: AddressLookup = {
                        municipalityCode: data.kommune.kode,
                        municipalityName: data.kommune.navn,
                        regionCode: data.region.kode,
                        regionName: data.region.navn,
                        postalCode: data.postnummer.nr,
                        city: data.postnummer.navn
                    };
                    return addressLookup;
                }),
                retry(3), // retry a failed request up to 3 times
                catchError(DawaService.handleError) // then handle the error
            );

    }

}
