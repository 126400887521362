<div class="content-wrapper">
    <app-content-header-section contentPageId="trailAreaDrafts" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                </div>
            </div>
        </div>
    </section>

</div>
