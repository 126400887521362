<div class="content-wrapper">
    <app-content-header-section [contentPageId]="contentPageId" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row" *ngIf="!decisionMade">
                <div class="col-12 col-lg-6">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('poi')">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getPluralIcon('poi') + ' mr-2'"></i>
                                Validate All POI's towards Published POI's</h3>
                        </div>
                        <div class="card-body">
                            <div class="card-text">Validate all pois towards the public available versions.</div>
                        </div>
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary" (click)="validateAllPois()">Validate All</button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row" *ngIf="decisionMade">
                <div class="col-12">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('poi')">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getPluralIcon('poi') + ' mr-2'"></i>
                                Validating All POI's towards Published POI's</h3>
                        </div>
                        <div class="card-body">
                            <div class="d-flex flex-wrap">
                                <div *ngFor="let poi of objectValues(allPois); index as i" [ngClass]="'mr-1 mb-1 p-1 bg-' + poiStates[poi.key].theme">
                                    <a [tabindex]="i+1" role="button" data-toggle="popover" data-trigger="focus" target="_blank"
                                       [title]="poi.name ?? '*Unnamed* ' + poi.key" [attr.data-content]="popoverContent(poi)"
                                       [routerLink]="layout.getUrl('editPoi', {trailAreaKey: null, poiKey: poi.key})">
                                        {{ poi.name ?? poi.key | slice:0:2 }}:{{ (trailAreaKeys[poi.key] ?? []).length }}
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="border-right flex-fill">
                                    <div class="description-block">
                                        <i [ngClass]="layout.getIcon('poi') + ' fa-2x'"></i>
                                        <h5 class="description-header" *ngIf="allPois">{{ objectValues(allPois).length }}</h5>
                                        <span class="description-text">TOTAL POIS</span>
                                    </div>
                                </div>
                                <div class="border-right flex-fill text-warning" *ngIf="this.statistics[PublishedStates.DRAFT_ALTERED] > 0">
                                    <div class="description-block">
                                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                                        <h5 class="description-header">{{ this.statistics[PublishedStates.DRAFT_ALTERED] }}</h5>
                                        <span class="description-text">LOADING STATE</span>
                                    </div>
                                </div>
                                <ng-container *ngIf="this.statistics[PublishedStates.DRAFT_ALTERED] === 0">
                                    <div class="border-right flex-fill text-secondary">
                                        <div class="description-block">
                                            <i class="fas fa-2x fa-save"></i>
                                            <h5 class="description-header">{{ this.statistics[PublishedStates.DRAFT_SAVED] }}</h5>
                                            <span class="description-text">DRAFT SAVED</span>
                                        </div>
                                    </div>
                                    <div class="border-right flex-fill text-primary">
                                        <div class="description-block">
                                            <i class="fas fa-2x fa-thumbs-up"></i>
                                            <h5 class="description-header">{{ this.statistics[PublishedStates.DRAFT_READY] }}</h5>
                                            <span class="description-text">DRAFT READY</span>
                                        </div>
                                    </div>
                                    <div class="border-right flex-fill text-danger">
                                        <div class="description-block">
                                            <i class="fas fa-2x fa-thumbs-down"></i>
                                            <h5 class="description-header">{{ this.statistics[PublishedStates.PUBLISHED_NOT_READY] }}</h5>
                                            <span class="description-text">PUBLISHED NOT READY</span>
                                        </div>
                                    </div>
                                    <div class="border-right flex-fill text-orange">
                                        <div class="description-block">
                                            <i class="fas fa-2x fa-sync mr-2"></i>
                                            <h5 class="description-header">{{ this.statistics[PublishedStates.PUBLISHED_OUT_OF_SYNC] }}</h5>
                                            <span class="description-text">PUBLISHED OUT OF SYNC</span>
                                            <div *ngIf="showOutOfSyncPublish">
                                                <button type="button" class="btn btn-lg btn-success" (click)="publishOutOfSync()">Publish</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-fill">
                                        <div class="description-block text-success">
                                            <i class="fas fa-2x fa-globe-europe"></i>
                                            <h5 class="description-header">{{ this.statistics[PublishedStates.PUBLISHED_ONLINE] }}</h5>
                                            <span class="description-text">ONLINE</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="overlay" *ngIf="!allPois">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
