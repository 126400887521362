import { AreaObject, TranslatableObject } from './general';

export enum TrailTypes {
    COMMON_TRAIL = 0,
    EVENT_TRAIL = 1,
    TRANSPORT = 2
}

export interface TrailColor {
    value: string;
    text: string;
}

export const TRANSPORT_TRAIL_COLOR = '#808285';
export const TrailColors = [
    {value: '#18A554', text: 'Light green (Very easy)'},
    {value: '#137FC3', text: 'Light blue (Easy)'},
    {value: '#D51D29', text: 'Red (Moderate)'},
    {value: '#231F20', text: 'Black (Difficult)'},
    {value: '#F38231', text: 'Orange (Extreme)'},
    {value: '#9013FE', text: 'Purple'},
    {value: '#417505', text: 'Dark green'},
    {value: '#F8E71C', text: 'Yellow'},
    {value: '#4A90E2', text: 'Blue'},
    {value: '#FFFFFF', text: 'White'}
];

export interface TrailGrading {
    iconURL: string;
    text: string;
}

export const TrailGradingWorld = [
    {
        iconURL: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2Fukendt.png?alt=media&token=d4d199e9-45ec-4e31-bc0d-2816911013be',
        text: 'Unknown'
    },
    {
        iconURL: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F1b_meget_let.png?alt=media&token=a3ee6a15-21f9-465d-bf6a-e0674372b60d',
        text: 'Very easy'
    },
    {
        iconURL: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F2b_let.png?alt=media&token=1fba2dca-d6d7-4861-afff-d278286cb899',
        text: 'Easy'
    },
    {
        iconURL: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F3b_middel.png?alt=media&token=a59eb191-07ec-43af-876a-342dc2b56319',
        text: 'Moderate'
    },
    {
        iconURL: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F4b_svaer.png?alt=media&token=b5731685-34bc-4004-b9b6-1c9bae26bbfb',
        text: 'Difficult'
    },
    {
        iconURL: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F5b_ekstrem.png?alt=media&token=b4dcb96c-b663-4526-8b6e-4a161ec55be5',
        text: 'Extreme'
    }
];

export interface GPSPoint {
    latitude: number;
    longitude: number;
    height?: number;
}

export interface Trail extends AreaObject, TranslatableObject {
    active: boolean;
    dateCreated?: string;
    gpxUrl?: string;
    key?: string;
    numberOfRatings?: number;
    shortDynamicLink?: string;
    trackStatus?: number;
    trailAreaKey?: string;
    webURL?: string;

    name: string;
    description?: string;
    imageUrl?: string;
    /**
     * @deprecated Since it is misspelled and since you should be able to obtain from a trail area... Maybe.
     */
    countrie: string;

    color: string;
    iconUrl: string;

    trackType: TrailTypes;
    encodedPolyline: string;
    startPoint: GPSPoint;
    stopPoint: GPSPoint;

    avgRating?: number;
    trackStatusTimestamp?: number;

    importKey?: string;

    postalCode?: string;
    city?: string;
    municipality?: string;
    municipalityCode?: string;
    region?: string;
    regionCode?: string;

    lengthInMeters?: number;
    heightIncrease?: string;
    heightDecrease?: string;

    awards?: { [awardKey: string]: string };

    length?: string;
    lengthType?: string;
    maxHeight?: string;
    minHeight?: string;

    /** @deprecated */
    status?: string;
    /** @deprecated */
    expirationDate?: null;
}

export interface TrailImport extends Trail {
    estimatedTime?: string;
    importVars?: FieldColumns;
    polyline: any[];
}

export interface TrailImportGb extends Trail {
    estimatedTime?: string;
    importVars?: FeatureProperties;
    polyline: any[];
}

interface FieldColumns {
    OBJECTID: number;
    OBJEKT: string;
    ROUTEN_TYP: string;
    GEMEINDE_NAME: string;
    ROUTENNUMMER: string;
    ROUTENNAME: string;
    STAND: number;
    CREATEUSER: string;
    CREATETIMESTAMP: number;
    UPDATEUSER: string;
    UPDATETIMESTAMP: number;

    BEZIRK_REGION?: string;
    ROUTENSTART?: string;
    ROUTENZIEL?: string;
    HOEHE_START?: number;
    HOEHE_ZIEL?: number;
    HM_BERGAUF?: number;
    HM_BERGAB?: number;
    LAENGE_HAUPTROUTE_KM?: number;
    FAHRZEIT?: string;
    SCHWIERIGKEITSGRAD?: string;
    ROUTENBESCHREIBUNG?: string;
    'SHAPE.LEN'?: number;

    ROUTE_IMPORT_INDEX?: number;
    TEIL_IMPORT_INDEX?: number;
}

export interface TrailDifficulty {
    en_text: string;
    text: string;
    iconUrl: string;
    color: string;
    de_text: string;
}

export interface AustrianTrailCandidate {
    displayFieldName: string;
    features: [{
        attributes: FieldColumns;
        geometry: {
            paths: any
        }
    }];
    fieldAliases: Object; // FieldColumns but with all strings - key = value.
    fields: [{
        name: string;
        type: string;
        alias: string;
        length: number;
    }];
    geometryType: string; // "esriGeometryPolyline"
    /**
     * The coordinate system used in the file. MGI / Austria GK West
     * @see https://epsg.io/31254
     */
    spatialReference: {
        wkid: number;
        latestWkid: number;
    };
}

export interface PopularStatisticsTrail {
    distance: number;
    time: number;
    visits: number;
    trail: Trail;
}


///////-------------------_____UK_______-------------------------//////


export interface UKTrailCandidate {
        type?:           PurpleType;
        features?:       Feature[];
        totalFeatures?:  number;
        numberMatched?:  number;
        numberReturned?: number;
        timeStamp?:      Date;
        crs?:            CRS;
    }
    
    export interface CRS {
        type?:       CRSType;
        properties?: CRSProperties;
    }
    
    export interface CRSProperties {
        name?: Name;
    }
    
    export enum Name {
        UrnOgcDefCRSEPSG4326 = "urn:ogc:def:crs:EPSG::4326",
    }
    
    export enum CRSType {
        Name = "name",
    }
    
    export interface Feature {
        type?:          FeatureType;
        id?:            string;
        geometry?:      Geometry;
        geometry_name?: GeometryName;
        properties?:    FeatureProperties;
    }
    
    export interface Geometry {
        type?:        GeometryType;
        coordinates?: Array<number[]>;
    }
    
    export enum GeometryType {
        LineString = "LineString",
    }
    
    export enum GeometryName {
        Way = "way",
    }
    
    export interface FeatureProperties {
        osm_id?:           number;
        access?:           Access | null;
        bicycle?:          Bicycle | null;
        foot?:             Access | null;
        highway?:          Highway | null;
        name?:             null | string;
        oneway?:           Access | null;
        operator?:         null | string;
        ref?:              null | string;
        route?:            Route;
        smoothness?:       Smoothness | null;
        surface?:          Surface | null;
        tourism?:          null;
        tracktype?:        Tracktype | null;
        trail_visibility?: TrailVisibility | null;
        width?:            null | string;
        z_order?:          number;
        way_area?:         null;
        distance?:         null | string;
        from?:             null | string;
        to?:               null | string;
        incline?:          null | string;
        ascent?:           null | string;
        descent?:          null | string;
        description?:      null | string;
        unsigned?:         Access | null;
        website?:          null | string;
        network?:          Route | null;
        roundtrip?:        Access | null;
        wikidata?:         null | string;
        source?:           null | string;
        cycleway?:         null | string;
        designation?:      null | string;
        addr_housename?:   null;
        mtb_scale?:        null | string;
        mtb_scale_imba?:   null | string;
        mtb_scale_uphill?: null | string;
        mtb_type?:         MtbType | null;
        mtb_description?:  null;
    }
    
    export enum Access {
        Designated = "designated",
        No = "no",
        Permissive = "permissive",
        Private = "private",
        Yes = "yes",
    }
    
    export enum Bicycle {
        Customers = "customers",
        Designated = "designated",
        Destination = "destination",
        Permissive = "permissive",
        Yes = "yes",
    }
    
    export enum Highway {
        Bridleway = "bridleway",
        Cycleway = "cycleway",
        Footway = "footway",
        Path = "path",
        Steps = "steps",
        Track = "track",
        Unclassified = "unclassified",
    }
    
    export enum MtbType {
        Allmountain = "allmountain",
        Crosscountry = "crosscountry",
        Downhill = "downhill",
        Freeride = "freeride",
        Singletrack = "singletrack",
        Trail = "trail",
        Trial = "trial",
        Uphill = "uphill",
    }
    
    export enum Route {
        CycleSurreyHills = "Cycle Surrey Hills",
        Delamere = "Delamere",
        DyfiBikePark = "Dyfi Bike Park",
        FriendsOfHurtwood = "Friends of Hurtwood",
        HampshireOffRoadCycleTrails = "Hampshire Off-Road Cycle Trails",
        Lcn = "lcn",
        Mtb = "mtb",
        Ncn = "ncn",
        Rcn = "rcn",
        WindHillBikepark = "Wind Hill Bikepark",
    }
    
    export enum Smoothness {
        Bad = "bad",
        Excellent = "excellent",
        Horrible = "horrible",
        Intermediate = "intermediate",
    }
    
    export enum Surface {
        Asphalt = "asphalt",
        Compacted = "compacted",
        Dirt = "dirt",
        Earth = "earth",
        FineGravel = "fine_gravel",
        Grass = "grass",
        Gravel = "gravel",
        Ground = "ground",
        Mud = "mud",
        Rock = "rock",
        Sand = "sand",
        Stone = "stone",
        Unpaved = "unpaved",
    }
    
    export enum Tracktype {
        Grade1 = "grade1",
        Grade2 = "grade2",
        Grade4 = "grade4",
    }
    
    export enum TrailVisibility {
        Excellent = "excellent",
        Good = "good",
        Intermediate = "intermediate",
    }
    
    export enum FeatureType {
        Feature = "Feature",
    }
    
    export enum PurpleType {
        FeatureCollection = "FeatureCollection",
    }
    
