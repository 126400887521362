import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ModalSettings, PublishedStates } from '../../interfaces/general';
import { LayoutService } from '../../core/layout.service';

declare var $: any;

@Component({
    selector: 'app-published-indicator',
    templateUrl: './published-indicator.component.html',
    styleUrls: ['./published-indicator.component.css']
})
export class PublishedIndicatorComponent implements OnInit, OnDestroy {
    @Input() contentName: string;
    @Input() contentType: string;

    @Input() set publishedState(publishedState: number) {
        if (typeof this._publishedState === 'undefined') {
            this._publishedState = publishedState;
            return;
        }
            this.spinnerContainer.remove();

        switch (publishedState) {
            case PublishedStates.DRAFT_ALTERED:
                this.theme = 'warning';
                this.icon = 'fas fa-user-edit';
                this.header = 'Editing draft';
                this.statusText = 'You have edited your draft.';
                this.actionText = 'Save the edited fields to continue.';
                break;

            case PublishedStates.DRAFT_SAVED:
                this.theme = 'secondary';
                this.icon = 'far fa-edit';
                this.header = 'Draft Saved';
                this.statusText = 'You draft is saved, but it is not ready to be published yet.';
                this.actionText = 'Fill out the required fields';
                break;

            case PublishedStates.DRAFT_READY:
                this.theme = 'primary';
                this.icon = 'fas fa-thumbs-up';
                this.header = 'Draft Ready';
                this.statusText = 'You draft is ready to be published.';
                this.actionText = 'Publish it to make it available online.';
                break;

            case PublishedStates.PUBLISHED_NOT_READY:
                this.theme = 'danger';
                this.icon = 'fas fa-thumbs-down';
                this.header = 'Cannot sync';
                this.statusText = 'You draft cannot be published, but you have a published version online.';
                this.actionText = 'Maybe you should unpublish it?';
                break;

            case PublishedStates.PUBLISHED_OUT_OF_SYNC:
                this.theme = 'orange';
                this.icon = 'fas fa-sync';
                this.header = 'Out of sync';
                this.statusText = 'Your draft and your online version are not synchronised.';
                this.actionText = 'You should either publish or unpublish it.';
                break;

            case PublishedStates.PUBLISHED_ONLINE:
                this.theme = 'success';
                this.icon = 'fas fa-globe-europe';
                this.header = 'Online';
                this.statusText = 'We are online!';
                this.actionText = '';
                break;
        }
        this.time = Date.now();
        this._publishedState = publishedState;
        $('#publishStateToast').toast('show');
    }

    @Input() modalSettings: ModalSettings;

    @Output() unpublishElement = new EventEmitter<void>();
    @Output() publishElement = new EventEmitter<void>();
    @Output() saveDraft = new EventEmitter<void>();
    @Output() leaveConfirmed = new EventEmitter<boolean>();

    _publishedState: number;
    publishedStates = PublishedStates;

    theme: string;
    icon: string;
    header: string;
    time: number;
    statusText: string;
    actionText: string;

    toastContainer: any;
    spinnerContainer: any;

    constructor(
        public layout: LayoutService,
    ) {
    }

    ngOnInit() {
        this.toastContainer = $('#publishStateToastContainer').detach();
        this.toastContainer.appendTo('body');
        this.spinnerContainer = $('#fullscreenSpinner').detach();
    }

    ngOnDestroy() {
        this.toastContainer.remove();
    }

    doPublish() {
        this.spinnerContainer.appendTo('body');
        this.publishElement.emit();
    }

    doUnpublish() {
        this.spinnerContainer.appendTo('body');
        this.unpublishElement.emit();
    }

    doSaveDraft() {
        this.spinnerContainer.appendTo('body');
        this.saveDraft.emit();
    }

    confirmed(confirmed: boolean): void {
        this.leaveConfirmed.emit(confirmed);
    }

}
