import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Services
import { GeoService } from '../services/geo.service';
import { UserService } from './user.service';

// Interfaces
import { Adventure, AdventureItem, AdventureItemStatus, Infolet, InfoletType } from '../interfaces/adventure';
import { Explorer, ExplorerItemStatus, User } from '../interfaces/user';


@Injectable({
    providedIn: 'root'
})
export class AdventureService {

    adventuresRef: firebase.database.Reference;
    adventureItemsRef: firebase.database.Reference;
    adventureStatusRef: firebase.database.Reference;
    adventureItemStatusRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService
    ) {
        this.adventuresRef = this.db.database.ref('adventures');
        this.adventureItemsRef = this.db.database.ref('adventureItems');
        this.adventureStatusRef = this.db.database.ref('adventureStatus');
        this.adventureItemStatusRef = this.db.database.ref('adventureItemStatus');
    }

    private static getAdventureItemFromSnap(adventureItemSnap: AngularFireAction<DatabaseSnapshot<{}>>): AdventureItem {
        const adventureItem: AdventureItem = <AdventureItem>adventureItemSnap.payload.val();
        adventureItem.key = adventureItemSnap.key;
        adventureItem.mapOnly = (typeof adventureItem.mapOnly === 'boolean') ? adventureItem.mapOnly : false;
        adventureItem.lang = adventureItem.lang || {};
        adventureItem.adventurePoints = adventureItem.adventurePoints || null;
        adventureItem.timeboxStart = adventureItem.timeboxStart || null;
        adventureItem.timeboxEnd = adventureItem.timeboxEnd || null;
        adventureItem.description = adventureItem.description || null;
        adventureItem.header = adventureItem.header || null;
        adventureItem.voucherPin = adventureItem.voucherPin || null;
        return adventureItem;
    }

    /**
     * Gets an adventure observable from a db-key
     */
    getAdventure(adventureKey: string): Observable<Adventure> {
        // Gets the adventure - since we also need the key, we get this from snapshot, rather than just value
        return this.db.object<Adventure>(this.adventuresRef.child(adventureKey).ref).snapshotChanges().pipe(
            map((adventureSnapshot) => {
                if (!adventureSnapshot.payload.exists()) {
                    return null;
                }

                const adventureVal: Adventure = adventureSnapshot.payload.val();
                if (!adventureVal.infolets) {
                    adventureVal.infolets = [];
                }
                for (let i = 0; i < 3; i++) {
                    if (!adventureVal.infolets[i]) {
                        adventureVal.infolets[i] = {
                            active: false,
                            type: InfoletType.EDITOR,
                            tabName: null,
                            bodyContent: null,
                            content: null
                        };
                    } else if (typeof adventureVal.infolets[i].bodyContent === 'string') {
                        adventureVal.infolets[i].type = adventureVal.infolets[i].type || InfoletType.EDITOR;
                        adventureVal.infolets[i].content = null;
                    } else if (typeof adventureVal.infolets[i].content === 'string') {
                        adventureVal.infolets[i].type = adventureVal.infolets[i].type || InfoletType.RAW;
                        adventureVal.infolets[i].bodyContent = null;
                    } else {
                        adventureVal.infolets[i].content = null;
                        adventureVal.infolets[i].bodyContent = null;
                    }
                }
                adventureVal.active = (adventureVal.accessProductKey ? (adventureVal.active || false) : false);
                adventureVal.description = adventureVal.description || null;
                adventureVal.header = adventureVal.header || null;
                adventureVal.lang = adventureVal.lang || {};
                return <Adventure>{
                    key: adventureSnapshot.key,
                    ...adventureVal
                };
            })
        );
    }

    /**
     * Get adventures from an array of adventure-keys
     */
    getAdventures(adventureKeys: string[]): Observable<Adventure[]> {
        if (adventureKeys.length === 0) {
            return of([]);
        }
        return combineLatest(adventureKeys.map((adventureKey) => this.getAdventure(adventureKey)))
            .pipe(
                take(1),
                map((adventures) => {
                    adventures.forEach((adventure) => {
                        adventure.explorerCount = -1;
                        this.getExplorerCount(adventure.key)
                            .pipe(take(1))
                            .subscribe((count) => adventure.explorerCount = count);
                    });
                    return adventures;
                })
            );
    }

    getAdventureItem(adventureItemKey: string): Observable<AdventureItem> {
        return <Observable<AdventureItem>>this.db.object(this.adventureItemsRef.child(adventureItemKey).ref).snapshotChanges()
            .pipe(
                map(adventureItemSnapshot => AdventureService.getAdventureItemFromSnap(adventureItemSnapshot))
            );
    }

    getAdventureItems(adventureKey: string): Observable<AdventureItem[]> {
        return this.db.list<AdventureItem>(
            this.adventureItemsRef.ref,
            ref => ref.orderByChild('adventureKey').equalTo(adventureKey)
        ).snapshotChanges()
            .pipe(
                map((adventureItemsSnaps) => {
                    const adventureItems = adventureItemsSnaps.map((adventureItemsSnap) => {
                        return AdventureService.getAdventureItemFromSnap(adventureItemsSnap);
                    });
                    adventureItems.sort((a, b) => a.order - b.order);
                    return adventureItems;
                })
            );
    }

    getAdventureElementOriginalName(element: AdventureItem): Observable<string> {
        switch (element.refType) {
            case 1:
                return this.db.object<string>(`pois/${element.refKey}/name`).valueChanges();
            case 2:
                return this.db.object<string>(`tracks/${element.refKey}/name`).valueChanges();
        }
    }

    /**
     * Updates the settings of the adventure
     */
    updateSettings(adventure: Adventure): Promise<Adventure> {
        const updateAdventureSettings = <Adventure>{
            active: adventure.active,
            latitude: adventure.latitude,
            longitude: adventure.longitude,
            geohash: GeoService.latLngLiteralToGeohash({lat: adventure.latitude, lng: adventure.longitude}),
            tintColor: adventure.tintColor,
            expirationDate: null
        };
        return this.adventuresRef.child(adventure.key).update(updateAdventureSettings)
            .then(() => Object.assign(adventure, updateAdventureSettings));
    }

    /**
     * Updates the texts of the adventure
     */
    updateTexts(adventure: Adventure): Promise<void> {
        // Delete texts that are blank
        for (const lang of Object.keys(adventure.lang)) {
            for (const text of Object.keys(adventure.lang[lang])) {
                if (!adventure.lang[lang][text] || adventure.lang[lang][text] === 'undefined') {
                    adventure.lang[lang][text] = null;
                }
            }
        }
        const updateAdventureTexts = {
            name: adventure.name,
            header: adventure.header,
            description: adventure.description,
            lang: adventure.lang
        };
        return this.adventuresRef.child(adventure.key).update(updateAdventureTexts);
    }

    /**
     * Update an adventure infolet
     */
    updateInfolet(adventureKey: string, infoletIndex: number, infolet: Infolet): Promise<void> {
        if (infolet.content !== null) {
            infolet.bodyContent = null;
        } else {
            infolet.content = null;
        }
        return this.adventuresRef.child(adventureKey).child('infolets').child(infoletIndex.toString()).update(infolet);
    }

    /**
     * Updates the trail area image (called trackGroup in Firebase)
     */
    updateImage(adventure: Adventure): Promise<void> {
        const updateAdventureImg = {
            imageUrl: adventure.imageUrl
        };
        return this.adventuresRef.child(adventure.key).update(updateAdventureImg);
    }

    updateAdventureItemSettings(adventureItem: AdventureItem): Promise<void> {
        const updateObject: AdventureItem = <AdventureItem>{
            timeboxStart: adventureItem.timeboxStart || null,
            timeboxEnd: adventureItem.timeboxEnd || null,
            voucherPin: adventureItem.voucherPin || null,
            adventurePoints: adventureItem.adventurePoints || null,
            order: adventureItem.order || null
        };
        return this.adventureItemsRef.child(adventureItem.key).update(updateObject);
    }

    updateAdventureItemTexts(adventureItem: AdventureItem): Promise<void> {
        // Delete texts that are blank
        for (const lang of Object.keys(adventureItem.lang)) {
            for (const text of Object.keys(adventureItem.lang[lang])) {
                if (!adventureItem.lang[lang][text] || adventureItem.lang[lang][text] === 'undefined') {
                    adventureItem.lang[lang][text] = null;
                }
            }
        }
        const updateAdventureItemTexts = {
            name: adventureItem.name,
            header: adventureItem.header || null,
            description: adventureItem.description || null,
            lang: adventureItem.lang
        };
        return this.adventureItemsRef.child(adventureItem.key).update(updateAdventureItemTexts);
    }

    updatePayment(adventure: Adventure): Promise<void> {
        const paymentUpdates: Adventure = <Adventure>{
            accessProductKey: adventure.accessProductKey || null
        };
        return this.adventuresRef.child(adventure.key).update(paymentUpdates);
    }

    /**
     * Creates a new adventure in firebase
     */
    createNewAdventure(newAdventure: Adventure): string {
        const adventureThenableRef = this.adventuresRef.push(newAdventure);
        return adventureThenableRef.key;
    }

    createNewAdventureItem(newItem: AdventureItem): string {
        const adventureItemThenableRef: firebase.database.ThenableReference = this.adventureItemsRef.push(newItem);
        return adventureItemThenableRef.key;
    }

    /**
     * Deletes an adventure item.
     * @param {string} adventureItemKey
     * @returns {Promise<void>} A promise, which is resolved when the adventure item has been deleted.
     */
    deleteAdventureItem(adventureItemKey: string): Promise<void> {
        return this.adventureItemsRef.child(adventureItemKey).remove();
    }

    getExplorers(adventureKey: string): Observable<User[]> {
        return this.db.list<User>(
            this.adventureStatusRef.ref,
            ref => ref.orderByChild(`${adventureKey}/activatedTimeStamp`).startAt(1)
        ).snapshotChanges()
            .pipe(
                take(1),
                switchMap((userSnaps) => {
                    return this.userService.getUsers(userSnaps.map((userSnap) => userSnap.key))
                        .pipe(map((users) => users.filter((user) => user)));
                })
            );
    }

    getExplorerCount(adventureKey: string): Observable<number> {
        return this.db.list(
            this.adventureStatusRef.ref,
            ref => ref.orderByChild(`${adventureKey}/activatedTimeStamp`).startAt(1)
        ).valueChanges()
            .pipe(
                take(1),
                map((userSnaps) => userSnaps.length)
            );
    }

    getExplorersItemStatuses(adventureKey: string, adventureItems: AdventureItem[], users: User[]): Observable<Explorer[]> {
        return combineLatest(users.map(user => this.getExplorerItemStatuses(adventureKey, adventureItems, user))).pipe(take(1));
    }

    setAdventureElementStatus(profileKey: string, adventureKey: string,
                              adventureElementKey: string, updateElement: ExplorerItemStatus): Promise<void> {
        return this.adventureItemStatusRef.child(profileKey).child(adventureKey).child(adventureElementKey).update(updateElement);
    }

    deleteAdventureElementStatus(profileKey: string, adventureKey: string, adventureElementKey: string): Promise<void> {
        return this.adventureItemStatusRef.child(profileKey).child(adventureKey).child(adventureElementKey).remove();
    }

    private getExplorerItemStatuses(adventureKey: string, adventureItems: AdventureItem[], user: User): Observable<Explorer> {
        return this.db.list<AdventureItemStatus>(this.adventureItemStatusRef.child(user.userID).child(adventureKey).ref).snapshotChanges()
            .pipe(
                take(1),
                switchMap((statusSnaps) => {
                    const explorer: Explorer = <Explorer>user;
                    explorer.itemStatuses = [];
                    explorer.score = 0;
                    explorer.percentageScore = 0;
                    adventureItems.forEach((adventureItem, adventureItemIndex) => {
                        let startTime = 0;
                        let percentage: number = null;
                        statusSnaps.forEach((statusSnap) => {
                            if (statusSnap.key === adventureItem.key) {
                                const statusVal: ExplorerItemStatus = <ExplorerItemStatus>{
                                    adventureElementKey: statusSnap.key,
                                    ...statusSnap.payload.val()
                                };
                                startTime = statusVal.startTime;
                                if (typeof statusVal.percentageCompleted === 'number') {
                                    percentage = statusVal.percentageCompleted;
                                }
                            }
                        });
                        explorer.itemStatuses[adventureItemIndex] = {
                            adventureElementKey: adventureItems[adventureItemIndex].key,
                            startTime: startTime,
                            type: adventureItems[adventureItemIndex].refType
                        };
                        if (startTime > 0) {
                            explorer.itemStatuses[adventureItemIndex].percentageCompleted = percentage;
                            explorer.score += adventureItems[adventureItemIndex].adventurePoints || 0;
                            explorer.percentageScore +=
                                Math.round((adventureItems[adventureItemIndex].adventurePoints || 0) * (percentage || 100) / 100);
                        }
                    });
                    return of(explorer);
                })
            );
    }
}
