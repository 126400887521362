import { TranslatableObject } from './general';

export enum TextModelItemType {
    INPUT = 'input',
    TEXT_AREA = 'textarea'
}

export interface TextModelItem {
    help: string;
    html?: string;
    ignorePublic?: boolean;
    name: string;
    placeholder: string;
    publicVarName?: string;
    readonly?: boolean;
    required?: boolean;
    sort?: number;
    type: TextModelItemType;
    varName: string;
}

interface TextModelSubItem {
    name: string;
    items: TextModelItem[];
}

export interface TextModel {
    items: TextModelItem[];
    subItems?: { [varName: string]: TextModelSubItem };
}

export interface TextObject extends TranslatableObject {
    [varName: string]: any;
}
