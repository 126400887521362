import { Component, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

// Services
import { AuthService } from '../../core/auth.service';
import { NewsStoryService } from '../../firebase-services/news-story.service';

// Interfaces
import { NewsStory } from '../../interfaces/news-story';

@Component({
    selector: 'app-news-story-add-box',
    templateUrl: './news-story-add-box.component.html',
    styleUrls: ['./news-story-add-box.component.css']
})
export class NewsStoryAddBoxComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    @Input() roomName: string;
    @Input() newsStory: NewsStory = <NewsStory>{};
    @Input() navigateTo: string;

    newTitle: string = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        private newsStoryService: NewsStoryService
    ) {
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    createNewsStory(): void {
        if (typeof this.newTitle !== 'string' || this.newTitle === '') {
            alert('Please give your news story a title');
            return;
        }
        this.newsStory.title = this.newTitle;
        this.newsStory.disableInteractions = true;
        var newKey = this.newsStoryService.createNewsStory(this.newsStory, this.roomName, this.authService.user);
        this.router.navigate([this.navigateTo + newKey]);
    }
}
