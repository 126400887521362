import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

import { AuthService } from '../core/auth.service';
import { UserService } from '../firebase-services/user.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
    email: string;

    constructor(
        public authService: AuthService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.userService.getProfileEmailFromKey(this.authService.user.userID)
            .pipe(
                map((email) => this.email = email)
            )
            .subscribe();
    }

}
