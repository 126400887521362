// environment
import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from 'moment';

// Interfaces
import {
    ElasticProfileSegmentKeys,
    ElasticProfileSegmentKeysSearch,
    ElasticsearchCountResponseBody,
    ElasticsearchPitResponseBody,
    ElasticsearchResponseBody,
    ElasticUser
} from '../interfaces/elasticsearch';
import { User } from '../interfaces/user';

const headers: HttpHeaders = new HttpHeaders('Authorization: ApiKey ' + environment.elasticsearch.auth.apiKey);
const PROFILE_FETCH_SIZE = 15;
const PROFILE_SEGMENT_FETCH_SIZE = 10000;
const PIT_KEEP_ALIVE = '1m';

@Injectable({
    providedIn: 'root'
})
export class ElasticSearchService {

    constructor(
        private client: HttpClient
    ) {
    }

    private static segmentMustSearchArray(platform: string,
                                          appVersion: string,
                                          countryCode: string,
                                          seenFrom: number,
                                          seenTo: number): object[] {
        // Platform
        const mustArray: { [searchType: string]: { [searchTerms: string]: {} } }[] = [];
        if (platform.length > 0 && appVersion.length > 0) {
            const platformSearch = {
                'match': {
                    'deviceOS': platform
                }
            };
            const appVersionSearch = {
                'range': {
                    'deviceAppVersion': {
                        'gte': appVersion
                    }
                }
            };
            mustArray.push(platformSearch);
            mustArray.push(appVersionSearch);
        }

        // Country
        if (countryCode.length === 2) {
            const countrySearch = {
                'query_string': {
                    'query': '*' + countryCode,
                    'default_field': 'deviceCountry'
                }
            };
            mustArray.push(countrySearch);
        }

        // Last seen
        if (seenFrom > 0 || seenTo > 0) {
            let gte: number = null;
            let lte: number = null;
            if (seenFrom > 0) {
                gte = seenFrom;
                if (seenFrom < seenTo) {
                    lte = seenTo;
                }
            } else {
                lte = seenTo;
            }

            const seenSearch = {
                'range': {
                    'lastSeen': {
                        'gte': gte,
                        'lte': lte
                    }
                }
            };
            mustArray.push(seenSearch);
        }

        return mustArray;
    }

    searchProfiles(searchTerm: string): Observable<ElasticUser[]> {
        const search = {
            'query': {
                'simple_query_string': {
                    'query': searchTerm.split(' ').join('~ ') + '~',
                    'analyzer': 'simple',
                    'default_operator': 'AND',
                    'fuzzy_prefix_length': 2,
                    'fields': [
                        'deviceOS',
                        'email',
                        'name^8',
                        'userID',
                        'searchmix',
                        'searchmix._2gram',
                        'searchmix._3gram'
                    ]
                }
            },
            'sort': [
                {'_score': {'order': 'desc'}}
            ]
        };

        const url = environment.elasticsearch.host + '/profiles/_search?size=' + PROFILE_FETCH_SIZE;
        return this.client.post<ElasticsearchResponseBody>(url, search, {headers: headers})
            .pipe(
                map((results) => {
                    const users: ElasticUser[] = [];
                    if (typeof results.hits.hits === 'object' && results.hits.hits !== null) {
                        results.hits.hits.forEach((result) => {
                            const resultUser = <ElasticUser>{
                                user: <User>result._source,
                                _score: result._score,
                                lastSeenAgo: moment(result._source['lastSeen']).fromNow(),
                                lastSeenFormatted: moment(result._source['lastSeen']).format('llll')
                            };
                            users.push(resultUser);
                        });
                    }
                    return users;
                })
            );
    }

    getProfileSegmentCount(platform: string, appVersion: string, countryCode = '', seenFrom = 0, seenTo = 0): Observable<number> {
        // Putting together search
        const search = {
            'query': {
                'bool': {
                    'must': ElasticSearchService.segmentMustSearchArray(platform, appVersion, countryCode, seenFrom, seenTo)
                }
            }
        };

        const url = environment.elasticsearch.host + '/profiles/_count';
        return this.client.post<ElasticsearchCountResponseBody>(url, search, {headers: headers})
            .pipe(
                map((result) => result.count)
            );
    }

    getProfileSegmentKeys(platform: string, appVersion: string, countryCode = '', seenFrom = 0, seenTo = 0): Promise<string[]> {
        // Get PIT => Point in time marker
        const pit$ = this.client.post<ElasticsearchPitResponseBody>(
            environment.elasticsearch.host + '/profiles/_pit?keep_alive=' + PIT_KEEP_ALIVE, null, {headers: headers})
            .pipe(
                map((result) => result.id)
            );
        const pitPromise: Promise<string> = lastValueFrom(pit$);

        // Putting together search
        const searchPromise: Promise<ElasticProfileSegmentKeysSearch> = pitPromise
            .then((pitId) => {
                const search: ElasticProfileSegmentKeysSearch = {
                    'size': PROFILE_SEGMENT_FETCH_SIZE,
                    'query': {
                        'bool': {
                            'must': ElasticSearchService.segmentMustSearchArray(platform, appVersion, countryCode, seenFrom, seenTo)
                        }
                    },
                    'pit': {
                        'id': pitId,
                        'keep_alive': PIT_KEEP_ALIVE
                    },
                    'sort': [{
                        'userID': 'asc'
                    }],
                    '_source': false
                };
                return search;
            });

        // Get first search result
        const firstResultPromise = searchPromise
            .then((search) => this.profileKeysSearch(search));

        // Recursively get the next results until we have them all
        const allResultsPromise = Promise.all([searchPromise, firstResultPromise])
            .then(([search, partialResult]) => this.loadNextPage(1, search, partialResult));

        // Clean up: Delete Point in time / PIT
        const deletedPitPromise = allResultsPromise
            .then((allResults) => {
                const requestOptions: object = {
                    'headers': headers,
                    'body': {
                        'id': allResults.pit_id
                    }
                };
                const deleted$ = this.client.delete(environment.elasticsearch.host + '/_pit', requestOptions);
                return lastValueFrom(deleted$);
                //                    .then(() => console.log('PIT deleted', allResults.pit_id));
            });

        return Promise.all([allResultsPromise, deletedPitPromise])
            .then(([result]) => result.profileKeys);
    }

    private loadNextPage(runs: number,
                         search: ElasticProfileSegmentKeysSearch,
                         lastResult: ElasticProfileSegmentKeys): Promise<ElasticProfileSegmentKeys> {
        if (runs * PROFILE_SEGMENT_FETCH_SIZE > lastResult.profileKeys.length) {
            //            console.log('Finished searching', runs, '*', PROFILE_SEGMENT_FETCH_SIZE, '>', lastResult.profileKeys.length);
            return Promise.resolve(lastResult);
        }
        search.pit.id = lastResult.pit_id;
        search.search_after = lastResult.sort;
        //        console.log('Loading next page', runs, '*', PROFILE_SEGMENT_FETCH_SIZE, '<=', lastResult.profileKeys.length, search);
        return this.profileKeysSearch(search)
            .then((newResult) => {
                newResult.profileKeys = newResult.profileKeys.concat(lastResult.profileKeys);
                return this.loadNextPage(runs + 1, search, newResult);
            });
    }

    private profileKeysSearch(search: object): Promise<ElasticProfileSegmentKeys | null> {
        const $profileKeys = this.client.post<ElasticsearchResponseBody>(
            environment.elasticsearch.host + '/_search', search, {headers: headers})
            .pipe(
                map((results) => {
                    const users: string[] = [];
                    if (typeof results.hits.hits === 'object' && results.hits.hits !== null) {
                        results.hits.hits.forEach((result) => {
                            users.push(result._id);
                        });
                        const profileSegmentKeys: ElasticProfileSegmentKeys = {
                            profileKeys: users,
                            pit_id: results.pit_id,
                            sort: results.hits.hits[results.hits.hits.length - 1].sort
                        };
                        return profileSegmentKeys;
                    }
                    return null;
                })
            );
        return lastValueFrom($profileKeys);
    }

}
