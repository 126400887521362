import { Injectable } from '@angular/core';
import { PublishedState, PublishedStates } from '../interfaces/general';

@Injectable({
    providedIn: 'root'
})
export abstract class StateService {

    static updateState<T, U>(element: T, publicElement: U, state: PublishedState, extra?: any, extra2?: any) {
        state.canPublish = this.canPublish(element, extra);
        state.outOfSync = this.isOutOfSync(element, publicElement, extra);
        state.isPublished = this.isPublished(publicElement, extra2);

        if (state.altered) {
            state.progress = PublishedStates.DRAFT_ALTERED;
        } else if (!state.canPublish) {
            if (!state.isPublished) {
                state.progress = PublishedStates.DRAFT_SAVED;
            } else {
                state.progress = PublishedStates.PUBLISHED_NOT_READY;
            }
        } else {
            if (!state.isPublished) {
                state.progress = PublishedStates.DRAFT_READY;
            } else if (state.outOfSync) {
                state.progress = PublishedStates.PUBLISHED_OUT_OF_SYNC;
            } else {
                state.progress = PublishedStates.PUBLISHED_ONLINE;
            }
        }
        switch (state.progress) {
            case PublishedStates.DRAFT_SAVED:
                state.theme = 'secondary';
                state.icon = 'fas fa-save';
                break;
            case PublishedStates.DRAFT_READY:
                state.theme = 'primary';
                state.icon = 'fas fa-thumbs-up';
                break;
            case PublishedStates.PUBLISHED_NOT_READY:
                state.theme = 'danger';
                state.icon = 'fas fa-thumbs-down';
                break;
            case PublishedStates.PUBLISHED_OUT_OF_SYNC:
                state.theme = 'orange';
                state.icon = 'fas fa-sync';
                break;
            case PublishedStates.PUBLISHED_ONLINE:
                state.theme = 'success';
                state.icon = 'fas fa-globe-europe';
                break;
        }
    }

    protected static canPublish(element: any, extra?: any): boolean {
        console.log('canPublish - Implement in extended class.', element, extra);
        return false;
    }

    protected static isOutOfSync(element: any, publicElement: any, extra?: any): boolean {
        console.log('isOutOfSync - Implement in extended class.', element, publicElement, extra);
        return false;
    }

    protected static isPublished(publicElement: any, extra2?: any): boolean {
        console.log('isPublished - Implement in extended class.', publicElement, extra2);
        return false;
    }

}
