import { environment } from '../../../environments/environment';

import { Component, AfterViewChecked, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../core/layout.service';
import { RideService } from '../../firebase-services/ride.service';

// Interfaces
import { ProfileRide } from '../../interfaces/ride';

import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-rides',
    templateUrl: './rides.component.html',
    styleUrls: ['./rides.component.css']
})
export class RidesComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    firebaseRoot: string = environment.firebase.databaseURL;
    profileRides: ProfileRide[] = null;
    dataTableCreated = false;
    rideDataTable: any = null;
    dataReady = true;
    periodInputIsLoaded = false;
    userKey: string = null;

    startTime: number = Date.now() - 1000 * 60 * 60 * 24 * 7;
    endTime: number = Date.now();
    ridesDataTableName = '#ridesDataTable';
    periodInputName = '#periodInput';
    timeFormat = 'DD-MM-YYYY HH:mm';

    productionEnvironment = environment.production;

    constructor(
        public layout: LayoutService,
        private rideService: RideService
    ) {
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.ridesDataTableName + '>tbody>tr').length > 0)) {
            this.rideDataTable = $(this.ridesDataTableName).DataTable({
                'order': [[0, 'desc']]
            });
            this.dataTableCreated = true;
        }
        if (!this.periodInputIsLoaded && $(this.periodInputName).length > 0) {
            const ridesComponent = this;
            $(function () {
                $(ridesComponent.periodInputName).daterangepicker(
                    {
                        timePicker: true,
                        timePicker24Hour: true,
                        timePickerIncrement: 5,
                        startDate: moment(ridesComponent.startTime),
                        endDate: moment(ridesComponent.endTime),
                        locale: {
                            format: ridesComponent.timeFormat
                        }
                    },
                    function (start: moment.Moment, end: moment.Moment) {
                        ridesComponent.startTime = start.unix() * 1000;
                        ridesComponent.endTime = end.unix() * 1000;
                        ridesComponent.searchForRidesInPeriod();
                    }
                );
            });
            this.periodInputIsLoaded = true;
        }
    }

    searchForRidesInPeriod() {
        console.log('Search for rides in period begun', this.startTime, this.endTime);
        this.dataReady = false;
        this.rideService.getProfileRidesInDateRange(this.startTime, this.endTime)
            .pipe(takeUntil(this.destroy$))
            .subscribe(profileRides => {
                this.setProfileRides(profileRides);
            });
    }

    searchForUserRides(profileKey: string): void {
        console.log('Search for user rides begun', profileKey);
        this.dataReady = false;
        this.rideService.getProfileRidesForProfile(profileKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe(profileRides => {
                this.setProfileRides(profileRides);
            });
    }

    private setProfileRides(profileRides: ProfileRide[]) {
        if (profileRides.length === 0) {
            this.dataReady = true;
            this.profileRides = null;
        } else {
            this.profileRides = profileRides;

        }

        if (this.dataTableCreated && this.rideDataTable !== null) {
            this.rideDataTable.destroy();
            this.dataTableCreated = false;
        }
        this.dataReady = true;
    }

    copyKey(key: string): void {
        navigator.clipboard.writeText(key);
    }
}
