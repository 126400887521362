<button [ngClass]="'btn btn-'+btnClassType" data-toggle="modal" [attr.data-target]="'#findProfileSegmentModal_'+modalId">
    <ng-content></ng-content>
</button>
<div class="modal fade" [id]="'findProfileSegmentModal_'+modalId" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ modalTitle }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <div><label>Platform</label></div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" [name]="'platform'+modalId" value="iOS" [id]="'platformIos'+modalId"
                               [(ngModel)]="osPlatform" (change)="searchForSegment()">
                        <label class="form-check-label" [for]="'platformIos'+modalId"><i class="fab fa-apple"></i></label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" [name]="'platform'+modalId" value="Android" [id]="'platformAndroid'+modalId"
                               [(ngModel)]="osPlatform" (change)="searchForSegment()">
                        <label class="form-check-label" [for]="'platformAndroid'+modalId"><i class="fab fa-android"></i></label>
                    </div>
                </div>
                <div class="form-group">
                    <label [for]="'countryCode'+modalId">Country</label>
                    <select *ngIf="countries" [(ngModel)]="countryCode" [id]="'countryCode'+modalId" class="form-control"
                            (change)="searchForSegment()" [disabled]="osPlatform===''">
                        <option value="">-- The World --</option>
                        <option *ngFor="let country of countries" [value]="country.countryCode">{{ country.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Last seen on or after</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <i class="input-group-text far fa-clock"></i>
                        </div>
                        <input type="text" class="form-control" [id]="'seenFrom'+modalId" [disabled]="osPlatform===''">
                    </div>
                </div>
                <div class="form-group">
                    <label>Last seen on or before</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <i class="input-group-text far fa-clock"></i>
                        </div>
                        <input type="text" class="form-control" [id]="'seenTo'+modalId" [disabled]="osPlatform===''">
                    </div>
                </div>
                <div [ngClass]="'alert alert-'+(reach>0?btnClassType:'danger')">
                    <h5><i class="icon fas fa-users"></i> Reach: <b>{{ reach }}</b></h5>
                    The reach is the number of users in the specified segment.<br>
                    <span *ngIf="reach>0">Click "Apply" to complete the action on these users.</span>
                    <span *ngIf="reach===0 && osPlatform!=''">Please adjust your specifications to increase the reach.</span>
                    <span *ngIf="reach===0 && osPlatform===''">Please select a platform.</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                <button type="button" [ngClass]="'btn btn-'+btnClassType" (click)="select()" [disabled]="reach===0">Apply</button>
            </div>
        </div>
        <div class="overlay" *ngIf="disableSearch">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
    </div>
</div>
