<button class="btn btn-{{ btnClassType }}" data-toggle="modal" [attr.data-target]="'#findUserModal_'+modalId" (click)="focusInSearch()">
    <ng-content></ng-content>
</button>
<div class="modal fade" id="findUserModal_{{modalId}}" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ modalTitle }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <strong><i class="fas fa-search mr-2"></i> <label for="searchForUsers{{modalId}}">Search for users</label></strong>
                    <input (change)="searchForUsers()" (keyup)="searchForUsers()" [disabled]="disableSearch" id="searchForUsers{{modalId}}"
                           class="form-control" placeholder="Enter a search term...">
                </div>
                <div class="table-responsive">
                    <ul class="todo-list">
                        <li *ngFor="let user of foundUsers">
                            <img class="img-fluid img-circle img-sm" src="{{ user.user.userPicture }}" alt="">
                            <div class="tools">
                                <button class="btn btn-xs btn-success" (click)="appoint(user.user.userID)">Select</button> &nbsp;
                            </div>
                            <div class="img-push">
                                <span class="text">{{ user.user.name }}</span>
                            </div>
                            <div>
                                <i class="fas fa-search" title="Search score: {{ user._score | number:'1.1-3' }}">&nbsp;</i>
                                <i *ngIf="user.user.deviceOS === 'Android'" class="fab fa-android"
                                   title="Android {{ user.user.deviceOSVersion }}, app: {{ user.user.deviceAppVersion }}">&nbsp;</i>
                                <i *ngIf="user.user.deviceOS === 'iOS'" class="fab fa-apple" title="iOS">&nbsp;</i>
                                <i (click)="copyText(user.user.email)" role="button" class="fas fa-address-book"
                                   title="Click to copy email: {{ user.user.email }}">&nbsp;</i>
                                <i (click)="copyText(user.user.userID)" role="button" class="fas fa-fingerprint"
                                   title="Click to copy profile key: {{ user.user.userID }}">&nbsp;</i>
                                <i (click)="copyText(user.user.geohash)" role="button" class="fas fa-map-marker-alt"
                                   title="Click to copy geohash: {{ user.user.geohash }}">&nbsp;</i>
                                <small class="badge badge-default"
                                       title="Last seen in app {{ user.lastSeenFormatted }}">{{ user.lastSeenAgo }}</small>
                                <small class="badge badge-danger"
                                       title="Country: {{ user.user.deviceCountry }} / locale info: {{ user.user.deviceLocale }}">{{ user.user.deviceCountry }}
                                    / {{ user.user.deviceLocale }}</small>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
            </div>
        </div>
        <div class="overlay" *ngIf="disableSearch">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
    </div>
</div>
