import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';

// Services
import { ElasticSearchService } from '../../services/elastic-search.service';

// Interfaces
import { ElasticUser } from '../../interfaces/elasticsearch';

declare var $: any;

@Component({
    selector: 'app-find-user',
    templateUrl: './find-user.component.html',
    styleUrls: ['./find-user.component.css']
})
export class FindUserComponent implements AfterViewChecked {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Output() userSelected = new EventEmitter<string>();

    SEARCH_TIMEOUT = 1000;
    searchTimeout: number;
    lastSearchTerm = '';
    disableSearch = false;
    searchInput: HTMLInputElement = null;
    foundUsers: ElasticUser[] = [];

    constructor(
        private elasticSearch: ElasticSearchService
    ) {
    }

    ngAfterViewChecked() {
        if (this.searchInput === null) {
            this.searchInput = <HTMLInputElement>document.getElementById('searchForUsers' + this.modalId);
        }
    }

    focusInSearch(): void {
        window.setTimeout(() => this.searchInput.focus(), 500);
    }

    searchForUsers(): void {
        window.clearTimeout(this.searchTimeout);
        const searchTerm: string = this.searchInput.value.trim();
        if (searchTerm === this.lastSearchTerm || searchTerm.length < 3) {
            return;
        }
        this.searchTimeout = window.setTimeout(() => this.startDatabaseSearch(searchTerm), this.SEARCH_TIMEOUT);
    }

    appoint(profileKey: string): void {
        this.userSelected.emit(profileKey);
        $('#findUserModal_' + this.modalId).modal('hide');
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }

    private startDatabaseSearch(searchTerm: string): Promise<void> {
        if (this.disableSearch) {
            return;
        }
        this.disableSearch = true;
        this.lastSearchTerm = searchTerm;
        this.elasticSearch.searchProfiles(searchTerm)
            .subscribe((users) => {
                this.foundUsers = users;
                this.disableSearch = false;
                window.setTimeout(() => this.searchInput.focus(), 0);
            });
    }

}
