export interface CreateOnMap {
    name: string;
    lat: number;
    lng: number;
    geohash: string;
}

export const LOOSE_PADDING = 0.04;

export enum ZoomRestrictionSetting {
    LOOSE = 'loose',
    MEDIUM = 'medium',
    STRICT = 'strict'
}

export interface TrailAreaOnMap {
    centerMarker: google.maps.Marker;
    editPoiKey?: string;
    poiMarkerClusters?: { [poiLabel: string]: google.maps.Marker[] };
    trailPolylines?: { [trailKey: string]: google.maps.Polyline };
}

export const DefaultMapProp: google.maps.MapOptions = {
    clickableIcons: false,
    mapTypeControl: true,
    fullscreenControl: true,
    streetViewControl: false,
    styles: [{
        featureType: 'poi.business',
        stylers: [{visibility: 'off'}],
    }]
};
