<div class="content-wrapper">
    <app-content-header-section contentPageId="newsRoomWelcome" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title">Add Welcome Story to Country</h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Select welcome story</label>
                                        <select class="form-control" [(ngModel)]="selectStory">
                                            <option *ngFor="let story of publishedStories" [value]="story.key">{{ story.title }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <i class="fa fa-arrow-right"></i>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label>Select receivers</label>
                                        <select class="form-control" [(ngModel)]="selectCountry">
                                            <option value="default">Default</option>
                                            <option *ngFor="let country of countries" [value]="country.countryCode">{{ country.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="selectCountry === null || selectStory === null" (click)="addWelcomeToList()" class="btn btn-primary">
                                Add to country
                            </button>
                        </div>
                    </div>

                </div>
                <div *ngIf="countries" class="col-md-6">

                    <app-news-room-list listKey="default" navigateTo="/root/news-room/stories/"
                                        listName="Default Welcome News (used for countries not in list or with no stories in list)"></app-news-room-list>
                    <div *ngFor="let country of countries">
                        <app-news-room-list [listKey]="country.countryCode" navigateTo="/root/news-room/stories/"
                                            [listName]="'Welcome News for ' + country.name"></app-news-room-list>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
