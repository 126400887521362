<div class="card card-outline card-success" *ngIf="adventures">
    <div class="card-header">
        <h3 class="card-title">Adventures</h3>
    </div>
    <div class="card-body">
        <ul class="todo-list">
            <li *ngFor="let adventure of adventures">
                <i class="fas fa-eye-slash" *ngIf="!adventure.active" title="Deactivated"></i>
                <i class="far fa-compass" *ngIf="adventure.active"></i>
                {{ adventure.name }}
                <div class="tools">
                    <a *ngIf="adventure.explorerCount > 0"
                       title="View the {{ adventure.explorerCount }} explorers of the '{{adventure.name}}'-adventure"
                       routerLink="{{ routerRoot }}/adventures/{{ adventure.key }}/explorers"><i class="fas fa-biking mr-2"></i></a>
                    <a routerLink="{{ routerRoot }}/adventures/{{ adventure.key }}"><i class="far fa-edit"
                                                                                       title="Edit the '{{ adventure.name }}'-adventure"></i></a>
                </div>
            </li>
        </ul>
    </div>
</div>
