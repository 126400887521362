import {Injectable} from '@angular/core';
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {forkJoin, Observable, of, zip} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {UserService} from "../../firebase-services/user.service";
import {TrailAreaService} from "../../firebase-services/trail-area.service";
import firebase from "firebase/compat";
import {DmoManager, DmoManagerListItem} from "../../interfaces/dmo-manager";
import {TrailArea} from "../../interfaces/trailArea";

@Injectable({
    providedIn: 'root'
})
export class DmoManagerService {
    private dmoManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private trailAreaService: TrailAreaService
    ) {
        this.dmoManagersRef = this.db.database.ref('dmoManagers')
    }

    public getDmoManagers(): Observable<DmoManager[]> {
        return this.db.list<DmoManager>(this.dmoManagersRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((emSnapshots) => {
                    const managers: DmoManager[] = [];
                    for (const emSnapshot of emSnapshots) {
                        managers.push(this.DmoManagerFromSnap(emSnapshot));
                    }
                    return managers;
                })
            );
    }

    getDmoManagerListItems(): Observable<DmoManagerListItem[]> {
        return this.db.list<DmoManager>(this.dmoManagersRef.ref).snapshotChanges()
            .pipe(switchMap((emSnapshots) => {
                const dmoManagerObservables = emSnapshots.map(emSnapshot => {
                    const dmoManager: DmoManager = this.DmoManagerFromSnap(emSnapshot);
                    return this.userService.getUser(dmoManager.userKey)
                        .pipe(take(1), switchMap((emUser) => {
                            if (dmoManager.trailAreaKeys) {
                                return this.trailAreaService.getTrailAreas(dmoManager.trailAreaKeys)
                                    .pipe(take(1), map((emTrailAreas) => ({
                                        user: emUser,
                                        trailAreas: emTrailAreas
                                    })));
                            } else {
                                return of({user: emUser, trailAreas: []});
                            }
                        }));
                });
                return forkJoin(dmoManagerObservables);
            }));
    }

    public getDmoManagerTrailAreas(userKey: string): Observable<TrailArea[]> {
        return this.db.list<string>(this.dmoManagersRef.child(userKey).child('trailAreaKeys').ref).snapshotChanges()
            .pipe(
                switchMap((dmoManager) => {
                    const trailAreaKeys: string[] = [];
                    for (const userTrailArea of dmoManager) {
                        trailAreaKeys.push(userTrailArea.key);
                    }
                    if (trailAreaKeys.length === 0) {
                        return of(null);
                    }
                    return this.trailAreaService.getTrailAreas(trailAreaKeys);
                })
            );
    }

    private DmoManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<DmoManager>>): DmoManager {
        const dmoManager: DmoManager = snapshot.payload.val();
        dmoManager.userKey = snapshot.key;
        dmoManager.trailAreaKeys = (typeof dmoManager.trailAreaKeys === 'object' && dmoManager.trailAreaKeys !== null)
            ? Object.values(dmoManager.trailAreaKeys) : [];
        return dmoManager;
    }

    public addTrailAreaToDmoManager(userKey: string, areaKey: string): Promise<void> {
        const area: object = {
            [areaKey]: areaKey
        }
        return this.dmoManagersRef.child(userKey).child('trailAreaKeys').update(area);
    }

    public removeTrailAreaFromDmoManager(userKey: string, areaKey: string): Promise<void> {
        return this.dmoManagersRef.child(userKey).child('trailAreaKeys').child(areaKey).remove();
    }

}