import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/compat/database';

import firebase from 'firebase/compat';

// Services
import {GoogleGeocodeService} from '../services/google-geocode.service';

// Interfaces
import {Country, NewCountry} from '../interfaces/countries';
import {LatLngArray} from '../interfaces/trailArea';

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    countryRef: firebase.database.Reference;
    countryShapesRef: firebase.database.Reference;
    // Region is WIP
    // regionRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private geocodeService: GoogleGeocodeService
    ) {
        this.countryRef = this.db.database.ref('countries');
        this.countryShapesRef = this.db.database.ref('countryShapes');
        // Region is WIP
        // this.regionRef = this.db.database.ref('regions');
    }

    getCountry(countryCode: string): Observable<Country> {
        return this.db.object<Country>(this.countryRef.child(countryCode).ref).valueChanges()
            .pipe(
                take(1),
                switchMap((country) => this.getFullCountry(country))
            );
    }

    getCountries(): Observable<Country[]> {
        return this.db.list<Country>(this.countryRef.ref).valueChanges()
            .pipe(
                take(1),
                switchMap((countries) => {
                    const fullCountries: Observable<Country>[] = [];
                    countries.forEach((country) => fullCountries.push(this.getFullCountry(country)));
                    return zip(...fullCountries);
                })
            );
    }

    addAdventureToCountry(countryCode: string, adventureKey: string): string {
        return this.countryRef.child(countryCode).child('adventures').push(adventureKey).key;
    }

    storeCountryShapes(countryCode: string, landShapes: LatLngArray[]): Promise<any> {
        return this.countryShapesRef.child(countryCode).set(landShapes);
    }

    loadCountryShapes(countryCode: string): Observable<LatLngArray[]> {
        return this.db.object<LatLngArray[]>(this.countryShapesRef.child(countryCode).ref).valueChanges();
    }

    private getFullCountry(country: Country): Observable<Country> {
        country.adventures = (typeof country.adventures === 'object' && country.adventures !== null)
            ? Object.values(country.adventures) : [];
        if (typeof country.boundsNorth !== 'number') {
            return this.geocodeService.lookupCountry(country.name)
                .pipe(
                    switchMap((lookupCountry) => {
                        const updateCountry: Country = <Country>{
                            boundsNorth: lookupCountry.boundsNorth,
                            boundsSouth: lookupCountry.boundsSouth,
                            boundsEast: lookupCountry.boundsEast,
                            boundsWest: lookupCountry.boundsWest,
                            lat: lookupCountry.lat,
                            lng: lookupCountry.lng
                        };
                        return this.countryRef.child(country.countryCode).update(updateCountry)
                            .then(() => Object.assign(country, updateCountry));
                    })
                );
        }
        return of(country);
    }

    addNewCountry(country: NewCountry): Promise<void> {
        return this.countryRef.child(country.countryCode).update(country);
    }

    // Region is WIP
    // private static getRegionFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<Region>>): Region {
    //     const region: Region = snapshot.payload.val();
    //     region.key = snapshot.key;
    //     return region;
    // }

    // Region is WIP
    // getRegionsInCountry(countryCode: string): Observable<Region[]> {
    //     return this.db.list<Region>(this.regionRef.child(countryCode).ref).snapshotChanges()
    //         .pipe(
    //             take(1),
    //             map((regionSnaps) => regionSnaps.map((regionSnap) => CountryService.getRegionFromSnap(regionSnap)))
    //         );
    // }
}
