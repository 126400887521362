import {Component, EventEmitter, Input, Output} from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-edit-role-description',
    templateUrl: './edit-role-description.component.html',
    styleUrls: ['./edit-role-description.component.css']
})
export class EditRoleDescriptionComponent {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() oldRoleDesc: string;
    @Input() btnClassType: string;
    @Output() newRoleDescription = new EventEmitter<string>();

    constructor(
    ) {
    }

    updateRoleDescription() {
        const desc: HTMLInputElement = <HTMLInputElement>document.getElementById('searchForTrailAreas' + this.modalId);
        this.newRoleDescription.emit(desc.value);
        $('#editRoleDescriptionModal_' + this.modalId).modal('hide');
    }
}
