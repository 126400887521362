import { Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'trail-center-dashboard',
    templateUrl: './trail-center-dashboard.component.html',
    styleUrls: ['./trail-center-dashboard.component.css']
})
export class TrailCenterDashboardComponent implements OnInit, OnDestroy {


    constructor(

    ) {
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }
}
