import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../core/layout.service';
import { MediaLibraryService } from '../../firebase-services/media-library.service';

// Interfaces
import { DefaultImage, MediaIcon, MediaLibraryTypes, MediaType } from '../../interfaces/media-library';

@Component({
    selector: 'app-media-library',
    templateUrl: './media-library.component.html',
    styleUrls: ['./media-library.component.css']
})
export class MediaLibraryComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    contentPageId = 'mediaLibrary';
    MediaLibraryTypes = MediaLibraryTypes;

    mediaTypes: { [mediaType: string]: MediaType } = {};

    mediaIcons: MediaIcon[] = null;
    defaultImages: DefaultImage[] = null;

    constructor(
        public layout: LayoutService,
        private mediaLibraryService: MediaLibraryService,
    ) {
        this.initMediaLibrary();
    }

    ngOnInit(): void {
        this.loadMediaIcons();
        this.loadDefaultImages();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    mediaTypeCompareFunction(a: KeyValue<string, MediaType>, b: KeyValue<string, MediaType>): number {
        return (a.key > b.key) ? 1 : (a.key < b.key ? -1 : 0);
    }

    private initMediaLibrary() {
        this.mediaTypes[MediaLibraryTypes.ICONS] = {
            disableCreate: true,
            fileTypes: {
                'iconPdf': {
                    acceptedFileType: 'application/pdf',
                    disableUpload: true,
                    fileName: null,
                    iconUrl: 'fas fa-file-pdf',
                    name: 'Icon PDF',
                    tempFile: null,
                    storageFileName: null,
                    storageFullPath: null,
                    uploadInfo: 0
                },
                'iconSvg': {
                    acceptedFileType: 'image/svg+xml',
                    disableUpload: true,
                    fileName: null,
                    iconUrl: 'fas fa-file-code',
                    name: 'Icon SVG',
                    tempFile: null,
                    storageFileName: null,
                    storageFullPath: null,
                    uploadInfo: 0
                }
            },
            newName: '',
            theme: 'icon',
            typeKey: MediaLibraryTypes.ICONS,
            typeName: 'Icon',
            typeNamePlural: 'Icons'
        };

        this.mediaTypes[MediaLibraryTypes.DEFAULT_IMAGES] = {
            disableCreate: true,
            fileTypes: {
                'image': {
                    acceptedFileType: 'image/svg+xml,image/jpeg,image/png,image/webp,image/avif,image/gif,image/tiff',
                    disableUpload: true,
                    fileName: null,
                    iconUrl: 'fas fa-file-image',
                    name: 'Input image',
                    tempFile: null,
                    storageFileName: null,
                    storageFullPath: null,
                    uploadInfo: 0
                }
            },
            newName: '',
            theme: 'image',
            typeKey: MediaLibraryTypes.DEFAULT_IMAGES,
            typeName: 'Image (640x480)',
            typeNamePlural: 'Images (640x480)'
        };
    }

    private loadMediaIcons() {
        this.mediaLibraryService.getMediaIcons()
            .pipe(takeUntil(this.destroy$))
            .subscribe(mediaIcons => this.mediaIcons = mediaIcons);
    }

    private loadDefaultImages() {
        this.mediaLibraryService.getDefaultImages()
            .pipe(takeUntil(this.destroy$))
            .subscribe(defaultImages => this.defaultImages = defaultImages);
    }
}
