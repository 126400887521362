import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-news-room-stories',
    templateUrl: './news-room-stories.component.html',
    styleUrls: ['./news-room-stories.component.css']
})
export class NewsRoomStoriesComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
