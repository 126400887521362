export const AvailableLanguages = {
    da: 'Dansk',
    no: 'Norsk',
    se: 'Svenska',
    de: 'Deutsch',
    nl: 'Nederlands',
    fr: 'Français',
    cs: 'Čeština',
    sk: 'Slovenčina',
    sl: 'Slovenski jezik',
    hr: 'Hrvatski jezik'
};