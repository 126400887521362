<div class="container-fluid">
    <div class="row">
        <div class="col-12">

            <div class="card card-outline card-success">
                <div class="card-header">
                    <h3 class="card-title"><i class="fas fa-biking"></i> The Explorers</h3>
                </div>
                <div class="card-body px-0">
                    <div class="form-group px-1" *ngIf="!editing">
                        <button id="editButton" (click)="startEditMode()" class="btn btn-info">Edit results</button>
                    </div>
                    <div class="form-group px-1" *ngIf="editing">
                        <button id="updateButton" (click)="updateView()" class="btn btn-warning">Reload table</button>
                    </div>
                    <table *ngIf="allIsLoaded" id="explorersDataTable" class="table row-bordered table-striped" role="grid">
                        <thead>
                        <tr>
                            <th>Explorer</th>
                            <th *ngFor="let item of items">
                                {{ item.order }}.
                                <i *ngIf="item.refType == 1" class="fas fa-map-marker-alt"></i>
                                <i *ngIf="item.refType == 2" class="fas fa-route"></i>
                                {{ item.name }}
                                ({{ item.adventurePoints }})
                            </th>
                            <th>Score</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let explorer of explorers">
                            <td>
                                {{ explorer.name }}
                                <i *ngIf="explorer.deviceOS === 'Android'" class="fab fa-android"></i>
                                <i *ngIf="explorer.deviceOS !== 'Android'" class="fab fa-apple"></i>
                            </td>
                            <td *ngFor="let status of explorer.itemStatuses">
                                    <span *ngIf="status.startTime">
                                        {{ status.startTime | date:'dd/MM/yyy @HH:mm' }}
                                    </span>
                                <div *ngIf="status.type === 2">
                                        <span *ngIf="status.percentageCompleted >= 0 && !editing">
                                            ({{status.percentageCompleted}} %)
                                        </span>
                                    <span *ngIf="editing && status.percentageCompleted >= 0">
                                            <input type="number" min="20" max="100" step="0.1" [(ngModel)]="status.percentageCompleted"/>&nbsp;%
                                        </span>
                                </div>
                                <span *ngIf="editing && !status.startTime">
                                        <button (click)="status.percentageCompleted = 20; status.startTime = registerElement(explorer.userID, status);"
                                                class="btn btn-warning">Register</button>
                                    </span>
                                <span *ngIf="editing && status.startTime">
                                        <button *ngIf="status.type === 2" (click)="registerElement(explorer.userID, status)" class="btn btn-info">Update</button>
                                        <button (click)="status.percentageCompleted = status.startTime = deleteElement(explorer.userID, status.adventureElementKey)"
                                                class="btn btn-danger">Delete</button>
                                    </span>
                            </td>
                            <td [attr.data-order]="explorer.score * 1000 + explorer.percentageScore">
                                <span>{{ explorer.score }} ({{ explorer.percentageScore }})</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
