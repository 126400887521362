<div class="content-wrapper">
    <app-content-header-section contentPageId="newsRoomDrafts" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">

                    <app-news-room-list storyType="draft" roomName="news-room" navigateTo="/root/news-room/drafts/" listName="News Story Drafts"
                                        theme="newsDraft"></app-news-room-list>

                </div>
            </div>

            <div class="row">
                <div class="col-md-6">

                    <app-news-story-add-box roomName="news-room" navigateTo="/root/news-room/drafts/"></app-news-story-add-box>

                </div>
            </div>
        </div>
    </section>

</div>
