<div [ngClass]="'card card-outline card-tabs card-' + colorTheme">
    <div class="card-header border-bottom-0">
        <h3 class="card-title"><i class="fas fa-language mr-2"></i> {{ textsName }} Texts</h3>
    </div>

    <div class="card-header p-0 pt-1 border-bottom-0">
        <ul class="nav nav-tabs" [id]="'translatable_texts_tabs_' + componentId" role="tablist">
            <li class="nav-item">
                <a [id]="'tab_default_link_' + componentId" [href]="'#tab_default_' + componentId"
                   [attr.aria-controls]="'#tab_default_' + componentId" aria-selected="true" data-toggle="pill" role="tab" class="nav-link active">English</a>
            </li>
            <li *ngFor="let language of usedLanguages" class="nav-item">
                <a [id]="'tab_' + language + '_link_' + componentId" [href]="'#tab_' + language + '_' + componentId"
                   [attr.aria-controls]="'#tab_' + language + '_' + componentId" aria-selected="false" data-toggle="pill" role="tab"
                   class="nav-link">{{ language }}</a>
            </li>
            <li class="nav-item dropdown" [class.hidden]="_readonly">
                <a class="nav-link dropdown-toggle" href="#" [id]="'translatable_texts_dropdown_' + componentId" role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-language"></i>+
                </a>
                <div [attr.aria-labelledby]="'translatable_texts_dropdown_' + componentId" class="dropdown-menu">
                    <span class="dropdown-header">Add another language</span>
                    <a *ngFor="let language of unusedLanguages" class="dropdown-item"
                       (click)="addLanguage(language)">Add {{ languageNames[language] }}</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="card-body">
        <app-alert-info-box [infoTrigger]="infoTrigger">
            <h4>{{ textsName }} texts saved successfully</h4>
        </app-alert-info-box>

        <div class="tab-content">

            <div [id]="'tab_default_' + componentId" class="tab-pane fade active show" role="tabpanel">
                <div *ngFor="let item of _textModel.items" class="form-group">
                    <label [for]="item.varName + componentId" [innerHTML]="item.html ?? item.name"></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" title="By default texts are in english">English (default)</span>
                        </div>
                        <input *ngIf="item.type === inputType" [disabled]="_readonly || item.readonly" [(ngModel)]="_textObject[item.varName]"
                               (change)="textsChanged()" (keyup)="textsChanged()" [id]="item.varName + componentId" class="form-control" [placeholder]="item.placeholder">
                        <textarea *ngIf="item.type === textareaType" [disabled]="_readonly || item.readonly" [(ngModel)]="_textObject[item.varName]"
                                  (change)="textsChanged()" (keyup)="textsChanged()" [id]="item.varName + componentId"
                                  [attr.aria-describedby]="item.varName + 'Help' + componentId" class="form-control"
                                  [placeholder]="item.placeholder"></textarea>
                    </div>
                    <small class="form-text text-muted" [id]="item.varName + 'Help' + componentId">{{ item.help }}</small>
                </div>
                <ng-container *ngIf="_textModel.subItems && subItemKeys">
                    <div *ngFor="let subItemKey of subItemKeys" class="form-group form-group-sm">
                        <label>{{ _textModel.subItems[subItemKey].name }}</label>
                        <div *ngFor="let inputText of _textObject[subItemKey] | keyvalue: orderByNumericKey" class="row mb-2">
                            <div class="col-1">
                                #{{ plusOne(inputText.key + '') }}:<br>
                                <i class="far fa-trash-alt" (click)="removeSubItem(subItemKey, inputText.key)"></i>
                            </div>
                            <div class="col-11">
                                <ng-container *ngFor="let item of _textModel.subItems[subItemKey].items">
                                    <label [for]="item.varName + '_' + inputText.key + '_' + item.varName + componentId"
                                           class="control-label text-sm">{{ item.name }}</label>
                                    <ng-container *ngIf="item.type === inputType || true">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" title="By default texts are in english">English (default)</span>
                                            </div>
                                            <input [disabled]="_readonly || item.readonly"
                                                   [(ngModel)]="_textObject[subItemKey][inputText.key][item.varName]"
                                                   (change)="textsChanged()" (keyup)="textsChanged()"
                                                   [id]="item.varName + '_' + inputText.key + '_' + item.varName + componentId"
                                                   [attr.aria-describedby]="'help_' + item.varName + '_' + inputText.key + '_' + item.varName + componentId"
                                                   class="form-control input-sm" [placeholder]="item.placeholder">
                                        </div>
                                    </ng-container>
                                    <small class="form-text text-muted"
                                           [id]="'help_' + item.varName + '_' + inputText.key + '_' + item.varName + componentId">{{ item.help }}</small>
                                </ng-container>
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-sm" (click)="addSubItem(subItemKey)">+ Add a {{ _textModel.subItems[subItemKey].name }}</button>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div *ngFor="let language of usedLanguages" class="tab-pane fade" [id]="'tab_' + language + '_' + componentId" role="tabpanel">
                <div *ngFor="let item of _textModel.items" class="form-group">
                    <label [for]="item.varName + language + componentId" [innerHTML]="item.html ?? item.name"></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" [title]="'Translate to ' + language">{{ language }}</span>
                        </div>
                        <input *ngIf="item.type === inputType" [disabled]="_readonly || item.readonly"
                               [(ngModel)]="_textObject.lang[language][item.varName]"
                               (change)="textsChanged()" (keyup)="textsChanged()" [id]="item.varName + language + componentId" class="form-control"
                               [placeholder]="_textObject[item.varName] || item.placeholder">
                        <textarea *ngIf="item.type === textareaType" [disabled]="_readonly || item.readonly"
                                  [(ngModel)]="_textObject.lang[language][item.varName]"
                                  (change)="textsChanged()" (keyup)="textsChanged()" [id]="item.varName + language + componentId"
                                  [attr.aria-describedby]="'help_' + item.varName + language + componentId" class="form-control"
                                  [placeholder]="_textObject[item.varName] || item.placeholder"></textarea>
                        <span class="input-group-append" [class.hidden]="_readonly || item.readonly">
                            <button type="button" class="btn btn-info" [title]="'Copy default ' + item.name"
                                    (click)="copyDefaultText(item.varName, language)"><i class="far fa-copy"></i></button>
                        </span>
                    </div>
                    <small class="form-text text-muted" [id]="'help_' + item.varName + language + componentId">
                        <span *ngIf="_textObject[item.varName]">Default: <i>{{ _textObject[item.varName] }}</i><br></span>
                        {{ item.help }}
                    </small>
                </div>
                <ng-container *ngIf="_textModel.subItems && subItemKeys">
                    <div *ngFor="let subItemKey of subItemKeys" class="form-group form-group-sm">
                        <label>{{ _textModel.subItems[subItemKey].name }}</label>
                        <div *ngFor="let inputText of _textObject[subItemKey] | keyvalue: orderByNumericKey" class="row mb-2">
                            <div class="col-1">#{{ plusOne(inputText.key + '') }}:</div>
                            <div class="col-11">
                                <ng-container *ngFor="let item of _textModel.subItems[subItemKey].items">
                                    <label [for]="item.varName + '_' + language + '_' + inputText.key + '_' + item.varName + componentId"
                                           class="control-label text-sm">{{ item.name }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" [title]="'Translate to ' + language">{{ language }}</span>
                                        </div>
                                        <ng-container *ngIf="item.type === inputType || true">
                                            <input [(ngModel)]="_textObject[subItemKey][inputText.key].lang[language][item.varName]"
                                                   [disabled]="_readonly || item.readonly" (change)="textsChanged()" (keyup)="textsChanged()"
                                                   [id]="item.varName + '_' + language + '_' + inputText.key + '_' + item.varName + componentId"
                                                   [attr.aria-describedby]="'help_' + item.varName + '_' + language + '_' + inputText.key + '_' + item.varName + componentId"
                                                   class="form-control input-sm"
                                                   [placeholder]="_textObject[subItemKey][inputText.key][item.varName] || item.placeholder">
                                        </ng-container>
                                        <span class="input-group-append" [class.hidden]="_readonly || item.readonly">
                                            <button type="button" class="btn btn-info btn-sm" [title]="'Copy default ' + item.name"
                                                    (click)="copySubItemDefaultText(subItemKey, inputText.key, item.varName, language)"><i
                                                    class="far fa-copy"></i></button>
                                        </span>
                                    </div>
                                    <small class="form-text text-muted"
                                           [id]="'help_' + item.varName + '_' + language + '_' + inputText.key + '_' + item.varName + componentId">
                                        <span *ngIf="_textObject[item.varName]">Default: <i>{{ _textObject[subItemKey][inputText.key][item.varName] }}</i><br></span>
                                        {{ item.help }}
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>

    <div class="card-footer" *ngIf="!_readonly">
        <button [disabled]="disableSaveTexts" (click)="saveTexts()" [ngClass]="'btn btn-' + colorTheme">Save {{ textsName }} texts</button>
    </div>
</div>
