export const ON_TRAIL_AWARD_KEY = 'OnTrail';

export const StatusOptionRejected = {
    value: -1,
    text: 'Rejected',
    theme: 'default'
};

export const StatusOptionApplied = {
    value: 0,
    text: 'Applied (New)',
    theme: 'warning'
};

export const StatusOptionBeingProcessed = {
    value: 1,
    text: 'Being processed',
    theme: 'info'
};

export const StatusOptionApproved = {
    value: 2,
    text: 'Approved',
    theme: 'success'
};

export const StatusOptions = [
    StatusOptionRejected,
    StatusOptionApplied,
    StatusOptionBeingProcessed,
    StatusOptionApproved
];

import { Trail } from './trail';

export const FILE_NOT_UPLOADED_ERROR = ' not uploaded';

export interface OnTrailApplication {
    address: string;
    club: string;
    clubCvr: string;
    contractUrl: string;
    deleted?: string;
    difficulty?: string;
    email: string;
    gpxUrl?: string;
    heightMeters?: string;
    imgUrl?: string;
    length?: string;
    name: string;
    note: string;
    ownerEmail: string;
    phone: string;
    start?: {
        lat: string;
        lon: string;
    };
    status: any;
    stop?: {
        lat: string;
        lon: string;
    };
    timestamp: number;
    trackDescription?: string;
    trackHeader?: string;
    trackId?: string;
    trackLocated: string;
    trackName?: string;

    key: string;
    trail: Trail;
}
