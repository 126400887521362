<div class="login-box">
    <div class="login-logo">
        <a [href]="website_url" target="_blank"><img src="assets/images/menu_logo_login.png" alt="Mountainbike United" height="50" width="350"></a>
    </div>
    <div class="card">
        <div class="card-body login-card-body">
            <h4 class="text-center">Administration</h4>
            <p class="login-box-msg">
                Sign in with your Mountainbike United credentials.
            </p>
            <p class="login-box-msg">
                <i>Notice:</i> Use same method as in the app.
            </p>

            <div class="social-auth-links text-center">
                <p>
                    <button (click)="auth.googleLogin()" class="btn btn-block btn-google py-0">
                        <img src="assets/images/g-logo.png" alt="Google" class="mr-2"> Sign in with Google
                    </button>
                    <button (click)="auth.appleLogin()" class="btn btn-block btn-apple">
                        <i class="fab fa-apple mr-2"></i> Sign in with Apple
                    </button>
                    <button (click)="auth.facebookLogin()" class="btn btn-block btn-facebook">
                        <i class="fab fa-facebook-square mr-2"></i> Sign in with Facebook
                    </button>
                </p>
                <p>- OR -</p>
            </div>

            <form action="#" (ngSubmit)="emailLogin()">
                <div id="login-error-info" class="alert alert-warning alert-dismissible" role="alert" *ngIf="errorMessage">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <h5><i class="fa fa-exclamation-triangle"></i> Sign in error</h5>
                    {{ errorMessage }}
                </div>
                <div class="input-group mb-3 has-feedback">
                    <input [(ngModel)]="user_email" name="email" type="email" class="form-control" placeholder="Email">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3 has-feedback">
                    <input [(ngModel)]="user_password" name="password" type="password" class="form-control" placeholder="Password">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!signingIn">
                    <div class="offset-md-8 col-md-4">
                        <button (click)="emailLogin()" type="submit" id="email-login-button" class="btn btn-primary btn-block">Sign In</button>
                    </div>
                </div>
            </form>
            <div class="overlay" *ngIf="signingIn">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
        </div>
    </div>
</div>
