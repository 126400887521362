import { Component, Input, AfterViewChecked } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AdventureService } from '../../firebase-services/adventure.service';

// Interfaces
import { Adventure, AdventureItem } from '../../interfaces/adventure';
import { User, Explorer, ExplorerItemStatus } from '../../interfaces/user';

declare var $: any;
import * as moment from 'moment';

@Component({
    selector: 'app-explorers',
    templateUrl: './explorers.component.html',
    styleUrls: ['./explorers.component.css']
})
export class ExplorersComponent implements AfterViewChecked {
    destroy$: Subject<boolean> = new Subject<boolean>();

    @Input() set adventure(adventure: Adventure) {
        this._adventure = adventure;
        this.init();
    }

    _adventure: Adventure;

    explorerUsers: User[] = null;
    explorers: Explorer[] = null;
    items: AdventureItem[] = null;

    dataTableCreated: boolean = false;
    allIsLoaded: boolean = false;
    editing: boolean = false;
    now: number;

    constructor(
        private adventureService: AdventureService
    ) {
        this.now = moment.now();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($('#explorersDataTable>tbody>tr').length > 0)) {
            $('#explorersDataTable').DataTable({
                'order': [[this.items.length + 1, 'desc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private init() {
        this.explorerUsers = null;
        this.explorers = null;
        this.items = null;
        this.adventureService.getAdventureItems(this._adventure.key)
            .pipe(takeUntil(this.destroy$))
            .subscribe((items) => {
                let _items: AdventureItem[] = [];
                for (var i = 0; i < items.length; i++) {
                    if (items[i].order && items[i].adventurePoints) {
                        _items.push(items[i]);
                    }
                }
                this.items = _items.sort((a, b) => {
                    return a.order - b.order;
                });
                this.getExplorerItemStatuses();
            });
        this.adventureService.getExplorers(this._adventure.key)
            .pipe(takeUntil(this.destroy$))
            .subscribe((users) => {
                this.explorerUsers = users;
                this.getExplorerItemStatuses();
            });
    }

    /**
     * Loads the item statuses for each explorer
     */
    private getExplorerItemStatuses(): void {
        if (this._adventure && this.items && this.explorerUsers && this.allIsLoaded === false) {
            this.adventureService.getExplorersItemStatuses(this._adventure.key, this.items, this.explorerUsers)
                .pipe(takeUntil(this.destroy$))
                .subscribe(updatedExplorers => {
                    this.explorers = updatedExplorers;
                    this.allIsLoaded = true;
                });
        }
    }

    startEditMode(): void {
        this.editing = true;
    }

    registerElement(profileKey: string, explorerElementStatus: ExplorerItemStatus): number {
        var newStatus: ExplorerItemStatus = <ExplorerItemStatus>{};
        if (!explorerElementStatus.startTime) {
            newStatus.startTime = this.now;
        }
        if (explorerElementStatus.type === 2) {
            newStatus.percentageCompleted = explorerElementStatus.percentageCompleted;
        }
        this.adventureService.setAdventureElementStatus(profileKey, this._adventure.key, explorerElementStatus.adventureElementKey, newStatus);
        return this.now;
    }

    deleteElement(profileKey: string, adventureElementKey: string): void {
        this.adventureService.deleteAdventureElementStatus(profileKey, this._adventure.key, adventureElementKey);
    }

    updateView(): void {
        this.init();
    }
}
