<div class="card card-dark">
    <div class="card-header">
        <h3 class="card-title">+&nbsp;<i class="far fa-newspaper"></i> Add News Story</h3>
    </div>

    <div class="card-body">
        <div class="form-group">
            <label for="title">Title (default, en)</label>
            <input [(ngModel)]="newTitle" id="title" aria-describedby="titleHelp" class="form-control" placeholder="News story title"/>
            <small class="form-text text-muted" id="titleHelp">A short title for your news story.</small>
        </div>
    </div>

    <div class="card-footer">
        <button (click)="createNewsStory()" class="btn btn-danger">Create News Story</button>
    </div>
</div>
