import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

// Services
import {AuthService} from '../../core/auth.service';
import {DmoManager, DmoManagerListItem} from "../../interfaces/dmo-manager";
import {TrailAreaService} from "../../firebase-services/trail-area.service";
import {DmoManagerService} from "./dmo-manager.service";
import {Roles} from "../../interfaces/role";
import {UserService} from "../../firebase-services/user.service";
import {RoleService} from "../../core/role.service";


// Interfaces

@Component({
    selector: 'app-dmo-managers',
    templateUrl: './dmo-managers.component.html',
    styleUrls: ['./dmo-managers.component.css']
})
export class DmoManagersComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    dmoManagers: DmoManager[];
    dmoManagerListItems: DmoManagerListItem[];
    state: 0;
    saveRoleDisabled = true;
    selectedUserKey: string;
    selectedUserName: string;
    selectedUserImage: string;
    selectedTrailAreaKey: string;
    selectedTrailAreaName: string;

    constructor(
        public authService: AuthService,
        private dmoManagerService: DmoManagerService,
        private trailAreaService: TrailAreaService,
        private userService: UserService,
        private roleService: RoleService
    ) {
        this.dmoManagerListItems = [];
        this.dmoManagerService.getDmoManagerListItems()
            .pipe(takeUntil(this.destroy$)).subscribe((dmoManagerList) => {
            this.dmoManagerListItems = dmoManagerList;
            for (let dm of dmoManagerList) {
                dm.trailAreas.sort((a,b)=> a.country.localeCompare(b.country))
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onAreaAdded($event: string, userKey: string): Promise<void> {
        return this.dmoManagerService.addTrailAreaToDmoManager(userKey, $event).then(() => {
            return this.trailAreaService.enableAdventures($event);
        });
    }

    onAreaRemoved(userKey: string, areaKey: string): Promise<void> {
        for (let item of this.dmoManagerListItems) {
            if (item.user.userID === userKey && item.trailAreas.length === 1) {
                this.dmoManagerService.removeTrailAreaFromDmoManager(userKey, areaKey).then(() => {
                    return this.roleService.revokeUserRole(userKey, this.roles.DMO_MANAGER)
                        .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            } else {
                return this.dmoManagerService.removeTrailAreaFromDmoManager(userKey, areaKey)
                    .catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }
        }
        return;
    }

    onUserSelected(userKey: string) {
        this.selectedUserKey = userKey;
        this.userService.getUser(userKey)
            .pipe(take(1)).subscribe((user) => {
            this.selectedUserName = user.name;
            this.selectedUserImage = user.userPicture;
        });
        if (this.selectedTrailAreaKey) this.saveRoleDisabled = false;
    }

    onAreaSelected(areaKey: string) {
        this.selectedTrailAreaKey = areaKey;
        this.trailAreaService.getTrailArea(areaKey)
            .pipe(take(1)).subscribe((area) => this.selectedTrailAreaName = area.name);
        if (this.selectedUserKey) this.saveRoleDisabled = false;
    }

    assignDmoManager(): void {
        this.roleService.assignUserRole(this.selectedUserKey, this.roles.DMO_MANAGER, this.authService.user.userID).then(() => {
            return this.dmoManagerService.addTrailAreaToDmoManager(this.selectedUserKey, this.selectedTrailAreaKey).then(() => {
                this.state++
                this.resetAssignDmoManager();
            }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
        }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
    }

    resetAssignDmoManager() {
        this.selectedUserKey = '';
        this.selectedUserName = '';
        this.selectedUserImage = '';
        this.selectedTrailAreaKey = '';
        this.selectedTrailAreaName = '';
    }
}
